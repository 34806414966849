import moduleService from '@services/module';
import { getCurrentInstance, onBeforeMount, reactive, ref, Ref, nextTick, watch } from 'vue';
import { useField, useForm } from 'vee-validate';
import router from '@/router';

import api from '@services/api';
import authService from '@/services/authentication';

import loginModel from '@/models/authentication/login';

import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

import { useBrandStore } from '@/store/brandStore';
import { useLoadingStore } from '@/store/loadingStore';
import { usePermissionStore } from '@/store/permissionStore';
import { useUserInfoStore } from '@/store/authStore';
import { useModuleStore } from '@/store/moduleStore';

import { storeToRefs } from 'pinia';

import useLoading from '@/views/components/loading/hooks/useLoading';
import { getLanguageDisplayModalError } from '@/utils/useLanguageDisplay';

export default function useLoginAction() {
  const brandStore = useBrandStore();
  const loadingStore = useLoadingStore();
  const permissionStore = usePermissionStore();
  const userInfoStore = useUserInfoStore();
  const moduleStore = useModuleStore();

  const { appLoadingConfig } = storeToRefs(loadingStore);
  const { mfa_modal, mfa_status, mfa_token, credential_valid } = storeToRefs(userInfoStore);

  const vm = getCurrentInstance()?.proxy;

  const { fetchLoginModel, fetchBrandListModel, fetchSubmitBrandModel, fetchResetPasswordModel, fetchResetForgetPassword } = loginModel();

  const { openDefaultErrorModal, openSuccessModal, openErrorModal } = useValidationModal();

  const { isLoading } = useLoading();

  const selectBrandForm: Authentication.SelectBrandForm = reactive({
    accessToken: '',
    refreshToken: '',
  });
  const hasBrandList = ref(false);
  const firstLoginAccount = ref(false);
  const showPassWord = ref(false);
  const isLoadingCDP: Ref<boolean> = ref(false);
  const isLoadingBtn: Ref<boolean> = ref(false);

  const brandList: Ref<any[]> = ref([]);

  const respLoginSession: Ref<Authentication.RespLogin | null> = ref(null);

  const {
    values: loginForm,
    errors: loginFormErrors,
    handleSubmit: onLoginFormSubmit,
  } = useForm<Authentication.LoginForm>({
    validationSchema: {
      email: 'required',
      password: 'required',
    },
  });

  useField('email', undefined, { label: 'auth.email' });
  useField('password', undefined, { label: 'auth.password' });

  const togglePasswordVisible = (val: boolean) => {
    return (showPassWord.value = val);
  };

  const handlerLoginSuccess = async (resp: Record<string, any>) => {
    loginForm.brandRef = resp.brand_ref || loginForm.brandSelected.brand_ref;
    let tempBrand: Array<any> = [];
    localStorage.removeItem('userInfo');
    authService.setLocalBrandData('');
    if (authService.getLocalBrandData()) {
      tempBrand = [
        ...JSON.parse(authService.getLocalBrandData()!)?.filter((o: Record<string, any>) => {
          return o.brandRef != loginForm.brandRef;
        }),
      ];
    }
    tempBrand.push({
      brandRef: loginForm.brandRef,
      brandData: loginForm.brandSelected,
      accessToken: resp.access_token,
      refreshToken: resp.refresh_token,
    });
    const userInfo = {
      first_name: resp.first_name,
      last_name: resp.last_name,
      picture_url: resp.picture_url,
      email: resp.email,
      role_en: resp.role_en,
      role_th: resp.role_th,
    };
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    localStorage.setItem('firstLogin', 'true');
    authService.setLocalBrandData(JSON.stringify(tempBrand));

    appLoadingConfig.value.isOpen = true;
    router.push('/').catch(() => {});

    await moduleService.fetchModuleVersions().then((res) => {
      //state
      moduleStore.setModuleVersions(res);
    });

    permissionStore.fetchAndAssignPermissions().then(() => {
      setTimeout(() => {
        appLoadingConfig.value.isOpen = false;
      }, 300);
    });
  };

  const login = onLoginFormSubmit((values, ctx) => {
    try {
      if (!loginForm.email || !loginForm.password) return;
      isLoadingBtn.value = true;
      fetchLoginModel.payload.username = loginForm.email;
      fetchLoginModel.payload.password = loginForm.password;
      fetchLoginModel.payload.brand_ref = router.currentRoute.value.query.brandRef || '';
      api
        .apiRequest(fetchLoginModel)
        .then((response) => {
          const res: Authentication.RespLogin = response.data;
          respLoginSession.value = res;
          mfa_status.value = res.mfa_status;
          mfa_token.value = res.access_token;
          mfa_modal.value = true;
          // await getBrandList(res.access_token, res.refresh_token);
          // if (response.status === 'success') {
          //   if (res.action == 'Home') {
          //     sessionStorage.setItem('brandSessionData', res.brand_ref);
          //     return handlerLoginSuccess(res);
          //   } else if (res.action == 'ResetPassword') {
          //     firstLoginAccount.value = true;
          //     router.replace({
          //       name: 'first-reset-password',
          //       query: {
          //         access_token: res.access_token,
          //         refresh_token: res.refresh_token,
          //         action: res.action,
          //         email: loginForm.email,
          //       },
          //     });
          //   } else {
          //     if (brandList.value.length === 1) {
          //       sessionStorage.setItem('brandSessionData', loginForm.brandSelected.brand_ref as string);
          //       return submitBrand(res.access_token, res.brand_ref);
          //     } else {
          //       hasBrandList.value = true;
          //       router.replace({
          //         name: 'select-brand',
          //         query: {
          //           access_token: res.access_token,
          //           refresh_token: res.refresh_token,
          //         },
          //       });
          //     }
          //   }
          // }
        })
        .catch((err) => {
          openDefaultErrorModal(err); //delete error
        })
        .finally(() => {
          isLoadingBtn.value = false;
        });
    } catch (e) {
      ctx.setErrors({
        email: 'E-mail is invalid',
        password: 'Password is invalid',
      });
    }
  });

  const handleLoginSession = async () => {
    sessionStorage.removeItem('segment_source_detail');
    if (respLoginSession.value != null) {
      await getBrandList(respLoginSession.value.access_token, respLoginSession.value.refresh_token);
      if (respLoginSession.value.action == 'Home') {
        sessionStorage.setItem('brandSessionData', respLoginSession.value.brand_ref);
        return handlerLoginSuccess(respLoginSession.value);
      } else if (respLoginSession.value?.action == 'ResetPassword') {
        firstLoginAccount.value = true;
        router.replace({
          name: 'first-reset-password',
          query: {
            access_token: respLoginSession.value?.access_token,
            refresh_token: respLoginSession.value?.refresh_token,
            action: respLoginSession.value?.action,
            email: loginForm.email,
          },
        });
      } else {
        if (brandList.value.length === 1) {
          sessionStorage.setItem('brandSessionData', loginForm.brandSelected.brand_ref as string);
          return submitBrand(respLoginSession.value.access_token, respLoginSession.value.brand_ref);
        } else {
          hasBrandList.value = true;
          router.replace({
            name: 'select-brand',
            query: {
              access_token: respLoginSession.value.access_token,
              refresh_token: respLoginSession.value.refresh_token,
            },
          });
        }
      }
    }
  };

  const getBrandList = (accessToken: string, refreshToken: string) => {
    return new Promise((resolve, reject) => {
      selectBrandForm.accessToken = accessToken;
      selectBrandForm.refreshToken = refreshToken;
      fetchBrandListModel.payload.access_token = accessToken;
      api
        .apiRequest(fetchBrandListModel)
        .then((response) => {
          const searchParams = new URLSearchParams(window.location.search);
          const brandRef: string = searchParams.get('brandRef')!;
          const selectedBrand = response.data.find((o: Record<string, any>) => {
            return o.brand_ref === brandRef;
          });
          const oneBrand = response.data[0];
          loginForm.brandSelected = selectedBrand || oneBrand;
          brandList.value = response.data;
          brandStore.SET_BRAND_LIST(brandList.value);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const onSubmitBrand = () => {
    submitBrand();
  };

  const submitBrand = (accessToken?: string, brandRef?: string) => {
    isLoadingBtn.value = true;
    if (loginForm.brandSelected) {
      fetchSubmitBrandModel.payload.access_token = selectBrandForm.accessToken;
      fetchSubmitBrandModel.payload.brand_ref = loginForm.brandSelected?.brand_ref;
    }

    if (!loginForm.brandSelected) {
      fetchSubmitBrandModel.payload.access_token = accessToken;
      fetchSubmitBrandModel.payload.brand_ref = brandRef;
    }

    api
      .apiRequest(fetchSubmitBrandModel)
      .then((response) => {
        const res: Authentication.RespSubmitBrand = response.data;
        sessionStorage.setItem('brandSessionData', res.brand_ref);
        sessionStorage.removeItem('segment_source_detail');
        handlerLoginSuccess(res);
      })
      .catch((err) => {
        openDefaultErrorModal(err); //delete error
      })
      .finally(() => {
        isLoadingBtn.value = false;
      });
  };

  const checkLoginStatus = () => {
    // statement for check contition before login
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('email')) {
      loginForm.email = searchParams.get('email')!;
    }
  };

  const goBackToLogin = () => {
    router.push({ name: 'auth-login' }).then(() => {});
    hasBrandList.value = false;
  };

  const onResetPassword = (password: string) => {
    isLoading(true);

    // 1st reset password
    if (router.currentRoute.value.query.action == 'ResetPassword') {
      const tempBrand: Array<any> = [];
      tempBrand.push({
        accessToken: router.currentRoute.value.query.access_token,
        refreshToken: router.currentRoute.value.query.refresh_token,
      });
      authService.setLocalBrandData(JSON.stringify(tempBrand));

      fetchResetPasswordModel.payload.password = password;

      api
        .apiRequest(fetchResetPasswordModel)
        .then((response) => {
          const res: Authentication.RespResetPassword = response.data;
          if (response.status == 'success') {
            isLoading(false);
            if (brandList.value.length === 1) {
              sessionStorage.setItem('brandSessionData', loginForm.brandSelected.brand_ref as string);
              return submitBrand(res.access_token, res.brand_ref);
            } else {
              hasBrandList.value = true;
              openSuccessModal('ทำรายการสำเร็จ', '', '', undefined, () => onRedirectSuccessCase('reset'));
            }
          }
        })
        .catch((err) => {
          openErrorModal(
            err.data.error.locale[getLanguageDisplayModalError()].title as string,
            err.data.error.locale[getLanguageDisplayModalError()].message as string,
          );
        });
    } else {
      // forget password
      fetchResetForgetPassword.payload.Token = router.currentRoute.value.query.token;
      fetchResetForgetPassword.payload.tx_Reference = router.currentRoute.value.query.tx_Reference;
      fetchResetForgetPassword.payload.New_Password = password;

      api
        .apiRequest(fetchResetForgetPassword)
        .then((response) => {
          if (response.status == 'success') {
            isLoading(false);
            openSuccessModal('ทำรายการสำเร็จ', '', '', undefined, () => onRedirectSuccessCase('forget'));
          }
        })
        .catch((err) => {
          isLoading(false);
          if (err.data.error.message == 'Token not valid or expired') {
            onRedirectSuccessCase('expired');
            openErrorModal(
              err.data.error.locale[getLanguageDisplayModalError()].title as string,
              err.data.error.locale[getLanguageDisplayModalError()].message as string,
            );
          }
        });
    }
  };

  const onRedirectSuccessCase = (type: string) => {
    if (type == 'forget') {
      router.push({
        path: '/login',
      });
      firstLoginAccount.value = false;
    } else if (type == 'expired') {
      router.push({
        path: '/expire-reset-password',
      });
      firstLoginAccount.value = false;
    } else {
      router.replace({
        name: 'select-brand',
        query: {
          access_token: router.currentRoute.value.query.access_token,
          refresh_token: router.currentRoute.value.query.refresh_token,
        },
      });
      firstLoginAccount.value = false;
    }
  };

  onBeforeMount(async () => {
    if (router.currentRoute.value.name == 'select-brand') {
      hasBrandList.value = true;
      await getBrandList(router.currentRoute.value.query.access_token as string, router.currentRoute.value.query.refresh_token as string);
    } else if (router.currentRoute.value.name == 'first-reset-password') {
      firstLoginAccount.value = true;
    } else {
      checkLoginStatus();
    }
  });

  watch(credential_valid, async (newValue) => {
    await nextTick(() => {
      // success from mfa
      if (!mfa_modal.value && credential_valid.value) {
        // มาจากจังหวะ mfa เสร็จสิ้นจะพากลับเข้า Flow เดิม
        handleLoginSession();
      }
    });
  });

  return {
    loginForm,
    loginFormErrors,
    hasBrandList,
    brandList,
    showPassWord,
    isLoadingCDP,
    isLoadingBtn,
    firstLoginAccount,
    login,
    getBrandList,
    goBackToLogin,
    submitBrand,
    togglePasswordVisible,
    onSubmitBrand,
    onResetPassword,
  };
}
