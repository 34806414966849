export default {
  data_relationship: 'Data Relationship',
  relationship_overall: 'Relationship Overall',
  detail_data_relationship_list: 'Manage data relationships using different sources.',
  overall_diagram: 'Overall Diagram',
  button_overall_diagram: 'Overall Diagram',
  button_create_relation: 'Relationship Matching​',
  diagram_relation: 'Diagram Relation',
  selected_relationship_pair: 'Selected relationship pair',
  source: 'Source',
  source_pair: 'Source pair to pair',
  relationship_pair: 'Relationship pair',
  existing_relationship: 'There is already a relationship between these 2 tables',
  step: 'Step',
  select_source: 'Select source',
  select_data_type: 'Select data type:',
  select_desired_source: 'Select the desired source.',
  from: 'From',
  to: 'To',
  data_type: 'Data type',
  select_columns_to_pair: 'Select columns to pair',
  please_select_source_and_data_type: 'Please select source and data type',
  to_select_columns_to_pair: 'to select columns to pair',
  validate_relation: 'Validate Relation',
  cancel: 'Cancel',
  apply: 'Apply',
  relationship_pair_completed: 'Relationship pair completed',
  creating_pair_relationships: 'Creating pair relationships',
  edit_pair_relationships: 'Edit pair relationships',
  get_relation_detail: 'Load relation detail',
  //column table
  left_source_name: 'From : source',
  left_table_name: 'Table name',
  left_column_concat: 'Table (column)',
  right_source_name: 'To : source',
  right_table_name: 'Table name',
  right_column_concat: 'Table (column)',
  status: 'Status',
  //status
  active: 'Active',
  inactive: 'Inactive',
  //validate-exist-relation
  validate_exist_relation: 'Validate Exist Relation',
  validate_table_different: 'Each table must be different.',
  validate_table_found: 'Table not found',
  validate_already_relationship: 'Already in a relationship',
};
