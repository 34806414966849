import { ref, Ref, reactive, onMounted, watch, markRaw, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';

import api from '@services/api';
import router from '@/router';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import useLoading from '@/views/components/loading/hooks/useLoading';
import useConvertDate from '@/utils/hooks/useConvertDate';

import AllFolderModel from '@/models/segment/taballsegment';
import SmsIcon from '@assets/icons/base/button/SmsIcon.vue';
import EmailIcon from '@assets/icons/base/button/EmailIcon.vue';
import LineIcon from '@assets/icons/base/button/LineIcon.vue';
import Edit from '@/assets/icons/modules/segment/all-segment/Edit.vue';
import Delete from '@/assets/icons/modules/segment/all-segment/Delete.vue';

import ability from '@/libs/acl/ability';

interface Props {
  selectedSource: Segment.Datasource;
  segmentConfig: Segment.Config;
}

export default function useAllFolder(props: Props) {
  const { openSuccessModal, openDefaultErrorModal } = useValidationModal();
  const { isLoading } = useLoading();
  const { convertUTCToDeviceTimeZone } = useConvertDate();

  const { fetchAllFolderListModel, createAllFolderListModel, deleteAllFolderListModel } = AllFolderModel();
  const { t } = useI18n();

  const loading: Ref<boolean> = ref(false);
  const dataList: Ref<Segment.AllFolder.Folder[]> = ref([]);
  const dataListFilter: Ref<Segment.AllFolder.Folder[]> = ref([]);
  const isOpenModalCreate: Ref<boolean> = ref(false);
  const isOpenModalDelete: Ref<boolean> = ref(false);
  const errorCount: Ref<number> = ref(0);

  const actionList: ActionList.Action[] = [];

  const itemDropDownShare = ref([
    { title: 'SMS', icon: markRaw(SmsIcon) },
    { title: 'E-mail', icon: markRaw(EmailIcon) },
    { title: 'LINE', icon: markRaw(LineIcon) },
  ]);
  const textSearch: Ref<string> = ref('');

  const onClickShare = () => {
    console.log('onClickShare');
  };

  const fields: Ref<BaseTable.TableColumn[]> = ref([
    { key: 'name', label: t('segment.all_folder.table.folder_name') },
    { key: 'total_segment', label: t('segment.all_folder.table.total_segment') },
    { key: 'updated_by_name', label: t('segment.all_folder.table.update_by'), sortable: true },
    { key: 'updated_dt', label: t('segment.all_folder.table.last_update'), sortable: true },
    { key: 'options', label: '' },
  ]);

  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 20,
    totalRows: 0,
  });

  const dataForm = reactive({
    payload: {
      name: '',
      folder_id: 0,
    },
  });

  const onSort = (sortedColumn: { key: string; direction: string }) => {
    const sortBy = sortedColumn.key;
    const sortDesc = sortedColumn.direction;

    dataList.value.sort((a: any, b: any) => {
      if (sortDesc == 'ASC') {
        document.getElementById('sorting-' + sortedColumn.key)?.classList.add('asc');
        loading.value = true;
        setTimeout(() => {
          loading.value = false;
        }, 1000);
        return a[sortBy] < b[sortBy] ? -1 : 1;
      } else if (sortDesc == 'DESC') {
        document.getElementById('sorting-' + sortedColumn.key)?.classList.add('desc');
        loading.value = true;
        setTimeout(() => {
          loading.value = false;
        }, 1000);
        return a[sortBy] > b[sortBy] ? -1 : 1;
      } else {
        fetchDataAllFolder();
        return a[sortBy] == b[sortBy] ? -1 : 1;
      }
    });

    dataListFilter.value = dataList.value;
    return dataListFilter.value;
  };

  const fetchDataAllFolder = () => {
    loading.value = true;
    fetchAllFolderListModel.payload.root_tableId = props.selectedSource.tableid;

    api
      .apiRequest(fetchAllFolderListModel)
      .then((response) => {
        dataList.value = response.data.payload;
        pagination.totalRows = response.data.total_record;
        dataListFilter.value = dataList.value;
        if (router.currentRoute.value.query.page != undefined) {
          pagination.currentPage = Number(router.currentRoute.value.query.page);
          router.replace({
            path: router.currentRoute.value.fullPath,
            query: { ...router.currentRoute.value.query, page: undefined },
          });
        }
      })
      .catch((err) => {
        ++errorCount.value;
        if (err.status != 422 && errorCount.value < 5) {
          retryPayload();
        } else {
          openDefaultErrorModal(err);
        }
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const retryPayload = () => {
    fetchDataAllFolder();
  };

  const onSubmitForm = () => {
    isLoading(true);
    createAllFolderListModel.payload.Root_TableId = props.selectedSource.tableid;
    createAllFolderListModel.payload.name = dataForm.payload.name;
    createAllFolderListModel.payload.group_type = props.selectedSource.group_type;

    api
      .apiRequest(createAllFolderListModel)
      .then(() => {
        isOpenModalCreate.value = false;
      })
      .catch((err) => {
        isOpenModalCreate.value = false;
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isLoading(false);
        fetchDataAllFolder();
      });
  };

  const onDelete = () => {
    isLoading(true);
    deleteAllFolderListModel.payload.ref_type = 1;
    deleteAllFolderListModel.payload.referenceId = dataForm.payload.folder_id;

    api
      .apiRequest(deleteAllFolderListModel)
      .then(() => {
        isOpenModalDelete.value = false;
      })
      .catch((err) => {
        isOpenModalDelete.value = false;
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isLoading(false);
        openSuccessModal(
          'ลบโฟลเดอร์สำเร็จ',
          '',
          '',
          () => fetchDataAllFolder(),
          () => fetchDataAllFolder(),
        );
      });
  };

  const toFolderInside = (segment: Segment.AllFolder.Folder) => {
    return router.push({
      path: 'folder-inside',
      query: {
        ...router.currentRoute.value.query,
        folderId: segment.folderid,
        folderName: segment.name,
        page: pagination.currentPage,
      },
    });
  };

  const onSelectAction = (action: ActionList.Action, segment: Segment.AllFolder.Folder) => {
    switch (action.key) {
      case 'edit':
        return router.push({
          path: 'folder-inside',
          query: {
            ...router.currentRoute.value.query,
            folderId: segment.folderid,
            folderName: segment.name,
            page: pagination.currentPage,
          },
        });

      case 'delete':
        dataForm.payload.folder_id = segment.folderid;
        dataForm.payload.name = segment.name || '';
        isOpenModalDelete.value = true;

      default:
        return;
    }
  };

  const onOpenModal = () => {
    dataForm.payload.name = '';
    isOpenModalCreate.value = true;
  };

  const onCloseModal = () => {
    if (isOpenModalCreate.value) {
      isOpenModalCreate.value = false;
    } else if (isOpenModalDelete.value) {
      isOpenModalDelete.value = false;
    }
  };

  const convertDateTable = (date: string) => {
    return convertUTCToDeviceTimeZone(date);
  };

  const onChangeSearch = () => {
    const filterList: any = [];
    if (textSearch.value == '') {
      dataListFilter.value = dataList.value;
      pagination.totalRows = dataList.value.length;
    } else {
      if (dataList.value.length > 0) {
        dataList.value.map((column) => {
          if (
            column.name.toLowerCase().indexOf(textSearch.value.toLowerCase()) > -1 ||
            column.total_segment.toString().toLowerCase().indexOf(textSearch.value) > -1 ||
            column.updated_by_name.toLowerCase().indexOf(textSearch.value) > -1
          ) {
            filterList.push({
              name: column.name,
              folderid: column.folderid,
              total_segment: column.total_segment,
              updated_by_name: column.updated_by_name,
              updated_dt: column.updated_dt,
            });
          }
        });
      }
      pagination.totalRows = filterList.length;
      dataListFilter.value = filterList;
    }
  };

  function checkPermissionSegment() {
    if (ability.can('portal-cdp', 'cdp-portal-segment-folder')) {
      actionList.push({
        key: 'edit',
        tooltip: t('segment.all_folder.action.edit'),
        icon: Edit,
        tooltipColor: '#007FFF',
        tooltipBackgroundColor: '#BBDBFF',
      });
      actionList.push({
        key: 'delete',
        tooltip: t('segment.all_folder.action.delete'),
        icon: Delete,
        tooltipColor: '#007FFF',
        tooltipBackgroundColor: '#BBDBFF',
      });
    }
  }

  watch(
    () => props.selectedSource,
    () => {
      loading.value = true;
      fetchDataAllFolder();
    },
  );

  watch(
    () => pagination.currentPage,
    () => {
      if (textSearch.value.length == 0) return fetchDataAllFolder();
      else return onChangeSearch();
    },
  );

  watch(
    () => textSearch.value,
    () => {
      pagination.currentPage = 1;
      onChangeSearch();
    },
  );

  onBeforeMount(() => {
    // check permission
    checkPermissionSegment();
  });

  onMounted(() => {
    setTimeout(() => {
      fetchDataAllFolder();
    }, 10);
  });

  return {
    loading,
    fields,
    dataListFilter,
    pagination,
    isOpenModalCreate,
    isOpenModalDelete,
    dataForm,
    itemDropDownShare,
    textSearch,
    actionList,
    onClickShare,
    onSubmitForm,
    onOpenModal,
    onCloseModal,
    onDelete,
    onSort,
    onSelectAction,
    toFolderInside,
    convertDateTable,
  };
}
