import { onBeforeMount, ref, watch, reactive, Ref, onMounted } from 'vue';

import router from '@/router';
import api from '@services/api';

import { useI18n } from 'vue-i18n';

interface Props {
  dataTable: Record<string, any>[];
  isPreviewActive: number;
  isOpenModal: boolean;
  isLoading: boolean;
}
enum PageActive {
  Comfirm = 4,
}

export default function useCheckCouponList(props: Props) {
  const { t } = useI18n();
  const loading: Ref<boolean> = ref(true);
  const dataTablePreview: Ref<Record<string, any>[]> = ref([]);

  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
  });

  const fields: Ref<BaseTable.TableColumn[]> = ref([
    { key: 'code', label: t('dc.code') },
    { key: 'detail', label: t('dc.detail') },
    { key: 'link', label: t('dc.link') },
    { key: 'expire_dt', label: t('dc.expireDT') },
  ]);

  const fetchDataTableProps = () => {
    dataTablePreview.value = props.dataTable;
  };

  onMounted(() => {
    if (props.isPreviewActive == PageActive.Comfirm) {
      fetchDataTableProps();
    }
  });

  watch(
    () => props.isOpenModal,
    () => {
      if (!props.isOpenModal) {
        dataTablePreview.value = [];
        loading.value = true;
      }
    },
    { deep: true },
  );

  watch(
    () => props.isLoading,
    () => {
      if (props.isLoading) {
        loading.value = true;
      } else {
        loading.value = false;
      }
    },
    { deep: true },
  );

  return { dataTablePreview, fields, loading, pagination };
}
