import { ref, reactive, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
export default function usePreviewTable() {
  const rowSort = ref(false);
  const columnSort = ref(true);
  const loading = ref(false);
  const isSelectAll = ref(false);

  const { t } = useI18n();
  const fields = ref([
    { key: 'seq', label: t('communication.No') },
    { key: 'name', label: 'Inventory Name', sortable: true },
    { key: 'detail', label: 'Detail', sortable: true },
    { key: 'alt_reference', label: t('communication.reference'), sortable: true },
    { key: 'test1', label: 'test1', sortable: true },
    { key: 'test2', label: 'test2', sortable: true },
    { key: 'test3', label: 'test3' },
    { key: 'test4', label: 'test4' },
    { key: 'test5', label: 'test5' },
  ]);

  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 21,
    totalRows: 0,
  });

  const testList: Ref<Array<any>> = ref([]);

  const generateList = () => {
    for (let i = 0; i < 200; i++) {
      testList.value.push({
        seq: i + 1,
        name: 'fas',
        detail: 'asdfsd',
        alt_reference: 'test',
        test1: 'teeeeeee',
        test2: 'teeeeeee',
        test3: 'teeeeeee',
        test4: 'teeeeeee',
        test5: 'teeeeeee',
      });
    }

    pagination.totalRows = testList.value.length;
  };

  const handleRowChange = (list: Array<any>) => {};

  const handleColumnChange = (col: Array<any>) => {
    fields.value = col;
  };

  const onSort = (sortedColumn: { key: string; direction: string }) => {};

  const onRowClick = (row: Record<string, any>) => {};

  const onSelectAllRow = (rows: Record<string, any>[]) => {};
  const onUnSelectAllRow = (rows: Record<string, any>[]) => {};

  const onRowSelect = (selectedRows: Record<string, any>[]) => {};

  return {
    fields,
    testList,
    rowSort,
    columnSort,
    pagination,
    loading,
    isSelectAll,
    generateList,
    handleRowChange,
    handleColumnChange,
    onSort,
    onRowClick,
    onSelectAllRow,
    onUnSelectAllRow,
    onRowSelect,
  };
}
