import { ref, Ref, reactive, onBeforeMount, watch } from 'vue';
import apiService from '@/services/api';

import router from '@/router';
import { RouteLocationRaw } from 'vue-router';
import api from '@services/api';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import AllFolderModel from '@/models/segment/taballsegment';
import segmentModel from '@/models/segment/segment';
import useLoading from '@/views/components/loading/hooks/useLoading';
import useConvertDate from '@/utils/hooks/useConvertDate';

import Edit from '@/assets/icons/modules/segment/all-segment/Edit.vue';
import Copy from '@/assets/icons/modules/segment/all-segment/Copy.vue';
import Delete from '@/assets/icons/modules/segment/all-segment/Delete.vue';

import { useI18n } from 'vue-i18n';

import ability from '@/libs/acl/ability';
import { getLanguageDisplayModalError } from '@/utils/useLanguageDisplay';
interface Props {
  selectedSource: Segment.Datasource;
}

export default function useSegmentList(props: Props) {
  const { isLoading } = useLoading();
  const { convertUTCToDeviceTimeZone } = useConvertDate();
  const { fetchSegmentListModel, deleteAllFolderListModel, renameAllFolderListModel } = AllFolderModel();
  const { openDefaultErrorModal, openWarningModal, openSuccessModal, openErrorModal } = useValidationModal();
  const { fetchDisplayColumnModel, fetchCountRealtimeModel } = segmentModel();
  const openAllFolder: Ref<boolean> = ref(false);
  const isModalEditName: Ref<boolean> = ref(false);
  const loading: Ref<boolean> = ref(false);
  const dataList: Ref<Segment.Segment[]> = ref([]);
  const dataListFilter: Ref<Segment.Segment[]> = ref([]);
  const segmentListPerPage: Ref<number[]> = ref([]);
  const segmentFolderName: Ref<string> = ref(String(router.currentRoute.value.query.folderName));
  const textSearch: Ref<string> = ref('');
  const { t } = useI18n();

  const fields = ref([
    { key: 'name', label: t('segment.all_segment.table.segment_name') },
    { key: 'segment_size', label: t('segment.all_segment.table.segment_size'), sortable: true },
    { key: 'campaign_count', label: t('segment.all_segment.table.campaign'), sortable: true },
    { key: 'updated_by_name', label: t('segment.all_segment.table.update_by'), sortable: true },
    { key: 'updated_dt', label: t('segment.all_segment.table.fast_update'), sortable: true },
    { key: 'type', label: t('segment.all_segment.table.segment_type') },
    { key: 'options', label: '' },
  ]);

  const createSegmentModal = reactive({
    isOpen: false,
    isLoading: false,
    columnList: [] as DynamicTable.Column[],
    dataSource: props.selectedSource,
  });

  const actionList: ActionList.Action[] = [];

  const dataFolder: Ref<string | any> = ref(router.currentRoute.value.query);

  const urlRouter: Ref<RouteLocationRaw> = ref({
    path: '/segment/data-segment/folder-inside',
    query: {
      tab: router.currentRoute.value.query.tab,
      folderId: router.currentRoute.value.query.folderId,
      folderName: router.currentRoute.value.query.folderName,
      subtab: router.currentRoute.value.query.subtab,
      page: router.currentRoute.value.query.page,
    },
  });

  const breadcrumbList: Ref<BreadCrumbList.BreadCrumb[]> = ref([
    {
      key: 1,
      url: urlRouter.value,
      name: dataFolder.value.folderName,
    },
  ]);

  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 20,
    totalRows: 0,
  });

  const isDialogSegmentOpen: Ref<boolean> = ref(false);
  const dataSourceDisplaySave: Ref<AllDataSegment.DatasourceDisplay> = ref(null!);
  const selectSegmentRow: Ref<Segment.Segment> = ref(null!);
  const segmentSelectForModal = reactive({
    segmentid: 0,
    root_tableid: 0,
    segment_size: 0,
    name: '',
    ref_segmentid: 0,
    folder_id: 0,
    result_table: '',
    result_type: 0,
    dest_ds_tableid: 0,
    result_ref_tableid: 0,
  });

  const fetchCountRealtime = async () => {
    fetchCountRealtimeModel.payload.SegmentIdList = segmentListPerPage.value;
    await apiService
      .apiRequest(fetchCountRealtimeModel)
      .then(() => {})
      .catch((err) => openDefaultErrorModal(err));
  };

  const fetchListSegment = async (showLoading?: boolean) => {
    fetchSegmentListModel.payload.ref_type = 1;
    fetchSegmentListModel.payload.reference = router.currentRoute.value.query.folderId;
    if (showLoading) loading.value = true;
    await api
      .apiRequest(fetchSegmentListModel)
      .then((response) => {
        dataList.value = response.data.segment_list;
        dataListFilter.value = response.data.segment_list;

        const totalPages = Math.ceil(dataListFilter.value.length / pagination.perPage);

        if (pagination.currentPage > 1 && pagination.currentPage - totalPages == 1) {
          pagination.currentPage--;
        }
        pagination.totalRows = response.data.segment_list.length;
        if (router.currentRoute.value.query.page != undefined) {
          pagination.currentPage = Number(router.currentRoute.value.query.page);
          router.replace({
            path: router.currentRoute.value.fullPath,
            query: { ...router.currentRoute.value.query, page: undefined },
          });
        }
        const startIndex = pagination.perPage * (pagination.currentPage - 1);
        const endIndex = pagination.perPage * pagination.currentPage;

        segmentListPerPage.value = dataListFilter.value?.slice(startIndex, endIndex).map((segment) => segment.segmentid) || [];

      })
      .catch((error) => {
        openDefaultErrorModal(error);
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const fetchDisplayColumn = () => {
    fetchDisplayColumnModel.payload.TableId = props.selectedSource.tableid;
    createSegmentModal.isLoading = true;

    api
      .apiRequest(fetchDisplayColumnModel)
      .then((response) => {
        const columnList = response.data.payload.columns;
        createSegmentModal.columnList = columnList;
      })
      .catch((err) => {
        openDefaultErrorModal(
          err,
          () => (createSegmentModal.isOpen = false),
          () => (createSegmentModal.isOpen = false),
        );
      })
      .finally(() => (createSegmentModal.isLoading = false));
  };

  const toSegmentDetail = (data: Segment.Segment) => {
    return router.push({
      path: '/segment/data-segment/segment-detail',
      query: {
        ...router.currentRoute.value.query,
        segmentId: data.segmentid,
        segmentName: data.name,
        type: data.type,
      },
    });
  };

  const onSelectItem = (tag: ActionList.Action, data: Segment.Segment) => {
    if (tag.key == 'edit') {
      router.push({
        path: '/segment/data-segment/segment-detail',
        query: {
          ...router.currentRoute.value.query,
          segmentId: data.segmentid,
          segmentName: data.name,
          type: data.type,
        },
      });
    } else if (tag.key == 'delete') {
      openWarningModal('ลบ Segment', `ต้องการลบ Segment ${data.name} หรือไม่`, () => onDeleteSegment(data.segmentid));
    } else if (tag.key == 'copy') {
      onCloneAction(data);
    }
  };

  const onOpenModalEditName = () => {
    isModalEditName.value = true;
  };
  const onCloseModalEditName = () => {
    isModalEditName.value = false;
  };

  const onEditNameFolder = (val: string) => {
    isLoading(true);
    renameAllFolderListModel.payload.ref_type = 1;
    renameAllFolderListModel.payload.reference = Number(router.currentRoute.value.query.folderId);
    renameAllFolderListModel.payload.name = val;

    api
      .apiRequest(renameAllFolderListModel)
      .then(() => {
        isLoading(false);
        openSuccessModal(
          'แก้ไขโฟลเดอร์สำเร็จ',
          '',
          '',
          () => redirectPath(val),
          () => redirectPath(val),
        );
      })
      .catch((err) => {
        isLoading(false);
        openErrorModal(err.data.error.locale[getLanguageDisplayModalError()].title, err.data.error.locale[getLanguageDisplayModalError()].message);
      });
  };

  const redirectPath = (val: string) => {
    router.push({
      path: 'folder-inside',
      query: { ...router.currentRoute.value.query, folderId: Number(router.currentRoute.value.query.folderId), folderName: val },
    });
    segmentFolderName.value = val;
    isModalEditName.value = false;
  };

  const onDeleteSegment = (segmentid: number) => {
    isLoading(true);
    deleteAllFolderListModel.payload.ref_type = 2;
    deleteAllFolderListModel.payload.referenceId = segmentid;

    api
      .apiRequest(deleteAllFolderListModel)
      .then(() => {
        isLoading(false);
        openSuccessModal(
          'ลบรายการสำเร็จ',
          '',
          '',
          () => fetchListSegment(),
          () => fetchListSegment(),
        );
      })
      .catch((error) => {
        openDefaultErrorModal(error);
      });
  };

  const onSort = (sortedColumn: { key: string; direction: string }) => {
    const sortBy = sortedColumn.key;
    const sortDesc = sortedColumn.direction;

    dataList.value.sort((a: any, b: any) => {
      if (sortDesc == 'ASC') {
        document.getElementById('sorting-' + sortedColumn.key)?.classList.add('asc');
        return a[sortBy] < b[sortBy] ? -1 : 1;
      } else {
        document.getElementById('sorting-' + sortedColumn.key)?.classList.add('desc');
        return a[sortBy] > b[sortBy] ? -1 : 1;
      }
    });
    dataListFilter.value = dataList.value;
    return dataListFilter.value;
  };

  const onCreateControl = () => {
    createSegmentModal.dataSource = props.selectedSource;
    fetchDisplayColumn();
    createSegmentModal.isOpen = true;
  };
  const closeCreateControl = () => {
    createSegmentModal.isOpen = false;
  };

  const convertDateTable = (date: string) => {
    return convertUTCToDeviceTimeZone(date);
  };

  const onChangeSearch = () => {
    const filterList: any = [];
    if (textSearch.value == '') {
      dataListFilter.value = dataList.value;
      pagination.totalRows = dataList.value.length;
    } else {
      if (dataList.value.length > 0) {
        dataList.value.map((column) => {
          if (
            column.name.toLowerCase().indexOf(textSearch.value.toLowerCase()) > -1 ||
            column.folder_name.toLowerCase().indexOf(textSearch.value.toLowerCase()) > -1 ||
            column.segment_size.toString().toLowerCase().indexOf(textSearch.value) > -1 ||
            column.campaign_count.toString().toLowerCase().indexOf(textSearch.value) > -1 ||
            column.updated_by_name.toLowerCase().indexOf(textSearch.value) > -1
          ) {
            filterList.push({
              name: column.name,
              folderid: column.folder_id,
              folder_name: column.folder_name,
              segmentid: column.segmentid,
              segment_size: column.segment_size,
              campaign_count: column.campaign_count,
              updated_by_name: column.updated_by_name,
              updated_dt: column.updated_dt,
              type: column.type,
            });
          }
        });
      }
      pagination.totalRows = filterList.length;
      dataListFilter.value = filterList;
    }
  };

  const onCloneAction = (segment: Segment.Segment) => {
    fetchDisplayColumnModel.payload.TableId = segment.result_ref_tableid;
    createSegmentModal.isLoading = true;

    api
      .apiRequest(fetchDisplayColumnModel)
      .then((response) => {
        dataSourceDisplaySave.value = response.data.payload;
      })
      .catch((err) => {
        openDefaultErrorModal(
          err,
          () => (createSegmentModal.isOpen = false),
          () => (createSegmentModal.isOpen = false),
        );
      })
      .finally(() => {
        selectSegmentRow.value = segment;
        segmentSelectForModal.segmentid = segment.segmentid;
        isDialogSegmentOpen.value = true;
      });
  };

  const onCloseDialogSegment = () => {
    selectSegmentRow.value = null!;
    dataSourceDisplaySave.value = null!;
    segmentSelectForModal.segmentid = 0;
    segmentSelectForModal.root_tableid = 0;
    segmentSelectForModal.segment_size = 0;
    segmentSelectForModal.name = '';
    segmentSelectForModal.ref_segmentid = 0;
    segmentSelectForModal.folder_id = 0;
    segmentSelectForModal.result_table = '';
    segmentSelectForModal.result_type = 0;
    segmentSelectForModal.dest_ds_tableid = 0;
    segmentSelectForModal.result_ref_tableid = 0;
    isDialogSegmentOpen.value = false;
  };

  function checkPermissionSegment() {
    if (ability.can('portal-cdp', 'cdp-portal-segment-duplicate')) {
      actionList.push({
        key: 'copy',
        tooltip: t('segment.all_folder.action.copy'),
        icon: Copy,
        tooltipColor: '#007FFF',
        tooltipBackgroundColor: '#BBDBFF',
      });
    }
    if (ability.can('portal-cdp', 'cdp-portal-segment-edit')) {
      actionList.push({
        key: 'edit',
        tooltip: t('segment.all_folder.action.edit'),
        icon: Edit,
        tooltipColor: '#007FFF',
        tooltipBackgroundColor: '#BBDBFF',
      });
    }
    if (ability.can('portal-cdp', 'cdp-portal-segment-delete')) {
      actionList.push({
        key: 'delete',
        tooltip: t('segment.all_folder.action.delete'),
        icon: Delete,
        tooltipColor: '#007FFF',
        tooltipBackgroundColor: '#BBDBFF',
      });
    }
  }

  watch(
    () => router.currentRoute.value.query.folderName,
    (newFolderName) => {
      if (newFolderName) {
        segmentFolderName.value = String(router.currentRoute.value.query.folderName);
        urlRouter.value = {
          path: '/segment/data-segment/folder-inside',
          query: {
            ...router.currentRoute.value.query,
            folderName: newFolderName,
          },
        };

        breadcrumbList.value = [
          {
            key: 1,
            url: urlRouter,
            name: newFolderName,
          },
        ];
      }
    },
  );

  watch(
    () => pagination.currentPage,
    async () => {
      await fetchListSegment(true);
      await fetchCountRealtime();
      if (textSearch.value.length == 0) return await fetchListSegment(false);
      else return onChangeSearch();
    },
  );

  watch(
    () => textSearch.value,
    () => {
      pagination.currentPage = 1;
      onChangeSearch();
    },
  );

  onBeforeMount(async () => {
    // check permission
    checkPermissionSegment();

    await fetchListSegment(true);
    await fetchCountRealtime();
    if (router.currentRoute.value.query.folderId) await fetchListSegment(false);
  });

  return {
    openAllFolder,
    fields,
    pagination,
    dataListFilter,
    loading,
    actionList,
    breadcrumbList,
    createSegmentModal,
    segmentFolderName,
    onCreateControl,
    closeCreateControl,
    onSort,
    onSelectItem,
    onEditNameFolder,
    toSegmentDetail,
    convertDateTable,
    textSearch,
    onOpenModalEditName,
    onCloseModalEditName,
    isModalEditName,
    isDialogSegmentOpen,
    onCloseDialogSegment,
    dataSourceDisplaySave,
    selectSegmentRow,
  };
}
