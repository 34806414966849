//en-US
export default {
  copy: 'Copy',
  //coupon set
  source: 'Source',
  format: 'Format',
  couponType: 'Coupon Type',
  status: 'Status',
  countDelivered: 'Delivered',
  countAvailable: 'Available',
  countRedeemed: 'Redeemed',
  upload: 'Upload',
  sync: 'Sync',
  action: 'Action',
  detail: 'Detail',
  exportFile: 'Export File',
  show: 'Show',
  list: 'Lists',
  back: 'Back',
  next: 'Next',
  done: 'Done',
  save: 'save',

  //coupon code
  uploadingData: 'Uploading data',
  noReference: 'No reference',
  noCouponName: 'No coupon Name',
  noDescription: 'No description',
  valueReference: 'Value Reference',
  total_numberOfRights: 'Total',
  trackingCoupon: 'Tracking Coupon',
  couponCode: 'Coupon Code',
  batch: 'Batch',
  channel: 'Channel',
  all: 'All',
  select: 'Select',
  exportCSV: 'Export CSV.',

  delivered: 'Delivered',
  available: 'Available',
  redeemed: 'Redeemed',

  no: 'No.',
  linkCouponCode: 'Link Coupon Code',
  batchId: 'Batch',
  exp_date: 'Expiration Date',
  exp_time: 'Expiration Time',
  campaignId: 'Campaign Id',
  cid: 'CID',
  redeemedDT: 'Redeemed DT',
  redeemedBranchName: 'Redeemed Branch',

  uploadFileCoupon_details: 'Please upload coupon information in CSV file format.', //Download an attached CSV file.

  image: 'image',
  replace: 'Replace',
  delete: 'Delete',
  reference: 'Reference',
  description: 'Description',
  summaryImported: 'Summary of the amount of data imported.', //สรุปจำนวนข้อมูลที่นำเข้า
  quantity: 'Quantity',
  success: 'Success',
  error: 'Error',
  sumRow_isInvalid: 'Sum of row data is invalid.', //ผลรวมของข้อมูลแถวที่ไม่ถูกต้อง
  errorRow: 'Error Row',
  downloadErrorReport: 'Download Error Report',

  //excelDataSource
  createFileTemplate: 'Create File Upload Template',
  downloadTemplate: 'Download Template',
  downloadTemplate_details: 'Before uploading a file, download the form and make sure all the coupon information is filled out accurately.',
  uploadFile: 'Upload File',
  uploadFile_details: 'Please upload a CSV file to create a form for the office to import accurate data.',

  inputData: 'Input Data',

  dataFormAlreadySetUp: 'Data form already set up',
  permission_importData: 'No permission to import data',

  confirmData: 'Confirm Data',
  checkInformation: 'Please check the information is correct.',
  progress: 'Progress',
  inProgress: 'in progress',
  complete: 'Complete',
  fileUploaded: 'File Uploaded',
  fileSize: 'CSV file. Not more than 250 MB.',

  duplicate: 'Duplicate',
  noDuplicate: 'No Duplicate',

  browseFile: 'Browse File',
  selectColumnCategorizing: 'Please select the column you want to use. For categorizing the form',

  code: 'Code',
  link: 'Link',
  expireDT: 'Expire DT',
  defaultTemplate: 'Default Template',
  detail_defaultTemplate: 'Please download the form to fill in the correct information before uploading.',
  customMappingTemplate: 'Custom Mapping Template',
  detail_customMappingTemplate: 'Please download the form to fill in the correct information before uploading.',

  dataSetName: 'Data Set Name',
  waitUploadingFiles: 'The system is uploading files. please wait a moment....',

  column: 'Column',
  rename: 'Rename',
  notSelected: 'Not Selected',

  selectTemplateType: 'Select Template Type', //เลือกประเภทเทมเพลต
  templateType: 'Template Type', //ประเภทเทมเพลต
  selectDataType: 'Please select data type ', //กรุณาเลือกประเภทข้อมูล
  templateName: 'Template Name',
  setTemplateName: 'Set Template Name', //ตั้งชื่อเทมเพลต
  selectAll: 'Select all',
  editTemplate: 'Edit Template',
  createTemplate: 'Create Template',

  mappingTemplate: 'Mapping Template',
  selectData: 'Select Data',

  mapping: 'Mapping',
  //add data source
  moreDetails: 'More details',
  readMore: 'Read More',
  //add data source Fh5
  goToSetting: 'Go to setting',
  contactUs: 'Contact us',
  connectFH5: 'To connect with FastHelps5, please contact us. Press the button.',
  contact: 'Contact',
  //Fh5
  fh5_button_open: 'Open FastHelp5',
  fh5_integration: 'FastHelp5 Integration',
  fh5_connection_info: 'FH5 connection information', //ข้อมูลการเชื่อมต่อ FH5
  fh5_webhook_setting: 'Webhook Setting',
  fh5_connect_chocoCDP_to_FH5: 'Information for connecting from ChocoCDP to FH5',

  //Upload history
  fileUploadHistory: 'File history', //File History
  detail_fileUploadHistory: 'Data file upload history',

  //
  deletingFileData: 'Deleting file data',
  //modal
  modal_notComeBackEdit: 'If cancelled, you will not be able \nto come back and edit the information again', //'หากยกเลิกแล้วจะไม่สามารถกลับมาแก้ไขข้อมูลได้อีก'
  modal_wantToConfirm: 'Want to confirm?', //   'ต้องการยืนยันใช่หรือไม่ ?'
  modal_wantToCancelDataImport: 'Want to cancel data import', //   'ต้องการยกเลิกการนำเข้าข้อมูล'
  modal_wantToCancel: 'Want to cancel?', // 'ต้องการยกเลิกใช่หรือไม่ ?'
  modal_deleteCustomTemplate: 'Delete Custom Template',
  modal_WantToDelete_orNot: 'Want to delete {name} or not ?',

  modal_AnErrorOccurred: 'An error occurred!',
  modal_transactionSuccess: 'Transaction completed successfully!',
  modal_transactionFailed: 'Failed to complete the transaction',
  //modal FastHelps5
  modal_contact_information: 'Contact information',
  modal_button_line_AddFriends: 'Add Friends',
  modal_button_back: 'Back',
};
