import { ref, Ref, getCurrentInstance } from 'vue';
import { useI18n } from 'vue-i18n';
import api from '@/services/api';
import templateModel from '@/models/communication/template';

import campaignModel from '@/models/communication/campaign';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

// import { NODE_ID } from '@/constants/modules/mka/mkaDetail';
import mkaModalConfig from '@/constants/components/mka/mka-modal';

interface Props {
  selectedChannel: Communication.Channel.Component.AllChannelTab | null;
  nodePayloadContents?: any;
  isSmsEditable?: boolean;

  // //setup mka modal props

  isOpen: boolean;
  nodeProps: MKA_Nodes.NodesProps;
  isChangeTemplate: boolean;
}

interface SmsContents {
  title: string;
  content: string;
  content_editor: string;
}

export default function useActionSmsModal(props: Props) {
  const { openErrorModal, openDefaultErrorModal } = useValidationModal();

  const { t } = useI18n();
  const vm = getCurrentInstance()?.proxy;

  const nodeData = ref<MKA_Nodes.NodesProps>(props.nodeProps);

  const modalConfig = mkaModalConfig().nodeActionSms;

  // apis
  const { fetchProviderListModel } = campaignModel();
  const { fetchTemplateDetailModel } = templateModel();

  // node config
  // const currentNodeId = NODE_ID.ACTION_SMS;
  const modalPage = ref(modalConfig.modalPage.SELECT_TEMPLATE);

  const isSelectTemplateModalOpen: Ref<boolean> = ref(false);
  const isPreviewTemplateModalOpen: Ref<boolean> = ref(false);
  const selectedTemplate: Ref<Communication.Template.Template> = ref(null!);

  const keyChange: Ref<number> = ref(1);
  const isLoading: Ref<boolean> = ref(false);
  const providerList: Ref<Communication.Provider.Provider[]> = ref([]);
  const messageList: Ref<any> = ref([]);

  const selectedProvider: Ref<any> = ref(null!);

  let requestCounter: any = 0;
  let selectMessageIndex: number | null = null;
  const messageData: any = ref({});

  const isEditSms = ref<boolean>(false);
  const smsContent_Temp = ref<string>('');

  const smsMessage: Ref<string> = ref('');

  const nodeActionSmsStep_Var: any = ref([
    {
      active: true,
      label: t('mka.content'),
    },
    {
      active: false,
      label: t('mka.sender'),
    },
  ]);

  const smsDisplay = ref({
    maxChars: 0,
    maxCredit: 0,
    creditUsed: 0,
    extraChars: 0,
    msgCount: 0,
  });

  const nodeActionSms_Var_Update = ref<boolean>(true);
  const nodeActionSms_Var: any = ref({
    mode: 2,
    templateId: [],
    providerId: null,
    contents: <SmsContents[]>[],
  });

  const action_SmsTemplateSelect_Connect: Ref<any> = ref({});

  function isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const action_SmsTemplateSelect_ConnectFunction = (connectFunction: any) => {
    action_SmsTemplateSelect_Connect.value = connectFunction;
  };

  const onCloseTemplateModal = () => {
    isSelectTemplateModalOpen.value = false;
  };

  const onClickPreviewTemplate = (template: Communication.Template.Template) => {
    action_SmsTemplateSelect_Connect.value.selectedTemplate = template;
    isPreviewTemplateModalOpen.value = true;
  };

  const onClosePreviewTemplateModal = () => {
    isPreviewTemplateModalOpen.value = false;
  };

  const autoSelectProvider = () => {
    if (nodeActionSms_Var.value.providerId != null) {
      const providerSelected: any = providerList.value.find((provider) => provider.providerid === nodeActionSms_Var.value.providerId);
      if (providerSelected != null) {
        selectedProvider.value = providerSelected;
        setAllMessageData();
      }
    }
  };

  const resolveDisplayMessage = (contentList: Communication.Template.TemplateContent[]) => {
    const resolvedList: any = [];

    if (contentList && contentList.length) {
      contentList.forEach((contentData) => {
        const displayContent = {
          key: keyChange.value,
          sender: selectedProvider.value?.name || '',
          message: contentData.content,
        };
        resolvedList.push(displayContent);
        keyChange.value++;
      });
    } else {
      resolvedList.push({ key: keyChange.value, sender: selectedProvider.value?.name || '', message: '' });
      keyChange.value++;
    }
    return (messageList.value = resolvedList);
  };

  const setAllMessageData = () => {
    const allMessagePreviewData: any = [];
    nodeActionSms_Var.value.templateId.map((templateId: any) => {
      const message: any = messageData.value[templateId];
      if (message != null) {
        allMessagePreviewData.push(message.contents[0]);
      }
    });
    resolveDisplayMessage(allMessagePreviewData as Communication.Template.TemplateContent[]);
  };

  const fetchTemplateDetail = async (templateId: number, isPreviewMode = false): Promise<void> => {
    fetchTemplateDetailModel.payload.TemplateId = templateId;
    isLoading.value = true;
    requestCounter += 1;
    return api
      .apiRequest(fetchTemplateDetailModel)
      .then((response) => {
        const responseData = response.data as Communication.Template.Template;
        messageData.value[responseData.template_id] = responseData;
        if (isPreviewMode) {
          messageData.value[responseData.template_id].contents[0].content_editor = props.nodeProps.payload.contents[0].content_editor;
          messageData.value[responseData.template_id].name = props.nodeProps.info as string;
        }

        const mkaContentEditor: string = messageData.value[responseData.template_id].contents[0].content_editor;

        if (isJsonString(mkaContentEditor)) {
          const parseMessage = JSON.parse(mkaContentEditor);
          smsMessage.value = decodeURI((parseMessage.text as string) || '');
        } else {
          smsMessage.value = mkaContentEditor;
        }

        Promise.resolve();
      })
      .catch((err) => {
        openDefaultErrorModal(err);
        Promise.reject();
      })
      .finally(() => {
        requestCounter -= 1;
        if (requestCounter <= 0) {
          isLoading.value = false;
          setAllMessageData();
        }
        addSpanMkaSmsMessage(smsMessage.value);
      });
  };

  const fetchAllTemplate = (isPreviewMode = false) => {
    messageData.value = {};
    nodeActionSms_Var.value.templateId.map((templateId: number) => {
      fetchTemplateDetail(templateId, isPreviewMode);
    });
  };

  const fetchProviderList = async (): Promise<void> => {
    isLoading.value = true;
    fetchProviderListModel.payload.Channel = 1;
    fetchProviderListModel.payload.Page = 1;
    fetchProviderListModel.payload.Limit = 100;

    return api
      .apiRequest(fetchProviderListModel)
      .then((response) => {
        providerList.value = response.data.records as Communication.Provider.Provider[];
        autoSelectProvider();
        Promise.resolve();
      })
      .catch((err) => {
        openDefaultErrorModal(err);
        Promise.reject();
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const nextStep = () => {
    if (!nodeActionSms_Var.value.templateId.length) {
      nodeActionSms_Var.value.contents = [];
    }
    modalPage.value = modalConfig.modalPage.SELECT_PROVIDER;
    const nodeActionSmsStep_VarTemp = nodeActionSmsStep_Var.value;
    nodeActionSmsStep_VarTemp[1].active = true;
    nodeActionSmsStep_Var.value = nodeActionSmsStep_VarTemp;

    onClosePreviewTemplateModal();
    if (action_SmsTemplateSelect_Connect.value.selectedTemplate != null) {
      const nodeActionSms_Var_Temp = nodeActionSms_Var.value;
      if (selectMessageIndex != null) {
        nodeActionSms_Var_Temp.templateId[selectMessageIndex] = action_SmsTemplateSelect_Connect.value.selectedTemplate.template_id;

        smsContent_Temp.value = action_SmsTemplateSelect_Connect.value.selectedTemplate.contents[0].content_editor ?? '';

        // set sms node content
        nodeActionSms_Var_Temp.contents[selectMessageIndex] = {
          title: action_SmsTemplateSelect_Connect.value.selectedTemplate.contents[0].title ?? '',
          content: action_SmsTemplateSelect_Connect.value.selectedTemplate.contents[0].content ?? '',
          content_editor: action_SmsTemplateSelect_Connect.value.selectedTemplate.contents[0].content_editor ?? '',
        };
      } else {
        nodeActionSms_Var_Temp.templateId.push(action_SmsTemplateSelect_Connect.value.selectedTemplate.template_id);
        // edit sms content
        smsContent_Temp.value = action_SmsTemplateSelect_Connect.value.selectedTemplate.contents[0].content_editor ?? '';
        // set sms node content
        nodeActionSms_Var_Temp.contents.push({
          title: action_SmsTemplateSelect_Connect.value.selectedTemplate.contents[0].title ?? '',
          content: action_SmsTemplateSelect_Connect.value.selectedTemplate.contents[0].content ?? '',
          content_editor: action_SmsTemplateSelect_Connect.value.selectedTemplate.contents[0].content_editor ?? '',
        });
      }
      action_SmsTemplateSelect_Connect.value.selectedTemplate = null;
      nodeActionSms_Var.value = nodeActionSms_Var_Temp;
      fetchAllTemplate();
    } else {
      fetchAllTemplate(true);
    }

    selectMessageIndex = null;
  };

  const previewMode = () => {
    modalPage.value = modalConfig.modalPage.PREVIEW;
    const nodeActionSmsStep_VarTemp = nodeActionSmsStep_Var.value;
    nodeActionSmsStep_VarTemp[1].active = true;
    nodeActionSmsStep_Var.value = nodeActionSmsStep_VarTemp;

    fetchAllTemplate(true);

    selectMessageIndex = null;
  };

  const onChangeTemplate = (index: number) => {
    selectMessageIndex = index;
    modalPage.value = modalConfig.modalPage.SELECT_TEMPLATE;

    const nodeActionSmsStep_VarTemp = nodeActionSmsStep_Var.value;
    nodeActionSmsStep_VarTemp[1].active = false;
    nodeActionSmsStep_Var.value = nodeActionSmsStep_VarTemp;
  };

  const onRemoveTemplate = (index: number) => {
    const nodeActionSms_Var_Temp = nodeActionSms_Var.value;
    const templateIdTarget: number = nodeActionSms_Var_Temp.templateId[index];
    nodeActionSms_Var_Temp.templateId.splice(index, 1);
    nodeActionSms_Var_Temp.contents.splice(index, 1);
    nodeActionSms_Var.value = nodeActionSms_Var_Temp;
    const messageDataTemp: any = messageData.value;
    delete messageDataTemp[templateIdTarget];
    messageData.value = messageDataTemp;

    if (nodeActionSms_Var_Temp.templateId.length == 0) {
      modalPage.value = modalConfig.modalPage.SELECT_TEMPLATE;
      if (!props.isChangeTemplate) {
        selectedProvider.value = null!;
      }
      selectMessageIndex = null;

      const nodeActionSmsStep_VarTemp = nodeActionSmsStep_Var.value;
      nodeActionSmsStep_VarTemp[1].active = false;
      nodeActionSmsStep_Var.value = nodeActionSmsStep_VarTemp;
    }
  };

  const nodeActionSmsValidate = () => {
    const nodeActionSms_Var_Temp = nodeActionSms_Var.value;
    if (nodeActionSms_Var_Temp.mode === 1) {
    } else if (nodeActionSms_Var_Temp.mode === 2) {
      return nodeActionSms_Var_Temp.templateId.length > 0 && nodeActionSms_Var_Temp.providerId != null && nodeActionSms_Var_Temp.contents.length > 0;
    } else if (nodeActionSms_Var_Temp.mode === 3) {
    }
    return true;
  };

  const nodeActionSmsLoad = () => {
    modalPage.value = modalConfig.modalPage.SELECT_TEMPLATE;
    selectedProvider.value = null!;
    selectMessageIndex = null;

    setAllMessageData();

    const nodeActionSmsStep_VarTemp = nodeActionSmsStep_Var.value;
    nodeActionSmsStep_VarTemp[1].active = false;
    nodeActionSmsStep_Var.value = nodeActionSmsStep_VarTemp;

    // const nodeData: any = nodeDataList[nodeId];

    nodeActionSms_Var.value = nodeData.value.payload != null ? JSON.parse(JSON.stringify(nodeData.value.payload)) : {};

    fetchProviderList();
    if (nodeActionSms_Var.value.mode == 2 && nodeActionSms_Var.value.templateId.length > 0) {
      if (props.isChangeTemplate) {
        nextStep();
      } else {
        previewMode();
      }
    }
  };
  const nodeActionSmsSave = () => {
    if (nodeActionSmsValidate() == true) {
      nodeData.value.payload = nodeActionSms_Var.value || {};
      nodeData.value.info = messageData.value[nodeActionSms_Var.value.templateId].name;
      return true;
    } else {
      openErrorModal(t('mka.modal_incorrect_information'), t('mka.modal_incorrect_information_detail'));
      return null;
    }
    return null;
  };

  const onSelectProvider = (provider: Communication.Provider.Provider) => {
    selectedProvider.value = provider;
    nodeActionSms_Var.value.providerId = provider.providerid;
    vm?.$emit('select-sender', JSON.parse(JSON.stringify(selectedProvider.value)));
    setAllMessageData();
  };

  const onAddTemplate = () => {
    modalPage.value = modalConfig.modalPage.SELECT_TEMPLATE;
    selectedProvider.value = null!;
    selectMessageIndex = null;
  };

  const onChangeAndEdit = () => {
    nextStep();
  };

  const onChangeSmsContent = () => {
    alert('sms save');
  };

  const onChangeEdit = (isEdit: boolean) => {
    isEditSms.value = isEdit;
    // -> default hide comment
    // smsContent_Temp.value = messageData[action_SmsTemplateSelect_Connect.value.selectedTemplate.template_id].contents[0].content_editor;
  };

  const onGetSmsDisplay = (value: Campaign.SmsValue) => {
    smsDisplay.value = value;
  };

  function onClickApply() {
    const finalValid = nodeActionSmsSave();
    if (finalValid && selectedProvider.value) {
      vm?.$emit('apply-modal', nodeData.value);
    }
  }

  function onClickApplyTemplateChange() {
    if (nodeActionSmsValidate() == true && selectedProvider.value) {
      const nodePayloadTemp = nodeActionSms_Var.value || {};
      const nodeInfoTemp = messageData.value[nodeActionSms_Var.value.templateId].name;
      vm?.$emit('apply-template-change', nodePayloadTemp, nodeInfoTemp);
    }
  }

  function onClickClose() {
    vm?.$emit('close-modal');
  }

  function onClickCancel() {
    vm?.$emit('cancel-modal');
  }
  function addSpanMkaSmsMessage(smsMessage: string) {
    const mkaMessageContent = document.getElementById('mka-sms');
    if (mkaMessageContent != null) {
      mkaMessageContent.innerHTML = smsMessage;
    }
  }

  return {
    action_SmsTemplateSelect_ConnectFunction,
    onCloseTemplateModal,
    onClickPreviewTemplate,
    onClosePreviewTemplateModal,
    autoSelectProvider,
    resolveDisplayMessage,
    setAllMessageData,
    fetchTemplateDetail,
    fetchAllTemplate,
    fetchProviderList,
    nextStep,
    previewMode,
    onChangeTemplate,
    onRemoveTemplate,
    nodeActionSmsValidate,
    nodeActionSmsLoad,
    nodeActionSmsSave,
    onSelectProvider,
    onAddTemplate,
    onChangeAndEdit,
    onChangeSmsContent,
    onChangeEdit,
    onGetSmsDisplay,
    onClickApply,
    onClickClose,
    onClickCancel,
    onClickApplyTemplateChange,

    //variable
    modalPage,
    nodeActionSmsStep_Var,
    nodeActionSms_Var,
    modalConfig,
    selectedProvider,
    providerList,
    isEditSms,
    smsContent_Temp,
    smsMessage,
    messageData,
    messageList,
    action_SmsTemplateSelect_Connect,
    isPreviewTemplateModalOpen,
  };
}
