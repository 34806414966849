export default {
  forget_password: {
    title: 'ลืมรหัสผ่าน',
    description_first: 'กรุณากรอกอีเมลของคุณด้านล่าง เราจะส่งอีเมล ',
    description_second: 'คำแนะนำในการรีเซ็ตรหัสผ่านให้คุณ',
    placeholder: 'อีเมลของคุณ',
    button: 'ส่ง',
    error_text: 'อีเมลไม่ถูกต้อง',
    notemail_text: 'ไม่พบอีเมลนี้ในระบบ กรุณาตรวจสอบอีกครั้ง',
    back: 'กลับ',

    check_email: {
      title: 'เรากำลังช่วยเหลือคุณ',
      description_first: 'เรากำลังค้นหารายการในระบบเพื่อยืนยันบัญชี CHOCO CDP ',
      description_second: 'สำหรับ ',
      go_back: 'กลับไปที่ ChocoFor ',
      link_login: 'portal.chococdp.com/login',
    },

    expire_email: {
      title: 'ลิงก์ของคุณหมดอายุแล้ว',
      description_first: 'หากคุณต้องการดำเนินการรีเซ็ตรหัสผ่านต่อ ',
      re_click: 'กรุณาคลิกลิงก์นี้อีกครั้ง ',
      link_forgot_password: 'portal.chococdp.com/forgot-password',
    },
  },

  reset_password: {
    title: 'สร้างรหัสผ่านใหม่',
    sub_title_step1: 'รหัสผ่านใหม่ของคุณสำหรับ',
    sub_title_step2: ' ต้องแตกต่างจากรหัสผ่านที่คุณเคยใช้ก่อนหน้านี้',
    placeholder_password: 'รหัสผ่านใหม่',
    placeholder_confirm_password: 'ยืนยันรหัสผ่านใหม่',
    topic_rules: 'ดูแลความปลอดภัยของบัญชีของคุณด้วยการใช้รหัสผ่านที่มั่นคงและปลอดภัย',
    rule1: 'รหัสผ่านต้องตรงกัน',
    rule2: 'รหัสผ่านต้องมีมากกว่า 8 ตัวอักษร',
    rule3: 'รหัสผ่านต้องมีตัวอักษรตัวเล็กอย่างน้อย 1 ตัวอักษร',
    rule4: 'รหัสผ่านต้องมีตัวอักษรตัวใหญ่อย่างน้อย 1 ตัวอักษร',
    rule5: 'รหัสผ่านต้องมีตัวเลขอย่างน้อย 1 ตัวอักษร (0-9)',
    rule6: "รหัสผ่านต้องมีอักขระพิเศษอย่างน้อย 1 ตัวอักษร (!{'@'}#{'$'}%^&*())",
    btn: 'สร้างรหัสผ่าน',
  },
  // ---------------------- MFA ----------------------------
  text_header: 'ChocoCDP การยืนยันตัวตน',
  description_header1: 'ChocoCDP เพิ่มความปลอดภัยบัญชีของคุณ',
  description_header2: 'ด้วยขั้นตอนยืนยันตัวตน 2 ขั้น (Two-Factor Authentication)',
  step: 'ขั้นตอนที่',
  // --------- step 1 ----------
  start_by_getting_the_app: 'ดาวน์โหลดแอปพลิเคชัน',
  please_install1: 'กรุณาติดตั้งแอปพลิเคชัน Google Authenticator หรือ Microsoft Authenticator ลงบนโทรศัพท์มือถือของท่าน',
  divider: 'หรือ',
  // --------- step 2 ----------
  install_an_authentication_app: 'ติดตั้งแอปพลิเคชัน Authentication',
  please_install2: 'กรุณาติดตั้งแอปพลิเคชัน ',
  on_your_mobile: ' ลงบนโทรศัพท์มือถือของท่าน',
  // --------- step 3 ----------
  scan_qr_code: 'สแกน QR Code',
  use_the: 'ใช้ แอปพลิเคชัน',
  application_to_scan: 'หลังจากสแกน QR Code',
  application_with_your_account: '  จะเชื่อมต่อกับบัญชีของคุณ ',
  scan_to_next: 'หลังสแกน QR code กรุณากดปุ่ม "ถัดไป"',
  // --------- step 4 ----------
  verify_authentication_code: 'กรอกรหัส Authentication',
  enter_the_six_digit: 'กรอกรหัส 6 หลักของแอปพลิเคชัน Authentication',
  open_your_two_factor: 'เปิดแอปสำหรับการยืนยันตัวตนแบบสองขั้นตอน (TOTP) ของคุณเพื่อดูรหัส 6 หลักในการยืนยันตัวตน',
  error: 'ขออภัยรหัส Authentication ของคุณไม่ถูกต้อง กรุณาลองอีกครั้งหรือหากพบปัญหากรุณาติดต่อเจ้าหน้าที่ ChocoCRM',
  button_complete: 'ยืนยันตัวตน',
  loading: 'กำลังโหลด',
  // --------- step complete ----------
  authenticator_setup_complete: 'Authenticator setup complete!',
  description_header_complete: 'You can now generate verification codes for your ChocoCDP accounts',
  done: 'Done',
  // --------- button ----------
  back: 'ย้อนกลับ',
  next: 'ถัดไป',
  trouble_text: 'พบปัญหา? ติดต่อเจ้าหน้าที่ ChocoCRM',
};
