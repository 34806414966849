import { ref, Ref, getCurrentInstance, onMounted } from 'vue';
import apiService from '@/services/api';
import fileServiceModel from '@/models/file-service/file-service';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import useLoading from '@/views/components/loading/hooks/useLoading';
import { PATH } from '@/constants/modules/file-service/path';
import { useI18n } from 'vue-i18n';
import { getLanguageDisplayModalError } from '@/utils/useLanguageDisplay';

interface Props {
  messageKey: number;
  selectMenu: string;
  imageUrl?: string;
  videoUrl?: string;
  audioUrl?: string;
  sref?: string;
  templateMode?: string;
}

export default function useFileUpload(props: Props) {
  const { uploadFileModel } = fileServiceModel();
  const { openErrorModal } = useValidationModal();
  const { isLoading } = useLoading();
  const { t } = useI18n();
  const isUpload: Ref<boolean> = ref(false);
  const sref: Ref<string> = ref(props.sref || '');
  const vm = getCurrentInstance()?.proxy;
  const url: Ref<Blob> = ref(null!);

  const handleUploadChange = (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target && target.files) {
      const file: File = target.files[0];
      const is_Temp = true as boolean;
      //upload file
      if (onValidateFile(file, props.selectMenu)) {
        if (
          (isMaxFileSize_10_MB(file) && props.selectMenu === 'image') ||
          (isMaxFileSize_200_MB(file) && (props.selectMenu === 'video' || props.selectMenu === 'voice-message'))
        ) {
          isLoading(true, t('communication.campaign.line_oa.create.uploading'));
          vm?.$emit('uploading', true);
          uploadFileModel.payload.File = file;
          uploadFileModel.payload.Is_Temp = is_Temp;
          uploadFileModel.payload.Path = props.templateMode ? PATH.SYSTEM.COMMU.LINE : PATH.COMMU.LINE;

          if (props.selectMenu === 'image' || props.selectMenu === 'video') {
            uploadFileModel.payload.Options.Gen_preview = true;
          }

          apiService
            .apiFormRequest(uploadFileModel)
            .then((response) => {
              const detail = response.data;

              url.value = detail.public_url as Blob;
              sref.value = detail.sref;
              const previewImageUrl = detail.preview_url;
              isUpload.value = true;
              vm?.$emit('on-upload-file', [props.selectMenu, url.value, sref.value, previewImageUrl]);
            })
            .catch((err) => {
              if (err.data.error.code === 1000400) {
                openErrorModal(err.data.error.locale[getLanguageDisplayModalError()].title as string, err.data.error.message as string);
              } else {
                openErrorModal(
                  err.data.error.locale[getLanguageDisplayModalError()].title as string,
                  err.data.error.locale[getLanguageDisplayModalError()].message as string,
                );
              }
              clearInputValue(file);
            })
            .finally(() => {
              isLoading(false);
              vm?.$emit('uploading', false);
              const editorContainer = document.getElementById(`editor-container-${props.messageKey}`) as HTMLDivElement;

              editorContainer.classList.remove('warning');
            });
        } else {
          openErrorModal(t('communication.campaign.line_oa.create.error'), t('communication.campaign.line_oa.create.fileOverSize'), '');
          clearInputValue(file);
        }
      } else {
        openErrorModal(t('communication.campaign.line_oa.create.error'), t('communication.campaign.line_oa.create.wrongFileType'), '');
        clearInputValue(file);
      }
    }
  };

  const onClearFile = () => {
    if (isUpload.value && sref.value) {
      vm?.$emit('on-delete-file', sref.value);
      vm?.$emit('on-upload-file', [props.selectMenu]);
      vm?.$emit('on-upload-audio', undefined);
      isUpload.value = false;
    }
  };

  const onUpload = () => {
    if (props.imageUrl && props.imageUrl != '') {
      return (isUpload.value = true);
    } else if (props.videoUrl && props.videoUrl != '') {
      return (isUpload.value = true);
    } else if (props.audioUrl && props.audioUrl != '') {
      return (isUpload.value = true);
    } else return (isUpload.value = false);
  };

  const getDuration = () => {
    const vid = vm?.$refs[`audio-player-${props.messageKey}`] as HTMLVideoElement;
    if (vid !== null) {
      if (!Number.isNaN(vid.duration)) {
        const duration = vid.duration * 1000;
        vm?.$emit('on-upload-audio', duration);
      }
    } else return;
  };

  const onValidateFile = (file: File, selectMenu: string) => {
    const fileType = file.type.split('/');
    const extensionList = {
      image: ['png', 'jpeg', 'jpg'],
      video: ['mp4'],
      audio: ['x-m4a'],
    };

    if (fileType[0] === 'image' && selectMenu === 'image') {
      return extensionList.image.includes(fileType[1]);
    } else if (fileType[0] === 'video' && selectMenu === 'video') {
      return extensionList.video.includes(fileType[1]);
    } else if (fileType[0] === 'audio' && selectMenu === 'voice-message') {
      return extensionList.audio.includes(fileType[1]);
    } else return false;
  };

  const isMaxFileSize_10_MB = (file: File) => file && file.size <= 10485760;
  const isMaxFileSize_200_MB = (file: File) => file && file.size <= 209715200;

  const clearInputValue = (file: File) => {
    if (props.messageKey && file) {
      const elementId = `file-${props.messageKey}`;
      (document.getElementById(elementId) as HTMLInputElement).value = '';
      isUpload.value = false;
    }
  };

  onMounted(() => {
    onUpload();
  });

  return {
    isUpload,
    handleUploadChange,
    onClearFile,
    getDuration,
  };
}
