import { ref, reactive, Ref, toRaw } from 'vue';
import { charts_key, charts_type } from '../constants/commuChart';
import { EChartsOption } from 'echarts';
import { HEATMAP } from '@/constants/components/chart/base-chart';
import { saveAs } from 'file-saver';
import { useI18n } from 'vue-i18n';
import { CHANNEL_VALUE } from '@/views/modules/communication/pages/main/constants/channel';
import apiService from '@/services/api';
import dashboardByChannelModel from '@/models/api/communication/apiDashboardByChannel';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
///icon
import SeeMoreIcon from '@/assets/icons/modules/communication/dashboard-by-channel/see-more-icon.png';
import LineOaIcon from '@/assets/icons/modules/communication/all-channel-tab/LineOaIcon.png';
interface ExtraTopLegendItem {
  label: string;
  value: number;
  color: string;
}

export default function useChartsOption() {
  const { getChartDataModel, getChartDataInfoModel, exportChartDataInfoModel } = dashboardByChannelModel();
  const { openDefaultErrorModal, openErrorModal, openSuccessModal } = useValidationModal();

  const { t } = useI18n();

  const startDateTime: Ref<string | Date> = ref('');
  const endDateTime: Ref<string | Date> = ref('');
  const isLoadingDataInfo: Ref<boolean> = ref(false);

  const chartsObj = reactive({
    Comm_006_OverviewTotalStatus: {
      label: '', //Total Status
      option: {},
      loading: true,
      element: <HTMLElement>{},
      props: <Charts.ChartsProps>{},
      hasDateFilter: 'false',
      items: [
        {
          title: t('communication.draft'),
          value: 0,
          desc: t('communication.campaign_text'),
        },
        {
          title: t('communication.schedule'),
          value: 0,
          desc: t('communication.campaign_text'),
        },
        {
          title: t('communication.sending'),
          value: 0,
          desc: t('communication.campaign_text'),
        },
        {
          title: t('communication.completed'),
          value: 0,
          desc: t('communication.campaign_text'),
        },
      ] as Overview.Item[],
      color: '',
      descriptionColor: '',
      dataInfo: {
        chartLayoutId: 0,
        chartSetId: 0,
        chartId: 0,
        impl_Type: 0,
        result: {
          chart_desc: '',
          chart_name: '',
          column: [],
          rawData: [],
        },
      },
      dateTime: {
        startDateTime: <string | Date>'',
        endDateTime: <string | Date>'',
        startDate: ref<Date | null>(null),
        endDate: ref<Date | null>(null),
        startTime: ref<Date | null>(new Date()),
        endTime: ref<Date | null>(new Date()),
        relativeModel: ref<string | null>('P90D'),
        prevStartDate: <Date>undefined!,
        prevEndDate: <Date>undefined!,
        prevStartTime: <Date>undefined!,
        prevEndTime: <Date>undefined!,
        searchTimeout: <ReturnType<typeof setTimeout>>undefined!,
        textDateTimeInfo: <string>'',
      },
    },
    Comm_007_HeatmapCommuCampaignDeliveryRange: {
      label: '', //Delivery Range
      option: {},
      loading: true,
      element: <HTMLElement>{},
      props: <Charts.ChartsProps>{},
      hasDateFilter: 'true',
      dataInfo: {
        chartLayoutId: 0,
        chartSetId: 0,
        chartId: 0,
        impl_Type: 0,
        result: {
          chart_desc: '',
          chart_name: '',
          column: [],
          rawData: [],
        },
      },
      dateTime: {
        startDateTime: <string | Date>'',
        endDateTime: <string | Date>'',
        startDate: ref<Date | null>(null),
        endDate: ref<Date | null>(null),
        startTime: ref<Date | null>(new Date()),
        endTime: ref<Date | null>(new Date()),
        relativeModel: ref<string | null>('P90D'),
        prevStartDate: <Date>undefined!,
        prevEndDate: <Date>undefined!,
        prevStartTime: <Date>undefined!,
        prevEndTime: <Date>undefined!,
        searchTimeout: <ReturnType<typeof setTimeout>>undefined!,
        textDateTimeInfo: <string>'',
      },
    },
    Comm_015_HeatmapCommuClickRange: {
      label: '', //Click Range
      option: {},
      loading: true,
      element: <HTMLElement>{},
      props: <Charts.ChartsProps>{},
      hasDateFilter: 'true',
      dataInfo: {
        chartLayoutId: 0,
        chartSetId: 0,
        chartId: 0,
        impl_Type: 0,
        result: {
          chart_desc: '',
          chart_name: '',
          column: [],
          rawData: [],
        },
      },
      dateTime: {
        startDateTime: <string | Date>'',
        endDateTime: <string | Date>'',
        startDate: ref<Date | null>(null),
        endDate: ref<Date | null>(null),
        startTime: ref<Date | null>(new Date()),
        endTime: ref<Date | null>(new Date()),
        relativeModel: ref<string | null>('P90D'),
        prevStartDate: <Date>undefined!,
        prevEndDate: <Date>undefined!,
        prevStartTime: <Date>undefined!,
        prevEndTime: <Date>undefined!,
        searchTimeout: <ReturnType<typeof setTimeout>>undefined!,
        textDateTimeInfo: <string>'',
      },
    },
    Comm_008_PieDelivery: {
      label: '', //Delivery
      option: {},
      loading: true,
      element: <HTMLElement>{},
      props: <Charts.ChartsProps>{},
      hasDateFilter: 'false',
      dataInfo: {
        chartLayoutId: 0,
        chartSetId: 0,
        chartId: 0,
        impl_Type: 0,
        result: {
          chart_desc: '',
          chart_name: '',
          column: [],
          rawData: [],
        },
      },
      dateTime: {
        startDateTime: <string | Date>'',
        endDateTime: <string | Date>'',
        startDate: ref<Date | null>(null),
        endDate: ref<Date | null>(null),
        startTime: ref<Date | null>(new Date()),
        endTime: ref<Date | null>(new Date()),
        relativeModel: ref<string | null>('P90D'),
        prevStartDate: <Date>undefined!,
        prevEndDate: <Date>undefined!,
        prevStartTime: <Date>undefined!,
        prevEndTime: <Date>undefined!,
        searchTimeout: <ReturnType<typeof setTimeout>>undefined!,
        textDateTimeInfo: <string>'',
      },
    },
    Comm_009_VerticalStackBarDeliveryByCampaign: {
      label: '', //Delivery By Campaign
      option: {},
      loading: true,
      element: <HTMLElement>{},
      props: <Charts.ChartsProps>{},
      hasDateFilter: 'false',
      dataInfo: {
        chartLayoutId: 0,
        chartSetId: 0,
        chartId: 0,
        impl_Type: 0,
        result: {
          chart_desc: '',
          chart_name: '',
          column: [],
          rawData: [],
        },
      },
      dateTime: {
        startDateTime: <string | Date>'',
        endDateTime: <string | Date>'',
        startDate: ref<Date | null>(null),
        endDate: ref<Date | null>(null),
        startTime: ref<Date | null>(new Date()),
        endTime: ref<Date | null>(new Date()),
        relativeModel: ref<string | null>('P90D'),
        prevStartDate: <Date>undefined!,
        prevEndDate: <Date>undefined!,
        prevStartTime: <Date>undefined!,
        prevEndTime: <Date>undefined!,
        searchTimeout: <ReturnType<typeof setTimeout>>undefined!,
        textDateTimeInfo: <string>'',
      },
    },
    Comm_013_LineLineNewSubscribe: {
      label: '', //Line New Subscribe
      option: {},
      loading: true,
      element: <HTMLElement>{},
      props: <Charts.ChartsProps>{},
      hasDateFilter: 'true',
      dataInfo: {
        chartLayoutId: 0,
        chartSetId: 0,
        chartId: 0,
        impl_Type: 0,
        result: {
          chart_desc: '',
          chart_name: '',
          column: [],
          rawData: [],
        },
      },
      dateTime: {
        startDateTime: <string | Date>'',
        endDateTime: <string | Date>'',
        startDate: ref<Date | null>(null),
        endDate: ref<Date | null>(null),
        startTime: ref<Date | null>(new Date()),
        endTime: ref<Date | null>(new Date()),
        relativeModel: ref<string | null>('P90D'),
        prevStartDate: <Date>undefined!,
        prevEndDate: <Date>undefined!,
        prevStartTime: <Date>undefined!,
        prevEndTime: <Date>undefined!,
        searchTimeout: <ReturnType<typeof setTimeout>>undefined!,
        textDateTimeInfo: <string>'',
      },
    },
    Comm_014_PieLineSubscribe: {
      label: '', //Line Subscribe
      option: {},
      loading: true,
      element: <HTMLElement>{},
      props: <Charts.ChartsProps>{},
      hasDateFilter: 'false',
      dataInfo: {
        chartLayoutId: 0,
        chartSetId: 0,
        chartId: 0,
        impl_Type: 0,
        result: {
          chart_desc: '',
          chart_name: '',
          column: [],
          rawData: [],
        },
      },
      dateTime: {
        startDateTime: <string | Date>'',
        endDateTime: <string | Date>'',
        startDate: ref<Date | null>(null),
        endDate: ref<Date | null>(null),
        startTime: ref<Date | null>(new Date()),
        endTime: ref<Date | null>(new Date()),
        relativeModel: ref<string | null>('P90D'),
        prevStartDate: <Date>undefined!,
        prevEndDate: <Date>undefined!,
        prevStartTime: <Date>undefined!,
        prevEndTime: <Date>undefined!,
        searchTimeout: <ReturnType<typeof setTimeout>>undefined!,
        textDateTimeInfo: <string>'',
      },
    },
  });

  function buildChartOption(chartKey: string) {
    chartsObj[chartKey as keyof typeof chartsObj].loading = true;
    const chart = chartsObj[chartKey as keyof typeof chartsObj];
    getChartDataModel.payload.Impl_Key = chartKey;
    getChartDataModel.payload.Filters = [
      {
        Key: 'startDate',
        Value: chart.dateTime.startDateTime || (startDateTime.value as string),
      },
      {
        Key: 'endDate',
        Value: chart.dateTime.endDateTime || (endDateTime.value as string),
      },
      {
        Key: 'hasDateFilter',
        Value: chartsObj[chartKey as keyof typeof chartsObj].hasDateFilter,
      },
    ];
    if (
      chartKey === charts_key.TOTAL_STATUS ||
      chartKey === charts_key.DELIVERY_RANGE ||
      chartKey === charts_key.DELIVERY ||
      chartKey === charts_key.DELIVERY_BY_CAMPAIGN ||
      chartKey === charts_key.CLICK_RANGE
    ) {
      getChartDataModel.payload.Filters.push({ Key: 'channel', Value: [CHANNEL_VALUE.LINE] });
    }

    apiService
      .apiRequest(getChartDataModel)
      .then((response) => {
        const resp = chartPropsMapper(response.data.result as Charts.ChartsProps.Request.Response);
        chartsObj[chartKey as keyof typeof chartsObj].props = resp;
        chartsObj[chartKey as keyof typeof chartsObj].label = resp.title;
        chartsObj[chartKey as keyof typeof chartsObj].option = chartOptionMapper(
          resp.chartsType,
          resp.rawData,
          resp.colorList,
          <EChartsOption>resp.options,
        );
        chart.dataInfo.result.chart_desc = response.data.result.chart_desc;
      })
      .catch((err) => {
        // openDefaultErrorModal(err);
      })
      .finally(() => {
        chartsObj[chartKey as keyof typeof chartsObj].loading = false;
      });
  }

  async function fetchDataInfo(chartKey: string) {
    isLoadingDataInfo.value = true;
    const chart = chartsObj[chartKey as keyof typeof chartsObj];
    const chartProps = chartsObj[chartKey as keyof typeof chartsObj].props;
    getChartDataInfoModel.payload.ChartLayoutId = chartsObj[chartKey as keyof typeof chartsObj].dataInfo.chartLayoutId;
    getChartDataInfoModel.payload.ChartSetId = chartProps.chartSetId as number;
    getChartDataInfoModel.payload.ChartId = chartProps.chartId as number;
    getChartDataInfoModel.payload.Impl_Type = chartProps.implType as number;
    getChartDataInfoModel.payload.Impl_Key_Ref = chartProps.implKeyRef;
    getChartDataInfoModel.payload.Filters = [
      {
        Key: 'startDate',
        Value: chart.dateTime.startDateTime || (startDateTime.value as string),
      },
      {
        Key: 'endDate',
        Value: chart.dateTime.endDateTime || (endDateTime.value as string),
      },
      {
        Key: 'hasDateFilter',
        Value: chartsObj[chartKey as keyof typeof chartsObj].hasDateFilter,
      },
    ];
    if (
      chartKey === charts_key.TOTAL_STATUS ||
      chartKey === charts_key.DELIVERY_RANGE ||
      chartKey === charts_key.DELIVERY ||
      chartKey === charts_key.DELIVERY_BY_CAMPAIGN ||
      chartKey === charts_key.CLICK_RANGE
    ) {
      getChartDataInfoModel.payload.Filters.push({ Key: 'channel', Value: [CHANNEL_VALUE.LINE] });
    }

    await apiService
      .apiRequest(getChartDataInfoModel)
      .then((response) => {
        const result = response.data.result;
        chartsObj[chartKey as keyof typeof chartsObj].dataInfo.result.chart_desc = result.chart_desc;
        chartsObj[chartKey as keyof typeof chartsObj].dataInfo.result.chart_name = result.chart_name;
        chartsObj[chartKey as keyof typeof chartsObj].dataInfo.result.column = result.column;
        chartsObj[chartKey as keyof typeof chartsObj].dataInfo.result.rawData = result.rawdata.data[0].datalist;
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isLoadingDataInfo.value = false;
      });
  }

  function onExportReport(chartKey: string) {
    const chart = chartsObj[chartKey as keyof typeof chartsObj];
    const chartProps = chartsObj[chartKey as keyof typeof chartsObj].props;
    exportChartDataInfoModel.payload.ChartLayoutId = chartsObj[chartKey as keyof typeof chartsObj].dataInfo.chartLayoutId;
    exportChartDataInfoModel.payload.ChartSetId = chartProps.chartSetId as number;
    exportChartDataInfoModel.payload.ChartId = chartProps.chartId as number;
    exportChartDataInfoModel.payload.Impl_Type = chartProps.implType as number;
    exportChartDataInfoModel.payload.Impl_Key_Ref = chartProps.implKeyRef;
    exportChartDataInfoModel.payload.Filters = [
      {
        Key: 'startDate',
        Value: chart.dateTime.startDateTime || (startDateTime.value as string),
      },
      {
        Key: 'endDate',
        Value: chart.dateTime.endDateTime || (endDateTime.value as string),
      },
      {
        Key: 'hasDateFilter',
        Value: chartsObj[chartKey as keyof typeof chartsObj].hasDateFilter,
      },
    ];
    if (
      chartKey === charts_key.TOTAL_STATUS ||
      chartKey === charts_key.DELIVERY_RANGE ||
      chartKey === charts_key.DELIVERY ||
      chartKey === charts_key.DELIVERY_BY_CAMPAIGN ||
      chartKey === charts_key.CLICK_RANGE
    ) {
      exportChartDataInfoModel.payload.Filters.push({ Key: 'channel', Value: [CHANNEL_VALUE.LINE] });
    }

    apiService
      .apiRequest(exportChartDataInfoModel)
      .then((response) => {
        const datesTime = new Date();
        saveAs(
          response as Blob | string,
          `Report-Campaign-${
            chartsObj[chartKey as keyof typeof chartsObj].label
          }-${datesTime.getFullYear()}${datesTime.getMonth()}${datesTime.getDate()}_${datesTime.getHours()}${datesTime.getMinutes()}${datesTime.getSeconds()}.csv`,
        );
        openSuccessModal('ทำรายการสำเร็จ');
      })
      .catch((err) => {
        openErrorModal(t('communication.error'), t('communication.cannot_download_report'));
      })
      .finally(() => {});
  }

  function chartOptionMapper(
    chartType: Charts.ChartsProps.ChartType,
    rawData: Charts.ChartsProps.RawData,
    colorList: Charts.ChartsProps.ColorItem[],
    options: EChartsOption,
  ): EChartsOption {
    const newOption: EChartsOption = Object.assign({}, options);
    switch (chartType) {
      case charts_type.PIE: {
        let presetSeries: any = [];
        presetSeries = [
          {
            // TODO: avoid magic number
            ...(Array.isArray(newOption.series) ? newOption.series[0] : newOption.series),
            data: rawData.data.map((el: any, index: number) => ({
              name: el.data,
              value: el.value,
              itemStyle: {
                color: colorList[index].hexColor,
              },
            })),
          },
        ];
        newOption.series = presetSeries;

        return newOption;
      }
      case charts_type.LINE: {
        let presetColor: any = [];
        presetColor = [...colorList.map((color) => color.hexColor)];
        newOption.color = presetColor;

        let presetXAxis: any = {};
        // TODO: avoid magic number
        presetXAxis = {
          ...newOption.xAxis,
          data: rawData.data[0].xaxis,
          name: rawData.data[0].xaxis_label,
        };
        newOption.xAxis = presetXAxis;

        let presetYAxis: any = {};
        // TODO: avoid magic number
        presetYAxis = {
          ...newOption.yAxis,
          name: rawData.data[0].yaxis_label,
        };
        newOption.yAxis = presetYAxis;

        const presetSeries: any = [];
        rawData.data.forEach((rawDataItem: any) => {
          presetSeries.push({
            // TODO: avoid magic number
            ...(Array.isArray(newOption.series) ? newOption.series[0] : newOption.series),
            name: rawDataItem.data,
            type: charts_type.LINE,
            data: toRaw(rawDataItem.yaxis),
          });
        });
        newOption.series = presetSeries;

        return newOption;
      }
      case charts_type.VERTICAL_STACK_BAR: {
        let presetColor: any = [];
        presetColor = [...colorList.map((color) => color.hexColor)];
        newOption.color = presetColor;

        let presetXAxis: any = {};
        // TODO: avoid magic number
        presetXAxis = { ...newOption.xAxis, data: rawData.data[0].xaxis, name: rawData.data[0].xaxis_label };
        newOption.xAxis = presetXAxis;

        let presetYAxis: any = {};
        presetYAxis = {
          ...newOption.yAxis,
          name: rawData.data[0].yaxis_label,
        };
        newOption.yAxis = presetYAxis;

        const presetSeries: any = [];
        rawData.data.forEach((rawDataItem: any) => {
          presetSeries.push({
            // TODO: avoid magic number
            ...(Array.isArray(newOption.series) ? newOption.series[0] : newOption.series),
            name: rawDataItem.data,
            stack: rawDataItem.stack,
            data: rawDataItem.yaxis,
          });
        });
        newOption.series = presetSeries;

        return newOption;
      }
      case charts_type.HEATMAP: {
        let presetXAxis: any = {};
        presetXAxis = {
          ...newOption.xAxis,
          data: rawData.data[HEATMAP.X_AXIS_INDEX].xaxis,
          splitArea: {
            show: true,
          },
          axisLabel: {
            rotate: 0,
          },
        };
        newOption.xAxis = presetXAxis;

        let presetYAxis: any = {};
        presetYAxis = {
          ...newOption.yAxis,
          data: rawData.data[HEATMAP.Y_AXIS_INDEX].yaxis,
          splitArea: {
            show: true,
          },
        };
        newOption.yAxis = presetYAxis;

        let presetVisualMap: any = {};
        presetVisualMap = {
          type: 'piecewise',
          min: rawData.data[HEATMAP.RAW_DATA_INDEX].min,
          max: rawData.data[HEATMAP.RAW_DATA_INDEX].max,
          calculable: true,
          orient: 'horizontal',
          left: 'center',
          bottom: '-5px',
          showLabel: true,
          inverse: true,

          pieces: [
            { min: 1, max: Math.floor((1 / 3) * rawData.data[HEATMAP.RAW_DATA_INDEX].max) || 1, color: '#e2e2e2', label: t('communication.low') },
            {
              min: Math.floor((1 / 3) * rawData.data[HEATMAP.RAW_DATA_INDEX].max) + 1 || 2,
              max: Math.floor((2 / 3) * rawData.data[HEATMAP.RAW_DATA_INDEX].max) || 2,
              color: '#0091ff',
              label: t('communication.medium'),
            },
            {
              min: Math.floor((2 / 3) * rawData.data[HEATMAP.RAW_DATA_INDEX].max) + 1 || 3,
              max: rawData.data[HEATMAP.RAW_DATA_INDEX].max || 3,
              color: '#005493',
              label: t('communication.high'),
            },
            { value: 0, color: '#f5f5f5', label: t('communication.none') },
          ],
        };
        newOption.visualMap = presetVisualMap;

        const presetSeries: any = [];
        rawData.data.forEach((rawDataItem: any) => {
          presetSeries.push({
            ...(Array.isArray(newOption.series) ? newOption.series[HEATMAP.SERIES_INDEX] : newOption.series),
            name: rawDataItem.dataname,
            data: rawDataItem.datalist,
            label: {
              show: true,
            },
          });
        });
        newOption.series = presetSeries;

        const presetTooltip: any = {
          show: true,
          trigger: 'item',
          triggerOn: 'click',
          position: function (pos: any, params: any, el: any, elRect: any, size: any) {
            const obj: any = {};
            const canvasY = pos[1];
            const canvasX = pos[0];
            const height_canvas = size.viewSize[1];
            const width_canvas = size.viewSize[0];
            const height_tooltip = size.contentSize[1];
            const width_tooltip = size.contentSize[0];
            const distanceX = 15;

            //แบบที 1
            // const percentHeight_canvas = (canvasY * 100) / height_canvas;
            // const Y_tooltip = (height_tooltip * percentHeight_canvas) / 100;
            // obj['top'] = canvasY - Y_tooltip;
            // if (canvasX < width_canvas / 2) {
            //   obj['left'] = canvasX + distanceX;
            // } else {
            //   obj['left'] = canvasX - width_tooltip - distanceX;
            // }

            //แบบที่ 2
            let overSize = 0;
            if (canvasY < height_canvas / 2) {
              if (canvasY + height_tooltip > height_canvas) {
                overSize = canvasY + height_tooltip - height_canvas;
                obj['top'] = canvasY - overSize;
              } else {
                obj['top'] = canvasY;
              }
            } else {
              if (canvasY - height_tooltip < 0) {
                overSize = (canvasY - height_tooltip) * -1;
                obj['top'] = canvasY + overSize - height_tooltip;
              } else {
                obj['top'] = canvasY - height_tooltip;
              }
            }
            if (canvasX < width_canvas / 2) {
              obj['left'] = canvasX + distanceX * 2;
            } else {
              obj['left'] = canvasX - width_tooltip - distanceX;
            }
            return obj;
          },
          formatter: function (params: any) {
            // ใช้ HTML ในการกำหนดรูปแบบของ Tooltip
            return `<div id="heatmap-tooltip" class="heatmap-tooltip">
            <div style="width: 400px; height: auto;">
              <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: #fff; padding: 0 0 7px 0">
                <div class="heatmap-tooltip-day" style="font-size: 13px; color: #606A6C; margin-bottom: 12px;">Mon</div>
                <div class="heatmap-tooltip-total-messages" style="font-size: 20px; color: black; margin-bottom: 3px; font-weight: bold;">0</div>
                <div id="heatmap-tooltip-custom-classifier" style="font-size: 12px; color: black;">${t('communication.message_tooltip')}</div>
              </div>
              <table style="width: max-content; min-width: 100%; border-spacing: 0px !important;">
                <thead>
                  <tr style="background-color: #f7f7f7; text-align: center;">
                    <th style="padding: 7px 0;">${t('communication.channel')}</th>
                    <th>${t('communication.campaign_text')}</t>
                    <th id="heatmap-tooltip-custom-column">${t('communication.message_tooltip')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody style="text-align: center; background-color: #fff;">
                  <tr>
                    <td style="padding: 10px 0; color: black;"><img src="${LineOaIcon}" style="height:20px;"></img> Line</td>
                    <td class="heatmap-tooltip-campaign-line" style="padding: 10px 0; color: black;">0</td>
                    <td class="heatmap-tooltip-messages-line" style="padding: 10px 0; color: black;">0</td>
                    <td><div id="heatmap-tooltip-button-line" class="heatmap-tooltip-button-container"><img src="${SeeMoreIcon}"></img>
                    <span class="tooltiptext">${t('communication.see_more')}</span></div></td>
                  </tr>
                <tbody>
              </table>
            </div>
          </div>`;
          },
        };
        newOption.tooltip = presetTooltip;

        let presetGrid: any = {};
        presetGrid = {
          ...newOption.grid,
          height: '90%',
          top: '0%',
        };
        newOption.grid = presetGrid;

        return newOption;
      }
      case charts_type.OVERVIEW: {
        return rawData.data.forEach((data: Overview.Item, index: number) => {
          chartsObj.Comm_006_OverviewTotalStatus.items[index].value = data.value;
        });
      }
      default:
        return newOption;
    }
  }

  function chartPropsMapper(input: Charts.ChartsProps.Request.Response): Charts.ChartsProps {
    return {
      chartId: input.chartid,
      chartSetId: input.chartsetid,
      chartsType: input.type_name,
      title: input.chart_name || 'Not found, chart name',
      tooltip: input.chart_desc || 'Not found, tooltip',
      options: <EChartsOption>input.options,
      graphRatioHeight: input.ratio_height,
      graphRatioWidth: input.ratio_width,
      implType: input.impl_type,
      implKeyRef: input.impl_key_ref,
      extra: {
        top: {
          show: !!input.extra.extra_top,
          type: input.extra.extra_top,
        },
        left: {
          show: !!input.extra.extra_left,
          type: input.extra.extra_left,
        },
        right: {
          show: !!input.extra.extra_right,
          type: input.extra.extra_right,
        },
        bottom: {
          show: !!input.extra.extra_bottom,
          type: input.extra.extra_bottom,
        },
      },
      colorList: input.color_list.map((el) => ({
        seq: el.seq,
        colorId: el.colorid,
        colorName: el.colorname,
        hexColor: el.hex_color,
      })),
      rawData: {
        title: input.rawdata.title,
        data: input.rawdata.data,
        filter: {
          dateTime: input.rawdata.filter.date_time,
          source: input.rawdata.filter.source,
          store: input.rawdata.filter.store,
          dynamic_filter: input.rawdata.filter.dynamic_filter,
        },
      },
    };
  }

  function resolveExtraTopProps(chartKey: string) {
    if (chartsObj[chartKey as keyof typeof chartsObj].props.extra.top.show) {
      switch (chartsObj[chartKey as keyof typeof chartsObj].props.chartsType) {
        case charts_type.PIE: {
          // TODO: "extra-top-legend" should be "legend" need to be consult backend
          if (chartsObj[chartKey as keyof typeof chartsObj].props.extra.top.type === 'extra-top-legend') {
            return chartsObj[chartKey as keyof typeof chartsObj].props.rawData.data.map((el: any, index: number) => ({
              label: el.data,
              value: el.value,
              color: chartsObj[chartKey as keyof typeof chartsObj].props.colorList[index].hexColor,
            })) as ExtraTopLegendItem[];
          } else {
            return;
          }
        }
        default: {
          return;
        }
      }
    } else {
      return;
    }
  }

  function fetchChart(key: string) {
    switch (key) {
      case charts_key.TOTAL_STATUS:
        return buildChartOption(key);
      case charts_key.DELIVERY_RANGE:
        return buildChartOption(key);
      case charts_key.CLICK_RANGE:
        return buildChartOption(key);
      case charts_key.DELIVERY:
        return buildChartOption(key);
      case charts_key.DELIVERY_BY_CAMPAIGN:
        return buildChartOption(key);
      case charts_key.LINE_NEW_SUBSCRIBE:
        return buildChartOption(key);
      case charts_key.LINE_SUBSCRIBE:
        return buildChartOption(key);
    }
  }

  return {
    chartsObj,
    startDateTime,
    endDateTime,
    isLoadingDataInfo,
    fetchChart,
    fetchDataInfo,
    onExportReport,
    resolveExtraTopProps,
  };
}
