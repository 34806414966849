import { reactive, ref, Ref, getCurrentInstance, onMounted } from 'vue';
import { renderSlotName, resolveComponent } from '@views/components/chart/utils/resolver';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';

import { saveAs } from 'file-saver';

import api from '@services/api';

import biModel from '@models/bi';
import useValidationModal from '@views/components/modal/hooks/useValidationModal';
import { getLanguageDisplayModalError } from '@/utils/useLanguageDisplay';
interface Props {
  isOpen: boolean;
  chartName: string;
  description: string;
  filters: Record<string, any>[];
  dataInfo: DataInfoModal.DataInfoTable;
  chartDataInfoExportProps: Charts.ChartDataInfoExportProps;
}

export default function useDataInfoModal(props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const loadingExport: Ref<boolean> = ref(false);
  const { fetchBiChartExportDataInfoModel } = biModel();

  const { openSuccessModal, openErrorModal } = useValidationModal();

  const { t } = useI18n();

  const chartDataInfoExportProps: Ref<Charts.ChartDataInfoExportProps> = ref({
    chartLayoutId: props.chartDataInfoExportProps.chartLayoutId,
    chartId: props.chartDataInfoExportProps.chartId,
    chartSetId: props.chartDataInfoExportProps.chartSetId,
    implType: props.chartDataInfoExportProps.implType,
    implKeyRef: props.chartDataInfoExportProps.implKeyRef,
    filters: props.chartDataInfoExportProps.filters,
  });

  const columns: Ref<BaseTable.TableColumn[]> = ref([]);
  const data: Ref<Record<string, any>[]> = ref([]);
  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 5,
    totalRows: 0,
  });

  const onCloseModal = () => {
    vm?.$emit('close-modal');
  };

  const resolveInfoFilterKey = (key: string) => {
    switch (key) {
      case 'startDate':
        return t('bi.startDate');
      case 'endDate':
        return t('bi.endDate');
      case 'source':
        return t('bi.source');
    }
  };

  const resolveInfoFilterValue = (value: string | string[]) => {
    if (value instanceof Array) {
      return value;
    } else {
      const date = new Date(value);
      if (date instanceof Date) {
        return DateTime.fromISO(date.toISOString()).toFormat('dd MMM yyyy');
      }
    }
  };

  const onClickExportDataInfo = () => {
    loadingExport.value = true;

    fetchBiChartExportDataInfoModel.payload.ChartLayoutId = chartDataInfoExportProps.value.chartLayoutId!;
    fetchBiChartExportDataInfoModel.payload.ChartSetId = chartDataInfoExportProps.value.chartSetId!;
    fetchBiChartExportDataInfoModel.payload.ChartId = chartDataInfoExportProps.value.chartId!;
    fetchBiChartExportDataInfoModel.payload.Impl_Type = chartDataInfoExportProps.value.implType!;
    fetchBiChartExportDataInfoModel.payload.Impl_Key_Ref = chartDataInfoExportProps.value.implKeyRef;
    fetchBiChartExportDataInfoModel.payload.Filters = chartDataInfoExportProps.value.filters.map((el) => ({
      Key: el.key,
      Value: el.value,
    }));

    api
      .apiRequest(fetchBiChartExportDataInfoModel)
      .then((response) => {
        const datesTime = new Date();
        saveAs(
          response as Blob | string,
          `Report-${datesTime.getFullYear()}${datesTime.getMonth()}${datesTime.getDate()}_${datesTime.getHours()}${datesTime.getMinutes()}${datesTime.getSeconds()}.csv`,
        );
      })
      .catch((error) => {
        openErrorModal(
          error.data.error.locale[getLanguageDisplayModalError()].title as string,
          error.data.error.locale[getLanguageDisplayModalError()].message as string,
        );
      })
      .finally(() => {
        loadingExport.value = false;
        openSuccessModal('ทำรายการสำเร็จ');
      });
  };

  onMounted(() => {
    columns.value = props.dataInfo.columns;
    data.value = props.dataInfo.data;
    pagination.totalRows = data.value.length;
  });

  return {
    columns,
    data,
    pagination,
    onCloseModal,
    renderSlotName,
    resolveComponent,
    resolveInfoFilterKey,
    resolveInfoFilterValue,
    onClickExportDataInfo,
    loadingExport,
  };
}
