import { i18n } from '@/libs/i18n';

export type GenderAndAge = '1-male' | '2-female' | '3-not-specified' | '1-child' | '2-teen' | '3-late-teen' | '4-working' | '5-old';

export const GENDER_AND_AGE = {
  MALE: '1-male',
  FEMALE: '2-female',
  NOT_SPECIFIED: '3-not-specified',
  CHILD: '1-child',
  TEEN: '2-teen',
  LATE_TEEN: '3-late-teen',
  WORKING: '4-working',
  OLD: '5-old',
  NULL_DATA: 'null',
};

export function resolveGenderTypeTh(type: string, options?: any): string {
  if (options && Object.keys(options as object).length !== 0) {
    return options[type];
  } else {
    switch (type) {
      case GENDER_AND_AGE.FEMALE:
        return i18n.global.t('bi.female');
      case GENDER_AND_AGE.MALE:
        return i18n.global.t('bi.male');
      case GENDER_AND_AGE.NOT_SPECIFIED:
        return i18n.global.t('bi.not_specified');
      case GENDER_AND_AGE.CHILD:
        return i18n.global.t('bi.child') + ` (0-10${i18n.global.t('mka.modal_year')})`;
      case GENDER_AND_AGE.TEEN:
        return i18n.global.t('bi.teen') + ` (11-20${i18n.global.t('mka.modal_year')})`;
      case GENDER_AND_AGE.LATE_TEEN:
        return i18n.global.t('bi.late_teen') + ` (21-30${i18n.global.t('mka.modal_year')})`;
      case GENDER_AND_AGE.WORKING:
        return i18n.global.t('bi.working') + ` (31-60${i18n.global.t('mka.modal_year')})`;
      case GENDER_AND_AGE.OLD:
        return i18n.global.t('bi.old') + ` (61${i18n.global.t('bi.year_over')})`;
      default:
        return 'ไม่พบ';
    }
  }
}

export const DEFUALT_TOTAL_SUFFIX_FROM_ITEM_INDEX = 0;
