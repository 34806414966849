import { ref, Ref, reactive, watch, getCurrentInstance, toRaw, onMounted } from 'vue';

import { createPopper } from '@popperjs/core';
import { useI18n } from 'vue-i18n';
import calendarModel from '@/models/api/communication/apiCalendar';
import apiService from '@/services/api';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import thLocale from '@fullcalendar/core/locales/th'; // ภาษาไทย
import enLocale from '@fullcalendar/core/locales/en-gb'; // ภาษาอังกฤษ
import noteModel from '@/models/api/communication/apiNote';

import { NOTE_CHANNEL_FLAGS } from '@/constants/modules/commu/note-channel';
import { NOTE_STATUS } from '@/constants/modules/commu/note-status';
import { CAMPAIGN_CHENNEL } from '@/constants/modules/commu/campaign-chennel';
import { CAMPAIGN_STATUS } from '@/constants/modules/commu/campaign-status';

interface Props {
  isNoteModalOpen: boolean;
}

export default function useCalendarViewTab(props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const { fetchCalendarListModel } = calendarModel();
  const { getNoteDetailModel } = noteModel();
  const { openDefaultErrorModal } = useValidationModal();

  const { t, locale } = useI18n();

  const templateCategoryList: Ref<Communication.TemplateCard.Response.Category[]> = ref([]);
  const templateList: Ref<Communication.Template.Template[]> = ref([]);
  const isChangedListStyle: Ref<boolean> = ref(false);
  const keyChange: Ref<number> = ref(0);
  const prevBackgroundColor: Ref<string> = ref(null!);
  const fullCalendar: Ref<any> = ref(null!);
  const fullCalendarApi: Ref<any> = ref(null!);
  const currentDate: Ref<string[]> = ref(null!);
  const currentMonthYear: Ref<string> = ref(null!);
  const calendarList: Ref<Communication.Calendar.Request.Response.CalendarList[]> = ref([]);
  const events: Ref<any[]> = ref([]);
  const selectedChannel: Ref<Record<string, number>[]> = ref([]);
  const selectedCampaignStatus: Ref<Record<string, number>[]> = ref([]);
  const selectedNoteStatus: Ref<Record<string, number>[]> = ref([]);
  const filteredChannel: Ref<number[]> = ref([]);
  const filteredCampaignStatus: Ref<number[]> = ref([]);
  const filteredNoteStatus: Ref<number[]> = ref([]);
  const isNoteEditMode: Ref<boolean> = ref(false);
  const isNoteDetailModalOpen: Ref<boolean> = ref(false);
  const eventNoteDetail: Ref<Communication.Calendar.Request.Response.CalendarList> = ref(null!);
  const noteDetail: Ref<Communication.Note.Request.Response.Note> = ref(null!);
  const modalProps: Ref<Communication.Calendar.ModalProps> = ref({
    campaignId: 0,
    isCampaign: 0,
    campaignChannel: 0,
    name: '',
    providerName: '',
    recpDispatchable: 0,
    campaignStatus: 0,
    campaignStatusDesc: '',
    scheduleMode: 0,
    scheduleDt: '',
    startOnLabel: '',
    repeatMode: 0 as Communication.Calendar.RepeatMode,
    repeatEveryLabel: '',
    repeatOnValue: [],
    endDt: '',
  });

  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin],
    navLinks: false,
    initialView: 'dayGridMonth',
    locales: [thLocale, enLocale],
    locale: locale.value,
    dayHeaderFormat: { weekday: 'long' }, // แสดงชื่อวันแบบเต็ม
    monthHeaderFormat: { month: 'long', year: 'numeric' }, // แสดงชื่อเดือนแบบเต็ม
    firstDay: 0, // เริ่มที่วันอาทิตย์
    timeZone: 'local',
    headerToolbar: {
      start: '',
      center: '',
      end: '',
    },
    height: 750,
    dayMaxEventRows: true,
    eventClick: handleEventClick,
    eventMouseEnter: handleEventMouseEnter,
    eventMouseLeave: handleEventMouseLeave,
    events: events,
    eventDisplay: 'block',
    fixedWeekCount: false,
  });

  const calendarFilterForm: Record<string, any> = reactive({
    channel: [],
    campaignStatus: [],
    noteStatus: [],
    search: '',
    order: [],
  });

  const isLoading = reactive({ calendarViewList: false });

  const calendarFilterList: Ref<Record<string, any>> = ref({
    channel: [
      {
        label: 'SMS',
        value: 1,
      },
      {
        label: 'E-mail',
        value: 2,
      },
      {
        label: 'LINE',
        value: 3,
      },
      // {
      //   label: 'Mobile Push',
      //   value: 4,
      // },
    ],
    campaignStatus: [
      {
        label: t('communication.preparing'),
        value: 2,
      },
      {
        label: t('communication.scheduled'),
        value: 4,
      },
      {
        label: t('communication.dispath'),
        value: 5,
      },
      {
        label: t('communication.pause'),
        value: 7,
      },
      {
        label: t('communication.complete'),
        value: 9,
      },
    ],
    noteStatus: [
      {
        label: t('communication.open'),
        value: NOTE_STATUS.OPEN,
      },
      {
        label: t('communication.complete'),
        value: NOTE_STATUS.COMPLETE,
      },
    ],
  });

  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
  });

  const field: BaseTable.TableColumn[] = [
    { key: 'icon', label: '', sortable: false },
    { key: 'name', label: t('communication.campaign_name'), sortable: false },
    { key: 'updated_dt', label: t('communication.last_update'), sortable: true },
    { key: 'updated_by_firstname', label: t('communication.update_by'), sortable: true },
  ];

  let searchTimeout: ReturnType<typeof setTimeout> = null!;

  function fetchCalendarList() {
    isLoading.calendarViewList = true;
    calendarList.value = [];
    events.value = [];
    fetchCalendarListModel.payload.Year = parseInt(currentDate.value[0]);
    fetchCalendarListModel.payload.Month = parseInt(currentDate.value[1]);
    fetchCalendarListModel.payload.Channel = calendarFilterForm.channel;
    fetchCalendarListModel.payload.Campaign_Status = calendarFilterForm.campaignStatus;
    fetchCalendarListModel.payload.Note_Status = calendarFilterForm.noteStatus;
    fetchCalendarListModel.payload.Search = calendarFilterForm.search.trim();
    fetchCalendarListModel.payload.Filters = [];
    fetchCalendarListModel.payload.Ordering = calendarFilterForm.order;

    apiService
      .apiRequest(fetchCalendarListModel)
      .then((response) => {
        const resp = response.data.result;
        if (resp.length > 0) {
          resp.forEach((e: any, index: number) => {
            const event: Communication.Calendar.Request.Response.CalendarList = {
              index: index,
              isCampaign: e.iscampaign,
              noteId: e.noteid,
              noteStatus: e.note_status,
              noteStatusDesc: e.note_status_desc,
              noteChannel: e.note_channel,
              noteChannelDesc: e.note_channel_desc,
              campaignId: e.campaignid,
              campaignStatus: e.campaign_status,
              campaignStatusDesc: e.campaign_status_desc,
              campaignChannel: e.campaign_channel,
              campaignChannelDesc: e.campaign_channel_desc,
              campaignSubtype: e.campaign_subtype,
              campaignSubtypeDesc: e.campaign_subtype_desc,
              name: e.name,
              description: e.description,
              title: e.title,
              scheduleMode: e.schedule_mode,
              scheduleModeDesc: e.schedule_mode_desc,
              scheduleDt: e.schedule_dt,
              nextScheduleDt: e.next_schedule_dt,
              startOnLabel: e.start_on_label,
              repeatEvery: e.repeat_every,
              repeatMode: e.repeat_mode,
              repeatOn: e.repeat_on,
              repeatEveryLabel: e.repeat_every_label,
              repeatOnVal: e.repeat_on_val,
              endMode: e.end_mode,
              endModeDesc: e.end_mode_desc,
              endDt: e.end_dt,
              endOccurrence: e.end_occurrence,
              endOnLabel: e.end_on_label,
              recpDispatchable: e.recp_dispatchable,
              providerId: e.providerid,
              providerName: e.provider_name,
              updatedBy: e.updated_by,
              updated_by_firstname: e.updated_by_firstname,
              updated_dt: e.updated_dt,
              createdDt: e.created_dt,
            };
            calendarList.value.push(event);
          });
          mappingListEvent();
          filterEventsTableView();
        }
        pagination.totalRows = calendarList.value.length;
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isLoading.calendarViewList = false;
      });
  }

  function mappingListEvent() {
    if (calendarList.value.length > 0) {
      calendarList.value.forEach((e, index) => {
        const event: any = {
          index,
          title: e.name,
          start: e.scheduleDt || e.nextScheduleDt,
          backgroundColor: resolveBGColor(e.campaignChannel as number, e.noteChannel as number, e.noteStatus as number),
          textColor: resolveFontColor(e.isCampaign, e.noteStatus as number),
          borderColor: e.isCampaign === 0 && e.noteStatus === 1 ? '#D1D1D1' : 'transparent',
          isCampaign: e.isCampaign,
          noteId: e.noteId,
          noteStatus: e.noteStatus,
          noteStatusDesc: e.noteStatusDesc,
          noteChannel: e.noteChannel,
          noteChannelDesc: e.noteChannelDesc,
          campaignId: e.campaignId,
          campaignStatus: e.campaignStatus,
          campaignChannel: e.campaignChannel,
          campaignStatusDesc: resolveCampaignStatusDesc(e.campaignStatus),
          campaignChannelDesc: e.campaignChannelDesc,
          campaignSubtype: e.campaignSubtype,
          campaignSubtypeDesc: e.campaignSubtypeDesc,
          emailTitle: e.title,
          name: e.name,
          description: e.description,
          scheduleMode: e.scheduleMode,
          scheduleModeDesc: e.scheduleModeDesc,
          scheduleDt: e.scheduleDt,
          nextScheduleDt: e.nextScheduleDt,
          startOnLabel: e.startOnLabel,
          repeatEvery: e.repeatEvery,
          repeatMode: e.repeatMode,
          repeatOn: e.repeatOn,
          repeatOnVal: e.repeatOnVal,
          repeatEveryLabel: e.repeatEveryLabel,
          endMode: e.endMode,
          endModeDesc: e.endModeDesc,
          endDt: e.endDt,
          endOccurrence: e.endOccurrence,
          endOnLabel: e.endOnLabel,
          recpDispatchable: e.recpDispatchable,
          providerId: e.providerId,
          providerName: e.providerName,
          updatedBy: e.updatedBy,
          updatedByFirstname: e.updated_by_firstname,
          updatedDt: e.updated_dt,
          createdDt: e.createdDt,
        };
        events.value.push(event);
      });
    }
    fullCalendarApi.value.refetchEvents();
  }

  function filterEventsTableView() {
    calendarList.value = calendarList.value.filter(
      (event) => new Date((event.scheduleDt || event.nextScheduleDt) as string).getMonth() + 1 === parseInt(currentDate.value[1]),
    );
  }

  const onChangeSearch = () => {
    if (searchTimeout) clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      if (isChangedListStyle.value) {
        pagination.currentPage = 1;
      }

      return fetchCalendarList();
    }, 500);
  };

  const onChangeListStyle = () => {
    pagination.currentPage = 1;
    calendarFilterForm.order = [];
    isChangedListStyle.value = !isChangedListStyle.value;
    if (isChangedListStyle.value) {
      return keyChange.value++;
    }

    if (events.value.length > 0) {
      return fullCalendarApi.value.render();
    }
  };

  function onFilterChannel_Status(mode: string) {
    if (mode === 'channel') {
      filteredChannel.value = [];
      selectedChannel.value.forEach((ele: Record<string, number>) => {
        filteredChannel.value.push(toRaw(ele.value));
      });
      calendarFilterForm.channel = filteredChannel.value;
    } else if (mode === 'campaign') {
      filteredCampaignStatus.value = [];
      selectedCampaignStatus.value.forEach((ele: Record<string, number>) => {
        filteredCampaignStatus.value.push(toRaw(ele.value));
      });
      calendarFilterForm.campaignStatus = filteredCampaignStatus.value;
    } else if (mode === 'note') {
      filteredNoteStatus.value = [];
      selectedNoteStatus.value.forEach((ele: Record<string, number>) => {
        filteredNoteStatus.value.push(toRaw(ele.value));
      });
      calendarFilterForm.noteStatus = filteredNoteStatus.value;
    }

    if (isChangedListStyle.value) {
      calendarFilterForm.order = [];
      pagination.currentPage = 1;
      keyChange.value++;
    }

    return fetchCalendarList();
  }

  const onSort = (sortedColumn: { key: string; direction: string }) => {
    if (sortedColumn.key === 'name') return;

    if (sortedColumn.direction) {
      const sortModel = [
        {
          Seq: 1,
          Key: sortedColumn.key,
          Direction: sortedColumn.direction.toLocaleLowerCase(),
        },
      ];

      calendarFilterForm.order = sortModel as Communication.Template.Request.Ordering[];
    } else {
      calendarFilterForm.order = [];
    }
    pagination.currentPage = 1;
    return fetchCalendarList();
  };

  function handleEventClick(info: any): void {
    if (info.event.extendedProps.isCampaign === 0) {
      eventNoteDetail.value = null!;
      eventNoteDetail.value = info.event.extendedProps;
      isNoteDetailModalOpen.value = true;
    } else {
      const target: HTMLElement | null = document.querySelector(`#target_${info.event.extendedProps.index}`);
      const tooltip: HTMLElement | null = document.querySelector(`#tooltip_${info.event.extendedProps.index}`);
      if (target && tooltip) {
        tooltip.classList.add('show');
        createPopper(target, tooltip, {
          placement: 'right',
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['left', 'right'],
              },
            },
          ],
        });
      }
    }
  }

  function onClickOutside() {
    const elements: any = document.querySelectorAll('#full-calendar .modal-container');
    elements.forEach((element: Element) => {
      if (element?.classList.contains('show')) {
        element?.classList.remove('show');
      }
    });
  }

  function handleEventMouseEnter(info: any) {
    info.el.style.boxShadow = 'rgba(0, 0, 0, 0.24) 0px 3px 8px';
    prevBackgroundColor.value = info.el.style.backgroundColor;
    info.el.style.backgroundColor = '#fff';
    info.el.style.color = prevBackgroundColor.value;
  }

  function handleEventMouseLeave(info: any) {
    info.el.style.boxShadow = '';
    info.el.style.backgroundColor = prevBackgroundColor.value;
    info.el.style.color = '#fff';
    prevBackgroundColor.value = null!;
  }

  function resolveBGColor(campaignChannel: number, noteChannel: number, noteStatus: number) {
    if (noteChannel) {
      return noteStatus === 1 ? '#f7f7f7' : '#eff0f0';
    }
    switch (campaignChannel) {
      case CAMPAIGN_CHENNEL.SMS:
        return '#7F9CC3';
      case CAMPAIGN_CHENNEL.EMAIL:
        return '#78C5FD';
      case CAMPAIGN_CHENNEL.LINE_OA:
        return '#5AAF1A';
    }
  }

  function resolveFontColor(isCampaign: number, noteStatus: number) {
    if (isCampaign === 0) {
      if (noteStatus === 1) {
        return '#000';
      } else {
        return '#5E6A6C';
      }
    } else {
      return '';
    }
  }

  function resolveCampaignStatusDesc(status: Communication.Calendar.CampaignStatus = 1) {
    switch (status) {
      case CAMPAIGN_STATUS.DRAFT:
        return t('communication.draft');
      case CAMPAIGN_STATUS.PREPARING:
        return t('communication.preparing');
      case CAMPAIGN_STATUS.SCHEDULED:
        return t('communication.scheduled');
      case CAMPAIGN_STATUS.DISPATCH:
        return t('communication.dispath');
      case CAMPAIGN_STATUS.PAUSE:
        return t('communication.pause');
      case CAMPAIGN_STATUS.COMPLETE:
        return t('communication.complete');
      case CAMPAIGN_STATUS.CANCEL:
        return t('communication.cancel');
      default:
        return t('communication.draft');
    }
  }

  function onClickCalendarButton(mode: string) {
    if (mode === 'prev') fullCalendarApi.value.prev();
    else if (mode === 'next') fullCalendarApi.value.next();
    else if (mode === 'today') fullCalendarApi.value.today();

    currentDate.value = [];
    currentDate.value = fullCalendarApi.value.getCurrentData().currentDate.toISOString().split('-');
    currentMonthYear.value = fullCalendarApi.value.getDate().toLocaleString(locale.value, { month: 'long', year: 'numeric' });

    if (isChangedListStyle.value) {
      calendarFilterForm.order = [];
      pagination.currentPage = 1;
      keyChange.value++;
    }

    return fetchCalendarList();
  }

  function onCloseNoteModal() {
    isNoteEditMode.value = false;
    noteDetail.value = null!;
    vm?.$emit('close-modal', false);
  }

  function onApplyNote() {
    onCloseNoteModal();
    setTimeout(() => {
      calendarFilterForm.channel = [];
      calendarFilterForm.campaignStatus = [];
      calendarFilterForm.noteStatus = [];
      calendarFilterForm.search = '';
      selectedChannel.value = [];
      selectedCampaignStatus.value = [];
      selectedNoteStatus.value = [];

      currentDate.value = [];
      fullCalendarApi.value.today();
      currentDate.value = fullCalendarApi.value.getCurrentData().currentDate.toISOString().split('-');
      currentMonthYear.value = fullCalendarApi.value.getDate().toLocaleString(locale.value, { month: 'long', year: 'numeric' });

      if (isChangedListStyle.value) {
        calendarFilterForm.order = [];
        pagination.currentPage = 1;
        keyChange.value++;
      }

      return fetchCalendarList();
    }, 300);
  }

  function onRowClick(item: Communication.Calendar.Request.Response.CalendarList) {
    if (item.isCampaign === 0) {
      eventNoteDetail.value = null!;
      eventNoteDetail.value = item;
      isNoteDetailModalOpen.value = true;
    } else {
      const rows = document.querySelectorAll('.base-table tr.dragable-row');

      rows.forEach((row: any, i: number) => {
        row.classList.add(`target_row_${i}`);
      });

      const index = (item.index as number) % pagination.perPage;
      const target: HTMLElement | null = document.querySelector(`.target_row_${index}`);
      const tooltip: HTMLElement | null = document.querySelector(`#tooltip_row`);

      modalProps.value.campaignId = item.campaignId as number;
      modalProps.value.isCampaign = item.isCampaign;
      modalProps.value.campaignChannel = item.campaignChannel as number;
      modalProps.value.name = item.name;
      modalProps.value.providerName = item.providerName as string;
      modalProps.value.recpDispatchable = item.recpDispatchable as number;
      modalProps.value.campaignStatus = item.campaignStatus as number;
      modalProps.value.campaignStatusDesc = item.campaignStatusDesc;
      modalProps.value.scheduleMode = item.scheduleMode;
      modalProps.value.scheduleDt = item.scheduleDt as string;
      modalProps.value.startOnLabel = item.startOnLabel;
      modalProps.value.repeatMode = item.repeatMode as Communication.Calendar.RepeatMode;
      modalProps.value.repeatEveryLabel = item.repeatEveryLabel;
      modalProps.value.repeatOnValue = item.repeatOnVal;
      modalProps.value.endDt = item.endDt as string;

      if (target && tooltip) {
        tooltip.classList.add('show');
        createPopper(target, tooltip, {
          placement: 'top',
          modifiers: [
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['top', 'bottom'],
              },
            },
          ],
        });
      }
    }
  }

  function onClickOutsideRow() {
    const element: any = document.querySelector('#tooltip_row');
    if (element?.classList.contains('show')) {
      element?.classList.remove('show');
    }
  }

  function onCloseNoteDetailModal() {
    isNoteDetailModalOpen.value = false;
  }

  function onDeleteNote() {
    isNoteDetailModalOpen.value = false;

    return setTimeout(() => {
      fetchCalendarList();
    }, 300);
  }

  function isShowSMSIcon(channels: number) {
    if (channels & NOTE_CHANNEL_FLAGS.SMS) {
      return true;
    }
    return false;
  }

  function isShowEmailIcon(channels: number) {
    if (channels & NOTE_CHANNEL_FLAGS.EMAIL) {
      return true;
    }
    return false;
  }

  function isShowLineOaIcon(channels: number) {
    if (channels & NOTE_CHANNEL_FLAGS.LINE_OA) {
      return true;
    }
    return false;
  }

  function onChangeNoteStatus() {
    isNoteDetailModalOpen.value = false;

    return setTimeout(() => {
      fetchCalendarList();
    }, 300);
  }

  function onClickEditNote(noteId: number) {
    isNoteDetailModalOpen.value = false;
    getNoteDetailModel.payload.NoteId = noteId;

    apiService
      .apiRequest(getNoteDetailModel)
      .then((resp) => {
        noteDetail.value = resp.data.result;
        isNoteEditMode.value = true;
        vm?.$emit('open-note-modal', true);
      })
      .catch((err) => openDefaultErrorModal(err));
  }

  onMounted(() => {
    fullCalendar.value = vm?.$refs['full-calendar'] as any;
    fullCalendarApi.value = fullCalendar.value.getApi();
    currentDate.value = fullCalendarApi.value.getCurrentData().currentDate.toISOString().split('-');
    currentMonthYear.value = fullCalendarApi.value.getDate().toLocaleString(locale.value, { month: 'long', year: 'numeric' });
    fetchCalendarList();
  });

  return {
    modalProps,
    currentMonthYear,
    selectedChannel,
    selectedCampaignStatus,
    selectedNoteStatus,
    calendarList,
    fullCalendarApi,
    calendarOptions,
    isLoading,
    templateCategoryList,
    field,
    pagination,
    calendarFilterForm,
    calendarFilterList,
    isChangedListStyle,
    templateList,
    keyChange,
    isNoteEditMode,
    isNoteDetailModalOpen,
    isShowSMSIcon,
    isShowEmailIcon,
    isShowLineOaIcon,
    eventNoteDetail,
    noteDetail,
    onFilterChannel_Status,
    onChangeSearch,
    onChangeListStyle,
    onSort,
    onClickCalendarButton,
    onCloseNoteModal,
    onApplyNote,
    onClickOutside,
    onRowClick,
    onClickOutsideRow,
    onCloseNoteDetailModal,
    onDeleteNote,
    onChangeNoteStatus,
    onClickEditNote,
  };
}
