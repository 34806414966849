import { acceptHMRUpdate, defineStore } from 'pinia';

// define store
// see: https://pinia.vuejs.org/core-concepts/
//
// alternative to main store state type, we can also force state type by define the object interface
export interface UserInfo {
  first_name: string;
  last_name: string;
  picture_url: string;
  email: string;
  role_th: string;
  role_en: string;
  mfa_token: string;
  mfa_status: number; // 1 pending, 2 active
  mfa_modal: boolean;
  credential_valid: boolean;
}

export const useUserInfoStore = defineStore('userInfo', {
  state: () =>
    <UserInfo>{
      first_name: '',
      last_name: '',
      picture_url: '',
      email: '',
      role_th: '',
      role_en: '',
      mfa_token: '',
      mfa_status: 0,
      mfa_modal: false,
      credential_valid: false,
    },
  actions: {
    loadUserInfoFromLocalStorage(this: any) {
      const userInfoString = localStorage.getItem('userInfo');
      if (userInfoString) {
        const userInfo = JSON.parse(userInfoString);
        this.$patch(userInfo);
      }
    },
  },
  persist: {
    key: 'userInfo',
    paths: ['first_name', 'last_name', 'picture_url', 'email', 'role_th', 'role_en'],
    storage: localStorage,
  },
});

// const userInfoStore = useUserInfoStore();
// userInfoStore.loadUserInfoFromLocalStorage();

// to add HMR support with vue-cli/webpack, these code must be added to each store file
if (import.meta.webpackHot) import.meta.webpackHot.accept(acceptHMRUpdate(useUserInfoStore, import.meta.webpackHot));
