import { reactive, ref, Ref, onBeforeMount, onMounted, getCurrentInstance, watch } from 'vue';
import { DateTime } from 'luxon';
import apiService from '@/services/api';
import campaignModel from '@models/communication/campaign';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import { useI18n } from 'vue-i18n';
interface Props {
  campaign: Communication.LineOA.CreateCampaign.CampaignDetail;
}

export default function useSettingAndScheduleTab(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const { fetchProviderListModel } = campaignModel();
  const { openDefaultErrorModal } = useValidationModal();

  const { t } = useI18n();

  const isLoading: Ref<boolean> = ref(false);
  const providerList: Ref<Communication.Provider.Provider[]> = ref([]);
  const selectedProvider: Ref<Campaign.LineOAProvider> = ref(null!);
  const sendDateTime: Ref<Date> = ref(null!);
  const keyChange: Ref<number> = ref(0);
  const selectedRepeatType: Ref<number> = ref(1);
  const selectedRepeatUnit: Ref<number> = ref(null!);
  const repeatDayList: Ref<number[]> = ref([]);
  const endDateTime: Ref<Date> = ref(null!);
  const endOccurrence: Ref<string> = ref(null!);
  const selectedScheduleType: Ref<number> = ref(null!);
  const endMode: Ref<number> = ref(null!);
  const repeatEvery: Ref<string> = ref(null!);

  const scheduleType = reactive([
    {
      text: t('communication.send_now'),
      value: 1,
    },
    {
      text: t('communication.schedule_later'),
      value: 2,
    },
    {
      text: t('communication.repeat'),
      value: 3,
    },
  ]);

  const dateRepeat = reactive([
    {
      name: 'Sunday',
      label: 'S',
      value: 1,
    },
    {
      name: 'Monday',
      label: 'M',
      value: 2,
    },
    {
      name: 'Tuesday',
      label: 'T',
      value: 4,
    },
    {
      name: 'Wednesday',
      label: 'W',
      value: 8,
    },
    {
      name: 'Thursday',
      label: 'T',
      value: 16,
    },
    {
      name: 'Friday',
      label: 'F',
      value: 32,
    },
    {
      name: 'Saturday',
      label: 'S',
      value: 64,
    },
  ]);

  const endCampaignOption = reactive([
    {
      text: 'Never',
      value: 1,
    },
    {
      text: 'On',
      value: 2,
    },
    {
      text: 'After',
      value: 3,
    },
  ]);

  const repeatType = ref([
    {
      label: 'Custom',
      value: 1,
    },
  ]);

  const repeatUnitOptions = ref([
    {
      label: 'Day',
      value: 1,
    },
    {
      label: 'Week',
      value: 2,
    },
    {
      label: 'Month',
      value: 3,
    },
  ]);

  const userInfo = JSON.parse(localStorage.getItem('userInfo') || 'null');
  const timezone = userInfo.timezone || DateTime.fromISO(new Date().toISOString()).zoneName;

  const fetchProviderList = () => {
    isLoading.value = true;
    fetchProviderListModel.payload.Channel = 3;
    fetchProviderListModel.payload.Page = 1;
    fetchProviderListModel.payload.Limit = 100;

    apiService
      .apiRequest(fetchProviderListModel)
      .then((response) => {
        providerList.value = response.data.records as Communication.Provider.Provider[];
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const initData = () => {
    selectedProvider.value = (props.campaign.info.provider as Campaign.LineOAProvider) || null!;
    selectedScheduleType.value = props.campaign.schedule.scheduleMode || null!;
    endMode.value = props.campaign.schedule.endMode || 1;
    sendDateTime.value = props.campaign.schedule.scheduleDt ? new Date(props.campaign.schedule.scheduleDt) : null!;
    endDateTime.value = props.campaign.schedule.endDt ? new Date(props.campaign.schedule.endDt) : null!;
    endOccurrence.value = props.campaign.schedule.endOccurrence ? props.campaign.schedule.endOccurrence?.toString() : '1';
    selectedRepeatUnit.value = props.campaign.schedule.repeatMode || 1;
    repeatEvery.value = props.campaign.schedule.repeatEvery ? props.campaign.schedule.repeatEvery?.toString() : '1';

    initRepeatOnList();
  };

  const initRepeatOnList = () => {
    const repeatOn = props.campaign.schedule.repeatOn || 0;

    if (repeatOn == 0) {
      return;
    }
    if (repeatOn & 1) {
      repeatDayList.value.push(1);
    }
    if (repeatOn & 2) {
      repeatDayList.value.push(2);
    }
    if (repeatOn & 4) {
      repeatDayList.value.push(4);
    }
    if (repeatOn & 8) {
      repeatDayList.value.push(8);
    }
    if (repeatOn & 16) {
      repeatDayList.value.push(16);
    }
    if (repeatOn & 32) {
      repeatDayList.value.push(32);
    }
    if (repeatOn & 64) {
      repeatDayList.value.push(64);
    }
  };

  const getCurrentDay = () => {
    const date = new Date();
    const day = date.getDay();
    if (day === 0) return 1;
    if (day === 1) return 2;
    if (day === 2) return 4;
    if (day === 3) return 8;
    if (day === 4) return 16;
    if (day === 5) return 32;
    if (day === 6) return 64;
  };

  const onSelectProvider = (provider: Communication.Provider.Provider) => {
    selectedProvider.value = provider;
    vm?.$emit('select-sender', JSON.parse(JSON.stringify(selectedProvider.value)));
  };

  const onSelectScheduleType = () => {
    vm?.$emit('select-schedule-type', selectedScheduleType.value);
  };

  onBeforeMount(() => {
    fetchProviderList();
    initData();
  });

  watch(
    () => props.campaign.schedule.scheduleMode,
    () => {
      endMode.value = props.campaign.schedule.endMode || 1;
      sendDateTime.value = props.campaign.schedule.scheduleDt ? new Date(props.campaign.schedule.scheduleDt) : null!;
      endDateTime.value = props.campaign.schedule.endDt ? new Date(props.campaign.schedule.endDt) : null!;
      endOccurrence.value = props.campaign.schedule.endOccurrence ? props.campaign.schedule.endOccurrence?.toString() : '1';
      selectedRepeatUnit.value = props.campaign.schedule.repeatMode || 1;
      repeatEvery.value = props.campaign.schedule.repeatEvery ? props.campaign.schedule.repeatEvery?.toString() : '1';

      initRepeatOnList();
      keyChange.value++;
    },
  );

  watch(
    () => selectedRepeatUnit.value,
    () => {
      if (selectedRepeatUnit.value === 2 && repeatDayList.value?.length === 0) {
        repeatDayList.value.push(getCurrentDay() as number);
        vm?.$emit('select-repeate-on', repeatDayList.value);
      }
    },
  );

  watch(
    () => repeatDayList.value,
    () => {
      if (repeatDayList.value?.length === 0) {
        repeatDayList.value.push(getCurrentDay() as number);
      }
    },
  );

  return {
    selectedScheduleType,
    scheduleType,
    endCampaignOption,
    dateRepeat,
    providerList,
    isLoading,
    selectedProvider,
    sendDateTime,
    timezone,
    keyChange,
    repeatType,
    selectedRepeatType,
    repeatUnitOptions,
    selectedRepeatUnit,
    repeatDayList,
    endDateTime,
    endOccurrence,
    endMode,
    repeatEvery,
    onSelectProvider,
    onSelectScheduleType,
  };
}
