import { markRaw, onBeforeMount, ref, Ref, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import apiService from '@/services/api';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import templateModel from '@/models/communication/template';
import campaignModel from '@/models/communication/campaign';

import { TemplateMainTypeEnum } from '@/constants/modules/commu/template-mainType.enum';

import All from '@/assets/icons/modules/communication/template-tab/template-category/All.vue';
import Popular from '@/assets/icons/modules/communication/template-tab/template-category/Popular.vue';
import Promotions from '@/assets/icons/modules/communication/template-tab/template-category/Promotions.vue';
import Seasonal from '@/assets/icons/modules/communication/template-tab/template-category/Seasonal.vue';
import Awareness from '@/assets/icons/modules/communication/template-tab/template-category/Awareness.vue';
import WinBack from '@/assets/icons/modules/communication/template-tab/template-category/WinBack.vue';
import Engagement from '@/assets/icons/modules/communication/template-tab/template-category/Engagement.vue';
import Notification from '@/assets/icons/modules/communication/template-tab/template-category/Notification.vue';
import Other from '@/assets/icons/modules/communication/template-tab/template-category/Other.vue';
import UseIcon from '@assets/icons/modules/communication/template-tab/Use.vue';
import PreviewIcon from '@assets/icons/modules/communication/template-tab/Preview.vue';
import EditIcon from '@assets/icons/modules/communication/template-tab/Edit.vue';
import DuplicateIcon from '@assets/icons/modules/communication/template-tab/Duplicate.vue';
import DeleteIcon from '@assets/icons/modules/communication/template-tab/Delete.vue';

export default function useTemplateTab() {
  const router = useRouter();

  const { fetchTemplateCardModel, deleteTemplateModel, fetchTemplateListModel, duplicateTemplateModel } = templateModel();
  const { createCampaignModel } = campaignModel();

  const { t } = useI18n();

  const { openDefaultErrorModal, openSuccessModal, openWarningModal } = useValidationModal();

  const templateCategoryList: Ref<Communication.TemplateCard.Response.Category[]> = ref([]);
  const templateList: Ref<Communication.Template.Template[]> = ref([]);
  const subTypeList: Ref<number[]> = ref([]);
  const isChangedListStyle: Ref<boolean> = ref(false);
  const isTemplateLoading = ref(false);
  const isTemplateTableLoading = ref(false);
  const templateType: Ref<number> = ref(1);
  const keyChange: Ref<number> = ref(0);

  const previewModal: { isOpen: boolean; template: Communication.Template.Template } = reactive({
    isOpen: false,
    template: null!,
  });

  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
  });

  const templateFilterForm: Record<string, any> = reactive({
    search: '',
    subType: null,
    order: null,
    channel: null,
    category: null,
  });

  const standardActionList: Ref<Array<any>> = ref([
    {
      key: 'use',
      label: t('communication.use'),
      icon: markRaw(UseIcon),
      tooltip: t('communication.use'),
      tooltipBackgroundColor: '#BBDBFF',
      tooltipColor: '#007FFF',
    },
    {
      key: 'preview',
      label: t('communication.preview'),
      icon: markRaw(PreviewIcon),
      tooltip: t('communication.preview'),
      tooltipBackgroundColor: '#BBDBFF',
      tooltipColor: '#007FFF',
    },
  ]);

  const myTemplateActionList: Ref<Array<any>> = ref([
    {
      key: 'use',
      label: t('communication.use'),
      icon: markRaw(UseIcon),
      tooltip: t('communication.use'),
      tooltipBackgroundColor: '#BBDBFF',
      tooltipColor: '#007FFF',
    },
    {
      key: 'preview',
      label: t('communication.preview'),
      icon: markRaw(PreviewIcon),
      tooltip: t('communication.preview'),
      tooltipBackgroundColor: '#BBDBFF',
      tooltipColor: '#007FFF',
    },
    {
      key: 'edit',
      label: t('communication.edit_tooltip'),
      icon: markRaw(EditIcon),
      tooltip: t('communication.edit_tooltip'),
      tooltipBackgroundColor: '#BBDBFF',
      tooltipColor: '#007FFF',
    },
    {
      key: 'duplicate',
      label: t('communication.duplicate_tooltip'),
      icon: markRaw(DuplicateIcon),
      tooltip: t('communication.duplicate_tooltip'),
      tooltipBackgroundColor: '#BBDBFF',
      tooltipColor: '#007FFF',
    },
    {
      key: 'delete',
      label: t('communication.delete_tooltip'),
      icon: markRaw(DeleteIcon),
      tooltip: t('communication.delete_tooltip'),
      tooltipBackgroundColor: '#BBDBFF',
      tooltipColor: '#007FFF',
    },
  ]);

  const templateTypeOptions = [
    {
      label: t('communication.standard'),
      value: TemplateMainTypeEnum.STANDARD,
    },
    {
      label: t('communication.my_template'),
      value: TemplateMainTypeEnum.MY_TEMPLATE,
    },
  ];

  const field: BaseTable.TableColumn[] = [
    { key: 'name', label: t('communication.template_name'), sortable: true },
    { key: 'category_name', label: t('communication.category'), sortable: true },
    { key: 'updated_dt', label: t('communication.last_update'), sortable: true },
    { key: 'updated_by', label: t('communication.update_by'), sortable: true },
    { key: 'action', label: '' },
  ];

  let searchTimeout: ReturnType<typeof setTimeout> = null!;

  const fetchTemplateCardList = () => {
    isTemplateLoading.value = true;

    fetchTemplateCardModel.payload.Channel = templateFilterForm.channel as Communication.Campaign.ChannelType;
    fetchTemplateCardModel.payload.Status = 1;
    fetchTemplateCardModel.payload.Type = templateType.value || null;
    fetchTemplateCardModel.payload.List_SubType = subTypeList.value ?? [];
    fetchTemplateCardModel.payload.CategoryId = templateFilterForm.category;
    fetchTemplateCardModel.payload.Query = templateFilterForm.search.trim();
    fetchTemplateCardModel.payload.Limit = 999;

    apiService
      .apiRequest(fetchTemplateCardModel)
      .then((response) => {
        templateCategoryList.value = response.data || [];
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isTemplateLoading.value = false;
      });
  };

  const fetchTemplateList = () => {
    isTemplateTableLoading.value = true;
    fetchTemplateListModel.payload.Channel = templateFilterForm.channel as Communication.Campaign.ChannelType;
    fetchTemplateListModel.payload.Status = 1;
    fetchTemplateListModel.payload.Type = templateType.value;
    fetchTemplateListModel.payload.List_SubType = subTypeList.value ?? [];
    fetchTemplateListModel.payload.CategoryId = templateFilterForm.category;
    fetchTemplateListModel.payload.Query = templateFilterForm.search.trim();
    fetchTemplateListModel.payload.Ordering = templateFilterForm.order;
    fetchTemplateListModel.payload.Limit = pagination.perPage;
    fetchTemplateListModel.payload.Page = pagination.currentPage;

    apiService
      .apiRequest(fetchTemplateListModel)
      .then((response) => {
        const responseData: Communication.Template.Response.TemplateList = response.data;
        pagination.totalRows = responseData.filtered_records;
        templateList.value = responseData.records;
      })
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => (isTemplateTableLoading.value = false));
  };

  const deleteTemplate = (template: Communication.Template.Template) => {
    deleteTemplateModel.payload.TemplateId = template.template_id;
    isTemplateLoading.value = true;

    apiService
      .apiRequest(deleteTemplateModel)
      .then(() => openSuccessModal('ดำเนินการสำเร็จ', '', '', undefined, isChangedListStyle.value ? fetchTemplateList : fetchTemplateCardList))
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => {
        isTemplateLoading.value = false;
      });
  };

  function duplicateTemplate(template: Communication.Template.Template) {
    duplicateTemplateModel.payload.TemplateId = template.template_id;

    apiService
      .apiRequest(duplicateTemplateModel)
      .then(() => openSuccessModal('Duplicate สำเร็จ', '', '', undefined, isChangedListStyle.value ? fetchTemplateList : fetchTemplateCardList))
      .catch((err) => openDefaultErrorModal(err));
  }

  const createCampaign = (templateId: number) => {
    createCampaignModel.payload.Channel = templateFilterForm.channel;
    createCampaignModel.payload.TemplateId = templateId || null;
    createCampaignModel.payload.SubType = null;
    isTemplateLoading.value = true;

    apiService
      .apiRequest(createCampaignModel)
      .then((response) => {
        const campaignId = response.data.campaignid;

        switch (resolveChannel(templateFilterForm.channel as number)) {
          case 'line-oa':
            return router.push({ name: 'line-oa-campaign-create', query: { campaign: campaignId } });
          case 'sms':
            return router.push({ name: 'create-sms-campaign', params: { id: campaignId } });
          case 'email':
            return router.push({ name: 'create-edm-campaign', params: { id: campaignId } });
          default:
            return;
        }
      })
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => (isTemplateLoading.value = false));
  };

  const resolveIcon = (category: any) => {
    switch (category) {
      case 0:
        return markRaw(All);
      case 1:
        return markRaw(Popular);
      case 2:
        return markRaw(Promotions);
      case 3:
        return markRaw(Seasonal);
      case 4:
        return markRaw(Awareness);
      case 5:
        return markRaw(WinBack);
      case 6:
        return markRaw(Engagement);
      case 7:
        return markRaw(Notification);
      case 8:
        return markRaw(Other);
    }
  };

  const resolveChannel = (channel: number): string => {
    switch (channel) {
      case 1:
        return 'sms';
      case 2:
        return 'email';
      case 3:
        return 'line-oa';
      default:
        return '';
    }
  };

  const onResolveAction = (action: Record<string, any>, template: Communication.Template.Template) => {
    switch (action.key) {
      case 'use':
        return createCampaign(template.template_id);
      case 'preview':
        return openPreviewModal(template);
      case 'edit':
        if (template.channel == 3) {
          return router.push({
            path: `/communication/${resolveChannel(Number(router.currentRoute.value.query.channel))}/template/info/${template.template_id}`,
            query: { messageType: template.subtype },
          });
        } else {
          return router.push({
            path: `/communication/${resolveChannel(Number(router.currentRoute.value.query.channel))}/template/info/${template.template_id}`,
          });
        }
      case 'duplicate':
        return openWarningModal('Duplicate Template', `ต้องการ Duplicate Template ${template.name} หรือไม่`, () => duplicateTemplate(template));
      case 'delete':
        return openWarningModal('ลบ Template', `ต้องการลบ Template ${template.name} หรือไม่`, () => deleteTemplate(template));
    }
  };
  const onChangeSearch = () => {
    if (searchTimeout) clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      if (isChangedListStyle.value) {
        return pagination.currentPage == 1 ? fetchTemplateList() : (pagination.currentPage = 1);
      }

      return fetchTemplateCardList();
    }, 500);
  };

  const openPreviewModal = (template: Communication.Template.Template) => {
    previewModal.isOpen = true;
    previewModal.template = template;
  };

  const onClosePreviewModal = () => {
    previewModal.isOpen = false;
    previewModal.template = null!;
  };

  const onUseTemplate = (template: Communication.Template.Template) => {
    previewModal.isOpen = false;
    createCampaign(template.template_id);
  };

  const onClickCreateTemplate = () => {
    const channel = Number(router.currentRoute.value.query.channel) || 0;

    switch (channel) {
      case 1:
        return router.push({
          path: '/communication/sms/template/create',
          query: { category: router.currentRoute.value.query.category || 0 },
        });
      case 2:
        return router.push({
          path: '/communication/email/template/create',
          query: { category: router.currentRoute.value.query.category || 0 },
        });
      case 3:
        return router.push({
          path: '/communication/line-oa/template/create',
          query: { category: router.currentRoute.value.query.category || 0, messageType: templateFilterForm.subType },
        });
      default:
        return;
    }
  };

  const onSelectTemplateType = (type: number) => {
    templateCategoryList.value = [];
    templateType.value = type;

    if (isChangedListStyle.value) {
      keyChange.value++;
      return pagination.currentPage == 1 ? fetchTemplateList() : (pagination.currentPage = 1);
    }

    return fetchTemplateCardList();
  };

  const onSort = (sortedColumn: { key: string; direction: string }) => {
    if (sortedColumn.direction) {
      const sortModel = [
        {
          Seq: 1,
          Key: sortedColumn.key,
          Direction: sortedColumn.direction.toLocaleLowerCase(),
        },
      ];

      templateFilterForm.order = sortModel as Communication.Template.Request.Ordering[];
    } else {
      templateFilterForm.order = [];
    }

    if (pagination.currentPage == 1) return fetchTemplateList();

    return (pagination.currentPage = 1);
  };

  const onChangeListStyle = () => {
    pagination.currentPage = 1;
    isChangedListStyle.value = !isChangedListStyle.value;
    templateFilterForm.order = [];

    if (isChangedListStyle.value) {
      return fetchTemplateList();
    }

    return fetchTemplateCardList();
  };

  const onClickBack = () => {
    router.push({
      name: 'communication',
      query: { channel: resolveChannel(templateFilterForm.channel as number) || 1 },
    });
  };

  watch(
    () => pagination.currentPage,
    () => {
      if (isChangedListStyle.value) {
        fetchTemplateList();
      }
    },
  );

  onBeforeMount(() => {
    const query = router.currentRoute.value.query;
    const channel = Number(router.currentRoute.value.query.channel) || 0;
    const messageType = Number(query.messageType);
    templateType.value = Number(query.type) || 1;
    if (messageType == 0) {
      templateFilterForm.subType = 30;
    } else {
      templateFilterForm.subType = messageType;
    }
    if (channel == 3 && messageType !== 0) {
      subTypeList.value = [Number(query.messageType)];
    }
    templateFilterForm.channel = Number(query.channel);
    templateFilterForm.category = Number(query.category);

    fetchTemplateCardList();
  });

  return {
    isTemplateLoading,
    isTemplateTableLoading,
    templateCategoryList,
    previewModal,
    field,
    pagination,
    templateFilterForm,
    standardActionList,
    myTemplateActionList,
    isChangedListStyle,
    templateTypeOptions,
    templateType,
    templateList,
    keyChange,
    TemplateMainTypeEnum,
    onResolveAction,
    onClosePreviewModal,
    resolveIcon,
    onChangeSearch,
    onClickCreateTemplate,
    onSelectTemplateType,
    onSort,
    onChangeListStyle,
    onUseTemplate,
    onClickBack,
  };
}
