import { ref, Ref, reactive, getCurrentInstance, watch, onMounted, nextTick } from 'vue';
import { addVideoUI } from '../../../../constants/constant-edm';
import { PATH } from '@/constants/modules/file-service/path';
import apiService from '@/services/api';
import fileServiceModel from '@/models/file-service/file-service';
import useLoading from '@/views/components/loading/hooks/useLoading';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import { useI18n } from 'vue-i18n';
interface Props {
  selectedElement?: Communication.EdmEditor.EdmElement;
  backupElement: Communication.EdmEditor.CoreEdmElement;
  templateMode?: string;
}

export default function useVideoTabPanel(props: Props) {
  const { t } = useI18n();
  const vm = getCurrentInstance()?.proxy;
  const { uploadFileModel } = fileServiceModel();
  const { openErrorModal, openDefaultErrorModal } = useValidationModal();
  const { isLoading } = useLoading();
  //custom video
  let imgWidthOriginal = 0;
  let imgHeightOriginal = 0;
  let oldWidth = 0;
  let oldHeight = 0;
  let tableDataLength = 0;
  const disabledPadding: Ref<boolean> = ref(true);
  const uploadVideo: any = reactive({ file: null, url: null });
  const uploadThumbnail: any = reactive({ file: null, url: null });
  const sref: Ref<string> = ref('');
  const selectedVideoType: Ref<any> = ref(1);
  const videoLink: Ref<any> = ref(null);
  const isCorrectedUrlLink: Ref<boolean> = ref(true);
  const thumbnailWidth: Ref<number> = ref(0);
  const thumbnailHeight: Ref<number> = ref(0);
  const spacingTop: Ref<number> = ref(10);
  const spacingBottom: Ref<number> = ref(10);
  const indentLeft: Ref<number> = ref(10);
  const indentRight: Ref<number> = ref(10);
  const alignMent: Ref<string> = ref('center');
  const justifyMent: Ref<string> = ref('middle');

  const buildVideoObjProperties = () => {
    const currentNodeSelected = EDMGetObjList('cdp-edm-editor')[props.selectedElement?.id as string];
    if (uploadVideo.url || videoLink.value) {
      //optional prop
      currentNodeSelected.Properties.HTMLUI = '';
      //td prop
      currentNodeSelected.Properties.Style = {
        'vertical-align': justifyMent.value,
        'text-align': alignMent.value,
        'padding-top': `${spacingTop.value}px`,
        'padding-bottom': `${spacingBottom.value}px`,
        'padding-left': `${indentLeft.value}px`,
        'padding-right': `${indentRight.value}px`,
      };
      //blockdata prop
      currentNodeSelected.Properties.Content.Type = 'Video';
      currentNodeSelected.Properties.Content.Properties = {
        SummaryPadding: summaryPadding(),
        WidthMemory: thumbnailWidth.value,
        HeightMemory: thumbnailHeight.value,
        Link: videoLink.value || uploadVideo.url,
        VideoLink: uploadVideo.url,
        VideoType: 'video/mp4',
        Width: Math.min(realColumnSize(), thumbnailWidth.value),
        Alt: 'thumbnail',
        Url: uploadThumbnail.url,
        //optional prop
        VideoData: {
          DisablePadding: disabledPadding.value,
          VideoType: selectedVideoType.value,
          WidthOriginal: imgWidthOriginal,
          HeightOriginal: imgHeightOriginal,
          ThumbnailHeight: thumbnailHeight.value,
          Padding: {
            Top: spacingTop.value,
            Bottom: spacingBottom.value,
            Left: indentLeft.value,
            Right: indentRight.value,
          },
        },
      };
    } else {
      currentNodeSelected.Properties.Content.Type = 'VideoBlank';
      currentNodeSelected.Properties.Content.Properties = {};
      currentNodeSelected.Properties.Style = {};
      currentNodeSelected.Properties.HTMLUI = addVideoUI;
    }
    EDMRender('cdp-edm-editor');
    vm?.$emit('on-get-edm-config-json', EDMRenderSave('cdp-edm-editor'));
    vm?.$emit('on-get-edm-content', EDMRenderResult('cdp-edm-editor'));
    nextTick(() => {
      vm?.$emit('set-is-selected');
      vm?.$emit('handle-selected-element');
    });
  };

  const handleDisablePadding = () => {
    disabledPadding.value = !disabledPadding.value;
    if (disabledPadding.value == false) {
      spacingTop.value = 0;
      spacingBottom.value = 0;
      indentLeft.value = 0;
      indentRight.value = 0;
    } else {
      spacingTop.value = 10;
      spacingBottom.value = 10;
      indentLeft.value = 10;
      indentRight.value = 10;
    }
  };

  const onSelectAlignMent = (align: string) => {
    alignMent.value = align;
  };

  const onSelectJustifyMent = (justify: string) => {
    justifyMent.value = justify;
  };

  const matchYoutubeUrl = (url: string) => {
    const p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    const matches = url.match(p);
    if (matches) {
      return matches[1];
    } else {
      isCorrectedUrlLink.value = false;
      return false;
    }
  };

  const onInputVideoLink = (link: string) => {
    if (matchYoutubeUrl(link)) {
      const regex: any = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
      videoLink.value = `https://www.youtube.com/embed/${regex.exec(link)[3] as string}`;
      regex.lastIndex = 0;
      uploadThumbnail.url = `https://img.youtube.com/vi/${regex.exec(link)[3] as string}/hqdefault.jpg`;
      onGetImageSize(uploadThumbnail.url as string);
      isCorrectedUrlLink.value = true;
    } else {
      uploadThumbnail.url = null;
    }
  };

  const onChangeVideoLink = () => {
    if (!isCorrectedUrlLink.value) {
      videoLink.value = '';
    }
  };

  const onSelectVideoType = (type: number) => {
    selectedVideoType.value = type;
    videoLink.value = null;
    uploadVideo.file = null;
    uploadVideo.url = null;
    uploadThumbnail.file = null;
    uploadThumbnail.url = null;
    isCorrectedUrlLink.value = true;
  };

  const handleUploadFileChange = (e: any, type: string) => {
    const filesOBJ: File = e.target?.files[0];
    if (filesOBJ) {
      if (onValidateFile(filesOBJ, type)) {
        if ((isMaxFileSize_10_MB(filesOBJ) && type == 'image') || (isMaxFileSize_200_MB(filesOBJ) && type == 'video')) {
          isLoading(true, 'กำลังอัพโหลดไฟล์');
          vm?.$emit('uploading', true);
          uploadFileModel.payload.Path = props.templateMode ? PATH.SYSTEM.COMMU.EDM : PATH.COMMU.EDM;
          uploadFileModel.payload.File = filesOBJ;
          uploadFileModel.payload.Is_Temp = true;
          if (type == 'video') {
            uploadFileModel.payload.Options.Gen_preview = true;
          }
          apiService
            .apiFormRequest(uploadFileModel)
            .then((response) => {
              const res = response.data;

              if (type == 'image') {
                uploadThumbnail.url = res.public_url as Blob;
                onGetImageSize(uploadThumbnail.url as string);
              } else if (type == 'video') {
                uploadVideo.url = res.public_url as Blob;
                uploadThumbnail.url = res.preview_url as Blob;
                onGetImageSize(uploadThumbnail.url as string);
              }
              sref.value = res.sref;
              vm?.$emit('on-add-sref-list', sref.value);
              buildVideoObjProperties();
            })
            .catch((err) => {
              openDefaultErrorModal(err);
            })
            .finally(() => {
              isLoading(false);
              vm?.$emit('uploading', false);
            });
        } else {
          openErrorModal(t('global.error'), 'ขนาดไฟล์ใหญ่เกินไป', '');
          clearFile(e as Event, type);
        }
      } else {
        openErrorModal(t('global.error'), 'ไฟล์ผิดประเภท', '');
        clearFile(e as Event, type);
      }
    }
  };

  const isMaxFileSize_10_MB = (file: File) => file && file.size <= 10485760;
  const isMaxFileSize_200_MB = (file: File) => file && file.size <= 209715200;

  const columnSize = () => {
    return tableDataLength * props.backupElement.ColObj.Properties.Length;
  };

  const summaryPadding = () => {
    return indentLeft.value + indentRight.value;
  };

  const realColumnSize = () => {
    return columnSize() - summaryPadding();
  };

  const onImgWidthChanged = () => {
    const contentObj = EDMGetObjList('cdp-edm-editor')[props.selectedElement?.id as string];
    const contentElem = document.querySelector(`#${contentObj.Id as string}`);
    const imageElem = contentElem?.closest('table')?.querySelector('img');

    if (thumbnailWidth.value > oldWidth) {
      thumbnailWidth.value = Math.min(realColumnSize(), thumbnailWidth.value);
    } else if (thumbnailWidth.value < oldWidth) {
      thumbnailWidth.value = Math.max(1, thumbnailWidth.value);
    }
    thumbnailHeight.value = calImageHeight(thumbnailWidth.value);

    imageElem?.setAttribute('width', String(Math.min(realColumnSize(), thumbnailWidth.value)));
  };

  const onImgHeightChanged = () => {
    const contentObj = EDMGetObjList('cdp-edm-editor')[props.selectedElement?.id as string];
    const contentElem = document.querySelector(`#${contentObj.Id as string}`);
    const imageElem = contentElem?.closest('table')?.querySelector('img');

    const calWidth = calImageWidth(thumbnailHeight.value);
    if (thumbnailHeight.value > oldHeight) {
      if (calWidth <= realColumnSize()) {
        oldHeight = thumbnailHeight.value;
        thumbnailWidth.value = calWidth;
      } else {
        thumbnailWidth.value = realColumnSize();
        oldWidth = realColumnSize();
        thumbnailHeight.value = calImageHeight(thumbnailWidth.value);
      }
    } else if (thumbnailHeight.value < oldHeight) {
      if (calWidth >= 1) {
        oldHeight = thumbnailHeight.value;
        thumbnailWidth.value = calWidth;
      } else {
        thumbnailWidth.value = 1;
        oldWidth = 1;
        thumbnailHeight.value = calImageHeight(thumbnailWidth.value);
      }
    }
    imageElem?.setAttribute('width', String(Math.min(realColumnSize(), thumbnailWidth.value)));
  };

  const onGetImageSize = (url: string) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      imgWidthOriginal = img.width;
      imgHeightOriginal = img.height;
      if (img.width > realColumnSize()) {
        thumbnailWidth.value = realColumnSize();
      } else {
        thumbnailWidth.value = img.width;
      }
      thumbnailHeight.value = calImageHeight(thumbnailWidth.value);
      oldWidth = thumbnailWidth.value;
      oldHeight = thumbnailHeight.value;
      buildVideoObjProperties();
    };
  };

  const calImageWidth = (h: number): number => {
    const w = Math.ceil((imgWidthOriginal / imgHeightOriginal) * h);
    return w;
  };

  const calImageHeight = (w: number): number => {
    const h = Math.ceil((imgHeightOriginal / imgWidthOriginal) * w);
    return h;
  };

  const checkInputPadding = () => {
    const min = 0;
    const max = 40;
    if (spacingTop.value < min) {
      spacingTop.value = min;
    }
    if (spacingBottom.value < min) {
      spacingBottom.value = min;
    }
    if (indentLeft.value > max) {
      indentLeft.value = max;
    } else if (indentLeft.value < min) {
      indentLeft.value = min;
    }
    if (indentRight.value > max) {
      indentRight.value = max;
    } else if (indentRight.value < min) {
      indentRight.value = min;
    }
  };

  const onValidateFile = (file: File, type: string) => {
    const fileType = file.type.split('/');
    const extensionList = {
      image: ['png', 'jpeg', 'jpg'],
      video: ['mp4'],
    };
    if (fileType[0] === 'image' && type === 'image') {
      return extensionList.image.includes(fileType[1]);
    } else if (fileType[0] === 'video' && type === 'video') {
      return extensionList.video.includes(fileType[1]);
    } else return false;
  };

  const clearFile = (e: Event, type: string) => {
    if (sref.value !== '') {
      vm?.$emit('on-delete-file', sref.value);
    }
    if (type == 'image') {
      uploadThumbnail.file = null;
      uploadThumbnail.url = null;
      thumbnailWidth.value = 0;
      thumbnailHeight.value = 0;
    } else {
      uploadVideo.file = null;
      uploadVideo.url = null;
      uploadThumbnail.file = null;
      uploadThumbnail.url = null;
      thumbnailWidth.value = 0;
      thumbnailHeight.value = 0;
      videoLink.value = null;
    }
    (e.target as HTMLInputElement).value = '';
  };

  onMounted(() => {
    const currentNodeSelected = EDMGetObjList('cdp-edm-editor')[props.selectedElement?.id as string];
    tableDataLength = EDMGetEditorRunTime('cdp-edm-editor').Config.TableDataLength;
    if (currentNodeSelected.Properties.Content.Type == 'Video') {
      disabledPadding.value = currentNodeSelected.Properties.Content.Properties.VideoData.DisablePadding ?? false;
      selectedVideoType.value = currentNodeSelected.Properties.Content.Properties.VideoData.VideoType ?? 1;
      thumbnailHeight.value = currentNodeSelected.Properties.Content.Properties.HeightMemory ?? 0;
      thumbnailWidth.value = currentNodeSelected.Properties.Content.Properties.WidthMemory ?? 0;
      imgWidthOriginal = currentNodeSelected.Properties.Content.Properties.VideoData.WidthOriginal ?? 0;
      imgHeightOriginal = currentNodeSelected.Properties.Content.Properties.VideoData.HeightOriginal ?? 0;
      oldWidth = currentNodeSelected.Properties.Content.Properties.WidthMemory ?? 0;
      oldHeight = currentNodeSelected.Properties.Content.Properties.HeightMemory ?? 0;
      videoLink.value = currentNodeSelected.Properties.Content.Properties.Link;
      uploadVideo.url = currentNodeSelected.Properties.Content.Properties.VideoLink;
      uploadThumbnail.url = currentNodeSelected.Properties.Content.Properties.Url ?? '';
      justifyMent.value = currentNodeSelected.Properties.Style['vertical-align'] ?? '';
      alignMent.value = currentNodeSelected.Properties.Style['text-align'] ?? '';
      spacingTop.value = currentNodeSelected.Properties.Content.Properties.VideoData.Padding.Top;
      spacingBottom.value = currentNodeSelected.Properties.Content.Properties.VideoData.Padding.Bottom;
      indentLeft.value = currentNodeSelected.Properties.Content.Properties.VideoData.Padding.Left;
      indentRight.value = currentNodeSelected.Properties.Content.Properties.VideoData.Padding.Right;
    }
  });

  watch(
    [
      uploadVideo,
      uploadThumbnail,
      selectedVideoType,
      videoLink,
      disabledPadding,
      spacingTop,
      spacingBottom,
      indentLeft,
      indentRight,
      alignMent,
      justifyMent,
    ],
    () => {
      buildVideoObjProperties();
    },
    { deep: true },
  );

  return {
    uploadVideo,
    uploadThumbnail,
    selectedVideoType,
    videoLink,
    isCorrectedUrlLink,
    disabledPadding,
    spacingTop,
    spacingBottom,
    indentLeft,
    indentRight,
    thumbnailWidth,
    thumbnailHeight,
    alignMent,
    justifyMent,
    checkInputPadding,
    onImgWidthChanged,
    onImgHeightChanged,
    handleDisablePadding,
    onSelectAlignMent,
    onSelectJustifyMent,
    onInputVideoLink,
    onChangeVideoLink,
    onSelectVideoType,
    handleUploadFileChange,
    clearFile,
    buildVideoObjProperties,
  };
}
