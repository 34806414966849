import { reactive, ref, onBeforeMount, watch, Ref, toRaw, getCurrentInstance } from 'vue';
import { useI18n } from 'vue-i18n';
interface Props {
  campaign: Campaign.ResponseDetail;
}

export default function useSettingAndScheduleTab(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const timeZone = 'UTC+7';

  const { t } = useI18n();

  const repeatDayList: Ref<number[]> = ref([]);
  const sendDateTime: Ref<Date> = ref(null!);
  const keyChange: Ref<number> = ref(0);
  const selectedRepeatType: Ref<number> = ref(1);
  const selectedRepeatMode: Ref<number> = ref(null!);
  const endDateTime: Ref<Date> = ref(null!);
  const endOccurrence: Ref<string> = ref(null!);
  const selectedScheduleType: Ref<number> = ref(null!);
  const endMode: Ref<number> = ref(null!);
  const repeatEvery: Ref<string> = ref(null!);

  const scheduleModeOption = reactive([
    {
      text: t('communication.send_now'),
      value: 1,
    },
    {
      text: t('communication.schedule_later'),
      value: 2,
    },
    {
      text: t('communication.repeat'),
      value: 3,
    },
  ]);

  const repeatType = reactive([
    {
      label: 'Custom',
      value: 1,
    },
  ]);

  const repeatModeOption = reactive([
    {
      label: 'Day',
      value: 1,
    },
    {
      label: 'Week',
      value: 2,
    },
    {
      label: 'Month',
      value: 3,
    },
  ]);

  const dateRepeatOn = reactive([
    {
      label: 'S',
      title: 'Sunday',
      value: 1,
    },
    {
      label: 'M',
      title: 'Monday',
      value: 2,
    },
    {
      label: 'T',
      title: 'Tuesday',
      value: 4,
    },
    {
      label: 'W',
      title: 'Wednesday',
      value: 8,
    },
    {
      label: 'T',
      title: 'Thursday',
      value: 16,
    },
    {
      label: 'F',
      title: 'Friday',
      value: 32,
    },
    {
      label: 'S',
      title: 'Saturday',
      value: 64,
    },
  ]);

  const endModeOption = reactive([
    {
      text: 'Never',
      value: 1,
    },
    {
      text: 'On',
      value: 2,
    },
    {
      text: 'After',
      value: 3,
    },
  ]);

  const initRepeatOnList = () => {
    const repeatOn = props.campaign.schedule.repeatOn || 0;

    if (repeatOn == 0) {
      return;
    }
    if (repeatOn & 1) {
      repeatDayList.value.push(1);
    }
    if (repeatOn & 2) {
      repeatDayList.value.push(2);
    }
    if (repeatOn & 4) {
      repeatDayList.value.push(4);
    }
    if (repeatOn & 8) {
      repeatDayList.value.push(8);
    }
    if (repeatOn & 16) {
      repeatDayList.value.push(16);
    }
    if (repeatOn & 32) {
      repeatDayList.value.push(32);
    }
    if (repeatOn & 64) {
      repeatDayList.value.push(64);
    }
  };

  const getCurrentDay = () => {
    const date = new Date();
    const day = date.getDay();
    if (day === 0) return 1;
    if (day === 1) return 2;
    if (day === 2) return 4;
    if (day === 3) return 8;
    if (day === 4) return 16;
    if (day === 5) return 32;
    if (day === 6) return 64;
  };

  const initData = () => {
    selectedScheduleType.value = props.campaign.schedule.scheduleMode || null!;
    endMode.value = props.campaign.schedule.endMode || 1;
    sendDateTime.value = props.campaign.schedule.scheduleDt ? new Date(props.campaign.schedule.scheduleDt) : null!;
    endDateTime.value = props.campaign.schedule.endDt ? new Date(props.campaign.schedule.endDt) : null!;
    endOccurrence.value = props.campaign.schedule.endOccurrence ? props.campaign.schedule.endOccurrence?.toString() : '1';
    selectedRepeatMode.value = props.campaign.schedule.repeatMode || 1;
    repeatEvery.value = props.campaign.schedule.repeatEvery ? props.campaign.schedule.repeatEvery?.toString() : '1';

    initRepeatOnList();
  };

  const onSelectScheduleType = () => {
    vm?.$emit('select-schedule-type', selectedScheduleType.value);
  };

  onBeforeMount(() => {
    initData();
  });

  watch(
    () => props.campaign.schedule.scheduleMode,
    () => {
      endMode.value = props.campaign.schedule.endMode || 1;
      sendDateTime.value = props.campaign.schedule.scheduleDt ? new Date(props.campaign.schedule.scheduleDt) : null!;
      endDateTime.value = props.campaign.schedule.endDt ? new Date(props.campaign.schedule.endDt) : null!;
      endOccurrence.value = props.campaign.schedule.endOccurrence ? props.campaign.schedule.endOccurrence?.toString() : '1';
      selectedRepeatMode.value = props.campaign.schedule.repeatMode || 1;
      repeatEvery.value = props.campaign.schedule.repeatEvery ? props.campaign.schedule.repeatEvery?.toString() : '1';

      initRepeatOnList();
      keyChange.value++;
    },
  );

  watch(
    () => selectedRepeatMode.value,
    () => {
      if (selectedRepeatMode.value === 2 && repeatDayList.value?.length === 0) {
        repeatDayList.value.push(getCurrentDay() as number);
        vm?.$emit('select-repeate-on', repeatDayList.value);
      }
    },
  );

  watch(
    () => repeatDayList.value,
    () => {
      if (repeatDayList.value?.length === 0) {
        repeatDayList.value.push(getCurrentDay() as number);
      }
    },
  );

  return {
    selectedScheduleType,
    scheduleModeOption,
    endModeOption,
    dateRepeatOn,
    sendDateTime,
    timeZone,
    keyChange,
    repeatType,
    selectedRepeatType,
    repeatModeOption,
    selectedRepeatMode,
    repeatDayList,
    endDateTime,
    endOccurrence,
    endMode,
    repeatEvery,
    onSelectScheduleType,
  };
}
