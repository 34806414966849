import { ref, Ref, onBeforeMount, watch, nextTick, getCurrentInstance, ComponentPublicInstance, reactive, onMounted, onBeforeUnmount } from 'vue';
import useReplaceClassByStyle from '../hooks/useReplaceClassByStyle';
import useQuillTextEditor from './useQuillTextEditor';
import { useRouter } from 'vue-router';
import html2canvas from 'html2canvas';

import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import apiService from '@/services/api';
import campaignModel from '@/models/communication/campaign';
import fileServiceModel from '@/models/file-service/file-service';
import { Validation } from '@views/modules/communication/pages/line-oa-campaign/constants/validation';
import { Channel as ChannelConstant } from '../../main/constants/channel';
import { PATH } from '@/constants/modules/file-service/path';

export default function useEDMCampaignPage() {
  const vm = getCurrentInstance()?.proxy;
  const router = useRouter();

  const { replacerClassByStyle } = useReplaceClassByStyle();
  const { QuillSetHtml } = useQuillTextEditor();
  const { openDefaultErrorModal, openSuccessModal, openWarningModal } = useValidationModal();

  const { detailCampaignModel, saveCampaignModel, previewCampaignModel, publishCampaignModel, scheduleInfoPreviewModel, saveAsTemplateModel } =
    campaignModel();
  const { uploadFileModel } = fileServiceModel();

  const activeTab: Ref<number> = ref(0);
  const isDisabledReload: Ref<boolean> = ref(true);
  const loading = ref(false);
  const isEdmEditing = ref(false);
  const isSaved = ref(false);
  const validateResult: Ref<Campaign.ValidateResult | null> = ref(null);
  const selectedCustomerList: Ref<any[]> = ref([]);
  const isSelectAllCustomer: Ref<boolean> = ref(false);
  const isRemoveSegment: Ref<boolean> = ref(false);
  const isRemoveUnConsent: Ref<boolean> = ref(false);
  const excludeCustomerList: Ref<any[]> = ref([]);
  const isSelectAllWithExclude: Ref<boolean> = ref(false);
  const selectedSegment: Ref<Communication.Personalization.PersonalizeSegment> = ref(null!);
  const resolveSelectedRecp: Ref<Campaign.RecipientSegment[]> = ref([]);
  const resolveSelectedRecpUnConsent: Ref<Campaign.RecipientUnConsent> = ref(null!);
  const title: Ref<string> = ref(null!);
  const srefList: Ref<string[]> = ref([]);
  const isUploading: Ref<boolean> = ref(false);
  const interval: Ref<ReturnType<typeof setInterval>> = ref(null!);
  let onSaveTextEdm: any;
  let quillInstance: any;
  let quillHtml: string;

  const campaign: Ref<Communication.LineOA.CreateCampaign.CampaignDetail> = ref(null!);
  const selectedRecipient: Ref<Segment.Provider.SelectedRecipient> = ref(null!);
  const recipientsDispatchable: Ref<number> = ref(0);
  const creditDisplay: Ref<number> = ref(1);
  const edmConfigJson: Ref<string> = ref('');
  const edmHTMLContent: Ref<string> = ref('');
  const readOnly: Ref<boolean> = ref(false);
  const filterSearch: Ref<string> = ref('');
  const filteredRecord: Ref<number> = ref(null!);

  //personalize
  const isPersonalizeModalOpen: Ref<boolean> = ref(false);
  const isSegmentSelected: Ref<boolean> = ref(false);
  //Coupon
  const isCouponCodeModalOpen: Ref<boolean> = ref(false);
  //save as template
  const isClearUi: Ref<boolean> = ref(false);
  const isCategoryModalOpen: Ref<boolean> = ref(false);
  const isCampaignMode: Ref<boolean> = ref(false);
  const templateName: Ref<string> = ref('');
  const thumbnail: Record<string, string> = reactive({ url: '', sref: '' });

  const campaignForm: Campaign.ResponseDetail = reactive({
    campaignId: null,
    info: {
      status: null!,
      name: '',
      description: null,
      provider: null,
      sender: '',
      updatedDt: '',
    },
    content: [],
    creditBalance: null,
    estCreditPerRecp: null,
    isCreditEnough: false,
    recpSelect: [],
    hasallowunconsent: false,
    recipientsUnConsent: null,
    recpSelectUnConsent: {
      cids: [],
      recipients: null,
      selection_mode: null,
      selection_mode_desc: null,
      selectunconsentid: null,
    },
    schedule: {
      scheduleMode: null!,
      scheduleDt: null,
      repeatMode: null,
      repeatEvery: null,
      repeatOn: null,
      endMode: null,
      endDt: null,
      endOccurrence: null,
      nextScheduleDt: null,
      repeatOnVal: null,
      nextScheduleDtLabel: null,
      repeatEveryLabel: null,
      repeatOnLabel: null,
      startOnLabel: null,
      endOnLabel: null,
    },
  });

  const fetchCampaignDetail = async () => {
    loading.value = true;
    srefList.value = [];
    detailCampaignModel.payload.CampaignId = Number(router.currentRoute.value.params.id);

    return await apiService
      .apiRequest(detailCampaignModel)
      .then((response) => {
        const resp = response.data;

        campaignForm.campaignId = resp.campaignid;
        campaignForm.info.status = resp.status;
        campaignForm.info.name = resp.name;
        campaignForm.info.provider = resp.provider;
        campaignForm.info.sender = resp.provider?.sms_sender;
        campaignForm.info.updatedDt = resp.updated_dt;
        campaignForm.content = resp.contents;
        campaignForm.creditBalance = resp.credit_balance;
        campaignForm.estCreditPerRecp = resp.est_credit_per_recp;
        campaignForm.isCreditEnough = resp.is_credit_enough;
        campaignForm.recpSelect = resp.recp_select;
        campaignForm.hasallowunconsent = resp.hasallowunconsent;
        campaignForm.recpSelectUnConsent = resp.recp_select_unconsent;
        campaignForm.recipientsUnConsent = resp.recp_pdpa_allowunconsent;
        campaignForm.schedule.scheduleMode = resp.schedule_mode;
        campaignForm.schedule.scheduleDt = campaignForm.schedule.scheduleMode == 1 ? new Date().toISOString() : resp.schedule_dt;
        campaignForm.schedule.repeatMode = resp.repeat_mode;
        campaignForm.schedule.repeatEvery = resp.repeat_every;
        campaignForm.schedule.repeatOn = resp.repeat_on;
        campaignForm.schedule.endMode = resp.end_mode;
        campaignForm.schedule.endDt = resp.end_dt;
        campaignForm.schedule.endOccurrence = resp.end_occurrence;
        recipientsDispatchable.value = resp.recp_dispatchable + resp.recp_pdpa_allowunconsent || 0;

        edmConfigJson.value = campaignForm.content[0].content_editor || '';
        edmHTMLContent.value = campaignForm.content[0].content || '';
        //campaignForm.content[0].title = resp.contents[0].title;
        title.value = resp.contents[0].title;
        readOnly.value = campaignForm.info.status == 1 ? false : true;
        isDisabledReload.value = campaignForm.info.status === 1 ? false : true;

        campaign.value = JSON.parse(JSON.stringify(campaignForm));
        validateResult.value = validateForm();
        isSaved.value = true;

        // mapping to customer drawer
        selectedRecipient.value = {
          summary_selected: resp.recipients,
          list_source: resp.recp_select.map((item: any) => {
            return {
              type: item.type,
              segment_id: item.segmentid,
              total_selected: item.recipients,
              display_name: item.display_name,
            };
          }),
          hasallowunconsent: resp.hasallowunconsent,
          recipients: resp.recipients,
          recp_pdpa: resp.recp_pdpa,
          recp_credential: resp.recp_credential,
          recp_dispatchable: resp.recp_dispatchable,
          recp_pdpa_allowunconsent: resp.recp_pdpa_allowunconsent,
          recp_pdpa_allowunconsent_cred: resp.recp_pdpa_allowunconsent_cred,
        };
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const fetchPreviewCampaign = () => {
    previewCampaignModel.payload.CampaignId = Number(router.currentRoute.value.params.id);
    loading.value = true;

    apiService
      .apiRequest(previewCampaignModel)
      .then((response) => {
        const preview = response.data;

        campaignForm.campaignId = preview.campaignid;
        campaignForm.info.name = preview.name;
        campaignForm.info.provider = preview.provider;
        campaignForm.info.sender = preview.provider?.sms_sender;
        campaignForm.info.updatedDt = preview.updated_dt;
        campaignForm.content = preview.contents;
        campaignForm.recpSelect = preview.recp_select;
        recipientsDispatchable.value = preview.recp_dispatchable;
        title.value = preview.contents[0].title;
        fetchScheduleInfoPreviewCampaign(Number(preview.campaignid));

        isSaved.value = true;
        validateResult.value = validateForm();
        campaign.value = JSON.parse(JSON.stringify(campaignForm));
      })
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => {
        loading.value = false;
      });
  };

  const fetchScheduleInfoPreviewCampaign = (campaignId: number) => {
    scheduleInfoPreviewModel.payload.CampaignId = campaignId;

    apiService
      .apiRequest(scheduleInfoPreviewModel)
      .then((response) => {
        const info = response.data;

        campaignForm.schedule.scheduleMode = info.schedule_mode;
        campaignForm.schedule.scheduleDt = campaignForm.schedule.scheduleMode == 1 ? new Date().toISOString() : info.schedule_dt;
        campaignForm.schedule.repeatMode = info.repeat_mode;
        campaignForm.schedule.repeatEvery = info.repeat_every;
        campaignForm.schedule.repeatOn = info.repeat_on;
        campaignForm.schedule.endMode = info.end_mode;
        campaignForm.schedule.endDt = info.end_dt;
        campaignForm.schedule.endOccurrence = info.end_occurrence;
        campaignForm.schedule.repeatOnVal = info.repeat_on_val;
        campaignForm.schedule.nextScheduleDt = info.next_schedule_dt;
        campaignForm.schedule.nextScheduleDtLabel = info.next_schedule_dt_label;
        campaignForm.schedule.repeatEveryLabel = info.repeat_every_label;
        campaignForm.schedule.repeatOnLabel = info.repeat_on_label;
        campaignForm.schedule.startOnLabel = info.start_on_label;
        campaignForm.schedule.endOnLabel = info.end_on_label;
      })
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => {
        isSaved.value = true;
        validateResult.value = validateForm();
        campaign.value = JSON.parse(JSON.stringify(campaignForm));
      });
  };

  const isUnConsentModal: Ref<boolean> = ref(false);
  const isPasswordModal: Ref<boolean> = ref(false);
  const isRemoveUnConsentModal: Ref<boolean> = ref(false);
  function onCloseUnConsent() {
    isUnConsentModal.value = false;
  }
  function onOpenPasswordModal() {
    isUnConsentModal.value = false;
    isPasswordModal.value = true;
  }
  function onClosePasswordModal() {
    isPasswordModal.value = false;
  }
  function onOpenRemoveUnConsentModal() {
    isUnConsentModal.value = false;
    isRemoveUnConsentModal.value = true;
  }
  function onCloseRemoveUnConsentModal() {
    isRemoveUnConsentModal.value = false;
  }
  function onValidCodePassword() {
    // () => confirmPublish();
    isPasswordModal.value = false;
    return openWarningModal('เริ่ม Campaign', 'คุณต้องการเริ่ม Campaign นี้หรือไม่', () => confirmPublish());
  }
  async function onConfirmRemoveUnConsent(segmentId?: number) {
    isRemoveUnConsent.value = true;
    resolveSelectedRecpUnConsent.value = resolveRecipientsUnConsent();
    await onSave(true);
    // () => confirmPublish();
    isRemoveUnConsentModal.value = false;
    return openWarningModal('เริ่ม Campaign', 'คุณต้องการเริ่ม Campaign นี้หรือไม่', () => confirmPublish());
  }

  const onPublishCampaign = () => {
    const validate = validateForm();
    //เช็ค selected Recipient unconsent เพื่อแสดง modal ยืนยัน PublishCampaign แบบ selected Recipient unconsent
    if (isUnConsentModal.value == false && selectedRecipient.value.hasallowunconsent == true) {
      return (isUnConsentModal.value = true);
    } else if (validate) {
      return (validateResult.value = validate);
    }

    return openWarningModal('เริ่ม Campaign', 'คุณต้องการเริ่ม Campaign นี้หรือไม่', () => confirmPublish());
  };

  const confirmPublish = async () => {
    loading.value = true;

    publishCampaignModel.payload.CampaignId = Number(router.currentRoute.value.params.id);

    buildSavePayload();

    try {
      if (!isSaved.value) await apiService.apiRequest(saveCampaignModel);
      await apiService.apiRequest(publishCampaignModel);
      openSuccessModal('เริ่ม Campaign สำเร็จ', '', '', undefined, () => router.push({ path: '/communication', query: { channel: 'sms' } }));
    } catch (err) {
      openDefaultErrorModal(err);
    } finally {
      loading.value = false;
    }
  };

  const saveCampaign = (isSwitchTab?: boolean, successModal?: boolean) => {
    loading.value = true;

    if (isSwitchTab) {
      return apiService
        .apiRequest(saveCampaignModel)
        .then(() => {
          isSaved.value = true;
          fetchCampaignDetail();
          return;
        })
        .catch((err) => {
          openDefaultErrorModal(err);
          fetchCampaignDetail();
        })
        .finally(() => {
          saveCampaignModel.payload.Recipients.Segments = [];
          resolveSelectedRecp.value = [];
          isSelectAllCustomer.value = false;
          selectedCustomerList.value = [];
          excludeCustomerList.value = [];
          isSelectAllWithExclude.value = false;
          selectedSegment.value = null!;
          filterSearch.value = '';
          filteredRecord.value = null!;
          isRemoveSegment.value = false;
          isRemoveUnConsent.value = false;
          resolveSelectedRecpUnConsent.value = null!;
        });
    }

    if (successModal) {
      return apiService
        .apiRequest(saveCampaignModel)
        .then(() => {
          isSaved.value = true;

          return activeTab.value == 3
            ? openSuccessModal('บันทึกข้อมูลสำเร็จ', '', '', fetchCampaignDetail, fetchCampaignDetail)
            : openSuccessModal(
                'บันทึกข้อมูลสำเร็จ',
                '',
                '',
                () => fetchCampaignDetail(),
                () => fetchCampaignDetail(),
              );
        })
        .catch((err) => {
          openDefaultErrorModal(err);
          fetchCampaignDetail();
        })
        .finally(() => {
          setTimeout(() => {
            loading.value = false;
          }, 0);
          saveCampaignModel.payload.Recipients.Segments = [];
          resolveSelectedRecp.value = [];
          isSelectAllCustomer.value = false;
          selectedCustomerList.value = [];
          excludeCustomerList.value = [];
          isSelectAllWithExclude.value = false;
          selectedSegment.value = null!;
          filterSearch.value = '';
          filteredRecord.value = null!;
          isRemoveSegment.value = false;
          isRemoveUnConsent.value = false;
          resolveSelectedRecpUnConsent.value = null!;
        });
    }

    return apiService
      .apiRequest(saveCampaignModel)
      .then(() => (isSaved.value = true))
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        setTimeout(() => {
          loading.value = false;
        }, 0);
        saveCampaignModel.payload.Recipients.Segments = [];
        resolveSelectedRecp.value = [];
        isSelectAllCustomer.value = false;
        selectedCustomerList.value = [];
        excludeCustomerList.value = [];
        isSelectAllWithExclude.value = false;
        selectedSegment.value = null!;
        filterSearch.value = '';
        filteredRecord.value = null!;
        isRemoveSegment.value = false;
        isRemoveUnConsent.value = false;
        resolveSelectedRecpUnConsent.value = null!;
      });
  };

  const onSave = async (isSwitchTab?: boolean, successModal?: boolean) => {
    try {
      if (onSaveTextEdm) {
        QuillSetHtml(quillInstance, quillHtml);
        onSaveTextEdm();
      }
      buildSavePayload();
      await saveCampaign(isSwitchTab, successModal);
    } catch (err) {
      openDefaultErrorModal(err);
    }
    onSaveTextEdm = null;
  };

  //#region saveAsTemplate
  function onClickSaveAsTemplate() {
    validateResult.value = validateForm();

    if (!validateResult.value?.content) {
      isCampaignMode.value = true;
      isCategoryModalOpen.value = true;
    } else {
      onClickValidation(Validation.content);
    }
  }

  function onCloseCategoryModal() {
    isCampaignMode.value = false;
    isCategoryModalOpen.value = false;
  }

  function inputTemplateName(text: string) {
    templateName.value = text;
  }

  async function saveAsTemplate(cat: Communication.TemplateCard.Response.CategoryList) {
    const categoryId = cat.categoryId as number;
    await onSave(true, false);
    await convertHtmlToBase64();
    saveAsTemplateModel.payload.CategoryId = categoryId;
    saveAsTemplateModel.payload.Channel = 2 as Campaign.ChannelType;
    saveAsTemplateModel.payload.Name = templateName.value;
    saveAsTemplateModel.payload.Type = 2;
    saveAsTemplateModel.payload.Contents = resolveEdmContent() as Campaign.SaveContent[];
    resolveSaveAsTemplateMapper(saveAsTemplateModel.payload.Contents);
    saveAsTemplateModel.payload.Thumbnail_Url = thumbnail.url;
    saveAsTemplateModel.payload.Thumbnail_Sref = thumbnail.sref;
    saveAsTemplateModel.payload.Deleted_File_Sref = [];

    apiService
      .apiRequest(saveAsTemplateModel)
      .then(() => {
        onCloseCategoryModal();

        openSuccessModal('ดำเนินการสำเร็จ', '', '', undefined, () =>
          router.push({
            path: '/communication/template/list',
            query: {
              channel: 2,
              category: categoryId,
              mode: ChannelConstant.email,
              type: 2,
            },
          }),
        );
      })
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => {
        isClearUi.value = false;
      });
  }

  async function clearEdmUi() {
    await new Promise((resolve) => {
      resolve((isClearUi.value = true));
    });
  }

  const convertHtmlToBase64 = async () => {
    await clearEdmUi();
    const node = document.querySelector('.edm-content-container') as HTMLElement;

    //TODO : Convert into url link file service
    node.style.overflow = 'hidden';
    node.style.transform = 'scale(0.3)';
    node.style.width = '900px';
    node.style.height = '900px';

    return html2canvas(node, {
      allowTaint: true,
      useCORS: true,
    })
      .then(async function (canvas) {
        const png = canvas.toDataURL('png');
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.onload = function () {
          img.width = 90;
          img.height = 90;
        };
        img.src = png;

        function urltoFile(url: string, filename: string, mimeType: string) {
          return fetch(url)
            .then(function (res) {
              return res.arrayBuffer();
            })
            .then(function (buf) {
              return new File([buf], filename, { type: mimeType });
            });
        }

        //Usage example:
        await urltoFile(png, `${templateName.value}.png`, 'image/png').then(function (file) {
          uploadFileModel.payload.Path = PATH.SYSTEM.COMMU.EDM;
          uploadFileModel.payload.File = file;
          uploadFileModel.payload.Is_Temp = true;
          return apiService
            .apiFormRequest(uploadFileModel)
            .then((response) => {
              const res = response.data as Communication.File.Request.Response.File;
              thumbnail.url = res.public_url;
              thumbnail.sref = res.sref;
            })
            .catch((err) => {
              openDefaultErrorModal(err);
            });
        });
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      })
      .finally(() => {
        //TODO : Convert into url link file service
        node.style.transform = 'unset';
        node.style.width = 'unset';
        node.style.height = 'unset';
        node.style.overflow = 'unset';
      });
  };

  function resolveSaveAsTemplateMapper(contents: Campaign.SaveContent[]) {
    if (contents && contents.length) {
      const edmEditor = document.getElementById('cdp-edm-editor') as HTMLDivElement;
      let newTagMp = -1;

      const mapper = JSON.parse(contents[0].Content_Editor).Properties.mapper;

      if (mapper && mapper.length > 0) {
        const personalizeButton = edmEditor.getElementsByTagName('button');
        const scanCouponCodeImg = edmEditor.getElementsByClassName('scan-coupon-code');
        let content = contents[0].Content;
        let contentEditor = contents[0].Content_Editor;

        ///tracking open mail
        const trackingOpenMailString = EDMGetEditor('cdp-edm-editor').Properties.AfterContentHtml;
        if (trackingOpenMailString && trackingOpenMailString !== '') {
          const trackingOpenMailHtml = new DOMParser().parseFromString(trackingOpenMailString as string, 'text/html');
          const mapper: Campaign.TrackingMapper = JSON.parse(
            trackingOpenMailHtml.querySelector('.tracking-open-mail')?.getAttribute('value') as string,
          );
          if (mapper && mapper?.tag) {
            const tagMp = mapper.tag.replaceAll('"', '').replaceAll('{', '').replaceAll('}', '');
            content = content.replaceAll(tagMp, `mp:${newTagMp}`);
            contentEditor = contentEditor.replaceAll(tagMp, `mp:${newTagMp}`);
            newTagMp--;
          }
        }

        //personalize
        for (let i = 0; i < personalizeButton.length; i++) {
          const tagMp = JSON.stringify(JSON.parse(personalizeButton[i].getAttribute('value') as string).tag)
            .replaceAll('"', '')
            .replaceAll('{', '')
            .replaceAll('}', '');
          content = content.replaceAll(tagMp, `mp:${newTagMp}`);
          contentEditor = contentEditor.replaceAll(tagMp, `mp:${newTagMp}`);
          newTagMp--;
        }
        //coupon
        for (let i = 0; i < scanCouponCodeImg.length; i++) {
          const tagMp = JSON.stringify(JSON.parse(scanCouponCodeImg[i].getAttribute('value') as string).CouponColumnDetail.tag)
            .replaceAll('"', '')
            .replaceAll('{', '')
            .replaceAll('}', '');
          content = content.replaceAll(tagMp, `mp:${newTagMp}`);
          contentEditor = contentEditor.replaceAll(tagMp, `mp:${newTagMp}`);
          newTagMp--;
        }
        /// tracking url
        edmEditor.querySelectorAll('a').forEach((element: HTMLElement) => {
          if (isJsonString(element.getAttribute('value') as string)) {
            const mapper: Campaign.TrackingMapper = JSON.parse(element.getAttribute('value') as string);
            if (mapper && mapper?.tag) {
              const tagMp = mapper.tag.replaceAll('"', '').replaceAll('{', '').replaceAll('}', '');
              content = content.replaceAll(tagMp, `mp:${newTagMp}`);
              contentEditor = contentEditor.replaceAll(tagMp, `mp:${newTagMp}`);
              newTagMp--;
            }
          }
        });

        contents[0].Content = content;
        contents[0].Content_Editor = contentEditor;
      }
    }
  }

  //#endregion saveAsTemplate

  const buildSavePayload = () => {
    saveCampaignModel.payload.CampaignId = Number(router.currentRoute.value.params.id);
    saveCampaignModel.payload.Info.Description = '';
    saveCampaignModel.payload.Info.Name = campaignForm.info.name;
    saveCampaignModel.payload.Info.ProviderId = campaignForm.info.provider?.providerid;
    saveCampaignModel.payload.Contents = resolveEdmContent();
    resolveContent();
    saveCampaignModel.payload.Deleted_File_Sref = srefList.value;
    saveCampaignModel.payload.Recipients.Segments = resolveSelectedRecp.value;
    saveCampaignModel.payload.Recipients_Unconsent = resolveSelectedRecpUnConsent.value;
    saveCampaignModel.payload.Schedule.Mode = campaignForm.schedule.scheduleMode;
    saveCampaignModel.payload.Schedule.Schedule_DT = resolveDate(campaignForm.schedule.scheduleDt!);
    saveCampaignModel.payload.Schedule.Repeat_Every = campaignForm.schedule.repeatEvery || 1;
    saveCampaignModel.payload.Schedule.Repeat_On = campaignForm.schedule.repeatOn || 0;
    saveCampaignModel.payload.Schedule.End_Mode = campaignForm.schedule.endMode || 1;
    saveCampaignModel.payload.Schedule.End_DT = campaignForm.schedule.endDt ? resolveDate(campaignForm.schedule.endDt) : null;
    saveCampaignModel.payload.Schedule.End_Occurrence = campaignForm.schedule.endOccurrence || 1;
    saveCampaignModel.payload.Schedule.Repeat_Mode = campaignForm.schedule.repeatMode || 1;
  };

  const resolveContent = () => {
    if (saveCampaignModel.payload.Contents) {
      const content: Campaign.SaveContent = {
        Content: saveCampaignModel.payload.Contents[0].Content,
        Content_Editor: saveCampaignModel.payload.Contents[0].Content_Editor as string,
        title: title.value || null,
      };
      return (saveCampaignModel.payload.Contents[0] = content);
    } else {
      const content: Campaign.SaveContent = {
        Content: replacerClassByStyle(edmHTMLContent.value),
        Content_Editor: edmConfigJson.value,
        title: title.value || null,
      };
      return (saveCampaignModel.payload.Contents = [content]);
    }
  };

  const onDeleteFile = (sref: string) => {
    srefList.value.push(sref);
  };

  const onInputSubject = (subject: string) => {
    title.value = subject;
  };

  const resolveDate = (date: string) => {
    const resolveDate = date.includes('.') ? date.split('.')[0] : date;
    return resolveDate;
  };

  const resolveRecipients = (): Campaign.RecipientSegment[] => {
    if (!selectedSegment.value) {
      return resolveSelectedRecp.value;
    }
    if (isRemoveSegment.value) {
      resolveSelectedRecp.value.push({
        Action: 'remove',
        SegmentId: selectedSegment.value.segment_id,
      });
    } else if (isSelectAllCustomer.value || isSelectAllWithExclude.value) {
      resolveSelectedRecp.value.push({
        Action: 'add',
        Type: 2,
        Selection_Mode: 2,
        SegmentId: selectedSegment.value.segment_id,
        CIDs: excludeCustomerList.value.map((customer) => customer.cid),
        Filter_Search: filterSearch.value,
        Filtered_Record: filteredRecord.value,
      });
    } else {
      resolveSelectedRecp.value.push({
        Action: 'add',
        Type: 2,
        Selection_Mode: 3,
        SegmentId: selectedSegment.value.segment_id,
        CIDs: selectedCustomerList.value.map((customer) => customer.cid),
        Filter_Search: filterSearch.value,
        Filtered_Record: filteredRecord.value,
      });
    }
    resolveSelectedRecp.value = [...new Map(resolveSelectedRecp.value.map((item) => [item['SegmentId'], item])).values()];

    return resolveSelectedRecp.value;
  };

  const resolveRecipientsUnConsent = (): Campaign.RecipientUnConsent => {
    if (isRemoveUnConsent.value) {
      if (campaignForm.recpSelectUnConsent && campaignForm.recpSelectUnConsent.selectunconsentid != null) {
        resolveSelectedRecpUnConsent.value = {
          SelectUnconsentId: campaignForm.recpSelectUnConsent.selectunconsentid,
          Action: 'remove',
        };
      }
    } else if (isSelectAllCustomer.value || isSelectAllWithExclude.value) {
      resolveSelectedRecpUnConsent.value = {
        SelectUnconsentId: campaignForm.recpSelectUnConsent ? campaignForm.recpSelectUnConsent.selectunconsentid || null : null,
        Action: 'add',
        Selection_Mode: 2,
        CIDs: excludeCustomerList.value.map((customer) => customer.cid),
        Filter_Search: filterSearch.value,
        Filtered_Record: filteredRecord.value,
      };
      if (!campaignForm.recpSelectUnConsent) {
        delete resolveSelectedRecpUnConsent.value.SelectUnconsentId;
      }
    } else {
      resolveSelectedRecpUnConsent.value = {
        SelectUnconsentId: campaignForm.recpSelectUnConsent ? campaignForm.recpSelectUnConsent.selectunconsentid || null : null,
        Action: 'add',
        Selection_Mode: 3,
        CIDs: selectedCustomerList.value.map((customer) => customer.cid),
        Filter_Search: filterSearch.value,
        Filtered_Record: filteredRecord.value,
      };
      if (!campaignForm.recpSelectUnConsent) {
        delete resolveSelectedRecpUnConsent.value.SelectUnconsentId;
      }
    }
    return resolveSelectedRecpUnConsent.value;
  };

  function onChangeFilterSearch(searchVal: string, record: number) {
    filterSearch.value = searchVal;
    filteredRecord.value = record;
  }

  const resolveEdmContent = () => {
    if (edmConfigJson.value) {
      const edmContent = [
        {
          Content: replacePersonalizeTag(replacerClassByStyle(edmHTMLContent.value) as string) as unknown as string,
          Content_Editor: edmConfigJson.value,
        },
      ];
      return edmContent;
    }
  };

  const replacePersonalizeTag = (contentHtml: string) => {
    const htmlElement = new DOMParser().parseFromString(contentHtml, 'text/html');
    const personalizeButton = htmlElement.getElementsByTagName('button');
    const scanCouponCodeImg = htmlElement.getElementsByClassName('scan-coupon-code');

    for (let i = 0; i < personalizeButton.length; i++) {
      const tagMp = JSON.stringify(JSON.parse(personalizeButton[i].value).tag).replaceAll('"', '');
      contentHtml = contentHtml.replace(personalizeButton[i].outerHTML, tagMp);
    }
    ///scan coupon code
    for (let i = 0; i < scanCouponCodeImg.length; i++) {
      const tagMp = JSON.stringify(JSON.parse(scanCouponCodeImg[i].getAttribute('value') as string).CouponColumnDetail.tag).replaceAll('"', '');
      const width = JSON.stringify(JSON.parse(scanCouponCodeImg[i].getAttribute('width') as string));
      const height = JSON.stringify(JSON.parse(scanCouponCodeImg[i].getAttribute('value') as string).BarcodeLineHeight);
      const barcodeDisplayValue = JSON.stringify(JSON.parse(scanCouponCodeImg[i].getAttribute('value') as string).BarcodeDisplayValue);
      const barcodeColor = JSON.stringify(JSON.parse(scanCouponCodeImg[i].getAttribute('value') as string).BarcodeColor)
        .replaceAll('#', '')
        .replaceAll('"', '');
      const barcodeBgColor = resolveBarcodeBgColor(
        JSON.stringify(JSON.parse(scanCouponCodeImg[i].getAttribute('value') as string).BarcodeBgColor)
          .replaceAll('#', '')
          .replaceAll('"', ''),
      );
      const src = JSON.stringify(scanCouponCodeImg[i].getAttribute('src') as string).replaceAll('"', '');
      const apiBarcode = `https://${
        process.env.NODE_ENV === 'development' ? 'dev-' : ''
      }cdp-action-api.azurewebsites.net//CDP-Action/api/v1.0/Comm/ImageRender/GetBarcode?barcodeType=31&couponCode=${tagMp}&width=${width}&height=${height}&enableLabel=${barcodeDisplayValue}&barcodeColor=${barcodeColor}&backgroundColor=${barcodeBgColor}`;

      contentHtml = contentHtml.replace(src, apiBarcode);
    }
    ///url
    htmlElement.querySelectorAll('a').forEach((element: HTMLElement) => {
      if (isJsonString(element.getAttribute('value') as string)) {
        const trackingMapper: Campaign.TrackingMapper = JSON.parse(element.getAttribute('value') as string);
        if (trackingMapper?.tag) {
          //Replace '&' with '&amp;' because links containing '&' are escaped.
          const href = JSON.stringify(element.getAttribute('href') as string).replace(/&/g, '&amp;');
          contentHtml = contentHtml.replace(href, JSON.stringify(trackingMapper.tag));
        }
      }
    });
    ///tracking open mail
    const tracker = htmlElement.querySelector('.tracking-open-mail');
    if (tracker) {
      const trackerValue = JSON.parse(tracker?.getAttribute('value') as string);
      const trackerSrc = JSON.stringify(tracker?.getAttribute('src'));
      if (trackerValue?.tag) {
        contentHtml = contentHtml.replace(trackerSrc, trackerValue.tag as string);
      }
    }

    return contentHtml;
  };

  const onGetEdmConfigJson = (json: string) => {
    edmConfigJson.value = json;
  };

  const onGetEdmContent = (contentHtml: string) => {
    edmHTMLContent.value = contentHtml;
  };

  const onGetFuncSaveText = (saveTextFunc: string) => {
    onSaveTextEdm = saveTextFunc;
  };

  const onGetQuillInstance = (quill: any, html: string) => {
    quillInstance = quill;
    quillHtml = html;
  };

  const onGetCreditDisplay = (credit: Campaign.SmsValue) => {
    creditDisplay.value = credit.creditUsed;
  };

  const onInputCampaignName = () => {
    if (campaignForm.info.name) {
      const campaignName = vm?.$refs['campaign-name'] as HTMLDivElement;
      campaignName.classList.remove('warning');
    }
  };

  const useMessageTemplate = (template: Communication.Template.Template) => {
    if (template.contents && template.contents.length) {
      // smsMessage.value = JSON.parse(template.contents[0].content_editor || '');
    }
  };

  const onAddCustomer = (addedDetail: {
    isSelectAll: boolean;
    selectedList: any[];
    excludeList: any[];
    isSelectAllWithExclude: boolean;
    segment: Communication.Personalization.PersonalizeSegment;
    totalRows: number;
  }) => {
    isSelectAllCustomer.value = addedDetail.isSelectAll;
    selectedCustomerList.value = addedDetail.selectedList;
    excludeCustomerList.value = addedDetail.excludeList;
    isSelectAllWithExclude.value = addedDetail.isSelectAllWithExclude;
    selectedSegment.value = addedDetail.segment;
    resolveSelectedRecp.value = resolveRecipients();

    const resolveCustomerNumber = addedDetail.isSelectAll
      ? addedDetail.totalRows
      : addedDetail.isSelectAllWithExclude
      ? addedDetail.totalRows - addedDetail.excludeList.length
      : addedDetail.selectedList.length;

    onSave(true);
  };

  function onApplySelectedCustomer(addedDetail: {
    isSelectAll: boolean;
    selectedList: any[];
    excludeList: any[];
    isSelectAllWithExclude: boolean;
    segment: number;
    totalRows: number;
  }) {
    isSelectAllCustomer.value = addedDetail.isSelectAll;
    selectedCustomerList.value = addedDetail.selectedList;
    excludeCustomerList.value = addedDetail.excludeList;
    isSelectAllWithExclude.value = addedDetail.isSelectAllWithExclude;
    selectedSegment.value = { segment_id: addedDetail.segment };
    if (selectedCustomerList.value.length <= 0) {
      isRemoveSegment.value = true;
    }
    resolveSelectedRecp.value = resolveRecipients();

    onSave(true);
  }
  function onApplySelectedCustomerUnConsent(addedDetail: {
    isSelectAll: boolean;
    selectedList: any[];
    excludeList: any[];
    isSelectAllWithExclude: boolean;

    totalRows: number;
  }) {
    isSelectAllCustomer.value = addedDetail.isSelectAll;
    selectedCustomerList.value = addedDetail.selectedList;
    excludeCustomerList.value = addedDetail.excludeList;
    isSelectAllWithExclude.value = addedDetail.isSelectAllWithExclude;
    if (selectedCustomerList.value.length <= 0 && excludeCustomerList.value.length <= 0) {
      isRemoveUnConsent.value = true;
    }

    resolveSelectedRecpUnConsent.value = resolveRecipientsUnConsent();

    onSave(true);
  }

  function onRemoveSegment(segmentId: number) {
    isRemoveSegment.value = true;
    selectedSegment.value = { segment_id: segmentId };
    resolveSelectedRecp.value = resolveRecipients();

    onSave(true);
  }

  const onSelectProvider = (provider: Communication.Provider.Provider) => {
    const settingTab = vm?.$refs['setting-tab'] as ComponentPublicInstance;
    const senderSelect = settingTab.$refs['sender-dropdown-container'] as HTMLDivElement;
    senderSelect?.classList.remove('warning');
    campaignForm.info.provider = provider;
  };

  const onSelectDateTime = (date: Date) => {
    campaignForm.schedule.scheduleDt = date?.toISOString() || null;
  };

  const onSelectRepeatMode = (mode: Campaign.RepeatMode) => {
    campaignForm.schedule.repeatMode = mode;
  };

  const onSelectRepeatOn = (repeatList: number[]) => {
    let sum = 0;

    repeatList.forEach((value) => (sum += value));
    campaignForm.schedule.repeatOn = sum;
  };

  const onInputEndOccurrence = (occurence: string) => {
    campaignForm.schedule.endOccurrence = Number(occurence);
  };

  const onSelectEndDateTime = (date: Date) => {
    campaignForm.schedule.endDt = date?.toISOString() || null;
  };

  const onInputRepeatEvery = (input: string) => {
    campaignForm.schedule.repeatEvery = Number(input) || 0;
  };

  const onSelectEndMode = (mode: Campaign.EndMode) => {
    campaignForm.schedule.endMode = mode;
  };

  const onSelectScheduleType = (scheduleType: Campaign.ScheduleMode) => {
    if (scheduleType == 1) {
      campaignForm.schedule.scheduleDt = new Date().toISOString();
    } else {
      campaignForm.schedule.scheduleDt = campaign.value.schedule.scheduleDt;
    }

    campaignForm.campaignId = campaign.value.campaignId;
    campaignForm.info.name = campaign.value.info.name;
    campaignForm.info.sender = campaign.value.info.sender;
    campaignForm.info.updatedDt = campaign.value.info.updatedDt;
    campaignForm.content = campaign.value.content;
    campaignForm.recpSelect = campaign.value.recpSelect;
    campaignForm.schedule.repeatMode = campaign.value.schedule.repeatMode;
    campaignForm.schedule.repeatEvery = campaign.value.schedule.repeatEvery;
    campaignForm.schedule.repeatOn = campaign.value.schedule.repeatOn;
    campaignForm.schedule.endMode = campaign.value.schedule.endMode;
    campaignForm.schedule.endDt = campaign.value.schedule.endDt;
    campaignForm.schedule.endOccurrence = campaign.value.schedule.endOccurrence;
    campaignForm.schedule.scheduleMode = scheduleType;
  };

  const validateForm = (): Campaign.ValidateResult | null => {
    const validateResult: Campaign.ValidateResult = {};

    if (!campaignForm.info.provider) validateResult.provider = { ref: 'sender-dropdown' };
    if (!campaignForm.recpSelect || !campaignForm.recpSelect.length) validateResult.segment = { ref: 'select-customer-container' };
    if (!campaignForm.info.name) validateResult.campaignName = { ref: 'campaign-name' };

    if (!campaignForm.isCreditEnough) {
      validateResult.isCreditEnough = { ref: 'isCreditEnough' };
    } else if ((campaignForm.creditBalance || 0) < resolveAllCreditUsed()) {
      validateResult.isCreditEnough = { ref: 'isCreditEnough' };
    }

    return Object.entries(validateResult).length ? validateResult : null;
  };
  const resolveAllCreditUsed = () => {
    let allCreditUsed = 0;
    if (selectedRecipient.value) {
      allCreditUsed =
        (selectedRecipient.value.recp_dispatchable + (selectedRecipient.value.recp_pdpa_allowunconsent || 0)) * (creditDisplay.value || 0);
    }
    return allCreditUsed;
  };
  const onClickValidation = (validateType: number) => {
    if (validateType == Validation.campaignName) {
      const campaignName = vm?.$refs['campaign-name'] as HTMLDivElement;
      campaignName.classList.add('warning');
    }
    if (validateType == Validation.provider) {
      activeTab.value = 2;

      nextTick(() => {
        const settingTab = vm?.$refs['setting-tab'] as ComponentPublicInstance;
        const senderSelect = settingTab.$refs['sender-dropdown-container'] as HTMLDivElement;
        senderSelect?.classList.add('warning');
      });
    }
    if (validateType == Validation.content) {
      activeTab.value = 0;

      nextTick(() => {
        const createTab = vm?.$refs['create-tab'] as ComponentPublicInstance;
        const smsEditor = createTab.$refs['sms-text-editor'] as ComponentPublicInstance;
        const editorContainer = smsEditor.$refs['sms-editor-container'] as HTMLDivElement;
        editorContainer?.classList.add('warning');
      });
    }

    if (validateType == Validation.segment) {
      activeTab.value = 1;

      nextTick(() => {
        const selectCustomerTab = vm?.$refs['select-customer-tab'] as ComponentPublicInstance;
        const container = selectCustomerTab.$refs['select-customer-container'] as HTMLDivElement;
        container.classList.add('warning');
      });
    }
  };

  const onSelectEmojiCampaignName = (emoji: string) => {
    const campaignNameInput = vm?.$refs['campaign-name-input'] as HTMLInputElement;
    campaignForm.info.name += emoji;
    campaignNameInput.focus();
    if (campaignForm.info.name) {
      const campaignName = vm?.$refs['campaign-name'] as HTMLDivElement;
      campaignName.classList.remove('warning');
    }
  };

  const isEditingEdm = () => {
    isEdmEditing.value = !isEdmEditing.value;
  };

  function resolveBarcodeBgColor(hex: string) {
    if (hex.length > 6) {
      hex = '00' + hex.substring(0, 6);
    }
    return hex;
  }

  //Personalize
  function onSelectSegment() {
    if (campaignForm.recpSelect && campaignForm.recpSelect?.length > 0) return (isSegmentSelected.value = true);
    else return (isSegmentSelected.value = false);
  }

  function onSelectPersonalize() {
    isPersonalizeModalOpen.value = true;
  }

  function onClosePersonalizeModal() {
    isPersonalizeModalOpen.value = false;
  }

  //coupon
  function onSelectCoupon() {
    isCouponCodeModalOpen.value = true;
  }

  function onCloseCouponModal() {
    isCouponCodeModalOpen.value = false;
  }

  function autoSave() {
    if (!isSaved.value && !readOnly.value && !isUploading.value) return onSave(false, true);
  }

  function onUploading(uploading: boolean) {
    isUploading.value = uploading;
  }

  function isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  onBeforeMount(() => {
    if (router.currentRoute.value.params.id) {
      fetchCampaignDetail();
    }
  });

  onMounted(() => {
    interval.value = setInterval(autoSave, 3600000);
  });

  onBeforeUnmount(() => {
    if (interval.value) {
      clearInterval(interval.value);
    }
  });

  watch(
    () => isEdmEditing.value,
    () => {
      if (!isEdmEditing.value) isSaved.value = false;
    },
  );

  watch(
    () => campaignForm.info.name,
    () => {
      validateResult.value = validateForm();
    },
  );

  watch(
    [campaignForm, selectedCustomerList],
    () => {
      onSelectSegment();
      if (!loading.value) isSaved.value = false;
    },
    {
      deep: true,
    },
  );

  watch(title, () => {
    if (!loading.value) isSaved.value = false;
  });

  watch(activeTab, async () => {
    if (!isSaved.value && !readOnly.value) {
      await onSave(true);
    } else {
      await fetchCampaignDetail();
    }

    if (activeTab.value == 3) {
      fetchScheduleInfoPreviewCampaign(Number(router.currentRoute.value.params.id));
    }
  });

  return {
    readOnly,
    edmConfigJson,
    campaignForm,
    campaign,
    selectedRecipient,
    isDisabledReload,
    loading,
    validateResult,
    activeTab,
    isSaved,
    recipientsDispatchable,
    creditDisplay,
    isPersonalizeModalOpen,
    isSegmentSelected,
    isCouponCodeModalOpen,
    isCategoryModalOpen,
    isCampaignMode,
    isClearUi,
    isUnConsentModal,
    isPasswordModal,
    isRemoveUnConsentModal,
    onChangeFilterSearch,
    onGetEdmConfigJson,
    onGetEdmContent,
    onDeleteFile,
    isEditingEdm,
    onGetCreditDisplay,
    onSelectProvider,
    onSelectDateTime,
    onSelectRepeatMode,
    onSelectRepeatOn,
    onInputEndOccurrence,
    onSelectEndDateTime,
    onSelectScheduleType,
    onInputRepeatEvery,
    onSave,
    onAddCustomer,
    onSelectEndMode,
    confirmPublish,
    onPublishCampaign,
    onClickValidation,
    onInputCampaignName,
    useMessageTemplate,
    onInputSubject,
    onSelectEmojiCampaignName,
    onSelectPersonalize,
    onClosePersonalizeModal,
    onSelectCoupon,
    onCloseCouponModal,
    onUploading,
    onRemoveSegment,
    onApplySelectedCustomer,
    onGetFuncSaveText,
    onClickSaveAsTemplate,
    onCloseCategoryModal,
    inputTemplateName,
    saveAsTemplate,
    onGetQuillInstance,
    onCloseUnConsent,
    onOpenPasswordModal,
    onOpenRemoveUnConsentModal,
    onCloseRemoveUnConsentModal,
    onClosePasswordModal,
    onApplySelectedCustomerUnConsent,
    onValidCodePassword,
    onConfirmRemoveUnConsent,
  };
}
