import { i18n } from '@/libs/i18n';

export const getLanguageDisplayModalError = (): string => {
  switch (i18n.global.locale) {
    case 'en-US':
      return 'en-us';
    case 'th':
      return 'th-th';
    default:
      return 'th-th';
  }
};
