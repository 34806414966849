export default {
  all_customer: {
    tabs: {
      customer: 'ลูกค้าทั้งหมด',
      product: 'สินค้าทั้งหมด',
      transaction: 'ธุรกรรมทั้งหมด',
    },

    button: {
      create_segment: 'สร้างกลุ่มเป้าหมาย',
      duplicate: 'ทำซ้ำ',
      export: 'ดึงรีพอร์ต',
    },
    table: {
      countdata_before: 'ข้อมูลทั้งหมด',
      countdata_after: 'ข้อมูล จากเงื่อนไขที่กำหนดกำลังถูกเลือก',
      clear_select: ' ล้างข้อมูลที่เลือก',
      segments_inbox_before: 'เลือกข้อมูลทั้งหมด ',
      segments_inbox_after: ' ข้อมูล',
    },
  },

  all_folder: {
    tabs: 'โฟลเดอร์ทั้งหมด',

    button: {
      action: 'จัดการ',
      create_folder: 'โฟลเดอร์',
      create_segment: 'เพิ่มการจัดกลุ่ม',
    },

    table: {
      folder_name: 'ชื่อโฟลเดอร์',
      total_segment: 'จำนวนกลุ่มเป้าหมาย',
      update_by: 'อัปเดตโดย',
      last_update: 'อัปเดตล่าสุด',
    },

    action: {
      edit: 'แก้ไข',
      delete: 'ลบ',
      copy: 'คัดลอก',
      tooltip: 'จัดการ',
    },

    popup: {
      delete_success: 'ลบโฟลเดอร์สำเร็จ',
    },

    modal: {
      create_folder: {
        header: 'สร้างโฟลเดอร์',
        folder_name: 'ชื่อโฟลเดอร์',
        placeholder: 'ระบุ',
      },

      delete_confirm: {
        topic: 'ลบโฟลเดอร์',
        description_first: 'กลุ่มลูกค้าที่สร้างทั้งหมดจะถูกลบออกจาก',
        description_second: 'โฟลเดอร์นี้ด้วย ต้องการลบใช่หรือไม่?',
      },

      button: {
        cancel: 'ยกเลิก',
        save: 'บันทึก',
        delete: 'ลบ',
      },
    },
  },

  all_segment: {
    tabs: 'กลุ่มเป้าหมายทั้งหมด',
    topic_edit_modal: 'แก้ไขโฟลเดอร์',
    label_edit_modal: 'ชื่อโฟลเดอร์',
    placeholder_edit_modal: 'ระบุ..',

    button: {
      action: 'จัดการ',
      create_segment: 'เพิ่มการจัดกลุ่ม',
      edit: 'แก้ไข',
      cancel: 'ยกเลิก',
    },

    table: {
      segment_name: 'ชื่อกลุ่ม',
      folder: 'โฟลเดอร์',
      segment_size: 'จำนวนข้อมูล',
      campaign: 'แคมเปญ',
      update_by: 'อัปเดตโดย',
      fast_update: 'อัปเดตล่าสุด',
      segment_type: 'รูปแบบการเชื่อมต่อ',
    },

    action: {
      edit: 'แก้ไข',
      duplicate: 'ทำซ้ำ',
      delete: 'ลบ',
    },

    popup: {
      delete_success: 'ลบโฟลเดอร์สำเร็จ',
    },
  },

  all_data: {
    tabs: 'ข้อมูลทั้งหมด',
  },

  detail_segment: {
    last_update: 'อัปเดตล่าสุด',
    hours_ago: 'ชั่วโมงที่แล้ว',
    minutes_ago: 'นาทีที่แล้ว',
    seconds_ago: 'วินาทีที่แล้ว',
    table: {
      statusbar_total_record_all: 'ทั้งหมด',
      statusbar_total_record_selected: 'ข้อมูล จากเงื่อนไขที่กำหนดกำลังถูกเลือก',
      statusbar_total_record_button_all: 'เลือกข้อมูลทั้งหมด ',
      statusbar_total_record_button_inbox: ' ข้อมูล',
      statusbar_total_record_button_clear: 'ล้างข้อมูลที่เลือก',
    },
    modal: {
      edit_segment_title: 'แก้ไขกลุ่มเป้าหมาย',
      edit_segment_name: 'ชื่อกลุ่มเป้าหมาย',
      segment_delete: {
        segment_order_all: 'ลบรายการทั้งหมด',
        segment_order: 'รายการ',
        segment_description: 'ต้องการลบรายการใช่หรือไม่?',
      },
      segment_replace: {
        segment_content_first: 'ชื่อกลุ่มลูกค้า ',
        segment_content_last: ' มีอยู่แล้ว',
        segment_description: 'กรุณาตั้งชื่อกลุ่มใหม่อีกครั้ง',
      },
    },
    button: {
      edit_segment: 'แก้ไขกลุ่มเป้าหมาย',
      edit_name: 'Edit Name',
      edit: 'แก้ไข',
      delete: 'ลบ',
      cancel: 'ยกเลิก',
      duplicate: 'ทำซ้ำ',
      export: 'ดึงรีพอร์ต',
    },
  },

  preview_segment: {
    title: 'แสดงตัวอย่างข้อมูล',
    folder: 'โฟลเดอร์',
    table: {
      statusbar_total_record_all: 'ทั้งหมด',
      statusbar_total_record_selected: 'ข้อมูล จากเงื่อนไขที่กำหนดกำลังถูกเลือก',
      statusbar_total_record_button_all: 'เลือกข้อมูลทั้งหมด ',
      statusbar_total_record_button_inbox: ' ข้อมูล',
      statusbar_total_record_button_clear: 'ล้างข้อมูลที่เลือก',
    },
    modal: {
      topic_create_segment_name: 'สร้างชื่อกลุ่มเป้าหมาย',
      label_segment_name: 'ชื่อกลุ่มเป้าหมาย',
      reate_segment_placeholder: 'ระบุ...',
    },
    button: {
      edit_rule: 'แก้ไขเงื่อนไข',
      save: 'บันทึก',
      confirm: 'ยืนยัน',
      cancel: 'ยกเลิก',
      edit_name: 'แก้ไขชื่อ',
      export_report: 'ดึงรีพอร์ต',
    },
  },

  preview: {
    title: 'ตัวอย่างรายการกลุ่มเป้าหมาย',
    save_type: 'รูปแบบการบันทึก',
    manage_segment: 'จัดการกลุ่มเป้าหมาย',
  },

  breadcrumb: 'Segment',
  result: {
    segment_list: 'รายการกลุ่มเป้าหมาย',
    insight: 'ข้อมูลเชิงลึก',
    view_type: 'มุมมองการการบันทึก',
    filter_segment: 'กรองผลลัพท์อีกขั้น',
    edit_rule: 'แก้ไขเงื่อนไข',
    edit_segment: 'จัดการกลุ่มเป้าหมาย',
    modal: {
      rename_input: 'ระบุ',
    },
  },

  components: {
    create_segment_modal: {
      set_conditions: 'กำหนดเงื่อนไข',
      logic: 'สมการ',
      no_matching_options: 'ไม่พบข้อมูล',

      operator: {
        title: 'เงื่อนไข',
        and: 'และ',
        or: 'หรือ',
        advance: 'ขั้นสูง',
      },

      button: {
        add_rule_set: 'สร้างชุดเงื่อนไข',
        filter_by_segment: 'กรองกลุ่มเป้าหมาย',
        cancel: 'ยกเลิก',
        apply: 'ใช้งาน',
        create_group: 'จัดกลุ่ม',
        ungroup_all: 'ยกเลิกการจัดกลุ่ม',
      },

      ruleset_card: {
        rule_name: 'ชื่อเงื่อนไข',
        set_conditions: 'กำหนดเงื่อนไข',
        logic: 'สมการ',
        group_by: 'Group by',

        operator: {
          title: 'เงื่อนไข',
          and: 'และ',
          or: 'หรือ',
        },

        button: {
          add_rule: 'เพิ่มเงื่อนไข',
          function_aggregate: 'ฟังก์ชัน Aggregate',
          add_function: 'เพิ่มข้อกำหนด',
        },
      },

      notification: {
        button: {
          cancel: 'ยกเลิก',
          confirm: 'ยืนยัน',
          close: 'ปิด',
        },
      },

      filter_by_segment: {
        rule_name: 'ชื่อกฎ',
        include: 'รวม',
        exclude: 'ไม่รวม',
        select_view: '- เลือกดู -',
        select_segment: '- เลือกกลุ่ม -',
      },
    },

    view_mode: {
      placeholder: '-- โปรดเลือก --',
      no_item: 'ไม่พบข้อมูล',
      customer: 'ลูกค้า',
      product: 'สินค้า',
      transaction: 'ธุรกรรม',
      group_by: 'ผลลัพธ์ทั้งหมด',
      tooltip: 'เลือกมุมมอง',
    },

    save_custom_dialog: {
      title: 'แก้ไขกลุ่มลูกค้า',
      view_data: 'ข้อมูล',

      table: {
        countdata_before: 'ข้อมูลทั้งหมด',
        countdata_after: 'ข้อมูล จากเงื่อนไขที่กำหนดกำลังถูกเลือก',
        clear_select: ' ล้างข้อมูลที่เลือก',
        segments_inbox_before: 'เลือกข้อมูลทั้งหมด ',
        segments_inbox_after: ' ข้อมูล',
      },

      all_customer: {
        tabs: {
          customer: 'รายชื่อลูกค้า',
          product: 'สินค้าทั้งหมด',
          transaction: 'ธุรกรรมทั้งหมด',
        },

        btn: {
          create_segment: 'สร้างกลุ่มเป้าหมาย',
        },
      },

      all_folder: {
        tabs: 'โฟลเดอร์',

        dropdown: {
          folder: 'โฟลเดอร์',
          segment: 'กลุ่มเป้าหมาย',
        },
      },

      all_segment: {
        tabs: 'การจัดกลุ่มทั้งหมด',

        dropdown: {
          segment: 'กลุ่มเป้าหมาย',
        },
      },

      all_data: {
        tabs: 'ข้อมูลทั้งหมด',
      },

      button: {
        cancel: 'ยกเลิก',
        add: 'เพิ่ม',
      },

      modal_next_tab: {
        title_first: 'หากเปลี่ยนแปลงแถบแสดงข้อมูลแล้ว',

        title_second: 'รายการที่เลือกก่อนหน้า จะไม่ถูกรวม',

        description: 'ต้องการดำเนินการต่อใช่ หรือไม่?',

        button: {
          cancel: 'ยกเลิก',
          next: 'ถัดไป',
        },
      },
      modal_save_modal: {
        title_before: 'บันทึกทั้งหมด ',

        title_after: ' รายการ',

        description: 'ต้องการบันทึกใช่ หรือไม่?',

        button: {
          cancel: 'ยกเลิก',
          confirm: 'ตกลง',
        },
      },

      modal_success: {
        title: 'บันทึกรายการสำเร็จ',
      },
    },

    breadcrumb: {
      folder: 'โฟลเดอร์',
    },

    dialog_segment: {
      segment_name: 'ชื่อกลุ่มเป้าหมาย',
      search: 'ค้นหา',
      title_folder: 'กรุณาเลือกโฟลเดอร์',
      view_data: 'ข้อมูล',

      table: {
        segment_name: 'ชื่อกลุ่มเป้าหมาย',
        segment_type: 'ประเภทกลุ่มเป้าหมาย',
        no_item: 'ไม่พบข้อมูล',
      },

      modal: {
        topic_create_folder: 'สร้างโฟลเดอร์',
        create_folder_placeholder: 'ตั้งชื่อโฟลเดอร์',
      },

      button: {
        create_folder: 'สร้างโฟลเดอร์',
        save: 'บันทึก',
        cancel: 'ยกเลิก',
      },
    },
  },
  segment_conditions: 'การจัดกลุ่มเป้าหมายตามเงื่อนไข',
  wait_moment: 'รอสักครู่...',
  saving_please_wait_moment: 'กำลังบันทึก กรุณารอสักครู่...',
  group_by: 'จัดกลุ่มตาม',
  executing_campaign: 'กำลังดำเนินการแคมเปญ...',
  save_NewEntry: 'บันทึกกลุ่มเป้าหมายใหม่',
  placeholder_save_segment: 'กรุณากรอกชื่อกลุ่มเป้าหมาย',
  custom_segment: 'การจัดกลุ่มเป้าหมายทีบันทึกเอง',
  new_segment: 'กลุ่มเป้าหมายใหม่',
  segment_template: 'เทมเพลตกลุ่มเป้าหมาย',
  //NotificationGroupRuleSet
  modal_GroupRuleSet_unableGroup: 'ไม่สามารถจัดกลุ่มได้',
  modal_GroupRuleSet_conditions: 'เนื่องจาก เงื่อนไขการจัดกลุ่มต้องเป็นเงื่อนไขเดียวกัน',
  modal_GroupRuleSet_edit_conditions: 'กรุณาแก้ไขเงื่อนไขก่อนการจัดกลุ่ม',
  modal_GroupRuleSet_selected_rule: 'เนื่องจาก Rule Set ที่เลือกไม่ต่อเนื่องกัน',
  modal_GroupRuleSet_select_newConsecutive: 'กรุณาเลือก Rule Set ใหม่ที่ต่อเนื่องกัน ก่อนการจัดกลุ่ม',
  modal_GroupRuleSet_clear_group: 'หากต้องการ Ungroup Rule ระบบจะทำการล้าง การGroupทั้งหมด',
  modal_GroupRuleSet_want_confirm: 'ต้องการยืนยันใช่ หรือไม่?',
  //NotificationModal
  modal_agg_off: 'หากปิด Function Aggregate ของ Rule Set Name',
  modal_clear_groupby: 'ข้อมูล Group By จะถูกเคลียร์ออกไป และ จะไม่สามารถ Group By ได้',
  modal_last_rule_set: 'เนื่องจาก เป็น Rule Set สุดท้ายที่เปิด Function Aggregate อยู่',
  modal_confirm: 'เมื่อทำการลบเงื่อนไขที่ตั้งค่าไว้ สำหรับค้นหา ข้อมูลจะถูกลบทันที?',
  modal_agg_data_clear: 'ข้อมูล ที่ถูกตั้งค่าไว้ที่ Function Aggregate จะถูกลบออกไป',
  modal_rule_set_delete: 'ยืนยันการลบเงื่อนไข',
  modal_cancel: 'หากต้องการ Cancel ข้อมูลจะไม่ถูกบันทึก',
  //NotificationOperatorMain
  modal_operator_change_condition: 'เปลี่ยนเงื่อนไขของกลุ่มเป้าหมาย',
  modal_operator_after_change: 'ภายหลังการเปลี่ยนเงื่อนไขของกลุ่มเป้าหมาย',
  modal_operator_delete_old_condition: 'เงื่อนไขเดิมจะถูกลบทันที',
  modal_operator_confirm: 'ต้องการยืนยันใช่ หรือไม่?',
  // ModalSubmit
  preview_save_with_sync: 'บันทึกการจัดกลุ่มเป้าหมายตามเงื่อนไข (ซิงก์ข้อมูล)',
  preview_save_without_sync: 'บันทึกการจัดกลุ่มเป้าหมายตามเงื่อนไข (ไม่ซิงก์ข้อมูล)',
  preview_save_manual: 'บันทึกการจัดกลุ่มเป้าหมายที่บันทึกเอง',
  preview_save_changes: 'บันทึกการเปลี่ยนแปลง',
  preview_in_folder: 'ในโฟลเดอร์',
  preview_confirm_save: 'ต้องการบันทึกใช่ หรือไม่?',
  // PreviewErrorModal
  preview_group_name: 'ชื่อกลุ่มลูกค้า',
  preview_group_exists: 'มีอยู่แล้ว',
  preview_rename_group: 'กรุณาตั้งชื่อกลุ่มใหม่อีกครั้ง',
  // SelectFolderModal
  preview_condition_group_with_sync: 'การจัดกลุ่มเป้าหมายตามเงื่อนไข (ซิงก์ข้อมูล)',
  preview_condition_group_without_sync: 'การจัดกลุ่มเป้าหมายตามเงื่อนไข (ไม่ซิงก์ข้อมูล)',
  preview_manual_group: 'การจัดกลุ่มเป้าหมายแบบเลือกบันทึกเอง',

  //------customer profile-------
  setting_dashboard: 'ตั้งค่าแดชบอร์ด',
  dashboard_setting: 'ตั้งค่าโปรไฟล์ลูกค้า',
  dashboard_overview: 'ภาพรวมแดชบอร์ด',
  branch: 'สาขา',
  point: 'Point',
  quantity_amount_pieces: 'จำนวน {{ value }} ชิ้น',
  open_ticket_by: 'เปิดตั๋วโดย',
  customer_scoring: 'การให้คะแนนลูกค้า',
  add_charts: 'เพิ่มแผนภูมิ',
  add_tabs: 'เพิ่มแท็บใหม่',
  add_data: 'เพิ่มข้อมูล',
  please_click: 'กรุณาคลิก',
  data_type: 'ประเภทข้อมูล',
  display: 'แสดง',
  selected: 'เลือก',
  infographic: 'ภาพประกอบข้อมูล',
  normal: 'Normal',
  manage_properties: 'จัดการคุณสมบัติ',
  select_properties: 'กรุณาเลือกคุณสมบัติ',
  last_visit: 'การเข้าชมครั้งล่าสุด',
  manage_property: 'จัดการคุณสมบัติ',
  profile: 'โปรไฟล์',
  overall: 'ภาพรวม',

  segment_type_dropdown: {
    select_segment_type: 'เลือกประเภทกลุ่มเป้าหมาย',
    segment_type: 'ประเภทกลุ่มเป้าหมาย',
    all: 'ทั้งหมด',
    batch: 'ไม่ซิงค์ข้อมูล',
    sync: 'ซิงค์ข้อมูล',
    custom: 'กำหนดเอง',
  },

  segment_select_desc: {
    data: 'ข้อมูล',
    select_count: 'เลือก {count} ข้อมูล',
    total: 'มีทั้งหมด',
    select_all: 'กำลังเลือกข้อมูลทั้งหมด',
    for_all: ' จากทั้งหมด ',
    clear_select: ' ล้างการเลือก',
  },
  panel_bulider: {
    filter_data_results: 'ผลลัพธ์การกรองประเภทข้อมูล',
    source: 'แหล่งที่มา',
    table: 'ตาราง',
    set_condition: 'กำหนดเงื่อนไข',
    condition: 'เงื่อนไข',
    and: 'และ',
    or: 'หรือ',
    list: 'รายการ',
    advance: 'ขั้นสูง',
    aggregate_function: 'ฟังก์ชันรวมค่า​',
    drag_and_drop_columns: 'กรุณาลาก และวางคอลัมน์',
    add_condition: 'เพิ่มเงื่อนไข',
    add_filter_segment: 'เพิ่มการกรอง',
    reset: 'รีเซ็ต',
    cancel: 'ยกเลิก',
    apply: 'นำไปใช้',
    select_count: 'เลือกแล้ว',
    select_data: 'เลือกข้อมูล',
    clear_select: 'ล้าง',
    type: 'ประเภทข้อมูล',
    column: 'คอลัมน์',
    rule_ex: 'หัวข้อเงื่อนไข',
    include: 'รวม',
    exclude: 'ไม่รวม',
    folder: 'โฟล์เดอร์',
    segment: 'กลุ่มเป้าหมาย',
    ex: 'สมการ',
    groupby: 'จัดกลุ่มโดย',
  },
  close_back: 'ย้อนกลับ',
  apply: 'นำไปใช้',
  modal_select_view: {
    title: 'รูปแบบการบันทึก',
    des: 'กรุณาเลือกประเภทข้อมูลที่ต้องการบันทึก เพื่อแสดงรายการเฉพาะประเภทข้อมูลที่เลือก',
    only: 'แสดงเฉพาะข้อมูล{view}',
  },
  advance_view: {
    title: 'รูปแบบการจัดกลุ่มขั้นสูง',
    des: 'กำหนดรูปแบบการแสดงผลข้อมูลเอง เพื่อใช้แสดงผลลัพธ์ในมุมมองตามที่ต้องการหรือแสดงรายการมากกว่าหนึ่งประเภทตารางข้อมูล (เช่น เลขที่บิล, เลขที่การแลกแต้ม)',
    header_step_one: 'กำหนดประเภทตารางข้อมูลแสดงผล',
    header_step_two: 'กำหนดประเภทตารางข้อมูลแสดงผลเสริม',
    header_step_three: 'กำหนดการแสดงผลคอลัมน์',
    left_one: 'เลือกประเภทตารางข้อมูลพื้นฐาน',
    right_two: 'ตารางที่เลือก',
    main_select: 'กรุณาเลือกตารางหลัก',
    main: 'ตารางหลัก',
    additional: 'ตารางเสริม',
    comingsoon: 'เร็วๆ นี้..',
    back: 'ย้อนกลับ',
    next: 'ถัดไป',
    submit: 'นำไปใช้',
  },
  save_segment_sync: 'บันทึกกลุ่มเป้าหมายแบบซิงค์ข้อมูล',
  save_segment_batch: 'บันทึกกลุ่มเป้าหมายแบบไม่ซิงค์ข้อมูล',
  save_segment_custom: 'บันทึกกลุ่มเป้าหมายแบบกำหนดเอง',
  save: 'บันทึก',
  to_folder: 'ในโฟลเดอร์',
  leave_page: 'ต้องการออกจากหน้านี้หรือไม่',
  leave_page_des: 'ต้องการยกเลิกการสร้างเงื่อนไขกลุ่มเป้าหมายและออกจากหน้านี้?',
};
