import { start } from '@popperjs/core';

export default {
  mka: 'Marketing Automation',
  automations: 'Automations',
  summary: 'Campaign Overview', //Campaign Overview/ Overall Campaign
  status: 'Status',
  all_campaign: 'All Campaign',
  active_campaigne: 'Active',
  pause_campaigne: 'Pause',
  draft_campaigne: 'Draft',
  complete_campaigne: 'Finished',
  inactive_campaigne: 'Inactive',
  status_all: 'All',
  status_draft: 'Draft',
  status_preparing: 'Preparing',
  status_active: 'Active',
  status_archived: 'Archived',
  status_pause: 'Pause',
  status_complete: 'Complete',
  campaign_name: 'Campaign Name',
  start_date: 'Start Date',
  end_date: 'End Date',
  last_update: 'Last Update',
  update_by: 'Update By',
  manage: 'Manage',
  detail: 'Detail',
  clone: 'Clone ',
  edit: 'Edit',
  delete: 'Delete',
  create_campaign: 'Create Campaign',
  date: 'Date',
  clear: 'Clear',
  start_campaign: 'Start Campaign',
  pause_campaign: 'Pause Campaign',
  resume_campaign: 'Resume Campaign',
  saved: 'Saved',
  entry_source: 'Data Source',
  flow_control: 'Condition',
  action: 'action',
  actions: 'Export Channel', //Actions
  content: 'Content',
  sender: 'Sender',
  change: 'Change',
  save: 'Save',
  subject: 'Subject',
  segment: 'Segment',
  report: 'Report',
  success: 'Success',
  fail: 'Fail',
  resume: 'Resume',
  export: 'Export',
  preview: 'Preview',
  other: 'อื่นๆ',
  consent_pdpa: 'Send all',
  pdpa_restricted: 'Do not consent to PDPA',
  entry_source_segment: 'Segment',
  flow_control_fork_split: 'Number of Channels',
  flow_control_distribution_split: 'Distribution Split',
  flow_control_distribution_split_sub: '(Round Robin)',
  flow_control_random_split_true: 'Random Split',
  flow_control_random_split_true_sub: '(True Random)',
  flow_control_random_split_normal: 'Random Split',
  flow_control_random_split_normal_sub: '(Normalize)',
  flow_control_wait_by_duration: 'Waiting Time',
  flow_control_wait_until_date: 'Date & Time', //Wait Until Date
  select_date: 'Select Date',
  select_time: 'Select Time',
  flow_control_merge: 'All Channels', //Merge
  flow_control_limit: 'Limit Sending',
  actions_sms: 'SMS',
  actions_line: 'Line',
  actions_edm: 'EDM',
  modal_use: 'Use',
  modal_next: 'Next',
  modal_back: 'Back',
  modal_cancel: 'Cancel',
  modal_change: 'Change',
  modal_close: 'Close',
  modal_apply: 'Apply',
  modal_view_data: 'Data View',
  modal_folder: 'Folder',
  modal_segment: 'Segment', // Target Group/ Customer Segmentation
  modal_priority: 'Priority Level',
  modal_year: 'Year',
  modal_month: 'Month',
  modal_day: 'Day',
  modal_hour: 'Hour',
  modal_minute: 'Minute',
  modal_second: 'Second',
  modal_branchs: 'Number', //Branchs
  modal_total_customer_limit: 'Total Customer Limit',
  modal_limit: 'Determine',
  modal_unlimit: 'Not Determine',
  modal_limit_per_customer: 'Limit Number of Customer.',
  modal_mode: 'Mode',
  modal_wait_all: 'All Waiting',
  modal_wait_count: 'Waiting Up to Follow',
  modal_flow_mode: 'Priority Level',
  modal_non_priority: 'Not in order',
  modal_entry_priority: 'Set Up the Setting',
  modal_add: 'Add',
  modal_tooltip_campaign_public: 'Double Click to view more details.',
  modal_tooltip_campaign_draft: 'Double Click to fill in additional details.',
  summary_sent: 'Sent',
  summary_condition: 'Condition',
  summary_completed: 'Completed',
  summary_outstanding: 'Outstanding',
  summary_remaining: 'Credit',
  summary_failed: 'Failed',
  summary_last_update: 'Last Update',
  summary_total_customer: 'Total Customer',
  summary_contacts_today: 'Contacts Today',
  summary_ready_to_send: 'Ready to Send',
  summary_unconsented: 'Unconsented',
  summary_success: 'Success',
  summary_campaign_publish: 'Last Campaign Publish',
  summary_total: 'Total',
  summary_persons: 'Persons',
  action_info_title: 'Recipients',
  action_info_campaign_name: 'Campaign name',
  action_info_recipient: 'Recipient',
  action_info_sender_name: 'Sender',
  action_info_created_by: 'Created by',
  action_info_last_updated: 'Last updated',
  action_info_all_recipient: 'All recipients',
  action_info_success: 'Success',
  action_info_fail: 'Fail',
  action_info_not_enough_credit: 'Not enough credit',
  action_info_node_name: 'Name segment',
  action_info_type_desc_source: 'Segment type',
  action_info_type_desc_flow: 'Condition type',
  verify_error: 'Something went wrong',
  verify_error_node: 'Please check the node connection and try again.',
  verify_error_flow: 'Please check the edge connection and try again.',
  add_credit: 'Add Credit',
  journey_paused: 'Journey Paused',
  before_start_journey: 'Before start journey',
  detail_before_start_journey: {
    channel: '',
    start: 'channel, The message will not be delivered to the recipients.',
    end: 'Due to insufficient credit.',
  },
  detail_confirm_before_start_journey: {
    start: 'But you can still send the message by using',
    middle: 'channel',
    end: 'If you would like to start Journey. Please press ',
    confirm: '"confirm"',
  },
  credit_remaining_header: 'Credit Balance',
  channel: 'Channel',
  credit_remaining: 'Credit Balance',
  channel_no_provider: 'Provider not found',
  channel_no_provider_contact: 'Contact Us',
  segment_clone_modal_warning: 'Unable to proceed  because the segment version does not match the current version',
  segment_does_not_match: 'Segment does not match the current version',
  modal_detail_create_mka_journey: {
    a: 'To send a message through marketing automation,',
    b: "you need to use a template for your desired communication channel. Click 'Create Template'",
    c: "to make a new one, or if you already have a template, click 'Continue'",
  },
  modal_create_template: 'Create template',
  modal_continue: 'Continue',

  modal_select_communication_channel: 'Select a Communication Channel.',
  modal_select_communication_channel_detail: 'Please choose a channel of communication to create a template.',

  modal_confirm_data_submission: 'Start Campaign',
  modal_confirm_run_detail: 'Do you want to start this Journey ?',

  modal_confirm_pause: 'Pause Campaign',
  modal_confirm_pause_campaign: 'Do you want to pause this Journey ?',

  modal_confirm_resume: 'Resume Campaign',
  modal_confirm_resume_campaign: 'Do you want to resumption this Journey ?',

  modal_incorrect_information: 'Something went wrong.',
  modal_incorrect_information_detail: 'Please review and complete all required information.',
  modal_incorrect_information_detail_100: 'The proportions do not sum up to 100%.',

  clear_data: 'Clear Data',
  delete_all_mka: 'Delete all MKA Flow data?',
};
