import { ref, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
export default function useCreateRichMessage() {

  const { t } = useI18n();
  const isExpand: Ref<boolean> = ref(false);
  const isTemplateModalOpen: Ref<boolean> = ref(false);
  const actionList = ref([
    {
      key: 'uri',
      label: t('communication.url_link'),
      value: 1,
    },
  ]);

  function onOpenTemplateModal() {
    isTemplateModalOpen.value = true;
  }

  function onCloseTemplateModal() {
    isTemplateModalOpen.value = false;
  }

  const handleExpandCollapse = () => {
    isExpand.value = !isExpand.value;
    const configElem = document.querySelectorAll('[data-bs-toggle="collapse"]');
    if (isExpand.value == false) {
      for (let i = 0; i < configElem.length; i++) {
        configElem[i].classList.add('collapsed');
        configElem[i].querySelector('.collapse')?.classList.remove('show');
      }
    } else {
      for (let i = 0; i < configElem.length; i++) {
        configElem[i].classList.remove('collapsed');
        configElem[i].querySelector('.collapse')?.classList.add('show');
      }
    }
  };

  function preventCollapse(e: any) {
    const collapseElem = e.currentTarget.closest('.collapsed')?.querySelector('.collapsing');
    if (collapseElem) {
      const configElem = document.getElementById(`${collapseElem.getAttribute('id') as string}`);
      e.currentTarget.closest('.collapsed')?.classList.remove('collapsed');
      configElem?.classList.remove('collapsing');
      configElem?.classList.add('show');
    }
  }
  return {
    isExpand,
    isTemplateModalOpen,
    actionList,
    handleExpandCollapse,
    preventCollapse,
    onOpenTemplateModal,
    onCloseTemplateModal,
  };
}
