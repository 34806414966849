import { ref, Ref, reactive, onMounted, getCurrentInstance, watch, toRaw, nextTick } from 'vue';
import { addImageUI } from '../../../../constants/constant-edm';
import { PATH } from '@/constants/modules/file-service/path';
import apiService from '@/services/api';
import fileServiceModel from '@/models/file-service/file-service';
import useLoading from '@/views/components/loading/hooks/useLoading';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import { useI18n } from 'vue-i18n';
interface Props {
  selectedElement: Record<string, any>;
  backupElement: Communication.EdmEditor.CoreEdmElement;
  templateMode?: string;
  tagMp?: number;
}

export default function useImageTabPanel(props: Props) {
  const { t } = useI18n();
  const vm = getCurrentInstance()?.proxy;
  const { uploadFileModel } = fileServiceModel();
  const { openErrorModal, openDefaultErrorModal } = useValidationModal();
  const { isLoading } = useLoading();
  //custom image
  const uploadImage: Communication.EdmEditor.UploadFile = reactive({ sref: '', url: '' });
  const isLockImgSizeRatio: Ref<boolean> = ref(true);
  let imgWidthOriginal = 0;
  let imgHeightOriginal = 0;
  let oldWidth = 0;
  let oldHeight = 0;
  let tableDataLength = 0;
  const imgWidth: Ref<number> = ref(0);
  const imgHeight: Ref<number> = ref(0);
  const disabledPadding: Ref<boolean> = ref(true);
  const spacingTop: Ref<number> = ref(0);
  const spacingBottom: Ref<number> = ref(0);
  const indentLeft: Ref<number> = ref(0);
  const indentRight: Ref<number> = ref(0);
  const alignMent: Ref<string> = ref('center');
  const justifyMent: Ref<string> = ref('middle');

  const isChangedRadiusMode: Ref<boolean> = ref(false);
  const borderRadius: Record<string, number> = reactive({
    all: 0,
    topLeft: 0,
    bottomLeft: 0,
    topRight: 0,
    bottomRight: 0,
  });
  const borderType: Ref<string> = ref('solid');
  const borderPx: Ref<string> = ref('0px');
  const isBorderColorPickerOpen: Ref<boolean> = ref(false);
  const borderColor: Ref<string> = ref('');
  const altText: Ref<string> = ref('');
  const imgLink: Ref<string> = ref('');
  const openLink: Ref<number> = ref(1);
  ///mapper
  const trackingMapper: Campaign.TrackingMapper = {
    mapperType: 10,
    subType: 2,
    linkValue: null,
    tag: null,
  };

  const buildImageObjProperties = () => {
    if (!uploadImage.url) {
      // Case no image selected / upload yet
      EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Content.Type = 'ImageBlank';
      EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Content.Properties = {};
      EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Style = {};
      EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.HTMLUI = addImageUI;
    } else {
      //optional prop
      EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.HTMLUI = '';

      //td prop
      EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Style = {
        'vertical-align': justifyMent.value,
        'text-align': alignMent.value,
        'padding-top': `${spacingTop.value}px`,
        'padding-bottom': `${spacingBottom.value}px`,
        'padding-left': `${indentLeft.value}px`,
        'padding-right': `${indentRight.value}px`,
      };

      //blockdata prop & custom content type data
      EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Content.Type = 'Image';
      EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Content.Properties = {
        SummaryPadding: summaryPadding(),
        WidthMemory: imgWidth.value,
        HeightMemory: imgHeight.value,
        Width: Math.min(realColumnSize(), imgWidth.value),
        Url: uploadImage.url,
        Alt: altText.value,
        Link: imgLink.value,
        Value: setMapperValue(),
        Style: {
          'border-top-left-radius': isChangedRadiusMode.value === false ? borderRadius.all + 'px' : borderRadius.topLeft + 'px',
          'border-top-right-radius': isChangedRadiusMode.value === false ? borderRadius.all + 'px' : borderRadius.topRight + 'px',
          'border-bottom-left-radius': isChangedRadiusMode.value === false ? borderRadius.all + 'px' : borderRadius.bottomLeft + 'px',
          'border-bottom-right-radius': isChangedRadiusMode.value === false ? borderRadius.all + 'px' : borderRadius.bottomRight + 'px',
          'border-style': borderType.value,
          'border-width': borderPx.value,
          'border-color': borderColor.value,
        },

        ImageData: {
          UploadImage_Sref: uploadImage.sref,
          // Height: imgHeight.value,
          WidthOriginal: imgWidthOriginal,
          HeightOriginal: imgHeightOriginal,
          DisablePadding: disabledPadding.value,
          Padding_Top: spacingTop.value,
          Padding_Bottom: spacingBottom.value,
          Padding_Left: indentLeft.value,
          Padding_Right: indentRight.value,
          IsChangedRadiusMode: isChangedRadiusMode.value,
          BorderRadius_All: borderRadius.all,
          BorderRadius_TopLeft: borderRadius.topLeft,
          BorderRadius_BottomLeft: borderRadius.bottomLeft,
          BorderRadius_TopRight: borderRadius.topRight,
          BorderRadius_BottomRight: borderRadius.bottomRight,
          // BorderType: borderType.value,
          // BorderPx: borderPx.value,
          // BorderColor: borderColor.value,
          OpenLink: openLink.value,
        },
      };
    }

    EDMRender('cdp-edm-editor');
    vm?.$emit('on-build-obj');
    vm?.$emit('on-get-edm-config-json', EDMRenderSave('cdp-edm-editor'));
    vm?.$emit('on-get-edm-content', EDMRenderResult('cdp-edm-editor'));
    nextTick(() => {
      vm?.$emit('set-is-selected');
      vm?.$emit('handle-selected-element');
    });
  };

  const onChangeRadiusMode = () => {
    isChangedRadiusMode.value = !isChangedRadiusMode.value;
  };

  const handleDisablePadding = () => {
    disabledPadding.value = !disabledPadding.value;
    if (disabledPadding.value == true) {
      spacingTop.value = 0;
      spacingBottom.value = 0;
      indentLeft.value = 0;
      indentRight.value = 0;
    } else {
      spacingTop.value = 10;
      spacingBottom.value = 10;
      indentLeft.value = 10;
      indentRight.value = 10;
    }
  };

  const onSelectAlignMent = (align: string) => {
    alignMent.value = align;
  };

  const onSelectJustifyMent = (justify: string) => {
    justifyMent.value = justify;
  };

  const onSelectBorder = (border: string) => {
    borderType.value = border;
  };

  const onSelectBorderSize = (px: string) => {
    borderPx.value = px;
  };

  const onToggleColorPicker = () => {
    isBorderColorPickerOpen.value = !isBorderColorPickerOpen.value;
  };

  const onChangeBorderColor = (color: string) => {
    borderColor.value = color;
  };

  const onSelectOpenLink = (type: number) => {
    openLink.value = type;
  };

  const onToggleImgSizeRatioLock = (e: Event) => {
    isLockImgSizeRatio.value = !isLockImgSizeRatio.value;
  };

  const columnSize = () => {
    return tableDataLength * props.backupElement.ColObj.Properties.Length;
  };

  const summaryPadding = () => {
    return indentLeft.value + indentRight.value;
  };

  const realColumnSize = () => {
    return columnSize() - summaryPadding();
  };

  const onImgWidthChanged = () => {
    const contentObj = EDMGetObjList('cdp-edm-editor')[props.selectedElement.id];
    const contentElem = document.querySelector(`#${contentObj.Id as string}`);
    const imageElem = contentElem?.closest('table')?.querySelector('img');

    if (isLockImgSizeRatio.value) {
      if (imgWidth.value > oldWidth) {
        imgWidth.value = Math.min(realColumnSize(), imgWidth.value);
      } else if (imgWidth.value < oldWidth) {
        imgWidth.value = Math.max(1, imgWidth.value);
      }
      imgHeight.value = calImageHeight(imgWidth.value);
    }
    imageElem?.setAttribute('width', String(Math.min(realColumnSize(), imgWidth.value)));
  };

  const onImgHeightChanged = () => {
    const contentObj = EDMGetObjList('cdp-edm-editor')[props.selectedElement.id];
    const contentElem = document.querySelector(`#${contentObj.Id as string}`);
    const imageElem = contentElem?.closest('table')?.querySelector('img');

    if (isLockImgSizeRatio.value) {
      const calWidth = calImageWidth(imgHeight.value);
      if (imgHeight.value > oldHeight) {
        if (calWidth <= realColumnSize()) {
          oldHeight = imgHeight.value;
          imgWidth.value = calWidth;
        } else {
          imgWidth.value = realColumnSize();
          oldWidth = realColumnSize();
          imgHeight.value = calImageHeight(imgWidth.value);
        }
      } else if (imgHeight.value < oldHeight) {
        if (calWidth >= 1) {
          oldHeight = imgHeight.value;
          imgWidth.value = calWidth;
        } else {
          imgWidth.value = 1;
          oldWidth = 1;
          imgHeight.value = calImageHeight(imgWidth.value);
        }
      }
    }
    imageElem?.setAttribute('width', String(Math.min(realColumnSize(), imgWidth.value)));
  };

  const handleUploadImageChange = (e: Event) => {
    const filesOBJ: File = (e.target as HTMLInputElement).files?.item(0) as File;
    if (filesOBJ) {
      if (isFileImage(filesOBJ)) {
        if (isMaxFileSize_10_MB(filesOBJ)) {
          isLoading(true, 'กำลังอัพโหลดรูปภาพ');
          vm?.$emit('uploading', true);
          uploadFileModel.payload.Path = props.templateMode ? PATH.SYSTEM.COMMU.EDM : PATH.COMMU.EDM;
          uploadFileModel.payload.File = filesOBJ;
          uploadFileModel.payload.Is_Temp = true;
          apiService
            .apiFormRequest(uploadFileModel)
            .then((response) => {
              const res = response.data as Communication.File.Request.Response.File;

              uploadImage.sref = res.sref;
              uploadImage.url = res.public_url;
              getImageSize(res.public_url);
              vm?.$emit('on-add-sref-list', uploadImage.sref);
            })
            .catch((err) => {
              openDefaultErrorModal(err);
            })
            .finally(() => {
              isLoading(false);
              vm?.$emit('uploading', false);
            });
        } else {
          openErrorModal(t('global.error'), 'ขนาดไฟล์ใหญ่เกินไป', '');
          clearFile(e);
        }
      } else {
        openErrorModal(t('global.error'), 'ไฟล์ผิดประเภท', '');
        clearFile(e);
      }
    }
  };

  const getImageSize = (url: string) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      imgWidthOriginal = img.width;
      imgHeightOriginal = img.height;
      if (img.width > realColumnSize()) {
        imgWidth.value = realColumnSize();
      } else {
        imgWidth.value = img.width;
      }
      imgHeight.value = calImageHeight(imgWidth.value);
      oldWidth = imgWidth.value;
      oldHeight = imgHeight.value;
      buildImageObjProperties();
    };
  };

  const calImageWidth = (h: number): number => {
    const w = Math.ceil((imgWidthOriginal / imgHeightOriginal) * h);
    return w;
  };

  const calImageHeight = (w: number): number => {
    const h = Math.ceil((imgHeightOriginal / imgWidthOriginal) * w);
    return h;
  };

  const checkInputPadding = () => {
    const min = 0;
    const max = 40;
    if (spacingTop.value < min) {
      spacingTop.value = min;
    }
    if (spacingBottom.value < min) {
      spacingBottom.value = min;
    }
    if (indentLeft.value > max) {
      indentLeft.value = max;
    } else if (indentLeft.value < min) {
      indentLeft.value = min;
    }
    if (indentRight.value > max) {
      indentRight.value = max;
    } else if (indentRight.value < min) {
      indentRight.value = min;
    }
  };

  const isFileImage = (file: File) => file && file.type.split('/')[0] === 'image';

  const isMaxFileSize_10_MB = (file: File) => file && file.size <= 10485760;

  const clearFile = (e: Event) => {
    if (uploadImage.sref !== '') {
      vm?.$emit('on-delete-file', uploadImage.sref);
    }
    uploadImage.url = '';
    imgWidth.value = 0;
    imgHeight.value = 0;
    (e.target as HTMLInputElement).value = '';
  };

  function setMapperValue(): Campaign.TrackingMapper | null {
    if (imgLink.value) {
      const valueAttribute = EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Content.Properties.Value;
      if (!valueAttribute?.tag) {
        trackingMapper.tag = `{{mp:${props.tagMp as number}}}`;
        vm?.$emit('update-tag-number');
      }
      trackingMapper.linkValue = imgLink.value;
      return trackingMapper;
    }
    return null;
  }

  onMounted(() => {
    const currentNode = EDMGetObjList('cdp-edm-editor')[props.selectedElement.id];
    tableDataLength = EDMGetEditorRunTime('cdp-edm-editor').Config.TableDataLength;
    if (currentNode.Properties.Content.Type == 'Image') {
      uploadImage.sref = currentNode.Properties.Content.Properties.ImageData.UploadImage_Sref ?? '';
      uploadImage.url = currentNode.Properties.Content.Properties.Url;
      imgWidth.value = currentNode.Properties.Content.Properties.WidthMemory;
      imgHeight.value = currentNode.Properties.Content.Properties.HeightMemory ?? 0;
      oldWidth = currentNode.Properties.Content.Properties.WidthMemory;
      oldHeight = currentNode.Properties.Content.Properties.HeightMemory ?? 0;
      imgWidthOriginal = currentNode.Properties.Content.Properties.ImageData.WidthOriginal ?? 0;
      imgHeightOriginal = currentNode.Properties.Content.Properties.ImageData.HeightOriginal ?? 0;

      disabledPadding.value = currentNode.Properties.Content.Properties.ImageData.DisablePadding ?? false;
      spacingTop.value = currentNode.Properties.Content.Properties.ImageData.Padding_Top;
      spacingBottom.value = currentNode.Properties.Content.Properties.ImageData.Padding_Bottom;
      indentLeft.value = currentNode.Properties.Content.Properties.ImageData.Padding_Left;
      indentRight.value = currentNode.Properties.Content.Properties.ImageData.Padding_Right;

      justifyMent.value = currentNode.Properties.Style['vertical-align'];
      alignMent.value = currentNode.Properties.Style['text-align'];

      isChangedRadiusMode.value = currentNode.Properties.Content.Properties.ImageData.IsChangedRadiusMode ?? false;
      borderRadius.all = currentNode.Properties.Content.Properties.ImageData.BorderRadius_All ?? 0;
      borderRadius.topLeft = currentNode.Properties.Content.Properties.ImageData.BorderRadius_TopLeft ?? 0;
      borderRadius.bottomLeft = currentNode.Properties.Content.Properties.ImageData.BorderRadius_BottomLeft ?? 0;
      borderRadius.topRight = currentNode.Properties.Content.Properties.ImageData.BorderRadius_TopRight ?? 0;
      borderRadius.bottomRight = currentNode.Properties.Content.Properties.ImageData.BorderRadius_BottomRight ?? 0;
      borderType.value = currentNode.Properties.Content.Properties.Style['border-style'] ?? '';
      borderPx.value = currentNode.Properties.Content.Properties.Style['border-width'] ?? '';
      borderColor.value = currentNode.Properties.Content.Properties.Style['border-color'] ?? '';
      altText.value = currentNode.Properties.Content.Properties.Alt;
      imgLink.value = currentNode.Properties.Content.Properties.Link;
      openLink.value = currentNode.Properties.Content.Properties.ImageData.OpenLink ?? 1;
      trackingMapper.tag = currentNode.Properties.Content.Properties.Value?.tag ?? null;
    }
  });

  watch(
    [
      isChangedRadiusMode,
      disabledPadding,
      uploadImage,
      spacingTop,
      spacingBottom,
      indentLeft,
      indentRight,
      alignMent,
      justifyMent,
      borderType,
      borderRadius,
      borderPx,
      borderColor,
      altText,
      imgLink,
      openLink,
      isBorderColorPickerOpen,
    ],
    () => {
      buildImageObjProperties();
    },
    { deep: true },
  );

  return {
    isLockImgSizeRatio,
    isChangedRadiusMode,
    disabledPadding,
    uploadImage,
    imgWidth,
    imgHeight,
    spacingTop,
    spacingBottom,
    indentLeft,
    indentRight,
    alignMent,
    justifyMent,
    borderType,
    borderRadius,
    borderPx,
    borderColor,
    altText,
    imgLink,
    openLink,
    isBorderColorPickerOpen,
    checkInputPadding,
    onChangeRadiusMode,
    handleDisablePadding,
    onSelectAlignMent,
    onSelectJustifyMent,
    onSelectBorder,
    onSelectBorderSize,
    onChangeBorderColor,
    onToggleColorPicker,
    onSelectOpenLink,
    onToggleImgSizeRatioLock,
    onImgWidthChanged,
    onImgHeightChanged,
    handleUploadImageChange,
    clearFile,
    buildImageObjProperties,
  };
}
