import { reactive, Ref, ref, getCurrentInstance, markRaw, computed, watch, onMounted, onBeforeMount, nextTick } from 'vue';
import { Channel } from '@views/modules/communication/pages/main/constants/channel';
import templateModel from '@/models/communication/template';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import { useI18n } from 'vue-i18n';
import All from '@/assets/icons/modules/communication/template-tab/template-category/All.vue';
import Popular from '@/assets/icons/modules/communication/template-tab/template-category/Popular.vue';
import Promotions from '@/assets/icons/modules/communication/template-tab/template-category/Promotions.vue';
import Seasonal from '@/assets/icons/modules/communication/template-tab/template-category/Seasonal.vue';
import Awareness from '@/assets/icons/modules/communication/template-tab/template-category/Awareness.vue';
import WinBack from '@/assets/icons/modules/communication/template-tab/template-category/WinBack.vue';
import Engagement from '@/assets/icons/modules/communication/template-tab/template-category/Engagement.vue';
import Notification from '@/assets/icons/modules/communication/template-tab/template-category/Notification.vue';
import Other from '@/assets/icons/modules/communication/template-tab/template-category/Other.vue';
import apiService from '@/services/api';

// icon
import AllMessageTypeIcon from '@assets/icons/modules/communication/line-oa/AllMessageTypeIcon.vue';
import MessageIcon from '@assets/icons/modules/communication/line-oa/MessageIcon.vue';
import RichMessageIcon from '@assets/icons/modules/communication/line-oa/RichMessageIcon.vue';
import RichVideoIcon from '@assets/icons/modules/communication/line-oa/RichVideoIcon.vue';
import CardMessageIcon from '@assets/icons/modules/communication/line-oa/CardMessageIcon.vue';
import MixMessageIcon from '@assets/icons/modules/communication/line-oa/MixMessageIcon.vue';

// constant
import { NODE_ACTION_LINE_SUB_TYPE } from '@/constants/modules/mka/mkaDetail';
import { MESSAGE_TYPE } from '@/views/modules/communication/pages/line-oa-campaign/constants/messageType';

interface Props {
  isOpen: boolean;
  channel: number;
  connectFunction: any | null;
}

interface MessageType {
  key: string;
  label: string;
  icon: object;
  value: number;
  disable: boolean;
}

export default function useAction_LineTemplateSelect(props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const { fetchTemplateCardModel, fetchTemplateListModel } = templateModel();
  const { openDefaultErrorModal } = useValidationModal();

  const { t } = useI18n();

  const templateCategoryList: Ref<Communication.TemplateCard.Response.Category[]> = ref([]);
  const selectedTemplateCategory: Ref<Communication.TemplateCard.Response.Category> = ref(null!);
  const search: Ref<string> = ref('');
  const isChangedListStyle: Ref<boolean> = ref(false);
  const selectedCategory: Ref<number> = ref(0);
  const templateList: Ref<Communication.Template.Template[]> = ref([]);
  const tableSortingOrder: Ref<Communication.Template.Request.Ordering[]> = ref([]);
  const keyChange: Ref<number> = ref(0);
  const selectedTemplate: Ref<Communication.Template.Template | null> = ref(null);
  let searchTimeout: ReturnType<typeof setTimeout> = null!;

  const isTemplateCategoryListNull = ref<boolean>(false);

  const isLoading = reactive({
    all: false,
    category: false,
    table: false,
  });

  const messageTypeList: Ref<MessageType[]> = ref([
    {
      key: 'all',
      label: t('communication.all'),
      icon: markRaw(AllMessageTypeIcon),
      value: MESSAGE_TYPE.ALL,
      disable: false,
    },
    {
      key: 'message',
      label: t('communication.message_tooltip'),
      icon: markRaw(MessageIcon),
      value: MESSAGE_TYPE.MESSAGE,
      disable: false,
    },
    {
      key: 'rich-message',
      label: t('communication.rich_message_tooltip'),
      icon: markRaw(RichMessageIcon),
      value: MESSAGE_TYPE.RICH_MESSAGE,
      disable: false,
    },
    {
      key: 'rich-video',
      label:  t('communication.rich_video_message_tooltip'),
      icon: markRaw(RichVideoIcon),
      value: MESSAGE_TYPE.VIDEO,
      disable: false,
    },
    {
      key: 'card-message',
      label: t('communication.card_message_tooltip'),
      icon: markRaw(CardMessageIcon),
      value: MESSAGE_TYPE.CARD_MESSAGE,
      disable: false,
    },
    {
      key: 'mix',
      label: t('communication.multi_message_tooltip'),
      icon: markRaw(MixMessageIcon),
      value: MESSAGE_TYPE.MIX,
      disable: false,
    },
  ]);

  const templateCategory = reactive([
    {
      label: t('communication.all'),
      icon: markRaw(All),
      value: 1,
    },
    {
      label: t('communication.popular'),
      icon: markRaw(Popular),
      value: 2,
    },
    {
      label: t('communication.promotions'),
      icon: markRaw(Promotions),
      value: 3,
    },
    {
      label: t('communication.seasonal'),
      icon: markRaw(Seasonal),
      value: 4,
    },
    {
      label: t('communication.awareness'),
      icon: markRaw(Awareness),
      value: 5,
    },
    {
      label: t('communication.win_back'),
      icon: markRaw(WinBack),
      value: 6,
    },
    {
      label: t('communication.engagement'),
      icon: markRaw(Engagement),
      value: 7,
    },
    {
      label: t('communication.notification'),
      icon: markRaw(Notification),
      value: 8,
    },
    {
      label: t('communication.other'),
      icon: markRaw(Other),
      value: 9,
    },
  ]);

  const subTypeList: Ref<number[]> = ref([]);
  const templateFilterForm: Record<string, any> = reactive({
    mainType: 1,
    subType: [NODE_ACTION_LINE_SUB_TYPE.ALL],
  });

  const templateFilterList: Ref<Record<string, any>> = ref({
    mainType: [
      {
        id: 1,
        label: t('communication.standard'),
      },
      {
        id: 2,
        label: t('communication.my_template'),
      },
    ],
  });

  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
  });

  const field: BaseTable.TableColumn[] = [
    { key: 'radio', label: '' },
    { key: 'name', label: t('communication.template_name'), sortable: true },
    { key: 'category_name', label: t('communication.category'), sortable: true },
    { key: 'updated_dt', label: t('communication.last_update'), sortable: true },
    { key: 'updated_by', label: t('communication.update_by'), sortable: true },
    { key: 'action', label: '' },
  ];

  const fetchLineTemplateList = (subtype: number) => {
    templateFilterForm.subType = [subtype];
    subTypeList.value = [subtype];

    if (subtype === MESSAGE_TYPE.ALL) {
      subTypeList.value = [MESSAGE_TYPE.MIX, MESSAGE_TYPE.CARD_MESSAGE, MESSAGE_TYPE.MESSAGE, MESSAGE_TYPE.RICH_MESSAGE, MESSAGE_TYPE.VIDEO];
    }

    if (!isChangedListStyle.value) {
      // card list
      fetchTemplateCardList();
    } else {
      // table list
      fetchTemplateList();
    }
  };

  const fetchTemplateCardList = () => {
    fetchTemplateCardModel.payload.Channel = resolveChannel();
    fetchTemplateCardModel.payload.Status = 1;
    fetchTemplateCardModel.payload.Type = templateFilterForm.mainType || null;
    fetchTemplateCardModel.payload.CategoryId = selectedCategory.value || null;
    fetchTemplateCardModel.payload.Query = search.value;
    fetchTemplateCardModel.payload.Limit = 999;
    fetchTemplateCardModel.payload.List_SubType = subTypeList.value;

    if (selectedCategory.value) {
      isLoading.category = true;
    } else {
      isLoading.all = true;
    }

    apiService
      .apiRequest(fetchTemplateCardModel)
      .then((response) => {
        const categoryList: Communication.TemplateCard.Response.Category[] = response.data;

        templateCategoryList.value = [];

        if (categoryList) isTemplateCategoryListNull.value = false;
        else isTemplateCategoryListNull.value = true;

        const allCategory: Communication.TemplateCard.Response.Category = {
          category_icon_name: null,
          category_icon_url: null,
          category_id: 0,
          category_name: 'All',
          template_list: [],
        };
        templateCategoryList.value = [allCategory, ...categoryList];

        const searchedCategory = templateCategoryList.value.find((category) => category.category_id == selectedCategory.value);
        if (searchedCategory) selectedTemplateCategory.value = searchedCategory;
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isLoading.all = false;
        isLoading.category = false;
      });
  };

  const fetchTemplateList = () => {
    fetchTemplateListModel.payload.Channel = resolveChannel();
    fetchTemplateListModel.payload.Status = 1;
    fetchTemplateListModel.payload.Type = templateFilterForm.mainType || null;
    fetchTemplateListModel.payload.CategoryId = selectedCategory.value || null;
    fetchTemplateListModel.payload.Query = search.value;
    fetchTemplateListModel.payload.Limit = pagination.perPage;
    fetchTemplateListModel.payload.Page = pagination.currentPage;
    fetchTemplateListModel.payload.Ordering = tableSortingOrder.value;
    fetchTemplateListModel.payload.List_SubType = subTypeList.value;
    isLoading.table = true;

    apiService
      .apiRequest(fetchTemplateListModel)
      .then((response) => {
        const responseData: Communication.Template.Response.TemplateList = response.data;
        pagination.totalRows = responseData.filtered_records;
        templateList.value = responseData.records;
      })
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => (isLoading.table = false));
  };

  const resolveCategoryIcon = (categoryType: number | null) => {
    switch (categoryType) {
      case 0:
        return markRaw(All);
      case 1:
        return markRaw(Popular);
      case 2:
        return markRaw(Promotions);
      case 3:
        return markRaw(Seasonal);
      case 4:
        return markRaw(Awareness);
      case 5:
        return markRaw(WinBack);
      case 6:
        return markRaw(Engagement);
      case 7:
        return markRaw(Notification);
      case 8:
        return markRaw(Other);
      default:
        return null;
    }
  };

  const resolveChannel = (): Communication.Template.Request.ChannelType => {
    switch (props.channel) {
      case Channel.lineOA:
        return 3;
      case Channel.sms:
        return 1;
      default:
        return 0;
    }
  };

  const onSelectCategory = () => {
    const searchedCategory = templateCategoryList.value.find((category) => category.category_id == selectedCategory.value);

    selectedTemplateCategory.value = searchedCategory!;
    // templateFilterForm.mainType = 1;
    search.value = '';
    tableSortingOrder.value = [];
    selectedTemplate.value = null!;

    if (isChangedListStyle.value) {
      keyChange.value++;
      return pagination.currentPage == 1 ? fetchTemplateList() : (pagination.currentPage = 1);
    }

    return fetchTemplateCardList();
  };

  const onSelectFilter = (typeId: number) => {
    templateFilterForm.mainType = typeId;
    selectedTemplate.value = null!;
    if (isChangedListStyle.value) {
      keyChange.value++;
      return pagination.currentPage == 1 ? fetchTemplateList() : (pagination.currentPage = 1);
    }

    return fetchTemplateCardList();
  };

  const onClickViewAll = (categoryId: number) => {
    selectedCategory.value = categoryId;

    onSelectCategory();
  };

  const onSearch = () => {
    if (searchTimeout) clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      if (isChangedListStyle.value) {
        return pagination.currentPage == 1 ? fetchTemplateList() : (pagination.currentPage = 1);
      }

      return fetchTemplateCardList();
    }, 500);
  };

  const onCloseModal = () => {
    templateCategoryList.value = [];
    selectedCategory.value = null!;
    search.value = '';
    isChangedListStyle.value = false;
    selectedCategory.value = 0;
    isLoading.all = false;
    isLoading.category = false;
    isLoading.table = false;
    templateFilterForm.mainType = 1;
    tableSortingOrder.value = [];
    pagination.currentPage = 1;
    selectedTemplate.value = null!;

    vm?.$emit('close-modal');
  };

  const onChangeListStyle = () => {
    isChangedListStyle.value = !isChangedListStyle.value;
    pagination.currentPage = 1;
    selectedTemplate.value = null!;

    if (isChangedListStyle.value) return fetchTemplateList();

    tableSortingOrder.value = [];
    return fetchTemplateCardList();
  };

  const onClickTemplateCard = (template: Communication.Template.Template) => {
    selectedTemplate.value = template;
  };

  const onSort = (sortedColumn: { key: string; direction: string }) => {
    if (sortedColumn.direction) {
      const sortModel = [
        {
          Seq: 1,
          Key: sortedColumn.key,
          Direction: sortedColumn.direction.toLocaleLowerCase(),
        },
      ];

      tableSortingOrder.value = sortModel as Communication.Template.Request.Ordering[];
    } else {
      tableSortingOrder.value = [];
    }

    if (pagination.currentPage == 1) return fetchTemplateList();

    return (pagination.currentPage = 1);
  };

  const useTemplate = () => {
    vm?.$emit('use-template', selectedTemplate.value);
  };

  onMounted(() => {
    fetchTemplateCardList();
  });

  onBeforeMount(() => {
    if (props.connectFunction != null) {
      props.connectFunction({
        selectedTemplate: selectedTemplate,
      });
    }
  }),
    watch(
      () => pagination.currentPage,
      () => {
        if (isChangedListStyle.value) return fetchTemplateList();
      },
    );

  return {
    field,
    pagination,
    templateFilterForm,
    templateFilterList,
    templateCategory,
    search,
    isChangedListStyle,
    templateCategoryList,
    selectedCategory,
    selectedTemplateCategory,
    isLoading,
    templateList,
    keyChange,
    selectedTemplate,
    messageTypeList,
    isTemplateCategoryListNull,
    resolveCategoryIcon,
    onSelectCategory,
    fetchTemplateCardList,
    onCloseModal,
    onSelectFilter,
    onSearch,
    onClickViewAll,
    onChangeListStyle,
    onSort,
    onClickTemplateCard,
    useTemplate,
    fetchLineTemplateList,
  };
}
