export default {
  summaryCount: {
    sms: {
      amount: 2,
      notify: 1,
    },
    line: {
      amount: 2,
      notify: 1,
    },
    email: {
      amount: 2,
      notify: 0,
    },
  },
  timeline: {
    filters: {
      date: '2022-08-17T17:00:00.000Z',
    },
    items: [
      {
        datetime: '2022-08-17T17:00:00.000Z',
        items: [
          {
            datetime: '2022-08-18T07:09:20.000Z',
            typeId: 0,
            typeLabel: 'sms',
            title: 'SMS: Campaign Discount 15%',
            subtitle: 'คุณได้รับส่วนลด 15% ใช้ได้ถึงวันที่ 31 ธันวาคม 2565 ยาวววววววววววววววววววววววววววววววววววววววววววว',
          },
          {
            datetime: '2022-08-17T17:00:00.000Z',
            typeId: 1,
            typelabel: 'LINE',
            title: 'LINE OA : Remind Buy 1 Get 1',
            subtitle: 'ซื้อ 1 แถม 1 หมดเขตวันนี้ รีบๆไปใช้สิทธิ์กัน',
          },
        ],
      },
      {
        datetime: '2022-08-16T17:00:00.000Z',
        items: [
          {
            datetime: '2022-08-16T17:00:00.000Z',
            typeId: 2,
            typeLabel: 'email',
            title: 'Email : Campaign Discount 15%',
            subtitle: 'คุณได้รับส่วนลด 15% ใช้ได้ถึงวันที่ 31 ธันวาคม 2565 ยาวววววววววววววววววววววววววววววววววววววววววววว',
          },
          {
            datetime: '2022-08-16T17:00:00.000Z',
            typeId: 2,
            typeLabel: 'email',
            title: 'Email : Campaign Discount 10%',
            subtitle: 'คุณได้รับส่วนลด 10% ใช้ได้ถึงวันที่ 31 ธันวาคม 2565 ยาวววววววววววววววววววววววววววววววววววววววววววว',
          },
          {
            datetime: '2022-08-16T17:00:00.000Z',
            typeId: 2,
            typeLabel: 'email',
            title: 'LINE OA : Remind Buy 1 Get 1',
            subtitle: 'ซื้อ 1 แถม 1 หมดเขตวันนี้ รีบๆไปใช้สิทธิ์กัน',
          },
        ],
      },
      {
        datetime: '2022-08-15T18:00:00.000Z',
        items: [
          {
            datetime: '2022-08-15T18:00:00.000Z',
            typeId: 0,
            typeLabel: 'sms',
            title: 'SMS: Campaign Discount 15%',
            subtitle: 'คุณได้รับส่วนลด 15% ใช้ได้ถึงวันที่ 31 ธันวาคม 2565 ยาวววววววววววววววววววววววววววววววววววววววววววว',
          },
        ],
      },
    ],
  },
};
