import { DateTime, Zone, DiffOptions } from 'luxon';
import { computed, defineComponent, h, mergeProps, onBeforeMount, onMounted, Prop, ref, VNodeProps, nextTick } from 'vue';
import { SelectableFunctionParams } from '../../composed/calendar/use-calendar';
import { useDateRangeRelativePicker } from '../../composed/date-range-relative-picker';
import { getZone, useDateTimeFormat } from '../../composed/date-time';
import { ChocoCalendar } from '../calendar';
import { ChocoInputPopup } from '../input-popup';
import { ChocoDateRangeRelativePicker } from './index';
import { toReadableDuration } from './translator';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ChocoDateRangePicker',
  props: {
    // timezone
    timezone: <Prop<Zone | string | number | null>>{
      validate: (val: any) => val === null || getZone(val).isValid,
      default: () => null,
    },
    // date format
    format: {
      type: String,
      default: 'yyyy-MM-dd',
    },
    formatSeparator: {
      type: String,
      default: () => {
        const { t } = useI18n(); // ดึง useI18n มาใช้งาน
        return t('date_picker.to'); // คืนค่าข้อความแปล
      },
    },
    // model start-end date
    start: <Prop<Date | null>>{
      type: Object,
    },
    end: <Prop<Date | null>>{
      type: Object,
    },
    // relative options
    relativeModel: <Prop<string | null>>{},
    relativeOptions: <Prop<string[]>>{
      type: Array,
      default: () => [],
      validator: (_: unknown) => {
        // TODO: validate options ISO-8601 valid pattern with special case (e.g. today)
        return true;
      },
    },
    relativeTitle: <Prop<string>>{
      type: String,
      default: () => {
        const { t } = useI18n(); // ดึง useI18n มาใช้งาน
        return t('date_picker.date_range'); // คืนค่าข้อความแปล
      },
    },
    relativeTitleClass: <Prop<string | string[] | object>>{
      type: [Array, String, Object],
      default: '',
    },
    // placeholder
    placeholder: <Prop<string | null>>{
      type: String,
      default: () => {
        const { t } = useI18n(); // ดึง useI18n มาใช้งาน
        return t('date_picker.select_date'); // คืนค่าข้อความแปล
      },
    },
    placeholderStart: {
      type: String,
      default: () => {
        const { t } = useI18n(); // ดึง useI18n มาใช้งาน
        return t('date_picker.start_date'); // คืนค่าข้อความแปล
      },
    },
    placeholderEnd: {
      type: String,
      default: () => {
        const { t } = useI18n(); // ดึง useI18n มาใช้งาน
        return t('date_picker.end_date'); // คืนค่าข้อความแปล
      },
    },
    isShowApplyButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    // model start-end date
    'update:start',
    'update:end',
    // model relative
    'update:relativeModel',
    // popup events
    'hide',
    'show',
  ],
  setup(props, { slots, emit }) {
    const timezone = getZone(props.timezone ?? null);
    const { locale, t } = useI18n();
    // models
    const startDate = computed(() => props.start ?? null);
    const endDate = computed(() => props.end ?? null);

    // temps
    const tempStartDate = ref<Date | null>(null);
    const tempEndDate = ref<Date | null>(null);
    const tempRelativeModel = ref<string | null>(props.start && props.end ? 'custom' : null);

    // calendar: prevent start-end overlap selection
    const startDateLuxon = computed(() => (startDate.value !== null ? DateTime.fromJSDate(startDate.value).setZone(timezone) : null));
    const startSelectableCallback = ({ year, month, day }: SelectableFunctionParams) => {
      // if end date not selected, all date is available
      if (endDateLuxon.value === null) return true;

      // otherwise, only date <= end date
      const selectableDate = DateTime.fromObject({ year, month: month + 1, day });
      return selectableDate <= endDateLuxon.value;
    };
    const endDateLuxon = computed(() => (endDate.value !== null ? DateTime.fromJSDate(endDate.value).setZone(timezone) : null));
    const endSelectableCallback = ({ year, month, day }: SelectableFunctionParams) => {
      // if start date not selected, all date is available
      if (startDateLuxon.value === null) return true;

      // otherwise, only date >= start date
      const selectableDate = DateTime.fromObject({ year, month: month + 1, day });
      return selectableDate >= startDateLuxon.value;
    };

    // relative selections
    const hasRelative = computed(() => props.relativeOptions!.length > 0);
    const intRelativeModel = ref<string | null>(props.start && props.end ? 'custom' : null);
    const relativeModel = computed<string | null>({
      get: () => (props.relativeModel !== undefined ? props.relativeModel : intRelativeModel.value),
      set: (value: string | null) => (props.relativeModel !== undefined ? emit('update:relativeModel', value) : (intRelativeModel.value = value)),
    });
    // on init, if relative is selected (as default, not custom or null), update start-end date
    onBeforeMount(() => {
      if (relativeModel.value && relativeModel.value !== 'custom') {
        // use date relative range picker
        const picker = useDateRangeRelativePicker(getZone(props.timezone ?? null));
        // pick relative
        const d = picker.selectLastsDuration(relativeModel.value);
        // update start-end date
        emit('update:start', d.start);
        emit('update:end', d.end);
      }
    });

    onMounted(() => {
      tempStartDate.value = props.start!;
      tempEndDate.value = props.end!;
      tempRelativeModel.value = props.relativeModel!;
    });

    // display value
    const useStartFormat = useDateTimeFormat(startDate, timezone, locale.value);
    const useEndFormat = useDateTimeFormat(endDate, timezone, locale.value);
    const displayStartValue = computed(() => useStartFormat.toFormat(props.format));
    const displayEndValue = computed(() => useEndFormat.toFormat(props.format));
    const displayValue = computed(
      () => (displayStartValue.value ?? props.placeholderStart) + ' ' + props.formatSeparator + ' ' + (displayEndValue.value ?? props.placeholderEnd),
    );

    // render function
    return () =>
      // ChocoInputPopup.choco-date-picker
      h(
        ChocoInputPopup,
        {
          class: ['choco-date-picker'],
          modelValue: displayValue,
          onHide: () => emit('hide'),
          onShow: () => emit('show'),
        },
        {
          // v-slot:input{*} > use slot:input{*,startValue,endValue,separator} or default native input
          input: (inputProps: object & VNodeProps) => {
            if (slots['input']) {
              return slots['input'](
                mergeProps(inputProps, {
                  startValue: displayStartValue.value,
                  endValue: displayEndValue.value,
                  relative: hasRelative.value && relativeModel.value && relativeModel.value !== 'custom',
                  relativeValue: relativeModel.value,
                  relativeLabel: relativeModel.value ? toReadableDuration(relativeModel.value) : null,
                  placeholder: props.placeholder,
                  startPlaceholder: props.placeholderStart,
                  endPlaceholder: props.placeholderEnd,
                  separator: props.formatSeparator,
                }),
              );
            } else {
              return h('input', mergeProps(inputProps, { readonly: true }));
            }
          },
          // v-slot:default > div.choco-date-picker__calendar-wrapper
          default: ({ hide }: { hide: CallableFunction }) => [
            // div.choco-date-picker__popup-container
            h('div', { class: ['choco-date-picker__popup-calendar'] }, [
              // v-if = hasRelative > ChocoDateRangeRelativePicker
              hasRelative.value
                ? h(ChocoDateRangeRelativePicker, {
                    modelValue: relativeModel.value,
                    options: props.relativeOptions,
                    title: props.relativeTitle,
                    titleClass: props.relativeTitleClass,
                    startDate: props.start,
                    endDate: props.end,
                    'onUpdate:modelValue': (value: string) => (relativeModel.value = value),
                    'onUpdate:startDate': (value: Date) => emit('update:start', value),
                    'onUpdate:endDate': (value: Date) => emit('update:end', value),
                  })
                : null,

              // v-if = hasRelative > div.choco-date-picker__relative-separator
              hasRelative.value ? h('div', { class: ['choco-date-picker__wrapper-separator'] }) : null,

              // div.choco-date-picker__calendar-wrapper
              h('div', { class: ['choco-date-picker__calendar-wrapper'] }, [
                // ChocoCalendar for start date
                h(ChocoCalendar, {
                  timezone,
                  selectable: true,
                  select: props.start,
                  selectRangeEnd: props.end,
                  selectableCallback: startSelectableCallback,
                  ['onUpdate:select']: (value) => {
                    // change relative option to custom
                    relativeModel.value = 'custom';
                    // update start date
                    emit('update:start', value);
                  },
                }),

                // div.choco-date-picker__calendar-separator
                h('div', { class: ['choco-date-picker__calendar-separator'] }),

                // ChocoCalendar for end date
                h(ChocoCalendar, {
                  timezone,
                  selectable: true,
                  select: props.end,
                  selectRangeStart: props.start,
                  selectableCallback: endSelectableCallback,
                  ['onUpdate:select']: (value) => {
                    // change relative option to custom
                    relativeModel.value = 'custom';
                    // update end date
                    emit('update:end', value);
                  },
                }),
              ]),
              // isShowApplyButton = true -> enable apply and cancel button in date-picker moadal
              // Default -> disable(false)
              props.isShowApplyButton
                ? h('div', { class: ['choco-date-picker__button-wrapper'] }, [
                    h(
                      'button',
                      {
                        class: ['choco-date-picker__button', 'primary'],
                        onClick: async () => {
                          tempRelativeModel.value = props.relativeModel!;
                          tempStartDate.value = props.start!;
                          tempEndDate.value = props.end!;
                          nextTick(() => {
                            hide();
                          });
                        },
                      },
                      t('date_picker.apply'),
                    ),
                    h(
                      'button',
                      {
                        class: ['choco-date-picker__button'],
                        onClick: async () => {
                          emit('update:relativeModel', tempRelativeModel.value);
                          emit('update:start', tempStartDate.value);
                          emit('update:end', tempEndDate.value);
                          nextTick(() => {
                            hide();
                          });
                        },
                      },
                      t('date_picker.cancel'),
                    ),
                  ])
                : null,
            ]),
          ],
        },
      );
  },
});
