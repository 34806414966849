import { ref, Ref, onMounted, markRaw } from 'vue';
import router from '@/router';
import api from '@services/api';
import dataConnectModel from '@models/dataConnect/dataConnect';
import uploadIcon from '@assets/images/modules/data-connect/upload.vue';
import tableIcon from '@assets/images/modules/data-connect/table.vue';
import keyIcon from '@assets/images/modules/data-connect/key.vue';
import mappingIcon from '@assets/images/modules/data-connect/mapping.vue';
import checkIcon from '@assets/images/modules/data-connect/check.vue';
import XLSX from '@libs/xlsx/xlsx.full.min.js';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import { useI18n } from 'vue-i18n';

interface FileExcelSheetSelect {
  id?: number;
  label?: string;
}
export default function useCreateTemplateDrawer(props: any) {
  const { createDataSourceExcelDataTemplateModel } = dataConnectModel();
  const { openDefaultErrorModal, openErrorModal } = useValidationModal();
  const { t } = useI18n();
  let workbook: any = null;
  const pageStep: any = ref(0);
  const fileExcelName: any = ref('');
  const fileExcelData: any = ref({});
  const fileExcelHeaderSelected: any = ref({});
  const fileExcelHeaderKey: any = ref([]);
  const selectionType: any = ref('checkbox');
  const primaryKeyDropdownOption: any = ref([]);
  const primaryKeyList: any = ref([]);
  const templateType: any = ref(null);
  const templateName: any = ref(null);
  const templateDescription: any = ref(null);
  const checkAllColumn: any = ref(false);
  const columnPreviewList: any = ref(null);
  const filteredMappingOptions: any = ref([]);
  const disabledNext: any = ref(true);
  const fileExcelListSheetName: Ref<FileExcelSheetSelect[]> = ref([]);
  const fileExcelSheetSelect: Ref<FileExcelSheetSelect> = ref({});

  const maxRowsPerPage: Ref<number> = ref(10);
  const maxRowsTotal: Ref<number> = ref(10);
  const maxRowsPreview: Ref<number> = ref(10);
  const changeNewRow: Ref<boolean> = ref(false);

  const newDataConnect: any = () => {
    router.push({ path: `/dataConnector/addDataSource` });
  };

  const uploadFileStep: any = [
    { title: t('dc.uploadFile'), icon: markRaw(uploadIcon) },
    { title: t('dc.confirmData'), icon: markRaw(checkIcon) },
  ];

  const createTemplateStep: any = [
    { title: t('dc.browseFile'), icon: markRaw(uploadIcon) },
    { title: t('dc.selectData'), icon: markRaw(tableIcon) },
    //{ title: 'Select Primary Key', icon: keyIcon },
    { title: t('dc.mappingTemplate'), icon: markRaw(mappingIcon) },
  ];

  const excelHeaderSelectorSelecton = {
    onChange: (keys: any, row: any) => {
      disabledNext.value = false;
      if (fileExcelHeaderSelected.value != null && fileExcelHeaderSelected.value.RowNo == row.RowNo) {
        changeNewRow.value = false;
      } else {
        changeNewRow.value = true;
        fileExcelHeaderSelected.value = row;
        fileExcelHeaderKey.value = keys;
      }
    },
    getCheckboxProps: (row: any) => ({
      disabled: row.disabled === true,
      name: row.name,
    }),
  };

  const excelHeaderReset = () => {
    fileExcelName.value = '';
    fileExcelData.value = {};
    fileExcelHeaderSelected.value = null;
    fileExcelHeaderKey.value = [];
    disabledNext.value = true;
    fileExcelListSheetName.value = [];
    fileExcelSheetSelect.value = {};
  };

  const resetDrawer = () => {
    pageStep.value = 0;
    primaryKeyDropdownOption.value = [];
    primaryKeyList.value = [];
    templateType.value = null;
    templateName.value = null;
    templateDescription.value = null;
    checkAllColumn.value = null;
    columnPreviewList.value = null;
    filteredMappingOptions.value = [];
    excelHeaderReset();
  };

  const onCloseDrawer = () => {
    if (props.pageLoading) return false;
    resetDrawer();
    return props.onClose ? props.onClose() : () => {};
  };

  const excelHeaderSelectorCheckHeaderSelected = () => {
    if (templateType.value == null) disabledNext.value = true;
    return fileExcelHeaderKey.value.length > 0;
  };

  const createCustomTemplateValidation = () => {
    let checkColumn = 0;
    let validation = true;

    for (let column of fileExcelHeaderSelected.value.ColData) {
      if (column.checked || column.isPrimary) {
        checkColumn++;
        if (!column.mapping) {
          validation = false;
          break;
        }
      }
    }

    if (checkColumn > 0 && validation && templateName.value && templateType.value) {
      return true;
    } else {
      openErrorModal(t('global.error'), 'กรุณากรอกข้อมูลให้ครบถ้วน');
    }

    return false;
  };

  // Filter data for preview (max 10 record)
  const excelHeaderSelectorGetList = (colNo: any, rowNo: any) => {
    const rowDataList = fileExcelData.value.rowRawData[colNo];
    const finalRowIndex: number = Math.min(Math.max(Number(rowNo), 0), maxRowsPerPage.value - 1);
    return rowDataList.slice(
      finalRowIndex,
      Math.min(finalRowIndex + maxRowsPreview.value, Math.min(maxRowsPerPage.value, Number(rowDataList.length))),
    );
  };

  const createCustomTemplate = async () => {
    const params: any = {
      Datasource_Id: Number(localStorage.getItem('DataSourceId')),
      Dataset_Category_Id: templateType.value.category_id,
      Src_Mapping_Dataset_Id: templateType.value.id,
      Dataset_Name: templateName.value,
      Description: templateDescription.value || '',
      Header_Row: fileExcelHeaderSelected.value.RowNo + 1,
      Sample_Data: '',
      Sheet_Name: fileExcelSheetSelect.value.label,
      List_Column: [],
    };

    let sampleDataPackage: any = [];

    fileExcelHeaderSelected.value.ColData.forEach((column: any, index: any) => {
      if (column.isPrimary || column.checked) {
        let columnReturn = {
          Source_Name: column.column.toString(),
          Source_Display_Name: column.rename ? column.rename.toString() : '',
          Source_Col_Num: index + 1,
          Dest_Name: column.mapping.value,
          Dest_Display_Name: templateType.value.mapping.find((item: any) => item.label == column.mapping.label).display,
        };
        params.List_Column.push(columnReturn);

        sampleDataPackage.push({
          Col_No: index + 1,
          List: excelHeaderSelectorGetList(index, fileExcelHeaderSelected.value.RowNo),
        });
      }
    });

    params.Sample_Data = JSON.stringify(sampleDataPackage);
    props.setPageLoading(true);

    let model: any = createDataSourceExcelDataTemplateModel;
    model.payload = params;
    api
      .apiRequest(model)
      .then(async () => {
        await props.setPageLoading(false);
        props.fatchDataSet();
        onCloseDrawer();
      })
      .catch((error) => {
        props.setPageLoading(false);
        openDefaultErrorModal(error);
      });
  };

  const onNextStep = () => {
    const switchCondition: Record<any, any> = {
      0: () => {
        return false;
      },
      1: () => {
        return excelHeaderSelectorCheckHeaderSelected();
      },
      2: () => {
        return createCustomTemplateValidation();
      },
    };

    const stepCondition: any = switchCondition[pageStep.value]();

    if (pageStep.value == 2 && stepCondition) {
      createCustomTemplate();
    } else if (pageStep.value <= 1 && changeNewRow.value == true) {
      resetMappingDropdown();
    } else {
      setMappingDropdown();
    }

    pageStep.value = Math.min(Number(pageStep.value) + 1, 2);
  };

  const onPreviousStep = () => {
    if (pageStep.value > 1) {
      pageStep.value = pageStep.value - 1;
      changeNewRow.value = false;
    } else {
      onCloseDrawer();
    }
  };

  const checkFileFormat = (fileName: any, exts: any) => {
    return new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$', 'i').test(fileName);
  };

  const excelHeaderPlaceHolderReference = (headerNum: number) => {
    const dataFillTableHeader = 'abcdefghijklmnopqrstuvwxyz';

    if (headerNum >= dataFillTableHeader.length) {
      const firstIndex = Math.floor(headerNum / dataFillTableHeader.length) - 1;
      const secondIndex = headerNum % dataFillTableHeader.length;
      return dataFillTableHeader[firstIndex] + dataFillTableHeader[secondIndex];
    }
    return dataFillTableHeader.substring(Math.min(headerNum, dataFillTableHeader.length), Math.min(headerNum + 1, dataFillTableHeader.length + 1));
  };

  // Get sheetname
  const setSheetNameDropdownCallback = (fileData: any) => {
    fileExcelListSheetName.value = []; // Reset dropdown

    if (fileData != null) {
      if (checkFileFormat(fileData.name, ['.csv', '.xlsx']) == true) {
        let isExcel = checkFileFormat(fileData.name, ['.xlsx']);
        let reader = new FileReader();

        reader.onload = function (e: any) {
          let data = e.target.result;
          if (isExcel == true) {
            data = new Uint8Array(data);
          } else {
            data = data.substring(0, Math.min(data.length, 40000));
          }
          queueMicrotask(() => {
            let option: any = {};
            option.raw = true;
            if (isExcel == false) {
              option.type = 'binary';
            }
            workbook = XLSX.read(data, option);
            workbook.SheetNames.forEach(function (sheetName: string, index: number) {
              fileExcelListSheetName.value.push({
                id: index,
                label: sheetName,
              });
            });
            // Select sheetname onload
            fileExcelSheetSelect.value = fileExcelListSheetName.value[0];
            // Call funcion get data in sheet
            getDataInSheetCallback(fileExcelSheetSelect.value);
          });
        };
        if (isExcel == true) {
          reader.readAsArrayBuffer(fileData);
        } else {
          reader.readAsText(fileData);
        }

        fileExcelName.value = fileData.name;
      }
    }
  };

  // Get data in sheet
  const getDataInSheetCallback = (select: FileExcelSheetSelect) => {
    let returnData = null;
    // Select sheetname
    fileExcelSheetSelect.value = select;

    // Get data to table
    let result: Record<any, any> = {};
    let roa = XLSX.utils.sheet_to_json(workbook.Sheets[fileExcelSheetSelect.value.label as string], {
      header: 1,
    });
    for (let index = roa.length - 1; index >= 0; index--) {
      if (roa[index].length === 0) {
        roa.splice(index, 1);
      } else {
        break;
      }
    }

    if (roa.length) result[fileExcelSheetSelect.value.label as string] = roa;

    returnData = {
      name: fileExcelName.value,
      data: result,
    };

    let finalDataResult: any = {};
    let columnBuilder: any = [];
    let rowBuilder: any = [];
    let rowRawBuilder: any = [];
    for (const [sheetName, sheetData] of Object.entries(returnData.data)) {
      if (sheetData.length > 0) {
        let colMax = 0;
        for (let F = 0; F < Math.min(sheetData.length, maxRowsTotal.value); F++) {
          colMax = Math.max(colMax, sheetData[F].length);
        }
        columnBuilder.push({
          key: '_CheckBox_',
          label: ' ',
          class: 'dataconnect-excel-header-selection-table-col-text',
          render: '-',
        });
        for (let F = 0; F < colMax; F++) {
          const columnHeaderName = excelHeaderPlaceHolderReference(F);
          columnBuilder.push({
            key: columnHeaderName,
            label: 'Column ' + columnHeaderName.toUpperCase(),
            class: 'dataconnect-excel-header-selection-table-col-text',
            render: columnHeaderName,
          });
        }
        for (let F = 0; F < Math.min(sheetData.length, maxRowsTotal.value); F++) {
          let rowDataBuild: any = {};
          rowDataBuild['key'] = F;
          rowDataBuild['RowNo'] = F;
          rowDataBuild['ColData'] = [];
          for (let idx = 0; idx < colMax; idx++) {
            if (sheetData[F][idx] != null) {
              const columnHeaderName = excelHeaderPlaceHolderReference(idx);
              rowDataBuild[columnHeaderName] = sheetData[F][idx] !== '' ? String(sheetData[F][idx]) : '';
              rowDataBuild['ColData'].push(
                sheetData[F][idx] !== ''
                  ? { column: String(sheetData[F][idx]) }
                  : {
                      column: '',
                    },
              );
            } else {
              const columnHeaderName = excelHeaderPlaceHolderReference(idx);
              rowDataBuild[columnHeaderName] = '';
              rowDataBuild['ColData'].push({
                column: '',
              });
            }
          }
          rowBuilder.push(rowDataBuild);

          for (let idx = 0; idx < colMax; idx++) {
            if (rowRawBuilder[idx] == null) {
              rowRawBuilder[idx] = [];
            }
            if (sheetData[F][idx] != null) {
              rowRawBuilder[idx].push(sheetData[F][idx]);
            } else {
              rowRawBuilder[idx].push('');
            }
          }
        }
      }
      break;
    }

    finalDataResult.columnData = columnBuilder;
    finalDataResult.rowData = rowBuilder;
    finalDataResult.rowRawData = rowRawBuilder;
    finalDataResult.columnSelectEvent = excelHeaderSelectorSelecton;

    fileExcelName.value = returnData.name;
    fileExcelData.value = finalDataResult;
    fileExcelHeaderSelected.value = null;
    fileExcelHeaderKey.value = [];

    pageStep.value = 1;
    disabledNext.value = true;
  };

  const resetColumnMapping = () => {
    const temptColumnList: Record<any, any> = fileExcelHeaderSelected.value;
    temptColumnList.ColData.forEach((column: any) => (column.mapping = null));
    disabledNext.value = false;
    fileExcelHeaderSelected.value = temptColumnList;
  };

  // Event select dropdown template type
  const onTemplateTypeChange = (index: any) => {
    resetColumnMapping();
    templateType.value = JSON.parse(JSON.stringify(props.templateTypeOptions[index]));
  };

  const setTemplateName = (value: any) => {
    templateName.value = value;
  };

  const setTemplateDescription = (value: any) => {
    templateDescription.value = value;
  };

  // Event checked/unchecked all
  const onCheckAll = (event: any) => {
    const check = event.target.checked;
    const temptColumnList: Record<any, any> = fileExcelHeaderSelected.value;

    temptColumnList.ColData.forEach((column: any) => {
      column.checked = check;

      if (!check) {
        const mappingValue = column.mapping;

        if (mappingValue) {
          const oldIndex = templateType.value.mapping.findIndex((option: any) => option.value == mappingValue.value);

          if (oldIndex >= 0) {
            templateType.value.mapping[oldIndex].mapping = false;
            column.isPrimary = false;
          }

          return (column.mapping = null);
        }
      }
    });

    fileExcelHeaderSelected.value = temptColumnList;
    checkAllColumn.value = check;
  };

  // Hover preview data
  const onHoverTableIcon = (index: any) => {
    const previewList = excelHeaderSelectorGetList(index, fileExcelHeaderSelected.value.RowNo);

    const displayList: any = [...previewList];

    displayList.splice(0, 1);

    const titleDisplay: any = previewList[0] != '' ? previewList[0] : '';

    columnPreviewList.value = {
      title: titleDisplay,
      list: displayList,
    };
  };

  const onStopPreview = () => {
    columnPreviewList.value = null;
  };

  // Validate mapping all column
  const isCheckAll = (list: any) => {
    const filteredUncheckList: any = list.filter((item: any) => !item.checked && !item.isPrimaryKey);

    return filteredUncheckList.length <= 0;
  };

  // Event checked of checkbox column
  const onCheckColumn = (event: any, index: any) => {
    const temptColumnList: Record<any, any> = fileExcelHeaderSelected.value;
    temptColumnList.ColData[index]['checked'] = event.target.checked;

    const mappingValue = temptColumnList.ColData[index]?.mapping;

    if (!event.target.checked && mappingValue) {
      const oldIndex = templateType.value.mapping.findIndex((option: any) => option.value == mappingValue.value);

      if (oldIndex >= 0) {
        templateType.value.mapping[oldIndex].mapping = false;
        temptColumnList.ColData[index].isPrimary = false;
      }

      temptColumnList.ColData[index].mapping = null;
    }

    const isAll = isCheckAll(temptColumnList.ColData);

    checkAllColumn.value = isAll;
    fileExcelHeaderSelected.value = temptColumnList;

    onOpenMappingDropdown();
  };

  const onRenameColumn = (event: any, index: any) => {
    const temptColumnList: any = fileExcelHeaderSelected.value;

    temptColumnList.ColData[index].rename = event.target.value;
    fileExcelHeaderSelected.value = temptColumnList;
  };

  // Event select dropdown column
  const onMapping = (event: any, selectedIndex: any) => {
    const temptColumnList: any = fileExcelHeaderSelected.value;

    // Case change selcet dropdown
    const oldValue: any = temptColumnList.ColData[selectedIndex].mapping?.value || null;
    if (oldValue) {
      const oldIndex = templateType.value.mapping.findIndex((option: any) => option.value == oldValue);
      if (oldIndex >= 0) {
        templateType.value.mapping[oldIndex].mapping = false;
        temptColumnList.ColData[selectedIndex].isPrimary = false;
      }
    }

    // Set mapping column
    const optionIndex = templateType.value.mapping.findIndex((option: any) => option.value == event.value);
    if (optionIndex >= 0) {
      templateType.value.mapping[optionIndex].mapping = true;
      temptColumnList.ColData[selectedIndex].isPrimary = templateType.value.mapping[optionIndex].require;
      temptColumnList.ColData[selectedIndex].mapping = {
        label: templateType.value.mapping[optionIndex].label,
        value: templateType.value.mapping[optionIndex].value,
      };
    }
    fileExcelHeaderSelected.value = temptColumnList;
    setMappingDropdown();
  };

  // Event onclick dropdown column
  // Set column for show dropdown
  const onOpenMappingDropdown = () => {
    setMappingDropdown();
  };

  function setMappingDropdown() {
    const filterOptions = templateType.value.mapping?.filter((option: any) => !option.mapping) || [];
    filteredMappingOptions.value = filterOptions;
  }

  function resetMappingDropdown() {
    if (templateType.value != null) {
      for (const item of templateType.value.mapping) {
        if (item.mapping == true) {
          item.mapping = false;
        }
      }
      setMappingDropdown();
    }
  }

  onMounted(() => {});

  return {
    onCloseDrawer,
    onNextStep,
    onPreviousStep,
    disabledNext,
    uploadFileStep,
    createTemplateStep,
    pageStep,
    fileExcelSheetSelect,
    fileExcelListSheetName,
    setSheetNameDropdownCallback,
    getDataInSheetCallback,
    fileExcelName,
    fileExcelData,
    fileExcelHeaderKey,
    templateType,
    templateName,
    templateDescription,
    onTemplateTypeChange,
    setTemplateName,
    setTemplateDescription,
    onCheckAll,
    checkAllColumn,
    fileExcelHeaderSelected,
    onHoverTableIcon,
    onStopPreview,
    columnPreviewList,
    onCheckColumn,
    onRenameColumn,
    onMapping,
    filteredMappingOptions,
    onOpenMappingDropdown,
    maxRowsPerPage,
    maxRowsTotal,
  };
}
