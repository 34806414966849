export default {
  forget_password: {
    title: 'Forgot Password',
    description_first: 'Enter your email below and we’ll email you with',
    description_second: 'instructions on how to reset your password.',
    placeholder: 'Your E-mail',
    button: 'Send',
    error_text: 'E-mail is invalid',
    notemail_text: 'This email address was not found in our system. Please check again.',
    back: 'back',

    check_email: {
      title: 'Help is on the way',
      description_first: 'We’re combing through our records to find the CHOCO CDP account',
      description_second: 'For ',
      go_back: 'Go back to ChocoFor ',
      link_login: 'portal.chococdp.com/login',
    },

    expire_email: {
      title: 'Your URL link has expire',
      description_first: 'If you want to continue reset password,',
      re_click: 'please re’ click this link ',
      link_forgot_password: 'portal.chococdp.com/forgot-password',
    },
  },

  reset_password: {
    title: 'Create New Password',
    sub_title_step1: 'Your new password for',
    sub_title_step2: 'must be different from your previously used passwords.',
    placeholder_password: 'New Password',
    placeholder_confirm_password: 'Confirm New Password',
    topic_rules: 'Protect your account, make sure your password',
    rule1: 'Both passwords must match.',
    rule2: 'Passwords must contain at least 8 characters.',
    rule3: 'Passwords must contain at least one lowercase letter (a-z).',
    rule4: 'Passwords must contain at least one uppercase letter (A-Z).',
    rule5: 'Passwords must contain at least one number (0-9).',
    rule6: "Passwords must contain at least one special character (!{'@'}#{'$'}%^&*()), etc.)",
    btn: 'Create Password',
  },
  // ---------------------- MFA ----------------------------
  text_header: 'ChocoCDP Authenticator',
  description_header1: 'ChocoCDP increase security of your account',
  description_header2: 'by two-factor authentication',
  step: 'Step',
  // --------- step 1 ----------
  start_by_getting_the_app: 'Start by Getting the App',
  please_install1: 'Please install Microsoft Authenticator or Google Authenticator application on your mobile device.',
  divider: 'or',
  // --------- step 2 ----------
  install_an_authentication_app: 'Install an Authentication App',
  please_install2: 'Please install ',
  on_your_mobile: ' application on your mobile device.',
  // --------- step 3 ----------
  scan_qr_code: 'Scan QR Code',
  use_the: 'Use the ',
  application_to_scan: 'application to scan the QR code. This will connect the ',
  application_with_your_account: 'application with your account.',
  scan_to_next: 'After Scan QR Code Please "Next"',
  // --------- step 4 ----------
  verify_authentication_code: 'Verify Authentication Code',
  enter_the_six_digit: 'Enter the 6-digit code in authenticator',
  open_your_two_factor: 'Open your two-factor authenticator (TOTP) appor or browser extension to view your authentication code.',
  error: 'Sorry, the Authentication Code is incorrect. Please try again or contact support if the issue persists.',
  button_complete: 'Complete 2-step verification',
  loading: 'Loading',
  // --------- step complete ----------
  authenticator_setup_complete: 'Authenticator setup complete!',
  description_header_complete: 'You can now generate verification codes for your ChocoCDP accounts',
  done: 'Done',
  // --------- button ----------
  back: 'Back',
  next: 'Next',
  trouble_text: 'Having trouble? Contact ChocoCRM Team',
};
