import { DurationLikeObject } from 'luxon';
import { parseISODuration } from '../../composed/date-time';
import { useI18n } from 'vue-i18n';

const statics = ['today'];

// TODO: exposed translator via choco global translator injection
export function toReadableDuration(duration: DurationLikeObject | string): string {
  const { locale, t } = useI18n(); // ดึง useI18n มาใช้งาน
  if (typeof duration === 'string' && duration === 'custom') {
    return t('date_picker.relative.custom');
  } else if (typeof duration === 'string' && statics.includes(duration)) {
    // return duration.substring(0, 1).toUpperCase() + duration.substring(1);
    return locale.value == 'th' ? 'วันนี้' : duration.substring(0, 1).toUpperCase() + duration.substring(1);
  } else {
    const pd = typeof duration === 'string' ? parseISODuration(duration) : duration;
    let str = '';
    if (pd.years) {
      // str += ` ${pd.years} ` + t('date_picker.relative.year');
      str = t('date_picker.last_year', { num: pd.days });
    }
    if (pd.months) {
      // str += ` ${pd.months} ` + t('date_picker.relative.month');
      str = t('date_picker.last_month', { num: pd.days });
    }
    if (pd.days) {
      str = t('date_picker.last_day', { num: pd.days });
    }
    if (pd.hours) {
      // str += ` ${pd.hours} ` + t('date_picker.relative.hour');
      str = t('date_picker.last_hour', { num: pd.days });
    }
    if (pd.minutes) {
      // str += ` ${pd.minutes} ` + t('date_picker.relative.minute');
      str = t('date_picker.last_minute', { num: pd.days });
    }
    if (pd.seconds) {
      // str += ` ${pd.seconds} ` + t('date_picker.relative.second');
      str = t('date_picker.last_second', { num: pd.days });
    }
    return str;
  }
}
