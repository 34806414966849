import { ref, Ref, markRaw } from 'vue';

import { RuleSetRoot } from '@/views/modules/segment-v2/types/ruleset-payload';

import ViewCustomerIcon from '@/assets/icons/modules/segmentv2/ViewCustomerIcon.vue';
import ViewTransactionIcon from '@/assets/icons/modules/segmentv2/ViewTransactionIcon.vue';
import ViewProductIcon from '@/assets/icons/modules/segmentv2/ViewProductIcon.vue';
import ViewAdvanceIcon from '@/assets/icons/modules/segmentv2/ViewAdvanceIcon.vue';
import { SourceList, Structure, TableList, TableRelationGroup } from '@/views/modules/segment-v2/types/data-source';

export const viewOptions: Ref<SegmentView.ViewOption[]> = ref<SegmentView.ViewOption[]>([
  {
    id: 100,
    name_th: 'ลูกค้า',
    label_th: 'ลูกค้า',
    desc_th: 'แสดงผลลัพธ์ในมุมมองรายการลูกค้าด้วยคอลัมน์ข้อมูลต่างๆในตารางข้อมูลประเภทลูกค้าเช่น ชื่อ,วัดเกิด,ระดับสมาชิก,ช่องทางติดต่อ',
    name_en: 'Customers',
    label_en: 'Customers',
    desc_en: 'Display a list of customers with columns for name, birthdate, membership level, and contact information.',
    icon: markRaw(ViewCustomerIcon),
    baseTableId: null,
    isAdvance: false,
    isActive: false,
  },
  {
    id: 300,
    name_th: 'ธุรกรรม',
    label_th: 'ธุรกรรม',
    desc_th: 'แสดงผลลัพธ์ในมุมมองรายการธุรกรรม ด้วยคอลัมน์ข้อมูลต่างๆในตารางข้อมูลประเภท ธุรกรรม เช่น เลขที่บิล,ยอดรวมบิล,โปรโมชั่น',
    name_en: 'Transactions',
    label_en: 'Transactions',
    desc_en: 'Display a list of transactions with columns for bill number, total amount, and promotion',
    icon: markRaw(ViewTransactionIcon),
    baseTableId: null,
    isAdvance: false,
    isActive: false,
  },
  {
    id: 200,
    name_th: 'สินค้า',
    label_th: 'สินค้า',
    desc_th: 'แสดงผลลัพธ์ในมุมมองรายการสินค้า ด้วยคอลัมน์ข้อมูลต่างๆในตารางข้อมูลประเภท สินค้า เช่น รหัสสินค้า,ชื่อสินค้า,หมวดหมู่สินค้า',
    name_en: 'Products',
    label_en: 'Products',
    desc_en: 'Display a list of products with columns for product code, name, and category.',
    icon: markRaw(ViewProductIcon),
    baseTableId: null,
    isAdvance: false,
    isActive: false,
  },
  {
    id: 0,
    name_th: 'ขั้นสูง',
    label_th: 'ขั้นสูง',
    desc_th:
      'แสดงผลลัพธ์ในมุมมองรายการแบบมากกว่าหนึ่ง ประเภทตารางข้อมูลกรณีเงื่อนไขการตั้งกลุ่ม เป้าหมายมีข้อมูลที่เชื่อมต่อกับตารางที่ต้อง เช่น ต้องการดูมุมมองลูกค้าร่วมกับข้อมูลธุรกรรม',
    name_en: 'Advanced',
    label_en: 'Advanced',
    desc_en: 'Display multiple data types based on custom criteria. For example, view customers and their associated transactions.',
    icon: markRaw(ViewAdvanceIcon),
    baseTableId: null,
    isAdvance: true,
    isActive: false,
  },
]);

export function mapStructuresToViewOptions(structures: Structure[]) {
  viewOptions.value.forEach((option: SegmentView.ViewOption) => {
    option.baseTableId = null;
  });

  if (!structures || structures.length == 0) return;
  structures.forEach((structure: Structure) => {
    structure.source_list.forEach((source: SourceList) => {
      source.table_list.forEach((table: TableList) => {
        switch (table.table_type_id) {
          case 100:
          case 200:
          case 300:
            const option = viewOptions.value.find((option: SegmentView.ViewOption) => option.id == table.table_type_id);
            if (option) {
              option.baseTableId = table.table_id;
            }
            break;
        }
      });
    });
  });
}

export function getSelectedView(ruleSetRoot: RuleSetRoot) {
  if (!ruleSetRoot) return null;

  return viewOptions.value.find(
    (option: SegmentView.ViewOption) =>
      option.isActive &&
      ((!option.isAdvance && ruleSetRoot.view_table_list.length == 1 && ruleSetRoot.view_table_list[0].table_id == option.baseTableId) ||
        (option.isAdvance && ruleSetRoot.view_table_list.length > 0 && ruleSetRoot.view_column_list.length > 0)),
  );
}

export function getSelectedViewFromTableId(base_table: number, cross_tables: number[]) {
  const selctedBaseView = viewOptions.value.find((option) => option.baseTableId == base_table);
  if (cross_tables.length == 0 && selctedBaseView) return selctedBaseView;
  else return viewOptions.value.find((option: SegmentView.ViewOption) => option.isAdvance);
}

export function calAvailableTableList(ruleSetRoot: RuleSetRoot, tableRelationGroups: TableRelationGroup[]) {
  const availableTableList = tableRelationGroups
    .filter((group) =>
      ruleSetRoot.rule_set_list.every((ruleSet) =>
        ruleSet.table_list.some((table) => group.table_id == table.table_id || group.relations.includes(table.table_id)),
      ),
    )
    .map((group) => group.table_id);

  // set option disabled
  viewOptions.value.forEach((view) => {
    if (view.isAdvance) return (view.isActive = true);

    if (view.baseTableId === null) return (view.isActive = false);

    view.isActive = availableTableList.includes(view.baseTableId as number);
  });

  return availableTableList;
}
