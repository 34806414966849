import { onBeforeMount, ref, Ref, reactive, markRaw } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import apiService from '@/services/api';
import api from '@services/api';
import AllCustomerModel from '@/models/segment/taballcustomer';
import SegmentTemplateModel from '@/models/segment/segmenttemplate';
import segmentModel from '@/models/segment/segment';

import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import UseIcon from '@assets/icons/modules/segment/template-tab/Use.vue';
import PreviewIcon from '@assets/icons/modules/segment/template-tab/Preview.vue';
import { useModuleStore } from '@/store/moduleStore';

interface Props {
  selectedSource: Segment.Datasource;
  segmentConfig: Segment.Config;
}

export default function useSegmentTemplate(props: Props) {
  const { fetchDisplayColumnModel } = AllCustomerModel();
  const { fetchCategoryList, fetchSegmentTemplateList, fetchSegmentTemplateListCard } = SegmentTemplateModel();
  const { fetchSegmentDetailModel } = segmentModel();
  const { openDefaultErrorModal } = useValidationModal();

  const { t } = useI18n();

  const router = useRouter();

  const isTemplateLoading = ref(false);
  const isTemplateTableLoading = ref(false);
  const templateType: Ref<Record<string, any>[]> = ref([]);
  const templateSubType: Ref<Record<string, any>[]> = ref([]);
  const listSegCat: Ref<number[]> = ref([]);
  const templateSelectInfo: Ref<Segment.PreviewSegmentTemplate> = ref({
    template_id: 0,
    icon: '',
    title: '',
    description: '',
  });
  const editRuleModal: { isOpen: boolean; isLoading: boolean; columnList: DynamicTable.Column[]; segment: Segment.Segment } = reactive({
    isOpen: false,
    isLoading: false,
    columnList: [],
    segment: null!,
  });
  const templateTypeOptions: Ref<Segment.CategoryTemplateSegment[]> = ref([]);
  const templateSubTypeOptions: Ref<Segment.CategoryTemplateSegment[]> = ref([]);
  const templateSubTypeOptionsFilter: Ref<Segment.CategoryTemplateSegment[]> = ref([]);
  const DataTypeOperationList: Ref<DynamicTable.DataTypeOperationList[]> = ref([]);
  const AggregatePropertyList: Ref<DynamicTable.AggregatePropertyList[]> = ref([]);

  const isChangedListStyle: Ref<boolean> = ref(false);
  const keyChange: Ref<number> = ref(0);
  const templateCategoryList: Ref<Segment.TemplateSegmentListCard[]> = ref([]);
  const templateList: Ref<Segment.TemplateSegmentListTable[]> = ref([]);

  const previewModal: { isOpen: boolean; template: Segment.TemplateSegmentListTable } = reactive({
    isOpen: false,
    template: null!,
  });

  const createSegmentModal = reactive({
    isOpen: false,
    isLoading: false,
    columnList: [] as DynamicTable.Column[],
  });

  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
  });

  const templateFilterForm: Record<string, any> = reactive({
    search: '',
    order: null,
    channel: null,
    category: null,
  });

  const field: BaseTable.TableColumn[] = [
    { key: 'name', label: t('communication.template_name'), sortable: true },
    { key: 'parent_cat_name', label: t('communication.category') },
    { key: 'updated_dt', label: t('communication.last_update') },
    { key: 'updated_by_name', label: t('communication.update_by') },
    { key: 'action', label: '' },
  ];
  let searchTimeout: ReturnType<typeof setTimeout> = null!;
  const onSort = (sortedColumn: { key: string; direction: string }) => {
    if (sortedColumn.direction) {
      const sortModel = [
        {
          Seq: 1,
          Key: sortedColumn.key,
          Direction: sortedColumn.direction.toLocaleLowerCase(),
        },
      ];

      templateFilterForm.order = sortModel as Communication.Template.Request.Ordering[];
    } else {
      templateFilterForm.order = [];
    }

    if (pagination.currentPage == 1) return fetchTemplateList();

    return (pagination.currentPage = 1);
  };

  function createButtonSegment(segment_id: number) {
    onOpenEditRuleModal(segment_id);
  }

  function findIndex(object: any, text: string) {
    let i;
    for (i = 0; i < object.length; i++) {
      if (object[i].name == text) {
        return i;
      }
    }
    return -1;
  }

  const onPreviewTemplate = (idTemplateSelect: number) => {
    templateCategoryList.value.forEach((CategoryList) => {
      CategoryList.segment_template_list.forEach((template) => {
        if (template.segment_id == idTemplateSelect) {
          templateSelectInfo.value = {
            template_id: template.segment_id,
            icon: template.icon_url,
            title: template.name,
            description: template.description,
          };
          previewModal.isOpen = true;
        }
      });
    });
  };

  const openPreviewModal = (template: Segment.TemplateSegmentListTable) => {
    templateSelectInfo.value = {
      template_id: template.segment_id,
      icon: template.icon_url,
      title: template.name,
      description: template.description,
    };
    previewModal.isOpen = true;
  };
  const onClosePreviewModal = () => {
    previewModal.isOpen = false;
  };
  const onBackToTemplate = async () => {
    const allModuleVersionStore = useModuleStore();
    if (allModuleVersionStore.getSegmentVersion == '1.0.0') {
      await router.replace({
        path: '/segment/data-segment/main',
        query: {
          tab: router.currentRoute.value.query.tab,
        },
      });
    } else {
      await router.replace({
        path: '/segmentv2',
        query: {
          tab: router.currentRoute.value.query.tab,
        },
      });
    }
  };

  const onResolveAction = (action: Record<string, any>, template: Segment.TemplateSegmentListTable) => {
    switch (action.key) {
      case 'use':
        return createButtonSegment(template.segment_id);
      case 'preview':
        return openPreviewModal(template);
    }
  };
  const standardActionList: Ref<Array<any>> = ref([
    {
      key: 'use',
      label: t('communication.use'),
      icon: markRaw(UseIcon),
      tooltip: t('communication.use'),
      tooltipBackgroundColor: '#BBDBFF',
      tooltipColor: '#007FFF',
    },
    {
      key: 'preview',
      label: t('communication.preview'),
      icon: markRaw(PreviewIcon),
      tooltip: t('communication.preview'),
      tooltipBackgroundColor: '#BBDBFF',
      tooltipColor: '#007FFF',
    },
  ]);

  const myTemplateActionList: Ref<Array<any>> = ref([
    {
      key: 'use',
      label: t('communication.use'),
      icon: markRaw(UseIcon),
      tooltip: t('communication.use'),
      tooltipBackgroundColor: '#BBDBFF',
      tooltipColor: '#007FFF',
    },
    {
      key: 'preview',
      label: t('communication.preview'),
      icon: markRaw(PreviewIcon),
      tooltip: t('communication.preview'),
      tooltipBackgroundColor: '#BBDBFF',
      tooltipColor: '#007FFF',
    },
  ]);

  const onChangeListStyle = () => {
    pagination.currentPage = 1;
    isChangedListStyle.value = !isChangedListStyle.value;
    templateFilterForm.order = [];

    if (isChangedListStyle.value) {
      return fetchTemplateList();
    } else {
      return fetchTemplateCardList();
    }
  };

  const onClickCreateButton = () => {
    createSegmentModal.isOpen = true;
    fetchDisplayColumn();
  };

  const fetchTemplateList = () => {
    isTemplateTableLoading.value = true;

    fetchSegmentTemplateList.payload.list_seg_cat_ids = listSegCat.value;
    fetchSegmentTemplateList.payload.template_type = 1;
    fetchSegmentTemplateList.payload.search_value = templateFilterForm.search;
    fetchSegmentTemplateList.payload.ordering = [];
    fetchSegmentTemplateList.payload.page = 1;
    fetchSegmentTemplateList.payload.limit = 100;
    apiService
      .apiRequest(fetchSegmentTemplateList)
      .then((response) => {
        pagination.totalRows = response.data.filtered_record;
        templateList.value = response.data.data_list;
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isTemplateTableLoading.value = false;
      });
  };
  const fetchTemplateCardList = () => {
    isTemplateLoading.value = true;
    fetchSegmentTemplateListCard.payload.list_seg_cat_ids = listSegCat.value;
    fetchSegmentTemplateListCard.payload.template_type = 1;
    fetchSegmentTemplateListCard.payload.search_value = templateFilterForm.search;
    fetchSegmentTemplateListCard.payload.limit_per_patent_cat = -1;
    apiService
      .apiRequest(fetchSegmentTemplateListCard)
      .then((response) => {
        templateCategoryList.value = response.data.data_list || [];
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isTemplateLoading.value = false;
      });
  };

  const onCloseCreateSegmentModal = () => {
    createSegmentModal.isOpen = false;
  };

  const onChangeSearch = () => {
    if (searchTimeout) clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      if (isChangedListStyle.value) {
        return pagination.currentPage == 1 ? fetchTemplateList() : (pagination.currentPage = 1);
      }

      return fetchTemplateCardList();
    }, 500);
  };

  const onSelectTemplateType = (event: any) => {
    if (event.length == 0) {
      templateSubTypeOptionsFilter.value = templateSubTypeOptions.value;
    } else {
      templateSubType.value = [];
      let Filter: Segment.CategoryTemplateSegment[] = [];
      templateSubTypeOptionsFilter.value = [];
      Filter = templateSubTypeOptions.value;
      event.forEach((value: Segment.CategoryTemplateSegment) =>
        Filter.filter((el: Segment.CategoryTemplateSegment) => {
          if (el.parent_seg_cat_id === value.seg_cat_id) {
            templateSubTypeOptionsFilter.value.push(el);
            templateSubTypeOptionsFilter.value.sort((seq) => seq.seq);
          }
        }),
      );
    }
  };

  const onSelectTemplateSubType = (event: any) => {
    listSegCat.value = [];
    event.forEach((SegCat: any) => {
      listSegCat.value.push(SegCat.seg_cat_id as number);
    });
    pagination.currentPage = 1;
    templateFilterForm.order = [];

    if (isChangedListStyle.value) {
      return fetchTemplateList();
    } else {
      return fetchTemplateCardList();
    }
  };

  const fetchTemplateCategoryList = () => {
    createSegmentModal.isLoading = true;

    api
      .apiRequest(fetchCategoryList)
      .then((response) => {
        const templateTypeList: Array<Segment.CategoryTemplateSegment> = response.data;
        templateTypeOptions.value = templateTypeList.filter((item) => item.parent_seg_cat_id == null).sort((seq) => seq.seq);
        templateSubTypeOptions.value = templateTypeList.filter((item) => item.parent_seg_cat_id != null).sort((seq) => seq.seq);
        templateSubTypeOptionsFilter.value = templateSubTypeOptions.value;
      })
      .catch((err) => {
        openDefaultErrorModal(
          err,
          () => (createSegmentModal.isOpen = false),
          () => (createSegmentModal.isOpen = false),
        );
      })
      .finally(() => (createSegmentModal.isLoading = false));
  };

  const fetchDisplayColumn = (): Promise<DynamicTable.Column[]> => {
    return new Promise((resolve, reject) => {
      fetchDisplayColumnModel.payload.TableId = props.selectedSource.tableid;
      createSegmentModal.isLoading = true;
      apiService
        .apiRequest(fetchDisplayColumnModel)
        .then((response) => {
          const columnList = response.data.payload.columns as DynamicTable.Column[];
          DataTypeOperationList.value = response.data.payload.data_type_operation_list as DynamicTable.DataTypeOperationList[];
          AggregatePropertyList.value = response.data.payload.data_type_agg_operation_list as DynamicTable.AggregatePropertyList[];
          createSegmentModal.columnList = columnList;
          resolve(columnList);
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => (createSegmentModal.isLoading = false));
    });
  };

  const fetchSegmentDetail = (segmentId: number): Promise<Segment.Segment> => {
    return new Promise((resolve, reject) => {
      fetchSegmentDetailModel.payload.segmentId = Number(segmentId);

      apiService
        .apiRequest(fetchSegmentDetailModel)
        .then((response) => {
          resolve(response.data as Segment.Segment);
        })
        .catch((err) => reject(err));
    });
  };

  const onOpenEditRuleModal = async (segmentId: number) => {
    editRuleModal.isOpen = true;
    editRuleModal.isLoading = true;

    editRuleModal.columnList = await fetchDisplayColumn();

    editRuleModal.segment = await fetchSegmentDetail(segmentId);
    try {
    } catch (err) {
      editRuleModal.isOpen = false;
      openDefaultErrorModal(err);
    } finally {
      editRuleModal.isLoading = false;
    }
  };

  const onCloseEditRuleModal = () => {
    editRuleModal.segment = null!;
    editRuleModal.isOpen = false;
  };

  onBeforeMount(() => {
    templateType.value = [];
    templateFilterForm.channel = 1;
    templateFilterForm.category = 1;
    fetchTemplateCategoryList();
    fetchTemplateCardList();
  });

  return {
    isTemplateLoading,
    templateTypeOptions,
    templateType,
    templateSubType,
    onChangeListStyle,
    isChangedListStyle,
    templateFilterForm,
    onChangeSearch,
    templateCategoryList,
    field,
    templateList,
    isTemplateTableLoading,
    keyChange,
    onSort,
    pagination,
    onResolveAction,
    standardActionList,
    myTemplateActionList,
    previewModal,
    onClosePreviewModal,
    onPreviewTemplate,
    onBacktemplate: onBackToTemplate,
    templateSelectInfo,
    createSegmentModal,
    onCloseCreateSegmentModal,
    onClickCreateButton,
    findIndex,
    onSelectTemplateType,
    onSelectTemplateSubType,
    templateSubTypeOptionsFilter,
    editRuleModal,
    onOpenEditRuleModal,
    onCloseEditRuleModal,
    DataTypeOperationList,
    AggregatePropertyList,
  };
}
