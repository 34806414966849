import { ref, onMounted, onUnmounted, reactive, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Edge, Node, GraphNode, ElementData, useVueFlow, FlowExportObject } from '@braks/vue-flow';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import { useRoute } from 'vue-router';
import api from '@services/api';
import router from '@/router';
import useConvertDate from '@/utils/hooks/useConvertDate';
import { useModuleStore } from '@/store/moduleStore';

import { saveAs } from 'file-saver';
import { getLanguageDisplayModalError } from '@/utils/useLanguageDisplay';
// constants
import {
  MKA_NODE_CONSTANT_LIST as MkaNodeConstantList,
  MKA_MODEL_COMPONENTS as MkaModalComponents,
  MKA_MODEL_COMPONENTS_LIST as MkaComponentModalList,
  MKA_NODE_TYPE,
  JOURNEY_STATUS,
  JOURNEY_SAVE_MODE,
} from '@/constants/modules/mka/mkaDetail';

import {
  NODE_INFO_RESPONSE,
  OVERALL_INFO_RESPONSE,
  MKA_NODE,
  // TODO: declare type
  // PROPS_NODE_SUMMARY,
  // PROPS_SUMMARY_INFO_TYPE,
  // PROPS_NODE_SUMMARY_SOURCE,
  // PROPS_NODE_SUMMARY_CONTROL,
  // PROPS_NODE_SUMMARY_ACTION,
} from '@/types/modules/mka/mka';
// model
import mkaModel from '@models/mka/mka';
import {
  nodeEntrySegmentMechanic,
  nodeFlowDistributionSplitMechanic,
  nodeFlowForkSplitMechanic,
  nodeFlowLimitMechanic,
  nodeFlowRandomSplitNormalMechanic,
  nodeFlowRandomSplitTrueMechanic,
} from '../utils/node-mechanic';

import checkOutOfCredit from '../utils/checkOutOfCredit';
import campaignModel from '@/models/communication/campaign';

interface ModalMka {
  dataInfo: boolean;
  entrySegment: boolean;

  flowForkSplit: boolean;
  distributionSplit: boolean;
  randomSplitTrue: boolean;
  randomSplitNormal: boolean;
  flowWaitByDuration: boolean;
  flowWaitUntilDate: boolean;
  merge: boolean;
  limit: boolean;

  actionSms: boolean;
  actionEdm: boolean;
  actionLine: boolean;
}

interface ResumeCampaign {
  JourneyId: number;
  CampaignDT?: Date | null;
}

export default function useMkaDetail() {
  const { t } = useI18n();
  const { convertDate } = useConvertDate();
  const {
    fetchMkaCampaignDetail,
    fetchMkaNodeList,
    saveMkaCampaignDetail,
    updateMkaJourneyRunning,
    publishMkaCampaign,
    fetchMkaSummaryNodes,
    fetchMkaSummaryOverall,
    fetchMkaOverallReport,
    fetchMkaNodeReport,
    pauseMkaCampaign,
    resumeMkaCampaign,
    fetchMkaActionInfo,
    fetchMkaActionInfoSegment,
  } = mkaModel();
  const { toObject, setNodes, setEdges, setTransform, fitView, applyEdgeChanges, getNode, removeNodes, getEdge } = useVueFlow();
  const { openWarningModal, openErrorModal, openSuccessModal } = useValidationModal();
  const { resolveTextNormalCredit, resolveTextOutOfCredit, haveOutOfCredit } = checkOutOfCredit();
  const { getChannelCreditBalanceModel } = campaignModel();

  const mkaNodeList = ref<MKA_NODE[]>([]);
  const mkaNodeListData = ref<MKA.Nodes.Request.Response>({
    actions: [],
    controls: [],
    sources: [],
  });
  const mkaNodeData = ref<ElementData>({});
  const mkaNodeAction = ref<ElementData>([]);

  const creditBalance = ref<number>(0);

  const campaignId = ref<number>(0);
  const campaignName = ref<string>('');
  const textOutOf = ref<string>('');
  const textNormal = ref<string>('');

  const isContactUsModal: Ref<boolean> = ref(false);
  const isCheckStartModal: Ref<boolean> = ref(false);

  const campaignUpdateDate = ref<Date | null>(null);
  const campaignPublishDate = ref<Date | null>(null);
  const campaignStartDate = ref<Date | null>(null);
  const campaignEndDate = ref<Date | null>(null);
  const campaignStatus = ref<number>(0);
  const campaignSaveDateTime = ref<string>('');
  let eventAutoSave: any = null;
  let intervalFetchResult: any = null;
  const campaignSegmentVersion = ref<string>('');
  const allModuleVersionStore = useModuleStore();
  // modal create campaign
  const isCreateCampaign = ref<boolean>(localStorage.getItem('journeyid') ? true : false);

  // constant
  const campaignNameLimit = 500 as number;
  const autoSaveWait = 60000;

  const campaignReadOnly = ref<boolean>(false);
  const disabledDateTimePicker = ref(false);
  const isShowAddCreditButton = ref<boolean>(false);
  const isSegmentVersionValid = ref<boolean>(false);

  const nodeLocalIdIdx = ref<number>(0);

  // TODO: declare type
  const summaryInfoList = ref<any>();
  const summaryEdgeInfoList = ref<MKA.SummaryNodes.Request.EdgeInfo[]>([]);
  const summaryOverallInfo = ref<OVERALL_INFO_RESPONSE | null>(null);

  // loading
  const loadingCampaign = ref<boolean>(false);
  const loadingExport = ref<boolean>(false);
  const loadingNodeExport = ref<boolean>(false);
  const loadingOverall = ref<boolean>(false);
  const loadingAutoSave = ref<boolean>(false);
  const loadingDataInfo = ref<boolean>(false);
  const loadingDataInfoTable = ref<boolean>(false);
  const loadingOverallNodes = ref<boolean>(false);

  const dataInfoType = ref<string>();

  // summary info [multi nodes]

  const summarySegmentList = ref<MKA.SummaryInfo.ItemList.SummaryInfo[]>([]);
  const summaryFlowControlList = ref<MKA.SummaryInfo.ItemList.SummaryInfo[]>([]);
  const summaryActionList = ref<MKA.SummaryInfo.ItemList.SummaryInfo[]>([]);

  //modal state
  const modalOpen: ModalMka = reactive({
    dataInfo: false,
    entrySegment: false,

    flowForkSplit: false,
    distributionSplit: false,
    randomSplitTrue: false,
    randomSplitNormal: false,
    flowWaitByDuration: false,
    flowWaitUntilDate: false,
    merge: false,
    limit: false,

    actionSms: false,
    actionEdm: false,
    actionLine: false,
  });

  const nodeProps = ref<MKA_Nodes.NodesProps>({
    category: 0,
    editable: false,
    false_link: false,
    id: 0,
    info: '',
    localId: '',
    nodeIn: 0,
    nodeInLabel: [],
    nodeOut: 0,
    nodeOutLabel: [],
    payload: {},
    subTitle: '',
    subType: 0,
    title: '',
    type: 0,
  });

  const nodeDataInfoExportProps: Ref<any> = ref({
    journeyId: null,
    nodeId: null,
  });

  const nodeDataInfoModalProps = ref<MKA_Nodes.NodeDataInfo.DataInfo | null>(null);
  const nodeDataInfoTableModalProps = ref<MKA_Nodes.NodeDataInfo.DataTable | null>(null);

  const templateChangeMode = ref<boolean>(false);

  const setCampaignName = (event: any) => {
    campaignName.value = event.target.value.substring(0, campaignNameLimit);
  };

  const setNodeData = (value: any) => {
    mkaNodeData.value = value;
    mkaNodeAction.value = Object.keys(value).map((key) => ({
      data: value[key],
    }));
    mkaNodeAction.value = mkaNodeAction.value.filter((x: any) => x.data.category == 3);
    setAddCreditButton();
  };

  function clearFlowDetail() {
    mkaNodeData.value = {};
    mkaNodeAction.value = {};
    setNodes([]);
    setEdges([]);
  }

  function popupAlertClearFlowDetail() {
    openWarningModal(
      t('mka.clear_data'),
      t('mka.delete_all_mka'),
      () => {
        clearFlowDetail();
      },
      () => {},
    );
  }

  function closeContactUsModal() {
    isContactUsModal.value = false;
  }
  function openContactUsModal() {
    isContactUsModal.value = true;
  }
  // all mka editor nodes
  async function fetchMkaNode(): Promise<void> {
    return api
      .apiRequest(fetchMkaNodeList)
      .then((response: any) => {
        const data: MKA.Nodes.Request.Response = response.data;
        mkaNodeListData.value = data;

        const nodeList: any = [];
        data.sources.map((node: MKA.Nodes.Request.NodeInfo) => {
          nodeList.push({ category: node.category, type: node.type, subType: node.sub_type });
        });
        data.controls.map((node: MKA.Nodes.Request.NodeInfo) => {
          nodeList.push({ category: node.category, type: node.type, subType: node.sub_type });
        });
        data.actions.map((node: MKA.Nodes.Request.NodeInfo) => {
          nodeList.push({ category: node.category, type: node.type, subType: node.sub_type });
        });
        mkaNodeList.value = nodeList;

        Promise.resolve();
      })
      .catch(() => {
        Promise.reject();
      })
      .finally(() => {});
  }

  const saveFlowDetail = async () => {
    // if (campaignStatus.value == JOURNEY_STATUS.PAUSE) return;
    loadingAutoSave.value = true;
    try {
      const flowData = toObject();
      const nodeIds = flowData.nodes.map((item) => item.id);

      const mkaNodeDataTemp: { [key: string]: any } = { ...mkaNodeData.value };
      Object.keys(mkaNodeDataTemp).forEach((nodeLocalId) => {
        if (!nodeIds.includes(nodeLocalId)) {
          delete mkaNodeDataTemp[nodeLocalId];
        }
      });
      mkaNodeData.value = mkaNodeDataTemp;

      const jsonContent = {
        nodeData: mkaNodeData.value,
        flowData: flowData,
        localIndex: nodeLocalIdIdx.value,
      };

      if (!campaignReadOnly.value) {
        await saveFlowDetail_Info();
        await saveFlowDetail_Content(jsonContent, JOURNEY_SAVE_MODE.CONTENT);
      } else {
        await saveFlowDetail_Content(jsonContent, JOURNEY_SAVE_MODE.PUBLISHED);
      }
    } catch (error) {
      console.error(error);
    } finally {
      loadingAutoSave.value = false;
    }
  };

  async function saveFlowDetail_Info(): Promise<void> {
    // set api payload
    saveMkaCampaignDetail.payload.JourneyId = campaignId.value;
    saveMkaCampaignDetail.payload.Mode = JOURNEY_SAVE_MODE.INFO;
    saveMkaCampaignDetail.payload.Name = campaignName.value;
    saveMkaCampaignDetail.payload.Start_DT = campaignStartDate.value;

    // for set End_DT = 23.59.59
    if (campaignEndDate.value) {
      const setTimeEndDate = campaignEndDate.value;
      setTimeEndDate.setUTCHours(16);
      setTimeEndDate.setUTCMinutes(59);
      setTimeEndDate.setUTCSeconds(59);
      const isoTimestamp = setTimeEndDate.toISOString();
      saveMkaCampaignDetail.payload.End_DT = isoTimestamp;
    }

    return api
      .apiRequest(saveMkaCampaignDetail)
      .then(() => {
        Promise.resolve();
      })
      .catch(() => {
        Promise.reject();
      });
  }

  async function saveFlowDetail_Content(jsonContent: object, mode: string): Promise<void> {
    saveMkaCampaignDetail.payload.JourneyId = campaignId.value;
    saveMkaCampaignDetail.payload.Mode = mode;
    saveMkaCampaignDetail.payload.Content = JSON.stringify(jsonContent);
    saveMkaCampaignDetail.payload.Content_Data = saveToObject();

    return api
      .apiRequest(saveMkaCampaignDetail)
      .then((response: any) => {
        const data: any = response.data;
        if (!campaignReadOnly.value) {
          campaignSaveDateTime.value = t('mka.saved') + ' ' + resolveDateTime(data.updated_dt);
        }
        Promise.resolve();
      })
      .catch(() => {
        Promise.reject();
      });
  }

  async function updateJourney(): Promise<void> {
    updateMkaJourneyRunning.payload.JourneyId = campaignId.value;
    return api
      .apiRequest(updateMkaJourneyRunning)
      .then(() => {
        Promise.resolve();
      })
      .catch(() => {
        Promise.reject();
      });
  }

  const buildFlowDetail = (savePackage: string, campaignStatus: number) => {
    const renderSavePackage: any = JSON.parse(savePackage);
    if (renderSavePackage) {
      mkaNodeData.value = renderSavePackage.nodeData;
      mkaNodeAction.value = Object.keys(mkaNodeData.value).map((key) => ({
        data: mkaNodeData.value[key],
      }));
      mkaNodeAction.value = mkaNodeAction.value.filter((x: any) => x.data.category == 3);
      setAddCreditButton();
      const [x = 0, y = 0] = renderSavePackage.flowData.position;
      setNodes(renderSavePackage.flowData.nodes as GraphNode[]);

      if (campaignStatus == JOURNEY_STATUS.PUBLISHED) {
        renderSavePackage.flowData.edges.forEach((item: any) => {
          item.animated = true;
        });
      } else {
        renderSavePackage.flowData.edges.forEach((item: any) => {
          item.animated = false;
        });
      }

      setEdges(renderSavePackage.flowData.edges as Edge[]);
      setTransform({ x, y, zoom: renderSavePackage.flowData.zoom || 0 });
      if (window.outerWidth < 1632) {
        fitView({ padding: 0.25, includeHiddenNodes: false, maxZoom: 1 });
      } else {
        fitView({ padding: 0.5, includeHiddenNodes: false, maxZoom: 1 });
      }
      nodeLocalIdIdx.value = renderSavePackage.localIndex || 0;
    }
  };

  async function fetchMkaCampaignDetailRequest(journeyId: number): Promise<void> {
    fetchMkaCampaignDetail.payload.JourneyId = journeyId;
    return api
      .apiRequest(fetchMkaCampaignDetail)
      .then((response: any) => {
        const data: MKA.Detail.Request.Response = response.data;
        campaignName.value = data.name;
        campaignStartDate.value = new Date(data.start_dt);
        campaignEndDate.value = new Date(data.end_dt);
        campaignStatus.value = data.status;

        campaignReadOnly.value = data.status != JOURNEY_STATUS.DRAFT;

        campaignSegmentVersion.value = data.segment_version;

        isSegmentVersionValid.value = campaignSegmentVersion.value == allModuleVersionStore.getSegmentVersion;

        // disabledDateTimePicker.value = data.status != JOURNEY_STATUS.DRAFT && data.status != JOURNEY_STATUS.PAUSE;

        disabledDateTimePicker.value = !isSegmentVersionValid.value || (data.status != JOURNEY_STATUS.DRAFT && data.status != JOURNEY_STATUS.PAUSE);

        campaignPublishDate.value = data.publish_dt as Date;
        campaignUpdateDate.value = data.updated_dt as Date;

        buildFlowDetail(data.raw_content, data.status);

        Promise.resolve();
      })
      .catch(() => {
        // path id not found
        router.push({ path: '/mka/' });
        Promise.reject();
      })
      .finally(() => {
        loadingCampaign.value = false;
        // TODO: prevent journey expire, journey inactive
        if (isSegmentVersionValid.value) {
          eventAutoSave = setInterval(() => {
            saveFlowDetail();
          }, autoSaveWait);
        }
      });
  }

  const verifyCampaignRequest = async () => {
    let verify = false as boolean;
    await saveFlowDetail();
    textOutOf.value = await resolveTextOutOfCredit(mkaNodeAction.value);
    textNormal.value = await resolveTextNormalCredit(mkaNodeAction.value);

    try {
      const flowTemp = await saveToObject();
      if (flowTemp.nodes.length == 0) throw new Error(t('mka.verify_error_node'));
      if (flowTemp.links.length == 0) throw new Error(t('mka.verify_error_flow'));

      verify = true;
    } catch (error: any) {
      if (error) {
        openErrorModal(t('mka.verify_error'), error.message as string);
      }
      verify = false;
    }

    if (verify) {
      if (textOutOf.value == '') {
        openWarningModal(
          t('mka.modal_confirm_data_submission'),
          t('mka.modal_confirm_run_detail'),
          () => {
            publishCampaignRequest();
          },
          () => {},
        );
      } else {
        isCheckStartModal.value = true;
      }
    }
  };

  async function publishCampaignRequest(): Promise<void> {
    publishMkaCampaign.payload.JourneyId = campaignId.value;
    publishMkaCampaign.payload.Content_Data = saveToObject();
    return api
      .apiRequest(publishMkaCampaign)
      .then(() => {
        Promise.resolve();
        openSuccessModal('ทำรายการสำเร็จ', '', '', undefined, () => location.reload());
        location.reload();
      })
      .catch((error) => {
        openErrorModal(
          error.data.error.locale[getLanguageDisplayModalError()].title as string,
          `${error.data.error.locale[getLanguageDisplayModalError()].message as string} ${error.data.error.message as string}`,
        );
        Promise.reject();
      })
      .finally(() => {
        loadingCampaign.value = true;
        refreshCampaign(campaignId.value);
      });
  }

  async function setAddCreditButton() {
    isShowAddCreditButton.value = await haveOutOfCredit(mkaNodeAction.value);
  }

  const saveToObject = () => {
    const baseReturnData: FlowExportObject = toObject();
    const finalReturnData: any = {
      links: baseReturnData.edges.map((edge: any) => {
        return edge.data;
      }),
      nodes: baseReturnData.nodes.map((node: any) => {
        return mkaNodeData.value[node.id];
      }),
    };
    return finalReturnData;
  };

  function onPauseCampaign() {
    openWarningModal(
      t('mka.modal_confirm_pause'),
      t('mka.modal_confirm_pause_campaign'),
      () => {
        pauseCampaignRequest();
      },
      () => {},
    );
  }

  function onResumeCampaign() {
    openWarningModal(
      t('mka.modal_confirm_resume'),
      t('mka.modal_confirm_resume_campaign'),
      () => {
        resumeCampaignRequest();
      },
      () => {},
    );
  }

  async function pauseCampaignRequest(): Promise<void> {
    pauseMkaCampaign.payload.JourneyId = campaignId.value;
    return api
      .apiRequest(pauseMkaCampaign)
      .then(() => {
        Promise.resolve();
      })
      .catch((error) => {
        openErrorModal(
          error.data.error.locale[getLanguageDisplayModalError()].title as string,
          `${error.data.error.locale[getLanguageDisplayModalError()].message as string} ${error.data.error.message as string}`,
        );
        Promise.reject();
      })
      .finally(() => {
        loadingCampaign.value = true;
        refreshCampaign(campaignId.value);
      });
  }

  async function resumeCampaignRequest(): Promise<void> {
    resumeMkaCampaign.payload.JourneyId = campaignId.value;
    resumeMkaCampaign.payload.StartDT = campaignStartDate.value;
    resumeMkaCampaign.payload.EndDT = campaignEndDate.value;

    return await api
      .apiRequest(resumeMkaCampaign)
      .then(() => {
        Promise.resolve();
      })
      .catch((error) => {
        openErrorModal(
          error.data.error.locale[getLanguageDisplayModalError()].title as string,
          `${error.data.error.locale[getLanguageDisplayModalError()].message as string} ${error.data.error.message as string}`,
        );
        Promise.reject();
      })
      .finally(() => {
        loadingCampaign.value = true;
        refreshCampaign(campaignId.value);
      });
  }

  // set node id
  const setNodeLocalIdIdx = (value: any) => {
    nodeLocalIdIdx.value = value;
  };

  const resolveDateTime = (dateInput: any) => {
    if (dateInput) {
      const date = new Date(dateInput as Date);
      let dateStr = '';

      try {
        dateStr = `${date.getFullYear()}/${date.toLocaleString('default', {
          month: '2-digit',
        })}/${date.toLocaleString('default', {
          day: '2-digit',
        })} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      } catch {
        return '';
      }

      return dateStr;
    }

    return '';
  };

  const nodeMechanicUpdate = () => {
    const allFlowData: FlowExportObject = toObject();
    const allNodes: Node[] = allFlowData.nodes;
    // const allEdges: any = allFlowData.edges;
    const mkaNodeDataTemp: any = mkaNodeData.value;

    const updateNodeList: any = [];

    //node mapping
    allNodes.map((node: Node) => {
      updateNodeList.push(getNode.value(node.id));

      return node;
    });
    mkaNodeData.value = mkaNodeDataTemp;

    // update
    updateNodeList.map((node: Node) => {
      node.position = { x: node.position.x, y: node.position.y + 1 };
      setTimeout(() => {
        node.position = { x: node.position.x, y: node.position.y - 1 };
      }, 10);
    });
  };

  // remove node
  const removeNode = (nodeLocalId: string) => {
    setTimeout(() => {
      removeNodes([nodeLocalId], true);
      const mkaNodeDataTemp: any = mkaNodeData.value;
      delete mkaNodeDataTemp[nodeLocalId];
      mkaNodeData.value = mkaNodeDataTemp;
      mkaNodeAction.value = mkaNodeAction.value.filter((x: any) => x.data.localId != nodeLocalId);
      setAddCreditButton();
      nodeMechanicUpdate();
    }, 10);
  };

  // remove edge
  const removeEdge = (edgeId: string) => {
    setTimeout(() => {
      applyEdgeChanges([{ type: 'remove', id: edgeId }]);
      nodeMechanicUpdate();
    }, 10);
  };

  async function fetchMkaSummaryNodesInfo(): Promise<void> {
    function mapNodeInfo(node: MKA.SummaryNodes.Request.NodeInfo) {
      const arr: any = [];

      if (node.localid) {
        switch (node.category_desc) {
          case MKA_NODE_TYPE.Source: {
            const dataSource: any = node.data;
            return (arr[node.localid] = {
              node_id: node.nodeid,
              node_type: node.category_desc,
              segment_id: dataSource.segmentid,
              contacts_today: dataSource.increase_result,
              total_customer: dataSource.total_result,
              consent_pdpa_result: dataSource.consent_pdpa_result,
              pdpa_restricted: dataSource.not_consent_pdpa_result,
              entry_node: node.entry_node ? true : false,
              updated_dt: dataSource.updated_dt ? convertDate(dataSource.updated_dt as string) : '',
            });
          }

          case MKA_NODE_TYPE.FlowControl: {
            const dataControl: any = node.data;
            return (arr[node.localid] = {
              node_id: node.nodeid,
              node_type: node.category_desc,
              total_result: dataControl.total_result,
              complete_result: dataControl.complete_result,
              fail_result: dataControl.fail_result,
              updated_dt: dataControl.updated_dt ? convertDate(dataControl.updated_dt as string) : '',
            });
          }

          case MKA_NODE_TYPE.Action: {
            const dataAction: any = node.data;
            return (arr[node.localid] = {
              node_id: node.nodeid,
              node_type: node.category_desc,
              total_result: dataAction.total_result,
              complete_result: dataAction.complete_result,
              fail_result: dataAction.fail_result,
              remaining_credit: dataAction.remaining_credit,
              outstanding_result: dataAction.outstanding_result,
              updated_dt: dataAction.updated_dt ? convertDate(dataAction.updated_dt as string) : '',
            });
          }
        }
      }
    }

    loadingOverall.value = true;
    // set api payload
    fetchMkaSummaryNodes.payload.JourneyId = campaignId.value;

    return api
      .apiRequest(fetchMkaSummaryNodes)
      .then((response) => {
        const data: MKA.SummaryNodes.Request.Response = response.data;
        const nodeList: any = [];
        data.node_list.map((node: MKA.SummaryNodes.Request.NodeInfo) => {
          nodeList[node.localid] = mapNodeInfo(node);
        });

        summaryEdgeInfoList.value = data.edge_list;
        summaryInfoList.value = nodeList;

        mappingSummaryOverAllNodes(data.node_list);
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      })
      .finally(() => {
        loadingOverall.value = false;
      });
  }

  async function fetchMkaSummaryOverallInfo(): Promise<void> {
    loadingOverall.value = true;
    //set api payload
    fetchMkaSummaryOverall.payload.JourneyId = campaignId.value;

    return api
      .apiRequest(fetchMkaSummaryOverall)
      .then((response) => {
        summaryOverallInfo.value = response.data.overall;

        Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      })
      .finally(() => {
        loadingOverall.value = false;
      });
  }

  async function fetchMkaNodeActionInfo(): Promise<void> {
    loadingDataInfo.value = true;
    fetchMkaActionInfo.payload.JourneyId = campaignId.value;
    fetchMkaActionInfo.payload.NodeId = nodeProps.value.id;
    return api
      .apiRequest(fetchMkaActionInfo)
      .then((response) => {
        const data: MKA_Nodes.NodeDataInfo.DataInfo = response.data;
        nodeDataInfoModalProps.value = data;
        // console.log(nodeDataInfoModalProps.value);
        // console.log(nodeProps.value);
        Promise.resolve();
      })
      .catch(() => {
        Promise.reject();
      })
      .finally(() => {
        modalOpen.dataInfo = true;
        loadingDataInfo.value = false;
      });
  }

  const linkToSegment = async (segmentName: string, segmentId: number, tableId: number): Promise<void> => {
    if (segmentId == 0 || tableId == 0 || campaignId.value == 0) return;
    fetchMkaActionInfoSegment.payload.JourneyId = campaignId.value;
    fetchMkaActionInfoSegment.payload.SegmentId = segmentId;
    fetchMkaActionInfoSegment.payload.TableId = tableId;

    return api
      .apiRequest(fetchMkaActionInfoSegment)
      .then((res) => {
        const data = res.data.data;
        if (data) {
          if (campaignSegmentVersion.value == '1.0.0') {
            router.push({
              path: '/segment/data-segment/segment-detail',
              query: {
                tab: 0,
                subtab: 2,
                tableId: data.tableid,
                folderId: data.folderid,
                folderName: data.folder_name || '',
                segmentId: data.segmentid,
                segmentName: segmentName || '',
                type: 1,
              },
            });
          } else if (campaignSegmentVersion.value == '2.0.0') {
            router.push({
              name: 'segmentResultV2',
              params: {
                segmentId: data.segmentid,
              },
              query: {
                ...router.currentRoute.value.query,
              },
            });
          }
        }
        Promise.resolve();
      })
      .catch((err) => {
        Promise.reject(err);
      });
  };
  async function fetchMkaNodeCreditBalanceInfo(channalType: number) {
    let channal: number | null = null;
    switch (channalType) {
      case 301:
        channal = 1;
        break;
      case 302:
        channal = 2;
        break;
      case 303:
        channal = 3;
        break;
      default:
        channal = null;
        break;
    }

    if (!channal) return (creditBalance.value = 0);

    getChannelCreditBalanceModel.payload.Channel = channal;
    return api.apiRequest(getChannelCreditBalanceModel).then((response) => {
      const resp = response.data;
      resp.forEach((x: any) => {
        if (channal) {
          creditBalance.value = x.remaining;
        }
      });

      Promise.resolve();
    });
  }

  function onClickExportOverallReport(categoryId: number, typeId: number) {
    loadingExport.value = true;

    // set api payload
    fetchMkaOverallReport.payload.JourneyId = campaignId.value;
    fetchMkaOverallReport.payload.Category = categoryId;
    fetchMkaOverallReport.payload.Type = typeId;

    api
      .apiRequest(fetchMkaOverallReport)
      .then((response) => {
        const datesTime = new Date();
        saveAs(
          response as Blob | string,
          `Overall-Report-${datesTime.getFullYear()}${datesTime.getMonth()}${datesTime.getDate()}_${datesTime.getHours()}${datesTime.getMinutes()}${datesTime.getSeconds()}.csv`,
        );
      })
      .catch((error) => {
        openErrorModal(
          error.data.error.locale[getLanguageDisplayModalError()].title as string,
          error.data.error.locale[getLanguageDisplayModalError()].message as string,
        );
      })
      .finally(() => {
        loadingExport.value = false;
        openSuccessModal('ทำรายการสำเร็จ');
      });
  }

  async function onClickExportNodeReport(nodeId: number, categoryId: number, typeId: number): Promise<void> {
    if (nodeId !== undefined && categoryId !== undefined && typeId !== undefined) {
      loadingNodeExport.value = true;

      // set api payload
      fetchMkaNodeReport.payload.NodeId = nodeId;
      fetchMkaNodeReport.payload.JourneyId = campaignId.value;
      fetchMkaNodeReport.payload.Category = categoryId;
      fetchMkaNodeReport.payload.Type = typeId;

      return api
        .apiRequest(fetchMkaNodeReport)
        .then((response) => {
          const datesTime = new Date();
          saveAs(
            response as Blob | string,
            `Node-Report-${datesTime.getFullYear()}${datesTime.getMonth()}${datesTime.getDate()}_${datesTime.getHours()}${datesTime.getMinutes()}${datesTime.getSeconds()}.csv`,
          );
          Promise.resolve();
        })
        .catch((error) => {
          openErrorModal(
            error.data.error.locale[getLanguageDisplayModalError()].title as string,
            error.data.error.locale[getLanguageDisplayModalError()].message as string,
          );
          Promise.reject();
        })
        .finally(() => {
          loadingNodeExport.value = false;
          openSuccessModal('ทำรายการสำเร็จ');
        });
    } else {
      openErrorModal('เกิดปัญหาขัดข้อง');
    }
  }

  function nodePropsMapper(nodeLocalId: string): MKA_Nodes.NodesProps {
    return mkaNodeData.value[nodeLocalId];
  }

  async function onNodeDblClickEvent(nodeLocalId: string, nodeTypeId: number, nodeSubTypeId: number) {
    nodeProps.value = nodePropsMapper(nodeLocalId);
    if (campaignReadOnly.value) {
      nodeProps.value.id = summaryInfoList.value[nodeLocalId].node_id;

      //all summary node can read
      if (nodeProps.value.id == null || nodeProps.value.id == 0) {
        openErrorModal('เกิดปัญหาขัดข้อง');
      } else {
        await fetchMkaNodeCreditBalanceInfo(nodeTypeId);
        await fetchMkaNodeActionInfo();
      }
    } else {
      switch (resolveNodeType(nodeTypeId, nodeSubTypeId)) {
        case MkaModalComponents.EntrySegment:
          return (modalOpen.entrySegment = true);
        case MkaModalComponents.FlowWaitUntilDate:
          return (modalOpen.flowWaitUntilDate = true);
        case MkaModalComponents.FlowWaitByDuration:
          return (modalOpen.flowWaitByDuration = true);
        case MkaModalComponents.FlowForkSplit:
          return (modalOpen.flowForkSplit = true);
        case MkaModalComponents.DistributionSplit:
          return (modalOpen.distributionSplit = true);
        case MkaModalComponents.RandomSplitNormal:
          return (modalOpen.randomSplitNormal = true);
        case MkaModalComponents.RandomSplitTrue:
          return (modalOpen.randomSplitTrue = true);
        case MkaModalComponents.Limit:
          return (modalOpen.limit = true);
        case MkaModalComponents.Merge:
          return (modalOpen.merge = true);
        case MkaModalComponents.ActionSms:
          return (modalOpen.actionSms = true);
        case MkaModalComponents.ActionEdm:
          return (modalOpen.actionEdm = true);
        case MkaModalComponents.ActionLine:
          return (modalOpen.actionLine = true);
      }
    }
  }

  // 11 Node Types
  function resolveNodeType(nodeTypeId: number, nodeSubTypeId: number): string {
    if (nodeTypeId == 101 && nodeSubTypeId == 0) return MkaModalComponents.EntrySegment;
    else if (nodeTypeId == 202 && nodeSubTypeId == 1) return MkaModalComponents.FlowForkSplit;
    else if (nodeTypeId == 202 && nodeSubTypeId == 2) return MkaModalComponents.DistributionSplit;
    else if (nodeTypeId == 202 && nodeSubTypeId == 3) return MkaModalComponents.RandomSplitTrue;
    else if (nodeTypeId == 202 && nodeSubTypeId == 4) return MkaModalComponents.RandomSplitNormal;
    else if (nodeTypeId == 203 && nodeSubTypeId == 1) return MkaModalComponents.FlowWaitByDuration;
    else if (nodeTypeId == 203 && nodeSubTypeId == 2) return MkaModalComponents.FlowWaitUntilDate;
    else if (nodeTypeId == 204 && nodeSubTypeId == 0) return MkaModalComponents.Merge;
    else if (nodeTypeId == 205 && nodeSubTypeId == 0) return MkaModalComponents.Limit;
    else if (nodeTypeId == 301 && nodeSubTypeId == 0) return MkaModalComponents.ActionSms;
    else if (nodeTypeId == 302 && nodeSubTypeId == 0) return MkaModalComponents.ActionEdm;
    else if (nodeTypeId == 303 && nodeSubTypeId == 0) return MkaModalComponents.ActionLine;
    else return '';
  }

  function onNodeClickDelete(nodeLocalId: string) {
    removeNode(nodeLocalId);
  }

  // on apply modal
  function onApplySegmentModal(nodeData: MKA_Nodes.NodesProps) {
    if (nodeData.localId) {
      const allFlowData: FlowExportObject = toObject();
      mkaNodeData.value[nodeData.localId] = nodeData;
      nodeEntrySegmentMechanic(allFlowData, nodeData, removeEdge);
      nodeMechanicUpdate();
      modalOpen.entrySegment = false;
    }
  }

  function onApplyFlowWaitUntilDateModal(nodeData: MKA_Nodes.NodesProps) {
    if (nodeData.localId) {
      mkaNodeData.value[nodeData.localId] = nodeData;
      nodeMechanicUpdate();
    }
    modalOpen.flowWaitUntilDate = false;
  }

  function onApplyFlowWaitByDurationModal(nodeData: MKA_Nodes.NodesProps) {
    if (nodeData.localId) {
      mkaNodeData.value[nodeData.localId] = nodeData;

      nodeMechanicUpdate();
    }
    modalOpen.flowWaitByDuration = false;
  }

  function onApplyFlowForkSplitModal(nodeData: MKA_Nodes.NodesProps) {
    if (nodeData.localId) {
      const allFlowData: FlowExportObject = toObject();
      mkaNodeData.value[nodeData.localId] = nodeData;

      nodeFlowForkSplitMechanic(allFlowData, nodeData, removeEdge);
      nodeMechanicUpdate();

      modalOpen.flowForkSplit = false;
    }
  }

  function onApplyDistributionSplitModal(nodeData: MKA_Nodes.NodesProps) {
    const allFlowData: FlowExportObject = toObject();
    if (nodeData.localId) {
      mkaNodeData.value[nodeData.localId] = nodeData;
      nodeFlowDistributionSplitMechanic(allFlowData, nodeData, removeEdge);
      nodeMechanicUpdate();

      modalOpen.distributionSplit = false;
    }
  }

  function onApplyRandomSplitNormalModal(nodeData: MKA_Nodes.NodesProps) {
    if (nodeData.localId) {
      const allFlowData: FlowExportObject = toObject();
      mkaNodeData.value[nodeData.localId] = nodeData;
      nodeFlowRandomSplitNormalMechanic(allFlowData, nodeData, removeEdge, getEdge);
      nodeMechanicUpdate();
    }
    modalOpen.randomSplitNormal = false;
  }

  function onApplyRandomSplitTrueModal(nodeData: MKA_Nodes.NodesProps) {
    if (nodeData.localId) {
      const allFlowData: FlowExportObject = toObject();
      mkaNodeData.value[nodeData.localId] = nodeData;
      nodeFlowRandomSplitTrueMechanic(allFlowData, nodeData, removeEdge, getEdge);
      nodeMechanicUpdate();
    }
    modalOpen.randomSplitTrue = false;
  }

  function onApplyLimitModal(nodeData: MKA_Nodes.NodesProps) {
    if (nodeData.localId) {
      const allFlowData: FlowExportObject = toObject();
      mkaNodeData.value[nodeData.localId] = nodeData;
      nodeFlowLimitMechanic(allFlowData, nodeData, removeEdge);
      nodeMechanicUpdate();
    }
    modalOpen.limit = false;
  }

  function onApplyMergeModal(nodeData: MKA_Nodes.NodesProps) {
    if (nodeData.localId) {
      const allFlowData: FlowExportObject = toObject();
      mkaNodeData.value[nodeData.localId] = nodeData;
      nodeMechanicUpdate();
    }
    modalOpen.merge = false;
  }

  function onApplyActionSmsModal(nodeData: MKA_Nodes.NodesProps) {
    if (nodeData.localId) {
      const allFlowData: FlowExportObject = toObject();
      mkaNodeData.value[nodeData.localId] = nodeData;
      nodeMechanicUpdate();
    }
    modalOpen.actionSms = false;
  }
  function onApplyActionEdmModal(nodeData: MKA_Nodes.NodesProps) {
    if (nodeData.localId) {
      const allFlowData: FlowExportObject = toObject();
      mkaNodeData.value[nodeData.localId] = nodeData;
      nodeMechanicUpdate();
    }

    modalOpen.actionEdm = false;
  }

  function onApplyActionLineModal(nodeData: MKA_Nodes.NodesProps) {
    if (nodeData.localId) {
      const allFlowData: FlowExportObject = toObject();
      mkaNodeData.value[nodeData.localId] = nodeData;
      nodeMechanicUpdate();
    }

    modalOpen.actionLine = false;
  }

  function onApplyTemplateChangeModal(nodeData: MKA_Nodes.NodesProps) {
    if (nodeData.localId) {
      const allFlowData: FlowExportObject = toObject();
      mkaNodeData.value[nodeData.localId] = nodeData;
      nodeMechanicUpdate();
    }
  }

  function onApplySmsTemplateChange(nodePayloadTemp: any, nodeInfoTemp: any) {
    onCloseActionSmsModal();
    openWarningModal('ยืนยันการเปลี่ยนเทมเพลต', 'ผู้รับที่เข้าเงื่อนไขหลังจากนี้จะได้รับข้อความแบบเทมเพลตใหม่ทันที', () => {
      nodeProps.value.payload = nodePayloadTemp;
      nodeProps.value.info = nodeInfoTemp;
      onConfirmChangeTemplate(nodeProps.value);
    });
  }

  function onApplyEdmTemplateChange(
    nodePayloadTemp: any,
    templatePreviewTemp: any,
    nodePayloadContentsTemp: any,
    nodeInfoTemp: any,
    emailSubjectTemp: any,
  ) {
    onCloseActionEdmModal();
    openWarningModal('ยืนยันการเปลี่ยนเทมเพลต', 'ผู้รับที่เข้าเงื่อนไขหลังจากนี้จะได้รับข้อความแบบเทมเพลตใหม่ทันที', () => {
      nodeProps.value.payload = nodePayloadTemp;
      if (templatePreviewTemp) {
        nodeProps.value.payload.contents = nodePayloadContentsTemp;
        nodeProps.value.payload.contents[0].title = emailSubjectTemp;
      }
      nodeProps.value.info = nodeInfoTemp;
      onConfirmChangeTemplate(nodeProps.value);
    });
  }

  function onApplyLineTemplateChange(nodePayloadTemp: any, nodePayloadContentsTemp: any, nodeInfoTemp: any) {
    onCloseActionLineModal();
    openWarningModal('ยืนยันการเปลี่ยนเทมเพลต', 'ผู้รับที่เข้าเงื่อนไขหลังจากนี้จะได้รับข้อความแบบเทมเพลตใหม่ทันที', () => {
      nodeProps.value.payload = nodePayloadTemp;
      nodeProps.value.payload.contents = nodePayloadContentsTemp;
      nodeProps.value.info = nodeInfoTemp;
      onConfirmChangeTemplate(nodeProps.value);
    });
  }

  async function onConfirmChangeTemplate(nodeData: MKA_Nodes.NodesProps) {
    onApplyTemplateChangeModal(nodeData);
    await saveFlowDetail();
    await updateJourney();
  }

  // on close modal

  function onCloseDataInfoModal() {
    modalOpen.dataInfo = false;
  }

  function onCloseSegmentModal() {
    modalOpen.entrySegment = false;
  }

  function onCloseFlowWaitUntilDateModal() {
    modalOpen.flowWaitUntilDate = false;
  }

  function onCloseFlowWaitByDurationModal() {
    modalOpen.flowWaitByDuration = false;
  }

  function onCloseFlowForkSplitModal() {
    modalOpen.flowForkSplit = false;
  }

  function onCloseDistributionSplitModal() {
    modalOpen.distributionSplit = false;
  }

  function onCloseRandomSplitNormalModal() {
    modalOpen.randomSplitNormal = false;
  }

  function onCloseRandomSplitTrueModal() {
    modalOpen.randomSplitTrue = false;
  }

  function onCloseLimitModal() {
    modalOpen.limit = false;
  }

  function onCloseMergeModal() {
    modalOpen.merge = false;
  }

  function onCloseActionSmsModal() {
    modalOpen.actionSms = false;
  }

  function onCloseActionEdmModal() {
    modalOpen.actionEdm = false;
  }

  function onCloseActionLineModal() {
    modalOpen.actionLine = false;
  }

  function onModalNodeMechanicUpdate() {
    nodeMechanicUpdate();
  }

  function closeStartCampaignModal() {
    isCheckStartModal.value = false;
  }

  function confirmStartCampaignModal() {
    isCheckStartModal.value = false;
    publishCampaignRequest();
  }

  function onEdgeConnectUpdate(sourceNode: string, targetNode: string) {
    const allFlowData: FlowExportObject = toObject();
    const sourceNodeData = <MKA_Nodes.NodesProps>mkaNodeData.value[sourceNode];
    const targetNodeData = <MKA_Nodes.NodesProps>mkaNodeData.value[targetNode];
    // segment case
    if (sourceNodeData.type == 101) {
      nodeEntrySegmentMechanic(allFlowData, sourceNodeData, removeEdge);
      if (targetNodeData.type == 101) {
        nodeEntrySegmentMechanic(allFlowData, targetNodeData, removeEdge);
      }

      nodeMechanicUpdate();
    } else if (sourceNodeData.type != 101 && targetNodeData.type == 101) {
      nodeEntrySegmentMechanic(allFlowData, targetNodeData, removeEdge);
      nodeMechanicUpdate();
    }
    // random split true case
    if (sourceNodeData.type == 202 && sourceNodeData.subType == 3) {
      nodeFlowRandomSplitTrueMechanic(allFlowData, sourceNodeData, removeEdge, getEdge);
      nodeMechanicUpdate();
    }
    // random split normal case
    if (sourceNodeData.type == 202 && sourceNodeData.subType == 4) {
      nodeFlowRandomSplitNormalMechanic(allFlowData, sourceNodeData, removeEdge, getEdge);
      nodeMechanicUpdate();
    }
    // limit
    if (sourceNodeData.type == 205 && sourceNodeData.subType == 0) {
      nodeFlowLimitMechanic(allFlowData, sourceNodeData, removeEdge);
      nodeMechanicUpdate();
    }
  }

  function mappingNodeTypeName(localId: string): string {
    const type: number = mkaNodeData.value[localId] ? (mkaNodeData.value[localId].type as number) : 0;
    const subType: number = mkaNodeData.value[localId] ? (mkaNodeData.value[localId].subType as number) : 0;
    let nodeName = '';

    // node actions
    if (type > 300 && type < 400) {
      mkaNodeListData.value.actions.map((item: any) => {
        if (item.type == type && item.sub_type == subType) {
          nodeName = item.name;
        }
      });
    }
    // node flow controls
    else if (type > 200) {
      mkaNodeListData.value.controls.map((item: any) => {
        if (item.type == type && item.sub_type == subType) {
          nodeName = item.name;
        }
      });
    }
    // node sources
    else if (type > 100) {
      mkaNodeListData.value.sources.map((item: any) => {
        if (item.type == type && item.sub_type == subType) {
          nodeName = item.name;
        }
      });
    }

    return nodeName;
  }

  function mappingSummaryOverAllNodes(response: MKA.SummaryNodes.Request.NodeInfo[]) {
    loadingOverallNodes.value = true;

    summarySegmentList.value = [];
    summaryFlowControlList.value = [];
    summaryActionList.value = [];

    const segmentList: MKA.SummaryNodes.Request.NodeInfo[] = [];
    const flowControlList: MKA.SummaryNodes.Request.NodeInfo[] = [];
    const actionList: MKA.SummaryNodes.Request.NodeInfo[] = [];

    const resultFlow: MKA.SummaryNodes.Request.NodeControlData[] = [];
    const resultAction: MKA.SummaryNodes.Request.NodeActionData[] = [];
    const resultSeg: MKA.SummaryNodes.Request.NodeSourceData[] = [];
    try {
      // data == node_list
      const data = response;

      data.map((node: MKA.SummaryNodes.Request.NodeInfo) => {
        // sample node.type = 20101
        node.type = mkaNodeData.value[node.localid].type * 100 + mkaNodeData.value[node.localid].subType;
        if (node.category == 1) {
          segmentList.push(node);
        } else if (node.category == 2) {
          flowControlList.push(node);
        } else if (node.category == 3) {
          actionList.push(node);
        }
      });

      // group and sum
      // segment
      segmentList.reduce((res: any, value: any) => {
        if (!res[value.data.segmentid]) {
          res[value.data.segmentid] = { localid: value.localid, segmentid: value.data.segmentid, total_result: 0, entry_node: value.entry_node };
          resultSeg.push(res[value.data.segmentid]);
        }
        res[value.data.segmentid].total_result += value.data.total_result;
        res[value.data.segmentid].consent_pdpa_result = value.data.consent_pdpa_result;
        res[value.data.segmentid].not_consent_pdpa_result = value.data.not_consent_pdpa_result;
        return res;
      }, {});

      // flow control
      flowControlList.reduce((res: any, value: any) => {
        if (!res[value.type]) {
          res[value.type] = { localid: value.localid, type: value.type, subType: 0, total_result: 0, complete_result: 0, fail_result: 0 };
          resultFlow.push(res[value.type]);
        }
        res[value.type].total_result += value.data.total_result;
        res[value.type].complete_result += value.data.complete_result;
        res[value.type].fail_result += value.data.fail_result;
        return res;
      }, {});

      // action
      actionList.reduce((res: any, value: any) => {
        if (!res[value.type]) {
          res[value.type] = { localid: value.localid, type: value.type, total_result: 0, complete_result: 0, fail_result: 0 };
          resultAction.push(res[value.type]);
        }
        res[value.type].total_result += value.data.total_result;
        res[value.type].complete_result += value.data.complete_result;
        res[value.type].fail_result += value.data.fail_result;
        return res;
      }, {});

      // mapping data
      // segment
      resultSeg.map((item: any) => {
        if (item.entry_node) {
          let successPercentResult: number =
            summaryOverallInfo.value && summaryOverallInfo.value.source.total_result != 0
              ? Number(((item.total_result / summaryOverallInfo.value.source.total_result) * 100).toFixed(1))
              : 0;

          if (Number(successPercentResult) < 2) {
            successPercentResult = 2;
          }
          summarySegmentList.value.push({
            label: mkaNodeData.value[item.localid] ? mkaNodeData.value[item.localid].payload.Segment.label : '',
            successValue: item.total_result,
            failValue: 0,
            successPercent: successPercentResult,
            failPercent: 0,
            consent_pdpa_result: item.consent_pdpa_result,
            not_consent_pdpa_result: item.not_consent_pdpa_result,
            color: '#1589ee',
          });
        }
      });

      // flow control
      resultFlow.map((item: any) => {
        summaryFlowControlList.value.push({
          label: item.localid ? mappingNodeTypeName(item.localid as string) : '',
          successValue: item.complete_result,
          failValue: item.fail_result,
          successPercent:
            summaryOverallInfo.value && summaryOverallInfo.value.control.complete_result != 0
              ? ((item.complete_result / summaryOverallInfo.value.control.complete_result) * 100).toFixed(1)
              : 0,
          failPercent:
            summaryOverallInfo.value && summaryOverallInfo.value.control.fail_result
              ? ((item.fail_result / summaryOverallInfo.value.control.fail_result) * 100).toFixed(1)
              : 0,
          color: '#ffa200',
        });
      });

      // action
      resultAction.map((item: any) => {
        summaryActionList.value.push({
          label: item.localid ? mappingNodeTypeName(item.localid as string) : '',
          successValue: item.complete_result,
          failValue: item.fail_result,
          successPercent: item.total_result != 0 ? ((item.complete_result / item.total_result) * 100).toFixed(1) : 0,
          failPercent: item.total_result != 0 ? ((item.fail_result / item.total_result) * 100).toFixed(1) : 0,
          color: '#2aa700',
        });
      });

      loadingOverallNodes.value = false;
      Promise.resolve();
    } catch (error) {
      Promise.reject();
    }
  }

  async function refreshCampaign(journeyId: number) {
    clearJourneyInterval();
    await fetchMkaCampaignDetailRequest(journeyId);

    // Parallel fetch campaign summary
    await Promise.all([fetchMkaSummaryOverallInfo(), fetchMkaSummaryNodesInfo()]);

    if (campaignStatus.value == JOURNEY_STATUS.PUBLISHED) initialJourneyInterval();
  }

  // Initial auto fetch result data(every 10s)
  function initialJourneyInterval() {
    intervalFetchResult = setInterval(() => {
      fetchMkaSummaryOverallInfo();
      fetchMkaSummaryNodesInfo();
    }, 10000);
  }

  // Clear all Interval objects
  function clearJourneyInterval() {
    if (eventAutoSave != null) {
      clearInterval(eventAutoSave as number);
      eventAutoSave = null;
    }

    if (intervalFetchResult != null) {
      clearInterval(intervalFetchResult as number);
    }
  }
  // vue life cycle

  function onChangeTemplate(type: number) {
    modalOpen.dataInfo = false;
    templateChangeMode.value = true;
    switch (type) {
      case 301:
        modalOpen.actionSms = true;
        break;
      case 302:
        modalOpen.actionEdm = true;
        break;
      case 303:
        modalOpen.actionLine = true;
        break;
    }
  }

  onMounted(async () => {
    loadingCampaign.value = true;
    campaignId.value = Number(useRoute().params.campaignId);
    await fetchMkaNode();
    await fetchMkaCampaignDetailRequest(campaignId.value);

    if (campaignReadOnly.value && !loadingCampaign.value) {
      loadingOverall.value = true;

      // Parallel fetch campaign summary
      await Promise.all([fetchMkaSummaryOverallInfo(), fetchMkaSummaryNodesInfo()]);

      initialJourneyInterval();
    }
  });

  onUnmounted(() => {
    clearJourneyInterval();
  });

  return {
    mkaNodeConstantList: MkaNodeConstantList,
    MkaModalComponents,
    mkaNodeList,
    campaignName,
    campaignStartDate,
    campaignEndDate,
    setCampaignName,
    saveFlowDetail,
    JOURNEY_STATUS,
    campaignStatus,
    campaignReadOnly,
    disabledDateTimePicker,
    loadingCampaign,
    campaignSaveDateTime,
    campaignPublishDate,
    campaignUpdateDate,
    campaignSegmentVersion,
    mkaNodeData,
    setNodeData,
    popupAlertClearFlowDetail,
    verifyCampaignRequest,
    nodeLocalIdIdx,
    setNodeLocalIdIdx,
    isSegmentVersionValid,

    nodeMechanicUpdate,

    MkaComponentModalList,
    // TODO: declare type
    summaryInfoList,
    summaryEdgeInfoList,
    summaryOverallInfo,
    onClickExportOverallReport,
    onClickExportNodeReport,

    // loading
    loadingExport,
    loadingNodeExport,
    loadingOverall,
    loadingAutoSave,
    loadingDataInfo,
    loadingDataInfoTable,
    loadingOverallNodes,

    convertDate,
    onNodeDblClickEvent,
    onNodeClickDelete,
    onPauseCampaign,
    onResumeCampaign,

    //new Node Props
    onEdgeConnectUpdate,
    removeEdge,
    onModalNodeMechanicUpdate,

    nodeProps,

    //modal
    modalOpen,
    nodeDataInfoModalProps,
    nodeDataInfoTableModalProps,
    nodeDataInfoExportProps,
    isCreateCampaign,
    onApplySegmentModal,
    onApplyFlowWaitUntilDateModal,
    onApplyFlowWaitByDurationModal,
    onApplyFlowForkSplitModal,
    onApplyDistributionSplitModal,
    onApplyRandomSplitNormalModal,
    onApplyRandomSplitTrueModal,
    onApplyLimitModal,
    onApplyMergeModal,
    onApplyActionSmsModal,
    onApplyActionEdmModal,
    onApplyActionLineModal,

    onCloseDataInfoModal,
    onCloseSegmentModal,
    onCloseFlowWaitUntilDateModal,
    onCloseFlowWaitByDurationModal,
    onCloseFlowForkSplitModal,
    onCloseDistributionSplitModal,
    onCloseRandomSplitNormalModal,
    onCloseRandomSplitTrueModal,
    onCloseLimitModal,
    onCloseMergeModal,
    onCloseActionSmsModal,
    onCloseActionEdmModal,
    onCloseActionLineModal,
    dataInfoType,

    // summary info list
    summarySegmentList,
    summaryFlowControlList,
    summaryActionList,

    // action info to segment
    linkToSegment,
    closeContactUsModal,
    openContactUsModal,
    isContactUsModal,
    isShowAddCreditButton,
    isCheckStartModal,
    textOutOf,
    textNormal,
    closeStartCampaignModal,
    confirmStartCampaignModal,
    creditBalance,

    onChangeTemplate,
    templateChangeMode,
    onApplySmsTemplateChange,
    onApplyEdmTemplateChange,
    onApplyLineTemplateChange,
  };
}
