import { DateTime, Zone } from 'luxon';
import { computed, defineComponent, h, Prop, ref, VNode, watch } from 'vue';
import { isUseCalendarObject, SelectableFunctionType, useCalendar, UseCalendarObject } from '../../composed/calendar';
import { getZone, isUseDateTimeObject, UseDateTimeObject } from '../../composed/date-time';
import { ChocoClickObserver } from '../click-observer';
import { ChocoTransitionContainer } from '../transition-container';
import { localeMonthsFull, localeMonthsFullTH, localeWeekDaysChar, localeWeekDaysCharTH } from './locale/base-translations';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ChocoCalendar',
  props: {
    // backend
    timezone: <Prop<Zone | string | number | null>>{
      validate: (val: any) => val === null || getZone(val).isValid,
      default: () => null,
    },
    useDateTime: <Prop<UseDateTimeObject>>{
      type: Object,
      validate: isUseDateTimeObject,
    },
    useCalendar: <Prop<UseCalendarObject>>{
      type: Object,
      validate: isUseCalendarObject,
    },
    // states
    readonly: <Prop<boolean>>{
      type: Boolean,
      default: () => false,
    },
    // selection
    selectable: <Prop<boolean>>{
      type: Boolean,
      default: () => false,
    },
    select: <Prop<Date | null>>{
      type: Object,
      validate: (val: unknown) => val instanceof Date || val === null,
    },
    selectRangeStart: <Prop<Date | null>>{
      type: Object,
      validate: (val: unknown) => val instanceof Date || val === null,
    },
    selectRangeEnd: <Prop<Date | null>>{
      type: Object,
      validate: (val: unknown) => val instanceof Date || val === null,
    },
    selectableCallback: <Prop<SelectableFunctionType>>{},
  },
  emits: ['select', 'update:select'],
  setup(props, { emit }) {
    // use calendar
    // TODO: validate, priority
    // - if useCalendar is given, use it (no timezone/date converted/override)
    // - if useDateTime is given, use to construct UseCalendarObject
    //     - if timezone is also given, converted to timezone, otherwise use timezone of useDateTime
    // - if timezone is only given, use for construct current datetime (now()) of given timezone
    const calendar = props.useCalendar || useCalendar(props.useDateTime, props.timezone);
    const { locale, t } = useI18n();
    // watch for selectable function changes
    watch(
      () => props.selectableCallback,
      (value) => value && calendar.setSelectableCallback(value),
      { immediate: true },
    );

    // region year-month picker
    const pickerMonthShow = ref(false);
    const pickerYearShow = ref(false);
    const togglePicker = (t: 'month' | 'year') => {
      if (t === 'month') {
        pickerMonthShow.value = !pickerMonthShow.value;
        pickerYearShow.value = false;
      } else {
        pickerYearShow.value = !pickerYearShow.value;
        pickerMonthShow.value = false;
      }
    };
    const toggleMonthPicker = () => togglePicker('month');
    const toggleYearPicker = () => togglePicker('year');
    const onPickDate = (year?: number, month?: number) => {
      calendar.goToDate(year, month);
      pickerMonthShow.value = false;
      pickerYearShow.value = false;
    };

    /** on picker year vnode mounted, scroll to current calendar's viewed year */
    const onPickerYearMounted = (vNode: VNode) => {
      const mountedEl = vNode.el as HTMLElement;
      const activeEl = mountedEl.querySelector('.choco-picker-item.active')! as HTMLElement;
      mountedEl.scrollTo({ top: activeEl.offsetTop - activeEl.clientHeight - 10 /* padding */ });
    };
    // endregion

    // region tracking select start-end date range

    // tracking current selected date, convert to luxon object and set zone to calendar's timezone
    const selected = computed<DateTime | null>(() => (props.select ? DateTime.fromJSDate(props.select).setZone(calendar.timezone.value) : null));
    // tracking select range
    const selectedRangeStart = ref<DateTime | null>(null);
    const selectedRangeEnd = ref<DateTime | null>(null);

    // watch selected date, to update current calendar view date (for UI)
    watch(
      () => selected.value,
      (value) => {
        // selected date is updated and not null/undefined, update view to current selected date
        if (value) {
          // month - 1 for compatibility with native object (native month accept value is 0-11, luxon month is 1-12)
          calendar.goToDate(value.year, value.month - 1, value.day);
        }
      },
      { immediate: true }, // immediate trigger after watched
    );

    // watch selected range start (directly via props) or selected date changes
    watch(
      [() => props.selectRangeStart, () => selected.value],
      ([propDate, selectedDate]) => {
        // if props value provided, use props value for selected start date
        if (propDate) {
          selectedRangeStart.value = DateTime.fromJSDate(propDate).setZone(calendar.timezone.value);
        }
        // else, if selected end date is provided (is not an undefined) and current date is selected, use selected date as start of the range
        else if (props.selectRangeEnd !== undefined && selectedDate) {
          selectedRangeStart.value = selectedDate;
        }
        // else, set to null
        else {
          selectedRangeStart.value = null;
        }
      },
      { immediate: true },
    );

    // watch selected range end (directly via props) or selected date changes
    watch(
      [() => props.selectRangeEnd, () => selected.value],
      ([propDate, selectedDate]) => {
        // if props value provided, use props value for selected end date
        if (propDate) {
          selectedRangeEnd.value = DateTime.fromJSDate(propDate).setZone(calendar.timezone.value);
        }
        // else, if selected start date is provided (is not an undefined) and current date is selected, use selected date as end of the range
        else if (props.selectRangeStart !== undefined && selectedDate) {
          selectedRangeEnd.value = selectedDate;
        }
        // else, set to null
        else {
          selectedRangeEnd.value = null;
        }
      },
      { immediate: true },
    );
    // endregion

    return () =>
      // div.choco-calendar
      h('div', { class: 'choco-calendar' }, [
        // div.choco-calendar-header
        h('div', { class: 'choco-calendar-header' }, [
          // div.choco-calendar-nav-left
          h('div', { class: 'choco-calendar-nav-left' }, [
            // span.choco-calendar-nav__action[previousYear]
            h('span', { class: ['choco-calendar-nav__action'], onClick: calendar.goToPreviousYear }, '<<'),
            // span.choco-calendar-nav__action[previousMonth]
            h('span', { class: ['choco-calendar-nav__action'], onClick: calendar.goToPreviousMonth }, '<'),
          ]),
          // div.choco-calendar-nav
          h('div', { class: 'choco-calendar-nav' }, [
            // span.choco-calendar-nav__month
            h(
              'span',
              { class: ['choco-calendar-nav__month'], onClick: toggleMonthPicker },
              locale.value == 'th' ? localeMonthsFullTH[calendar.month.value] : localeMonthsFull[calendar.month.value],
            ),
            // span.choco-calendar-nav__year
            h(
              'span',
              { class: ['choco-calendar-nav__year'], onClick: toggleYearPicker },
              locale.value == 'th' ? calendar.year.value + 543 : calendar.year.value,
            ),
          ]),
          // div.choco-calendar-nav-right
          h('div', { class: 'choco-calendar-nav-right' }, [
            // span.choco-calendar-nav__action[nextMonth]
            h('span', { class: ['choco-calendar-nav__action'], onClick: calendar.goToNextMonth }, '>'),
            // span.choco-calendar-nav__action[nextYear]
            h('span', { class: ['choco-calendar-nav__action'], onClick: calendar.goToNextYear }, '>>'),
          ]),
        ]),

        // div.choco-calendar-week-header
        h(
          'div',
          { class: 'choco-calendar-week-header' },
          // div.choco-calendar-week-header__item
          locale.value == 'th'
            ? localeWeekDaysCharTH.map((s) => h('div', { class: 'choco-calendar-week-header__item' }, s))
            : localeWeekDaysChar.map((s) => h('div', { class: 'choco-calendar-week-header__item' }, s)),
        ),

        // hr.choco-calendar-separator
        h('hr', { class: 'choco-calendar-separator' }),

        // (calendar body) ChocoTransitionContainer as div.choco-calendar-transition-container
        h(
          ChocoTransitionContainer,
          {
            as: 'div',
            name: `choco-slide-${calendar.navigation.value === 'decrement' ? 'left' : 'right'}`,
            class: ['choco-transition-container', 'choco-calendar-transition-container'],
          },
          () => [
            // div:{year+month}.choco-calendar-month-container
            // we specify unique key to make the DOM unique when changing month that (force to create new DOM)
            // this will trick the transition to be animated
            h(
              'div',
              { key: `${calendar.year.value}+${calendar.month.value}`, class: 'choco-calendar-month-container' },
              // v-for = calendarObject.dayMonthList > div.choco-calendar-week
              calendar.dayMonthList.value.map((week) =>
                h('div', { class: 'choco-calendar-week' }, [
                  // v-for = week > div.choco-calendar-day
                  week.map((day) => {
                    // prepare class list
                    const classList = ['choco-calendar-day'];

                    // if day is not null (contains mapping), prepare class and compare with selected date and range
                    if (day) {
                      // outer scope (out of current month)
                      if (day.outer || (props.selectable && !day.selectable)) {
                        classList.push('choco-calendar-day--out-scope');
                      }

                      // selectable
                      if (props.selectable) {
                        // day is selectable
                        if (day.selectable) {
                          classList.push('choco-calendar-day--selectable');
                        } else {
                          classList.push('choco-calendar-day--disabled');
                        }
                      }

                      // selected
                      if (day.value.toISODate() === selected.value?.toISODate()) {
                        classList.push('choco-calendar-day--select');
                      }

                      // to show select range, must contain start and end of the date range
                      if (selectedRangeStart.value && selectedRangeEnd.value) {
                        // within select date range
                        if (
                          day.value.toISODate() >= selectedRangeStart.value!.toISODate() &&
                          day.value.toISODate() <= selectedRangeEnd.value!.toISODate()
                        ) {
                          classList.push('choco-calendar-day--select-range');
                        }

                        // is the start of the select date range
                        if (day.value.toISODate() === selectedRangeStart.value!.toISODate()) {
                          classList.push('choco-calendar-day--select-start');
                        }

                        // is the end of the select date range
                        if (day.value.toISODate() === selectedRangeEnd.value!.toISODate()) {
                          classList.push('choco-calendar-day--select-end');
                        }
                      }
                    }

                    // return render function of div.choco-calendar-day
                    return h(
                      'div',
                      {
                        class: classList,
                        onClick() {
                          // if day contains value, emit event
                          if (props.selectable && day && day.selectable) {
                            // emit Date (native object), but if date is selected, also preserve time
                            const preserved = props.select
                              ? DateTime.fromJSDate(props.select).setZone(calendar.timezone.value).set({
                                  year: day.value.year,
                                  month: day.value.month,
                                  day: day.value.day,
                                })
                              : day.value;

                            emit('select', preserved.toJSDate());
                            emit('update:select', preserved.toJSDate());
                          }
                        },
                      },
                      [
                        // div.choco-calendar-day__background
                        h('div', { class: 'choco-calendar-day__background' }),
                        // div.choco-calendar-day__highlight
                        h('div', { class: 'choco-calendar-day__highlight' }),
                        // div.choco-calendar-day__content
                        h('div', { class: 'choco-calendar-day__content' }, day?.value.day ?? ''),
                      ],
                    );
                  }),
                ]),
              ),
            ),
          ],
        ),

        // (month picker) ChocoTransitionContainer as div.choco-picker.choco-month-picker
        h(ChocoTransitionContainer, { name: 'choco-fade', as: 'div', class: ['choco-picker', 'choco-month-picker'] }, () => [
          // v-if = viewMonthPicker > ClickObserver as div.choco-picker-container
          pickerMonthShow.value
            ? h(
                ChocoClickObserver,
                {
                  class: ['choco-picker-container'],
                  onClickOutside: () => (pickerMonthShow.value = false),
                },
                () => [
                  // v-for = calendarObject.yearList > div.choco-picker-item
                  calendar.monthList.map((v) => {
                    const active = v === calendar.month.value ? 'active' : '';
                    return h('div', { class: ['choco-picker-item', active], onClick: () => onPickDate(undefined, v) }, [localeMonthsFull[v]]);
                  }),
                ],
              )
            : null,
        ]),

        // (year picker) div.choco-transition-container.choco-picker.choco-year-picker
        h(ChocoTransitionContainer, { name: 'choco-fade', as: 'div', class: ['choco-picker', 'choco-year-picker'] }, () => [
          // v-if = viewYearPicker > ChocoClickObserver as div.choco-picker-container
          pickerYearShow.value
            ? h(
                ChocoClickObserver,
                {
                  class: ['choco-picker-container'],
                  onClickOutside: () => (pickerYearShow.value = false),
                  onVnodeMounted: onPickerYearMounted,
                },
                () => [
                  // v-for = calendarObject.yearList > div.choco-picker-item
                  calendar.yearList.map((v) => {
                    const active = v === calendar.year.value ? 'active' : '';
                    return h('div', { class: ['choco-picker-item', active], onClick: () => onPickDate(v) }, [v]);
                  }),
                ],
              )
            : null,
        ]),

        // div.choco-calendar-readonly-overlay
        props.readonly ? h('div', { class: 'choco-calendar-readonly-overlay' }) : null,
      ]);
  },
});
