import { DurationLike, Zone, Duration, DateTime } from 'luxon';
import { computed, defineComponent, h, mergeProps, Prop } from 'vue';
import { useDateRangeRelativePicker } from '../../composed/date-range-relative-picker';
import { getZone, parseISODuration } from '../../composed/date-time';
import { DateRange } from '../../interfaces';
import { toReadableDuration } from './translator';
import { useI18n } from 'vue-i18n';

interface RelativeOptionObject {
  type: 'relative-static' | 'relative' | 'custom';
  duration: DurationLike | null;
  label: string;
  value: string;
}

export default defineComponent({
  name: 'ChocoDateRangeRelativePicker',
  props: {
    // timezone
    timezone: <Prop<Zone | string | number | null>>{
      default: () => null,
      validate: (val: any) => val === null || getZone(val).isValid,
    },
    // relative options
    modelValue: <Prop<string | null>>{
      required: true,
    },
    options: <Prop<string[]>>{
      required: true,
    },
    // picker title
    title: <Prop<string>>{
      default: () => {
        const { t } = useI18n(); // ดึง useI18n มาใช้งาน
        return t('date_picker.relative_picker'); // คืนค่าข้อความแปล
      },
    },
    titleClass: <Prop<string | string[] | object>>{},
    // for update start-end date
    startDate: <Prop<Date | null>>{},
    endDate: <Prop<Date | null>>{},
  },
  emits: ['update:modelValue', 'update:startDate', 'update:endDate'],
  setup(props, { emit }) {
    const relativeStatics = ['today'];
    const { locale, t } = useI18n();
    // use date relative range picker
    const picker = useDateRangeRelativePicker(getZone(props.timezone ?? null));

    // prepare options
    const options = computed<RelativeOptionObject[]>(() => {
      const options: RelativeOptionObject[] = [];
      for (let option of (props.options ?? []).concat('custom')) {
        // parse option type
        let type: RelativeOptionObject['type'];
        let duration: DurationLike | null = null;
        if (option === 'custom') {
          type = 'custom';
        } else if (relativeStatics.includes(option)) {
          type = 'relative-static';
        } else {
          type = 'relative';
          duration = parseISODuration(option);
        }

        // add to available relative options
        options.push({
          type,
          duration,
          label: toReadableDuration(option),
          value: option,
        });
      }

      return options;
    });

    // select option
    const select = (option: string) => {
      // update pick option
      emit('update:modelValue', option);

      // not pick a custom option, update start-end date based on picked option
      if (option !== 'custom') {
        let pickedDateRange: DateRange;
        if (option === 'today') {
          pickedDateRange = picker.selectToday(true);
        } else {
          pickedDateRange = picker.selectLastsDuration(option);
        }
        emit('update:startDate', pickedDateRange.start);
        emit('update:endDate', pickedDateRange.end);
      }
    };

    const diffDays = computed((): number => {
      const startDate = props.startDate ? DateTime.fromJSDate(props.startDate) : null;
      const endDate = props.endDate ? DateTime.fromJSDate(props.endDate) : null;
      if (startDate != null && endDate != null) {
        return endDate.diff(startDate, 'day').days;
      }
      return 0;
    });

    // render function
    return () =>
      h('div', { class: 'choco-date-range-relative-picker' }, [
        // title
        h('div', mergeProps({ class: 'choco-date-range-relative-picker__title' }, { class: props.titleClass }), [
          props.title,
          locale.value == 'th'
            ? ` (${
                diffDays.value > 0
                  ? Math.round(diffDays.value)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  : '0'
              } วัน)`
            : ` (${
                diffDays.value > 0
                  ? Math.round(diffDays.value)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  : '0'
              } day${diffDays.value > 1 ? 's' : ''})`,
        ]),
        // list
        h('ul', { class: 'choco-date-range-relative-picker__options' }, [
          options.value.map((option) =>
            h(
              'li',
              {
                class: ['choco-date-range-relative-picker__option-item', props.modelValue === option.value ? 'active' : null],
                onClick: () => select(option.value),
              },
              [option.label],
            ),
          ),
        ]),
      ]);
  },
});
