import { ref, watch, reactive, Ref, onMounted, onBeforeMount } from 'vue';

import router from '@/router';
import api from '@services/api';
import { saveAs } from 'file-saver';

import { useI18n } from 'vue-i18n';
import useConvertDate from '@/utils/hooks/useConvertDate';
import Export from '@/assets/icons/modules/data-connect/coupon-management/DownloadIcon.vue';
import Detail from '@/assets/icons/modules/data-connect/coupon-management/BookIcon.vue';

import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import useLoading from '@/views/components/loading/hooks/useLoading';

import couponManagementModel from '@models/dataConnect/couponManagement';

import ability from '@/libs/acl/ability';
import { getLanguageDisplayModalError } from '@/utils/useLanguageDisplay';

interface SortValue {
  Seq: number;
  Key: string;
  Direction: string;
}

export default function useCouponManagementPage() {
  const { t } = useI18n();
  const {
    fetchCouponSetList,
    editCouponSet,
    cancelCouponSet,
    exportFileCouponSet,
    fetchDropDownStatusList,
    fetchDropDownSourceList,
    fetchDropDownFormatList,
    fetchDropCouponTypeList,
  } = couponManagementModel();
  const { convertDate } = useConvertDate();
  const { isLoading } = useLoading();
  const { openErrorModal, openDefaultErrorModal } = useValidationModal();

  const loading: Ref<boolean> = ref(false);
  const textSearch: Ref<string> = ref('');
  const couponStatus: Ref<Record<string, any>> = ref(null!);
  const sourceStatus: Ref<Record<string, any>> = ref(null!);
  const formatStatus: Ref<Record<string, any>> = ref(null!);
  const couponTypeStatus: Ref<Record<string, any>> = ref(null!);
  const sortingResult: Ref<SortValue[]> = ref([]);
  const payloadFilter: Ref<Record<string, any>> = ref(null!);
  const payloadSearch: Ref<string> = ref(null!);

  const clearCouponStatus = ref(false);
  const couponListFilter: Ref<Dataconnect.Couponlist.Coupon[]> = ref([]);
  const couponList: Ref<Dataconnect.Couponlist.Coupon[]> = ref([]);
  const keyChange: Ref<number> = ref(0);

  const dropDownCouponStatus: Ref<Record<string, any>[]> = ref([
    // { Seq: 1, Key: 'Status', label: 'Active', valueStatus: 2 },
    // { Seq: 2, Key: 'Status', label: 'Inactive', valueStatus: 3 },
  ]);

  const dropDownSourceStatus: Ref<Record<string, any>[]> = ref([
    // { Seq: 1, Key: 'Source', label: 'ChocoCrmPos', valueStatus: 'ChocoCrmPos' },
    // { Seq: 2, Key: 'Source', label: 'External', valueStatus: 'External' },
  ]);
  const dropDownFormatStatus: Ref<Record<string, any>[]> = ref([
    // { Seq: 1, Key: 'Format', label: 'Upload', valueStatus: 1 },
    // { Seq: 2, Key: 'Format', label: 'Sync', valueStatus: 2 },
  ]);
  const dropCouponTypeStatus: Ref<Record<string, any>[]> = ref([
    // { Seq: 1, Key: 'CouponType', label: 'Static', valueStatus: 1 },
    // { Seq: 2, Key: 'CouponType', label: 'Dynamic', valueStatus: 2 },
  ]);
  const dropDownPagination = [
    { label: '20', valueStatus: 20 },
    { label: '10', valueStatus: 10 },
    { label: '5', valueStatus: 5 },
  ];
  const paginationStatus: Ref<Record<string, any>> = ref(dropDownPagination[0]);

  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 20,
    totalRows: 0,
  });
  const fields: Ref<BaseTable.TableColumn[]> = ref([
    { key: 'image_token_link', label: t('data_connector.image'), sortable: false },
    { key: 'name', label: t('data_connector.campaignName'), sortable: true },
    { key: 'coupontype', label: t('data_connector.couponType'), sortable: true },
    { key: 'credit', label: t('data_connector.total'), sortable: false },
    { key: 'tracking_code', label: t('data_connector.trackingCode'), sortable: false },
    { key: 'source', label: t('data_connector.source'), sortable: true },
    { key: 'format', label: t('data_connector.format'), sortable: true },
    { key: 'updated_dt', label: t('data_connector.lastUpdate'), sortable: true },
    { key: 'updated_by_name', label: t('data_connector.updateBy'), sortable: false },
    { key: 'status', label: t('data_connector.status'), sortable: true },
    { key: 'action', label: '', sortable: false },
  ]);

  const actionList: ActionList.Action[] = [
    { key: 'detail', tooltip: t('dc.detail'), icon: Detail, tooltipColor: '#0077FF', tooltipBackgroundColor: '#E5F2FF' },
  ];
  const isNotiCreditModalOpen = ref<boolean>(false);
  const isNotiCreditSuccessOpen = ref<boolean>(false);
  const onCloseNotiCreditModal = () => {
    isNotiCreditModalOpen.value = false;
  };
  const onOpenNotiCreditModal = () => {
    isNotiCreditModalOpen.value = true;
  };
  const onCloseNotiCreditSuccess = () => {
    isNotiCreditSuccessOpen.value = false;
  };
  const onOpenNotiCreditSuccess = () => {
    isNotiCreditSuccessOpen.value = true;
  };
  const fetchDropDownCoupon = () => {
    api
      .apiRequest(fetchDropDownStatusList)
      .then((response) => {
        dropDownCouponStatus.value = [];
        response.data.records.forEach((value: any) => {
          dropDownCouponStatus.value.push({ label: '' + value.description, valueStatus: value.value, Seq: value.seq, Key: value.key });
        });
      })
      .catch((err) => {
        openErrorModal(
          err.data.error.locale[getLanguageDisplayModalError()].title as string,
          err.data.error.locale[getLanguageDisplayModalError()].message as string,
        );
      });
  };
  const fetchDropDownSource = () => {
    api
      .apiRequest(fetchDropDownSourceList)
      .then((response) => {
        dropDownSourceStatus.value = [];
        response.data.records.forEach((value: any) => {
          dropDownSourceStatus.value.push({ Seq: value.seq, Key: value.key, label: value.description, valueStatus: value.value });
        });
      })
      .catch((err) => {
        openErrorModal(
          err.data.error.locale[getLanguageDisplayModalError()].title as string,
          err.data.error.locale[getLanguageDisplayModalError()].message as string,
        );
      });
  };
  const fetchDropDownFormat = () => {
    api
      .apiRequest(fetchDropDownFormatList)
      .then((response) => {
        dropDownFormatStatus.value = [];
        response.data.records.forEach((value: any) => {
          dropDownFormatStatus.value.push({ Seq: value.seq, Key: value.key, label: value.description, valueStatus: value.value });
        });
      })
      .catch((err) => {
        openErrorModal(
          err.data.error.locale[getLanguageDisplayModalError()].title as string,
          err.data.error.locale[getLanguageDisplayModalError()].message as string,
        );
      });
  };
  const fetchDropCouponType = () => {
    api
      .apiRequest(fetchDropCouponTypeList)
      .then((response) => {
        dropCouponTypeStatus.value = [];
        response.data.records.forEach((value: any) => {
          dropCouponTypeStatus.value.push({ Seq: value.seq, Key: value.key, label: value.description, valueStatus: value.value });
        });
      })
      .catch((err) => {
        openErrorModal(
          err.data.error.locale[getLanguageDisplayModalError()].title as string,
          err.data.error.locale[getLanguageDisplayModalError()].message as string,
        );
      });
  };
  const fetchCouponSet = () => {
    loading.value = true;
    fetchCouponSetList.payload.search = textSearch.value;
    fetchCouponSetList.payload.Filters = payloadFilter.value ?? [];
    fetchCouponSetList.payload.Limit = pagination.perPage;
    fetchCouponSetList.payload.Page = pagination.currentPage;
    fetchCouponSetList.payload.Ordering = sortingResult.value;
    api
      .apiRequest(fetchCouponSetList)
      .then((response) => {
        couponListFilter.value = response.data.records;
        couponList.value = response.data.records;
        pagination.totalRows = response.data.filtered_records;
        keyChange.value++;
      })
      .catch((err) => {
        openErrorModal(
          err.data.error.locale[getLanguageDisplayModalError()].title as string,
          err.data.error.locale[getLanguageDisplayModalError()].message as string,
        );
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const convertDateTable = (date: string) => {
    const apiDate = new Date(date).toISOString();
    const addHours = new Date(apiDate).getTime() + 7 * 60 * 60 * 1000;
    const result = new Date(addHours).toISOString();
    return convertDate(result);
  };

  const countProgressBar = (balance: number, credit: number) => {
    const progressPercent: Ref<number> = ref(0);
    if (balance > credit) {
      progressPercent.value = 100;
    } else {
      progressPercent.value = (balance / credit) * 100;
    }
    switch (true) {
      case progressPercent.value == 0:
        return 0;
      case progressPercent.value <= 10:
        return 10;
      default:
        return progressPercent.value;
    }
  };

  const onSort = (sort: { key: string; direction: string }) => {
    const column = fields.value.filter((el) => {
      return el.key == sort.key;
    });
    if (column[0].sortable) {
      sortingResult.value = [
        {
          Seq: 1,
          Key: sort.key,
          Direction: sort.direction,
        },
      ];
      fetchCouponSet();
    }
  };

  const updateStatus = (coupon: Dataconnect.Couponlist.Coupon) => {
    isLoading(true);
    let payloadObj: Coupon.PayloadConfirmEditResult = null!;
    payloadObj = {
      coupon_id: coupon.coupon_id,
      status: coupon.status == 2 ? 3 : 2,
    };
    const update = editCouponSet;
    update.payload = window.GlobalRequest.CalculateFormDataObject(payloadObj);
    api
      .apiRequest(update)
      .catch((err) => {
        openErrorModal(
          err.data.error.locale[getLanguageDisplayModalError()].title as string,
          err.data.error.locale[getLanguageDisplayModalError()].message as string,
        );
      })
      .finally(() => {
        fetchCouponSet();
        isLoading(false);
      });
  };

  const onDetailCoupon = (coupon: Dataconnect.Couponlist.Coupon) => {
    return router.push({
      path: '/dataConnector/detailListCoupon',
      query: {
        ...router.currentRoute.value.query,
        couponId: coupon.coupon_id,
      },
    });
  };

  const exportReportCoupon = (coupon: Dataconnect.Couponlist.Coupon) => {
    isLoading(true);
    exportFileCouponSet.payload.CouponId = coupon.coupon_id;
    api
      .apiRequest(exportFileCouponSet)
      .then((response) => {
        const datesTime = new Date();
        saveAs(
          response,
          `CouponCode_Report_${
            coupon.name
          }_${datesTime.getFullYear()}${datesTime.getMonth()}${datesTime.getDate()}_${datesTime.getHours()}${datesTime.getMinutes()}${datesTime.getSeconds()}.csv`,
        );
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isLoading(false);
      });
  };

  const onSelectAction = (action: ActionList.Action, coupon: Dataconnect.Couponlist.Coupon) => {
    switch (action.key) {
      case 'detail':
        return router.push({
          path: '/dataConnector/detailListCoupon',
          query: {
            ...router.currentRoute.value.query,
            couponId: coupon.coupon_id,
          },
        });
      case 'export':
        return exportReportCoupon(coupon);
      default:
        return;
    }
  };
  function onSelectPagination(data: any) {
    pagination.currentPage = 1;
    pagination.perPage = data.valueStatus;
    keyChange.value++;
    fetchCouponSet();
  }

  function checkPermissionCoupon() {
    if (ability.can('portal-cdp', 'cdp-portal-dataconnector-downloadcoupon')) {
      actionList.push({ key: 'export', tooltip: t('dc.exportFile'), icon: Export, tooltipColor: '#0077FF', tooltipBackgroundColor: '#E5F2FF' });
    }
  }

  watch(
    () => [sourceStatus.value, formatStatus.value, couponTypeStatus.value, couponStatus.value],
    () => {
      const payloadFilterStatus: any = [];
      payloadFilter.value = null!;
      if (sourceStatus.value != null) {
        payloadFilterStatus.push({
          Seq: sourceStatus.value.Seq,
          Key: sourceStatus.value.Key,
          Value: sourceStatus.value.valueStatus,
        });
      }
      if (formatStatus.value != null) {
        payloadFilterStatus.push({
          Seq: formatStatus.value.Seq,
          Key: formatStatus.value.Key,
          Value: formatStatus.value.valueStatus,
        });
      }
      if (couponTypeStatus.value != null) {
        payloadFilterStatus.push({
          Seq: couponTypeStatus.value.Seq,
          Key: couponTypeStatus.value.Key,
          Value: couponTypeStatus.value.valueStatus,
        });
      }
      if (couponStatus.value != null) {
        payloadFilterStatus.push({
          Seq: couponStatus.value.Seq,
          Key: couponStatus.value.Key,
          Value: couponStatus.value.valueStatus,
        });
      }
      payloadFilter.value = payloadFilterStatus;
      pagination.currentPage = 1;
      fetchCouponSet();
    },
    { deep: true },
  );

  watch(
    () => pagination.currentPage,
    () => {
      fetchCouponSet();
    },
  );

  watch(
    () => textSearch.value,
    () => {
      pagination.currentPage = 1;
      fetchCouponSet();
    },
  );

  watch(
    () => router.currentRoute.value.query.ref,
    (afterCreate) => {
      if (afterCreate) {
        fetchCouponSet();
      }
    },
  );

  const onConfirmCancel = (exit: number) => {
    cancelCouponSet.payload.coupon_id = exit;
    api.apiRequest(cancelCouponSet).then((response) => {});
  };
  onMounted(() => {
    fetchDropDownCoupon();
    fetchDropDownSource();
    fetchDropDownFormat();
    fetchDropCouponType();
  });
  onBeforeMount(() => {
    // check permission
    checkPermissionCoupon();
    fetchCouponSet();
    const confirmExit = () => window.confirm('Sei sicuro di uscire? Le modifiche non salvate andranno perse');
    if (router.currentRoute.value.query.fileId) {
      window.addEventListener('beforeunload', (evt) => {
        if (!confirmExit()) {
          evt.preventDefault();
          evt.returnValue = true;
          onConfirmCancel(Number(router.currentRoute.value.query.fileId));
        }
      });
    }
  });

  return {
    couponListFilter,
    fields,
    loading,
    textSearch,
    couponStatus,
    dropDownCouponStatus,
    clearCouponStatus,
    pagination,
    actionList,
    dropDownPagination,
    paginationStatus,
    dropDownSourceStatus,
    dropDownFormatStatus,
    dropCouponTypeStatus,
    sourceStatus,
    formatStatus,
    couponTypeStatus,
    keyChange,
    onSelectAction,
    onSort,
    onDetailCoupon,
    convertDateTable,
    updateStatus,
    countProgressBar,
    onSelectPagination,
    isNotiCreditModalOpen,
    onCloseNotiCreditModal,
    onOpenNotiCreditModal,
    isNotiCreditSuccessOpen,
    onOpenNotiCreditSuccess,
    onCloseNotiCreditSuccess,
  };
}
