import { ref, getCurrentInstance } from 'vue';
import { useI18n } from 'vue-i18n';
// apis
import api from '@/services/api';
import templateModel from '@/models/communication/template';
import campaignModel from '@/models/communication/campaign';

// modal
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import mkaModalConfig from '@/constants/components/mka/mka-modal';

interface Props {
  selectedChannel: Communication.Channel.Component.AllChannelTab | null;
  isOpen: boolean;
  nodeProps: MKA_Nodes.NodesProps;
  isChangeTemplate: boolean;
}

interface Contents {
  title: string;
  content: string;
  content_editor: string;
}

interface TemplateInfo {
  name: string;
  type_desc: string;
  category_name: string;
}

export default function useActionEdmModal(props: Props) {
  const { openErrorModal, openDefaultErrorModal } = useValidationModal();
  const vm = getCurrentInstance()?.proxy;

  const { t } = useI18n();

  const nodeData = ref<MKA_Nodes.NodesProps>(props.nodeProps);

  const modalConfig = mkaModalConfig().nodeActionEdm;

  // constants
  const templateConstant: Communication.Template.Template = {
    brand_id: 0,
    category_id: 0,
    category_name: '',
    channel: 0,
    channel_desc: '',
    contents: [],
    created_by: 0,
    created_by_req: 0,
    created_dt: '',
    description: '',
    is_deleted: false,
    name: '',
    status: 0,
    status_desc: '',
    subtype: 0,
    subtype_desc: '',
    template_id: 0,
    thumbnail_sref: '',
    thumbnail_url: '',
    type: 0,
    type_desc: '',
    updated_by: '',
    updated_by_req: '',
    updated_dt: '',
  };

  const stepConstant = { content: 'Content', sender: 'Sender' };

  const nodeActionStep: {
    active: boolean;
    label: string;
  }[] = [
    {
      active: false,
      label: t('mka.content'),
    },
    {
      active: false,
      label: t('mka.sender'),
    },
  ];

  const nodeAction_Var: any = ref({
    mode: 2,
    templateId: [],
    providerId: null,
    contents: <Contents[]>[],
  });

  const templatePreview = ref<Communication.Template.Template>(templateConstant);
  const selectedTemplatePreview = ref<Communication.Template.Template>(templateConstant);

  const emailSubject = ref<string>('');

  // apis
  const { fetchProviderListModel } = campaignModel();
  const { fetchTemplateDetailModel } = templateModel();

  const modalPage = ref(modalConfig.modalPage.SELECT_TEMPLATE);

  const isSelectTemplateModalOpen = ref<boolean>(false);
  const isPreviewTemplateModalOpen = ref<boolean>(false);

  const isLoading = ref<boolean>(false);
  const providerList = ref<Communication.Provider.Provider[]>([]);

  const selectedProvider = ref<any>(null!);
  const selectedTemplateIndex = ref<number | null>(null);
  const templateInfo = ref<TemplateInfo>({ name: '', type_desc: '', category_name: '' });

  const content_Temp = ref<string>('');

  const action_SmsTemplateSelect_Connect: any = ref({});

  // functions begin here

  const action_SmsTemplateSelect_ConnectFunction = (connectFunction: any) => {
    action_SmsTemplateSelect_Connect.value = connectFunction;
  };

  const onCloseTemplateModal = () => {
    isSelectTemplateModalOpen.value = false;
  };

  const onClickPreviewTemplate = (template: Communication.Template.Template) => {
    action_SmsTemplateSelect_Connect.value.selectedTemplate = template;
    isPreviewTemplateModalOpen.value = true;
  };

  const onClosePreviewTemplateModal = () => {
    isPreviewTemplateModalOpen.value = false;
  };

  const fetchAllTemplate = (isPreviewMode = false) => {
    nodeAction_Var.value.templateId.map((templateId: number) => {
      fetchTemplateDetail(templateId, isPreviewMode);
    });
  };

  const fetchTemplateDetail = async (templateId: number, isPreviewMode = false): Promise<void> => {
    isLoading.value = true;
    fetchTemplateDetailModel.payload.TemplateId = templateId;

    return api
      .apiRequest(fetchTemplateDetailModel)
      .then((response) => {
        const responseData = response.data as Communication.Template.Template;

        templatePreview.value = responseData;
        if (isPreviewMode) {
          templatePreview.value.contents = props.nodeProps.payload.contents;
        }
        Promise.resolve();
      })
      .catch((err) => {
        openDefaultErrorModal(err);
        Promise.reject();
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  // Fetch all Providers
  const fetchProviderList = async (): Promise<void> => {
    isLoading.value = true;
    fetchProviderListModel.payload.Channel = 2;
    fetchProviderListModel.payload.Page = 1;
    fetchProviderListModel.payload.Limit = 100;

    return api
      .apiRequest(fetchProviderListModel)
      .then((response) => {
        providerList.value = response.data.records as Communication.Provider.Provider[];
        autoSelectProvider();
        Promise.resolve();
      })
      .catch((err) => {
        openDefaultErrorModal(err);
        Promise.reject();
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const autoSelectProvider = () => {
    if (nodeAction_Var.value.providerId != null) {
      const providerSelected: any = providerList.value.find((provider) => provider.providerid === nodeAction_Var.value.providerId);
      if (providerSelected != null) {
        selectedProvider.value = providerSelected;
      }
    }
  };

  function onSelectTemplate(template: Communication.Template.Template) {
    selectedTemplatePreview.value = template;
    templatePreview.value = template;
    setEmailSubject();
  }

  function setEmailSubject() {
    if (emailSubject.value.length) {
      if (templatePreview.value) {
        if (!props.isChangeTemplate) {
          templatePreview.value.contents[0].title = emailSubject.value;
        }
      }
    }
  }

  function onChangeStep(step: string) {
    if (step == stepConstant.content) {
      nodeActionStep[0].active = true;
      nodeActionStep[1].active = false;
    } else if (step == stepConstant.sender) {
      nodeActionStep[0].active = true;
      nodeActionStep[1].active = true;
    }
  }

  const nextStep = () => {
    if (!nodeAction_Var.value.templateId.length) {
      nodeAction_Var.value.contents = [];
    }

    modeSwitcher(modalConfig.modalPage.SELECT_PROVIDER);
    onChangeStep(stepConstant.sender);
    onClosePreviewTemplateModal();

    if (action_SmsTemplateSelect_Connect.value.selectedTemplate != null) {
      // template info
      setTemplateInfo(action_SmsTemplateSelect_Connect.value.selectedTemplate.template_id as number);

      const nodeActionSms_Var_Temp = nodeAction_Var.value;

      nodeActionSms_Var_Temp.templateId[0] = action_SmsTemplateSelect_Connect.value.selectedTemplate.template_id;
      // edit sms content
      content_Temp.value = action_SmsTemplateSelect_Connect.value.selectedTemplate.contents[0].content_editor ?? '';
      // set sms node content
      nodeActionSms_Var_Temp.contents[0] = {
        title: action_SmsTemplateSelect_Connect.value.selectedTemplate.contents[0].title ?? '',
        content: action_SmsTemplateSelect_Connect.value.selectedTemplate.contents[0].content ?? '',
        content_editor: action_SmsTemplateSelect_Connect.value.selectedTemplate.contents[0].content_editor ?? '',
      };

      action_SmsTemplateSelect_Connect.value.selectedTemplate.value = null;
      nodeAction_Var.value = nodeActionSms_Var_Temp;

      fetchAllTemplate();
    } else {
      fetchAllTemplate(true);
    }

    selectedTemplateIndex.value = null;
  };

  const modeSwitcher = (mode: string) => {
    if (mode == modalConfig.modalPage.PREVIEW) {
      modalPage.value = modalConfig.modalPage.PREVIEW;
      // set step
      onChangeStep(stepConstant.sender);
      selectedTemplateIndex.value = nodeData.value.payload.templateId.length ? nodeData.value.payload.templateId[0] : null;
    } else if (mode == modalConfig.modalPage.SELECT_PROVIDER) {
      modalPage.value = modalConfig.modalPage.SELECT_PROVIDER;
    }
  };

  const onChangeTemplate = () => {
    selectedTemplateIndex.value = null;
    modalPage.value = modalConfig.modalPage.SELECT_TEMPLATE;
    onChangeStep(stepConstant.content);
  };

  const onRemoveTemplate = () => {
    // set empty template
    selectedTemplatePreview.value = templateConstant;
    modalPage.value = modalConfig.modalPage.SELECT_TEMPLATE;
    if (!props.isChangeTemplate) {
      selectedProvider.value = null;
    }
    selectedTemplateIndex.value = null;
    onChangeStep(stepConstant.content);
  };

  async function setTemplateInfo(templateId: number, isPreviewMode = false) {
    await fetchTemplateDetail(templateId, isPreviewMode);
    // template info
    templateInfo.value.name = templatePreview.value.name ?? '';
    if (isPreviewMode) {
      templateInfo.value.name = props.nodeProps.info as string;
    }
    templateInfo.value.category_name = templatePreview.value.category_name ?? '';
    templateInfo.value.type_desc = templatePreview.value.type_desc ?? '';
  }

  const nodeActionEdmValidate = () => {
    const nodeActionSms_Var_Temp = nodeAction_Var.value;
    if (nodeActionSms_Var_Temp.mode === 1) {
    } else if (nodeActionSms_Var_Temp.mode === 2) {
      return nodeActionSms_Var_Temp.templateId.length > 0 && nodeActionSms_Var_Temp.providerId != null && nodeActionSms_Var_Temp.contents.length > 0;
    } else if (nodeActionSms_Var_Temp.mode === 3) {
    }
    return true;
  };

  // onLoad
  const onLoadNodeAction = async () => {
    selectedProvider.value = null!;
    selectedTemplateIndex.value = 0;
    modalPage.value = modalConfig.modalPage.SELECT_TEMPLATE;

    // set step
    onChangeStep(stepConstant.content);

    nodeAction_Var.value = nodeData.value.payload != null ? JSON.parse(JSON.stringify(nodeData.value.payload)) : {};
    fetchProviderList();

    // has template
    if (nodeData.value.payload.templateId.length) {
      modeSwitcher(modalConfig.modalPage.PREVIEW);
      await setTemplateInfo(nodeData.value.payload.templateId[0] as number, true);
      // load email subject
      if (nodeData.value.payload.contents.length) {
        const propsTitle = nodeData.value.payload.contents[0].title;
        emailSubject.value = propsTitle ? propsTitle : '';
        setEmailSubject();
        if (props.isChangeTemplate) {
          nextStep();
        }
      }
    }
  };

  // onSave
  const onSaveNodeAction = () => {
    if (nodeActionEdmValidate() == true) {
      nodeData.value.payload = nodeAction_Var.value || {};
      if (templatePreview.value) {
        setEmailSubject();
        nodeData.value.payload.contents = templatePreview.value.contents || {};
      }

      nodeData.value.info = templateInfo.value.name;
      return true;
    } else {
      openErrorModal(t('mka.modal_incorrect_information'), t('mka.modal_incorrect_information_detail'));
      return null;
    }
  };

  const onSelectProvider = (provider: Communication.Provider.Provider) => {
    selectedProvider.value = provider;
    nodeAction_Var.value.providerId = provider.providerid;
    vm?.$emit('select-sender', JSON.parse(JSON.stringify(selectedProvider.value)));
  };

  const onChangeAndEdit = () => {
    nextStep();
  };

  function onClickApply() {
    const finalValid = onSaveNodeAction();
    if (finalValid && selectedProvider.value && emailSubject.value.length) {
      vm?.$emit('apply-modal', nodeData.value);
    } else {
      openErrorModal(t('mka.modal_incorrect_information'), t('mka.modal_incorrect_information_detail'));
    }
  }

  function onClickApplyTemplateChange() {
    if (nodeActionEdmValidate() == true && selectedProvider.value && emailSubject.value.length) {
      const nodePayloadTemp = nodeAction_Var.value || {};
      const templatePreviewTemp = templatePreview.value;
      let nodePayloadContentsTemp = {};
      const emailSubjectTemp = emailSubject.value;
      if (templatePreview.value) {
        nodePayloadContentsTemp = templatePreview.value.contents || {};
      }
      const nodeInfoTemp = templateInfo.value.name;
      vm?.$emit('apply-template-change', nodePayloadTemp, templatePreviewTemp, nodePayloadContentsTemp, nodeInfoTemp, emailSubjectTemp);
    } else {
      openErrorModal('ข้อมูลไม่ถูกต้อง', 'กรุณาตรวจสอบข้อมูล');
    }
  }

  function onClickClose() {
    vm?.$emit('close-modal');
  }

  function onClickCancel() {
    vm?.$emit('cancel-modal');
  }

  return {
    action_SmsTemplateSelect_ConnectFunction,
    onCloseTemplateModal,
    onClickPreviewTemplate,
    onClosePreviewTemplateModal,
    onSelectTemplate,
    nextStep,

    // template selection
    onChangeTemplate,
    onRemoveTemplate,
    onSelectProvider,

    // modal cycle
    onLoadNodeAction,
    onClickApply,
    onClickClose,
    onClickCancel,
    setEmailSubject,
    onChangeAndEdit,
    onClickApplyTemplateChange,

    // variable
    modalPage,
    nodeActionStep,
    nodeAction_Var,
    modalConfig,
    selectedProvider,
    providerList,

    action_SmsTemplateSelect_Connect,
    isPreviewTemplateModalOpen,

    // template
    templateInfo,
    templatePreview,
    selectedTemplatePreview,
    emailSubject,
  };
}
