import { ref, Ref, watch, onBeforeMount, getCurrentInstance } from 'vue';
import { PATH } from '@/constants/modules/file-service/path';
import apiService from '@/services/api';
import fileServiceModel from '@/models/file-service/file-service';
import useLoading from '@/views/components/loading/hooks/useLoading';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import { useI18n } from 'vue-i18n';
interface Props {
  readOnly: boolean;
  cardMessageList: Communication.LineOA.Component.CardMessge[];
  validatedCardIndex?: number;
  templateMode?: string;
}

export default function useCreateCardMessage(props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const { uploadFileModel } = fileServiceModel();

  const { t } = useI18n();
  const { openWarningModal, openDefaultErrorModal, openErrorModal } = useValidationModal();
  const { isLoading } = useLoading();

  const activeCard: Ref<number> = ref(0);
  const selectedCard: Ref<Communication.LineOA.Component.CardMessge> = ref(null!);
  const isExpand: Ref<boolean> = ref(false);
  const isCardMessageLayoutModalOpen: Ref<boolean> = ref(false);
  const isInpuLabelFocused: Ref<boolean> = ref(false);
  const isInpuActionFocused: Ref<boolean> = ref(false);
  const currentColorPicker: Ref<string> = ref('');
  const actionList = ref([
    {
      key: 'uri',
      label: t('communication.url_link'),
      value: 1,
    },
    {
      key: 'none',
      label: 'None',
      value: 2,
    },
  ]);
  const cardMessageForm: any = ref({
    layout: null,
    enableImage: false,
    bannerUrl: '',
    bannerSref: '',
    enableLabel: false,
    label: {
      text: '',
      bgColor: '#007fff',
      fontColor: '#fff',
      fontStyleList: [],
      fontWeight: 'regular',
      fontItalic: 'normal',
      textDecoration: 'none',
      align: 'center',
      width: 0,
    },
    enableAction: false,
    actionType: 1,
    actionLabel: {
      text: '',
      bgColor: '#007fff',
      fontColor: '#fff',
      fontStyleList: [],
      fontWeight: 'regular',
      fontItalic: 'normal',
      textDecoration: 'none',
      borderRadius: 0,
      width: 0,
    },
    linkUrl: '',
  });

  function setCardActive(index: number) {
    activeCard.value = index;
    vm?.$emit('on-get-active-card', index);
  }

  const handleExpandCollapse = () => {
    isExpand.value = !isExpand.value;
    const configElem = document.querySelectorAll('[data-bs-toggle="collapse"]');
    if (isExpand.value == false) {
      for (let i = 0; i < configElem.length; i++) {
        configElem[i].classList.add('collapsed');
        configElem[i].querySelector('.collapse')?.classList.remove('show');
      }
    } else {
      for (let i = 0; i < configElem.length; i++) {
        configElem[i].classList.remove('collapsed');
        configElem[i].querySelector('.collapse')?.classList.add('show');
      }
    }
  };

  function onOpenCardMessageLayoutModal() {
    isCardMessageLayoutModalOpen.value = true;
  }

  function onCloseCardMessageLayoutModal() {
    isCardMessageLayoutModalOpen.value = false;
  }

  function onChangeLayout(layout: any) {
    vm?.$emit('on-change-card-layout', layout);
    cardMessageForm.value.layout = layout;
  }

  function resolveDisplayLayout() {
    const layout: any = cardMessageForm.value.layout;
    if (layout) {
      switch (layout.key) {
        case 'vertical':
          return 'vertical';
        case 'horizontal':
          return 'horizontal';
        case 'small-square':
          return 'small-square';
        case 'large-square':
          return 'large-square';
      }
    }
  }

  function addCardMessage() {
    vm?.$emit('add-card-message', cardMessageForm.value.layout);
  }

  function addClosingCard() {
    vm?.$emit('add-closing-card', cardMessageForm.value.layout);
  }

  function deleteCardMessage() {
    openWarningModal('ต้องการลบการ์ดนี้', 'ยืนยันการทำรายการ ใช่หรือไม่?', () => {
      vm?.$emit('on-delete-card', selectedCard.value);
      activeCard.value = 0;
    });
  }

  function clickUpload(e: Event) {
    const currentTarget = e.currentTarget as HTMLElement;
    const targetObject = currentTarget
      .closest('.line-card-editor-container')!
      .querySelector(`.input-upload-file-${selectedCard.value.key}`) as HTMLInputElement;
    targetObject.value = '';
    targetObject.click();
  }

  const isFileImage = (file: File) => file && file.type.split('/')[0] === 'image';

  const isMaxFileSize_10_MB = (file: File) => file && file.size <= 10485760;

  function handleUploadImageChange(e: Event, type: string) {
    const filesOBJ: File = (e.target as HTMLInputElement).files?.item(0) as File;
    if (filesOBJ) {
      if (isFileImage(filesOBJ)) {
        if (isMaxFileSize_10_MB(filesOBJ)) {
          isLoading(true, 'กำลังอัพโหลดรูปภาพ');
          vm?.$emit('uploading', true);
          uploadFileModel.payload.Path = props.templateMode ? PATH.SYSTEM.COMMU.LINE : PATH.COMMU.LINE;
          uploadFileModel.payload.File = filesOBJ;
          uploadFileModel.payload.Is_Temp = true;
          apiService
            .apiFormRequest(uploadFileModel)
            .then((response) => {
              const res = response.data as Communication.File.Request.Response.File;

              if (type == 'banner') {
                cardMessageForm.value.bannerUrl = res.public_url;
              } else if (type == 'custom-icon') {
                // cardMessageForm.value.customIconUrl = res.public_url;
              }
              cardMessageForm.value.bannerSref = res.sref;
              vm?.$emit(
                'on-upload-image-card-message',
                type == 'banner' ? cardMessageForm.value.bannerUrl : cardMessageForm.value.customIconUrl,
                cardMessageForm.value.bannerSref,
                selectedCard.value.key,
                res.file_name,
              );
            })
            .catch((err) => {
              openDefaultErrorModal(err);
            })
            .finally(() => {
              isLoading(false);
              vm?.$emit('uploading', false);
            });
        } else {
          openErrorModal(t('global.error'), 'ขนาดไฟล์ใหญ่เกินไป', '');
          clearFile(e, type);
        }
      } else {
        openErrorModal(t('global.error'), 'ไฟล์ผิดประเภท', '');
        clearFile(e, type);
      }
    }
  }

  const clearFile = (e: Event, type: string) => {
    if (cardMessageForm.value.bannerSref.length) {
      vm?.$emit('on-delete-file', cardMessageForm.value.bannerSref);
    }
    if (type == 'banner') {
      cardMessageForm.value.bannerUrl = '';
    } else if (type == 'custom-icon') {
      // cardMessageForm.value.customIconUrl = '';
    }
    (e.target as HTMLInputElement).value = '';
  };

  function handleToggleImage() {
    cardMessageForm.value.enableImage = !cardMessageForm.value.enableImage;
    if (cardMessageForm.value.enableImage == false) {
      cardMessageForm.value.bannerUrl = '';
    }
  }

  function handleToggleLabel() {
    cardMessageForm.value.enableLabel = !cardMessageForm.value.enableLabel;
    if (cardMessageForm.value.enableLabel == false) {
      cardMessageForm.value.label.text = '';
    }
  }

  function handleToggleAction() {
    cardMessageForm.value.enableAction = !cardMessageForm.value.enableAction;
    if (cardMessageForm.value.enableAction == false) {
      cardMessageForm.value.actionLabel.text = '';
      cardMessageForm.value.linkUrl = '';
    }
  }

  function onToggleColorPicker(isOpen: boolean, name: string) {
    if (isOpen) {
      currentColorPicker.value = name;
    } else {
      currentColorPicker.value = '';
    }
  }

  function onSelectFontStyle(fStyle: string, type: string) {
    switch (type) {
      case 'label':
        if (cardMessageForm.value.label.fontStyleList.includes(fStyle)) {
          cardMessageForm.value.label.fontStyleList = cardMessageForm.value.label.fontStyleList.filter((style: string) => style !== fStyle);
          if (fStyle == 'underline') {
            cardMessageForm.value.label.textDecoration = 'none';
          } else if (fStyle == 'bold') {
            cardMessageForm.value.label.fontWeight = 'regular';
          } else if (fStyle == 'italic') {
            cardMessageForm.value.label.fontItalic = 'normal';
          }
        } else {
          if (fStyle == 'underline') {
            cardMessageForm.value.label.textDecoration = 'underline';
          } else if (fStyle == 'bold') {
            cardMessageForm.value.label.fontWeight = 'bold';
          } else if (fStyle == 'italic') {
            cardMessageForm.value.label.fontItalic = 'italic';
          }
          cardMessageForm.value.label.fontStyleList.push(fStyle);
        }
        break;
      case 'action':
        if (cardMessageForm.value.actionLabel.fontStyleList.includes(fStyle)) {
          cardMessageForm.value.actionLabel.fontStyleList = cardMessageForm.value.actionLabel.fontStyleList.filter(
            (style: string) => style !== fStyle,
          );
          if (fStyle == 'underline') {
            cardMessageForm.value.actionLabel.textDecoration = 'none';
          } else if (fStyle == 'bold') {
            cardMessageForm.value.actionLabel.fontWeight = 'regular';
          } else if (fStyle == 'italic') {
            cardMessageForm.value.actionLabel.fontItalic = 'normal';
          }
        } else {
          if (fStyle == 'underline') {
            cardMessageForm.value.actionLabel.textDecoration = 'underline';
          } else if (fStyle == 'bold') {
            cardMessageForm.value.actionLabel.fontWeight = 'bold';
          } else if (fStyle == 'italic') {
            cardMessageForm.value.actionLabel.fontItalic = 'italic';
          }
          cardMessageForm.value.actionLabel.fontStyleList.push(fStyle);
        }
        break;
    }
  }

  function onChangeColor(color: string, name: string) {
    switch (name) {
      case 'label-bg':
        return (cardMessageForm.value.label.bgColor = color);
      case 'label-font':
        return (cardMessageForm.value.label.fontColor = color);
      case 'action-bg':
        return (cardMessageForm.value.actionLabel.bgColor = color);
      case 'action-font':
        return (cardMessageForm.value.actionLabel.fontColor = color);
    }
  }

  function onSelectAlignMent(align: string) {
    cardMessageForm.value.label.align = align;
  }

  function onInputLabelFocus(e: Event) {
    if (e.target == document.activeElement) {
      isInpuLabelFocused.value = true;
    } else {
      isInpuLabelFocused.value = false;
    }
  }

  function onInputActionFocus(e: Event) {
    if (e.target == document.activeElement) {
      isInpuActionFocused.value = true;
    } else {
      isInpuActionFocused.value = false;
    }
  }

  function preventCollapse(e: any) {
    const collapseElem = e.currentTarget.closest('.collapsed')?.querySelector('.collapsing');
    if (collapseElem) {
      const configElem = document.getElementById(`${collapseElem.getAttribute('id') as string}`);
      e.currentTarget.closest('.collapsed')?.classList.remove('collapsed');
      configElem?.classList.remove('collapsing');
      configElem?.classList.add('show');
    }
  }

  function handleSwitchActionType(actionType: number) {
    if (actionType === 2) {
      cardMessageForm.value.linkUrl = '';
    }
  }

  function initCardMessageForm() {
    if (selectedCard.value) {
      //banner
      cardMessageForm.value.layout = selectedCard.value.properties?.layout ?? null;
      cardMessageForm.value.enableImage = selectedCard.value.properties?.enableImage ?? false;
      cardMessageForm.value.bannerUrl = selectedCard.value.properties?.bannerUrl ?? '';
      cardMessageForm.value.bannerSref = selectedCard.value.properties?.bannerSref ?? '';
      cardMessageForm.value.enableLabel = selectedCard.value.properties?.enableLabel ?? false;
      cardMessageForm.value.label.text = selectedCard.value.properties?.label.text ?? '';
      cardMessageForm.value.label.bgColor = selectedCard.value.properties?.label.bgColor ?? '#0056ac';
      cardMessageForm.value.label.fontStyleList = selectedCard.value.properties?.label.fontStyleList ?? [];
      cardMessageForm.value.label.fontColor = selectedCard.value.properties?.label.fontColor ?? '#fff';
      cardMessageForm.value.label.fontWeight = selectedCard.value.properties?.label.fontWeight ?? 'regular';
      cardMessageForm.value.label.fontItalic = selectedCard.value.properties?.label.fontItalic ?? 'normal';
      cardMessageForm.value.label.textDecoration = selectedCard.value.properties?.label.textDecoration ?? 'none';
      cardMessageForm.value.label.align = selectedCard.value.properties?.label.align ?? 'center';
      cardMessageForm.value.label.width = selectedCard.value.properties?.label.width ?? 0;

      //action
      cardMessageForm.value.enableAction = selectedCard.value.properties?.enableAction ?? false;
      cardMessageForm.value.actionType = selectedCard.value.properties?.actionType ?? 1;
      cardMessageForm.value.actionLabel.text = selectedCard.value.properties?.actionLabel.text ?? '';
      cardMessageForm.value.actionLabel.bgColor = selectedCard.value.properties?.actionLabel.bgColor ?? '#007fff';
      cardMessageForm.value.actionLabel.fontColor = selectedCard.value.properties?.actionLabel.fontColor ?? '#fff';
      cardMessageForm.value.actionLabel.fontStyleList = selectedCard.value.properties?.actionLabel.fontStyleList ?? [];
      cardMessageForm.value.actionLabel.fontWeight = selectedCard.value.properties?.actionLabel.fontWeight ?? 'regular';
      cardMessageForm.value.actionLabel.fontItalic = selectedCard.value.properties?.actionLabel.fontItalic ?? 'normal';
      cardMessageForm.value.actionLabel.textDecoration = selectedCard.value.properties?.actionLabel.textDecoration ?? 'none';
      cardMessageForm.value.actionLabel.borderRadius = selectedCard.value.properties?.actionLabel.borderRadius ?? 0;
      cardMessageForm.value.actionLabel.width = selectedCard.value.properties?.actionLabel.width ?? 0;

      cardMessageForm.value.linkUrl = selectedCard.value.properties?.linkUrl ?? '';
    }
  }

  function scrollToSelectedCard() {
    if (selectedCard.value) {
      const selectedCardElem = document.getElementById(`card-message-${selectedCard.value.key}`);
      selectedCardElem?.scrollIntoView({ block: 'nearest', inline: 'center', behavior: 'smooth' });
    }
  }

  onBeforeMount(() => {
    selectedCard.value = props.cardMessageList[activeCard.value];
  });

  watch(
    activeCard,
    () => {
      selectedCard.value = props.cardMessageList[activeCard.value];
      initCardMessageForm();
      scrollToSelectedCard();
    },
    { immediate: true },
  );

  watch(
    () => props.cardMessageList,
    (oldVal, newVal) => {
      if (newVal !== oldVal) {
        selectedCard.value = props.cardMessageList[activeCard.value];
        initCardMessageForm();
      }
    },
    { deep: true },
  );

  watch(
    () => props.validatedCardIndex,
    () => {
      if (props.validatedCardIndex) {
        activeCard.value = props.validatedCardIndex;
      }
    },
    { immediate: true },
  );

  watch(
    cardMessageForm,
    () => {
      vm?.$emit(
        'on-change-card-message-properties',
        Object.assign(JSON.parse(JSON.stringify(cardMessageForm.value)), {
          index: activeCard.value,
          key: selectedCard.value.key,
          cardLabel: selectedCard.value.label,
        }),
      );
    },
    { deep: true },
  );

  return {
    activeCard,
    selectedCard,
    isExpand,
    isCardMessageLayoutModalOpen,
    cardMessageForm,
    actionList,
    isInpuLabelFocused,
    isInpuActionFocused,
    currentColorPicker,
    onSelectAlignMent,
    onSelectFontStyle,
    onToggleColorPicker,
    onChangeColor,
    onInputActionFocus,
    onInputLabelFocus,
    handleExpandCollapse,
    preventCollapse,
    onOpenCardMessageLayoutModal,
    onCloseCardMessageLayoutModal,
    onChangeLayout,
    setCardActive,
    addCardMessage,
    addClosingCard,
    deleteCardMessage,
    handleToggleLabel,
    handleToggleImage,
    handleToggleAction,
    clickUpload,
    resolveDisplayLayout,
    handleUploadImageChange,
    clearFile,
    handleSwitchActionType,
  };
}
