import { ref, Ref, reactive, getCurrentInstance, onMounted } from 'vue';
import apiService from '@/services/api';
import fileServiceModel from '@/models/file-service/file-service';
import useLoading from '@/views/components/loading/hooks/useLoading';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import { useI18n } from 'vue-i18n';
interface Props {
  selectedElement?: any;
}

export default function useLayoutTabPanel(props: Props) {
  const { t } = useI18n();
  const vm = getCurrentInstance()?.proxy;
  const { uploadFileModel } = fileServiceModel();
  const { openErrorModal, openDefaultErrorModal } = useValidationModal();
  const { isLoading } = useLoading();

  const selectedLayout: Ref<any> = ref('600px');
  const disabledBackground: Ref<boolean> = ref(true);
  const bodyColor: Ref<string> = ref('#FFFFFF');
  const spacingTop: Ref<number> = ref(10);
  const spacingBottom: Ref<number> = ref(10);
  const uploadBackground: any = reactive({ file: null, url: null, sref: null });
  const isColorPickerOpen: Ref<boolean> = ref(false);

  const buildLayoutObjProperties = () => {
    EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.EnableBackground = disabledBackground.value;
    EDMGetObjList('cdp-edm-editor')[props.selectedElement.id].Properties.Style = {
      width: selectedLayout.value,
      'background-color': bodyColor.value,
      'background-image': `url(${uploadBackground.url})`,
    };
    EDMRender('cdp-edm-editor');
    vm?.$emit('set-is-selected');
    vm?.$emit('handle-selected-element');
  };

  const handleChangeLayoutType = (value: string) => {
    selectedLayout.value = value;
    buildLayoutObjProperties();
  };

  const handleDisableBackground = () => {
    disabledBackground.value = !disabledBackground.value;
    if (disabledBackground.value == false) {
      bodyColor.value = '#FFFFFF';
      uploadBackground.url = '';
    }
    buildLayoutObjProperties();
  };

  const onToggleColorPicker = () => {
    isColorPickerOpen.value = !isColorPickerOpen.value;
  };

  const onChangeBodyColor = (color: string) => {
    bodyColor.value = color;
    buildLayoutObjProperties();
  };

  const handleUploadBackgroundChange = (e: any) => {
    const filesOBJ: File = e.target?.files[0];
    if (filesOBJ) {
      if (isFileImage(filesOBJ)) {
        if (isMaxFileSize_10_MB(filesOBJ)) {
          isLoading(true, 'กำลังอัพโหลดรูปภาพ');
          uploadFileModel.payload.File = filesOBJ;
          uploadFileModel.payload.Is_Temp = true;
          apiService
            .apiFormRequest(uploadFileModel)
            .then((response) => {
              const res = response.data;

              uploadBackground.url = res.public_url as Blob;
              uploadBackground.sref = res.sref;
              buildLayoutObjProperties();
            })
            .catch((err) => {
              openDefaultErrorModal(err);
            })
            .finally(() => {
              isLoading(false);
            });
        } else {
          openErrorModal(t('global.error'), 'ขนาดไฟล์ใหญ่เกินไป', '');
          clearFile(e);
        }
      } else {
        openErrorModal(t('global.error'), 'ไฟล์ผิดประเภท', '');
        clearFile(e);
      }
    }
  };

  const isFileImage = (file: File) => file && file.type.split('/')[0] === 'image';

  const isMaxFileSize_10_MB = (file: File) => file && file.size <= 10485760;

  const clearFile = (e?: any) => {
    uploadBackground.file = null;
    uploadBackground.url = null;
    e.value = null;
    buildLayoutObjProperties();
  };

  onMounted(() => {
    buildLayoutObjProperties();
  });

  return {
    uploadBackground,
    selectedLayout,
    disabledBackground,
    bodyColor,
    spacingTop,
    spacingBottom,
    isColorPickerOpen,
    onChangeBodyColor,
    onToggleColorPicker,
    handleDisableBackground,
    handleUploadBackgroundChange,
    handleChangeLayoutType,
    clearFile,
  };
}
