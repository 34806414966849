import { ref, Ref, getCurrentInstance, watch, onMounted, markRaw, nextTick } from 'vue';
import { arr_ascii, arr_unicode, arr_extraChars } from '@views/modules/communication/pages/main/constants/msgChars';
import { maxLength, htmlToString } from '@/utils/editor';
import { useI18n } from 'vue-i18n';
import MessageIcon from '@assets/icons/modules/communication/line-oa/MessageIcon.vue';
import StickerIcon from '@assets/icons/modules/communication/line-oa/StickerIcon.vue';
import ImageIcon from '@assets/icons/modules/communication/line-oa/ImageIcon.vue';
import VideoIcon from '@assets/icons/modules/communication/line-oa/VideoIcon.vue';
import RichMessageIcon from '@assets/icons/modules/communication/line-oa/RichMessageIcon.vue';
import RichVideoIcon from '@assets/icons/modules/communication/line-oa/RichVideoIcon.vue';
import CardMessageIcon from '@assets/icons/modules/communication/line-oa/CardMessageIcon.vue';
import MicIcon from '@assets/icons/modules/communication/line-oa/MicIcon.vue';
import TextImageIcon from '@assets/icons/modules/communication/line-oa/TextImageIcon.vue';
import PersonalizationIcon from '@assets/icons/modules/communication/line-oa/PersonalizationIcon.vue';
import CouponIcon from '@assets/icons/modules/communication/line-oa/CouponIcon.vue';
import EmojiIcon from '@assets/icons/modules/communication/template-tab/Emoji.vue';
import LinkIcon from '@assets/icons/modules/communication/line-oa/LinkIcon.vue';
import EmojiPicker from '@/views/components/emoji-picker/EmojiPicker.vue';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import { MESSAGE_TYPE } from '../../../constants/messageType';
import ConnectorIcon from '@assets/icons/modules/communication/edm-editor/ConnectorIcon.vue';
interface Props {
  readOnly: boolean;
  messageKey: number;
  messageType?: number;
  modelValue?: string;
  imageUrl?: string;
  videoUrl?: string;
  audioUrl?: string;
  cardMessageList?: Communication.LineOA.Component.CardMessge[];
  richMessage?: Communication.LineOA.Component.RichMessage;
  richVideoMessage?: Communication.LineOA.Component.RichVideoMessage;
  sref?: string;
  isSegmentSelected?: boolean;
  templateMode?: string;
}

export default function useLineEditor(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const { openWarningModal } = useValidationModal();
  const editor: Ref<HTMLDivElement> = ref(null!);
  const editorConverted: Ref<string> = ref('');
  const textCounter: Ref<number> = ref(0);
  const isSelectTemplateModalOpen: Ref<boolean> = ref(false);
  const isPreviewTemplateModalOpen: Ref<boolean> = ref(false);
  const selectedTemplate: Ref<Communication.Template.Template> = ref(null!);
  const maxLength = 400;
  const selectMenu: Ref<string> = ref('message');
  const selectionEditor: Ref<any> = ref(null!);
  const isEditorFocus: Ref<boolean> = ref(false);

  const { t } = useI18n();

  const menuOptions: LineEditor.Option[] = [
    { key: 'message', tooltip: t('communication.message_tooltip'), tooltipBackgroundColor: '#BBDBFF', icon: MessageIcon },
    // { key: 'sticker', tooltip: 'Sticker', tooltipBackgroundColor: '#BBDBFF', icon: StickerIcon },
    { key: 'image', tooltip: t('communication.image_tooltip'), tooltipBackgroundColor: '#BBDBFF', icon: ImageIcon },
    { key: 'video', tooltip: t('communication.video_tooltip'), tooltipBackgroundColor: '#BBDBFF', icon: VideoIcon },
    { key: 'rich-message', tooltip: t('communication.rich_message_tooltip'), tooltipBackgroundColor: '#BBDBFF', icon: RichMessageIcon },
    { key: 'rich-video-message', tooltip: t('communication.rich_video_message_tooltip'), tooltipBackgroundColor: '#BBDBFF', icon: RichVideoIcon },
    { key: 'card-message', tooltip: t('communication.card_message_tooltip'), tooltipBackgroundColor: '#BBDBFF', icon: CardMessageIcon },
    { key: 'voice-message', tooltip: t('communication.voice_message_tooltip'), tooltipBackgroundColor: '#BBDBFF', icon: MicIcon },
  ];

  const subMenuOptions: LineEditor.Option[] = [
    { key: 'template', tooltip: t('communication.template_tooltip'), tooltipBackgroundColor: '#BBDBFF', icon: TextImageIcon },
    { key: 'personalization', tooltip: t('communication.personalize_tooltip'), tooltipBackgroundColor: '#BBDBFF', icon: PersonalizationIcon },
    { key: 'coupon', tooltip: t('communication.coupon_tooltip'), tooltipBackgroundColor: '#BBDBFF', icon: CouponIcon },
    { key: 'emoji', tooltip: t('communication.emoji_tooltip'), tooltipBackgroundColor: '#BBDBFF', icon: EmojiIcon },
    { key: 'link', tooltip: t('communication.tracking_link_tooltip'), tooltipBackgroundColor: '#BBDBFF', icon: ConnectorIcon },
  ];

  const onKeyPress = (event: KeyboardEvent) => {
    if (textCounter.value >= maxLength) event.preventDefault();
  };

  const isInnerHtmlOnlyContainLineBreak = (str: string) => {
    const temp = htmlToString(str);
    editorConverted.value = temp;
    return editorConverted.value === '' || editorConverted.value.length == 0 || /^(?:\r\n|\r|\n)+$/.test(temp);
  };

  const onInput = () => {
    if (isInnerHtmlOnlyContainLineBreak(editor.value.innerHTML)) {
      editor.value.innerHTML = '';
    }

    const editorContainer = vm?.$refs['editor-container'] as HTMLDivElement;
    editorContainer.classList.remove('warning');
    textCounter.value = countText();
    return vm?.$emit('update:modelValue', editor.value.innerHTML);
  };

  const setContent = () => {
    const selection = window.getSelection();
    const range = document.createRange();

    if (props.modelValue) {
      selectMenu.value = 'message';
    } else if (props.imageUrl) {
      selectMenu.value = 'image';
    } else if (props.videoUrl) {
      selectMenu.value = 'video';
    } else if (props.audioUrl) {
      selectMenu.value = 'voice-message';
    } else if (props.cardMessageList) {
      selectMenu.value = 'card-message';
    } else if (props.richMessage) {
      selectMenu.value = 'rich-message';
    } else if (props.richVideoMessage) {
      selectMenu.value = 'rich-video-message';
    }

    if (selectMenu.value == 'message') {
      editor.value.innerHTML = props.modelValue || '';
      removeStyle();
      selection?.removeAllRanges();
      range.selectNodeContents(editor.value);
      range.collapse(false);
      selection?.addRange(range);
      editor.value.focus();
      textCounter.value = countText();

      if (isInnerHtmlOnlyContainLineBreak(editor.value.innerHTML)) {
        editor.value.innerHTML = '';
      }
    }
  };

  const removeStyle = () => {
    const editorEle: any = document.querySelectorAll('.content-editor');
    if (editorEle.length > 1) {
      for (let i = 0; i < editorEle.length; i++) {
        // Handle the `paste` event
        editorEle[i].onpaste = (e: any) => {
          e.stopPropagation();
          e.preventDefault();
          let text: string = e.clipboardData.getData('text/plain');
          text = text.replace(/\r\n/g, '\n');
          if (document.queryCommandSupported('insertText')) {
            document.execCommand('insertText', false, text);
          } else {
            document.execCommand('paste', false, text);
          }
        };
      }
    } else {
      editorEle[0].onpaste = (e: any) => {
        e.stopPropagation();
        e.preventDefault();

        let text: string = e.clipboardData.getData('text/plain');
        text = text.replace(/\r\n/g, '\n');
        if (document.queryCommandSupported('insertText')) {
          document.execCommand('insertText', false, text);
        } else {
          document.execCommand('paste', false, text.replace('/\x0D/g', '\\n'));
        }
      };
    }
  };

  const countText = (): number => {
    const msg = editor.value.innerText.replace(/\n/g, ' ');
    let unicodeFlag = false;
    let extraChars = 0;
    let msgCount = 0;

    if (msg.length > 0) {
      if (!unicodeFlag) {
        for (let i = 0; i < msg.length; i++) {
          if (arr_unicode.indexOf(msg.charAt(i)) !== -1) {
            unicodeFlag = true;
          } else if (arr_ascii.indexOf(msg.charAt(i)) !== -1) {
          } else if (arr_extraChars.indexOf(msg.charAt(i)) !== -1) {
          } else {
            unicodeFlag = true;
          }
        }
      }
      // Count extraChars
      if (unicodeFlag === false) {
        for (let j = 0; j < msg.length; j++) {
          if (arr_extraChars.indexOf(msg.charAt(j)) !== -1) {
            extraChars++;
          }
        }
      }

      msgCount = msg.length + extraChars;
    }

    return msgCount;
  };

  const onSelectEmoji = (emoji: string) => {
    const selection: any = window.getSelection();
    const range = selection.getRangeAt(0);
    range?.deleteContents();
    const insertEmoji = document.createTextNode(emoji);
    range?.insertNode(insertEmoji);
    range?.setStartAfter(insertEmoji);
    selection?.removeAllRanges();
    selection?.addRange(range);
    onInput();
  };

  function resolveEmojiPicker(menu: any) {
    switch (menu.key) {
      case 'emoji':
        return EmojiPicker;
      default:
        return menu.icon;
    }
  }

  const resolveEmits = (menu: any): object => {
    let itemEmits = {} as object;
    switch (menu.key) {
      case 'emoji':
        itemEmits = {
          ['select-emoji']: (emoji: string) => onSelectEmoji(emoji),
        };
    }
    return itemEmits;
  };

  const resolveSubMenuAction = (key: string) => {
    switch (key) {
      case 'template':
        return onOpenTemplateModal();
      case 'personalization':
        return isSegmentSelected();
      case 'coupon':
        return vm?.$emit('on-select-coupon');
      case 'emoji':
        return vm?.$emit('select-emoji');
      case 'link':
        return vm?.$emit('on-select-link');
    }
  };

  function resolveLineSubType() {
    switch (selectMenu.value) {
      case 'message':
        return [MESSAGE_TYPE.MIX, MESSAGE_TYPE.MESSAGE];
      case 'rich-message':
        return [MESSAGE_TYPE.RICH_MESSAGE];
      case 'card-message':
        return [MESSAGE_TYPE.CARD_MESSAGE];
      case 'rich-video-message':
        return [MESSAGE_TYPE.VIDEO];
    }
  }

  function resolveModalHeaderIcon() {
    switch (selectMenu.value) {
      case 'message':
        return MessageIcon;
      case 'rich-message':
        return RichMessageIcon;
      case 'card-message':
        return CardMessageIcon;
    }
  }

  function resolveModalHeaderTitle() {
    switch (selectMenu.value) {
      case 'message':
        return t('communication.message_tooltip');
      case 'rich-message':
        return t('communication.rich_message_tooltip');
      case 'card-message':
        return t('communication.card_message_tooltip');
    }
  }

  const onOpenTemplateModal = () => {
    isSelectTemplateModalOpen.value = true;
  };

  const onCloseTemplateModal = () => {
    isSelectTemplateModalOpen.value = false;
    selectedTemplate.value = null!;
  };

  const onClickPreviewTemplate = (template: Communication.Template.Template) => {
    selectedTemplate.value = template;
    isPreviewTemplateModalOpen.value = true;
  };

  const onClosePreviewTemplateModal = () => {
    isPreviewTemplateModalOpen.value = false;
    selectedTemplate.value = null!;
  };

  function onConfirmSelectTemplate(template: Communication.Template.Template) {
    if (template.contents.length > 1) {
      openWarningModal('ใช้ Template', 'content เดิมทั้งหมดจะถูกแทนที่ด้วย content ใน template ยืนยันหรือไม่', () => onSelectTemplate(template));
    } else {
      onSelectTemplate(template);
    }
  }

  const onSelectTemplate = (template: Communication.Template.Template) => {
    const selection = window.getSelection();
    const range = document.createRange();

    if (selectMenu.value == 'message') {
      editor.value.innerHTML = template.contents[0].content_editor || '';
      selection?.removeAllRanges();
      range.selectNodeContents(editor.value);
      range.collapse(false);
      selection?.addRange(range);
      editor.value.focus();
      textCounter.value = countText();
    }
    isSelectTemplateModalOpen.value = false;
    selectedTemplate.value = null!;
    const editorContainer = vm?.$refs['editor-container'] as HTMLDivElement;
    editorContainer.classList.remove('warning');

    vm?.$emit('use-message-template', template);
  };

  function onConfirmUseMessageTemplate() {
    if (selectedTemplate.value.contents.length > 1) {
      openWarningModal('ใช้ Template', 'content เดิมทั้งหมดจะถูกแทนที่ด้วย content ใน template ยืนยันหรือไม่', () => onUseMessageTemplate());
    } else {
      onUseMessageTemplate();
    }
  }

  const onUseMessageTemplate = () => {
    vm?.$emit('use-message-template', selectedTemplate.value);
    isPreviewTemplateModalOpen.value = false;
    isSelectTemplateModalOpen.value = false;
    selectedTemplate.value = null!;
    const editorContainer = vm?.$refs['editor-container'] as HTMLDivElement;
    editorContainer.classList.remove('warning');
  };

  function isSegmentSelected() {
    if (props.isSegmentSelected) vm?.$emit('on-select-personalize');
    else vm?.$emit('on-select-personalize-no-segment');
  }

  const handleDisableMenu = () => {
    if (selectMenu.value == 'message') {
      if (props.messageType === MESSAGE_TYPE.MESSAGE) {
        menuOptions.forEach((menu) => {
          if (props.messageType && (menu.key == 'rich-message' || menu.key == 'card-message')) {
            Object.assign(menu, {
              disabled: true,
            });
          }
        });
      }
      if (props.modelValue) {
        if (props.modelValue.length > 0) {
          return true;
        }
      }
      return false;
    } else if (selectMenu.value == 'image') {
      if (props.imageUrl) {
        return true;
      }
      return false;
    } else if (selectMenu.value == 'video') {
      if (props.videoUrl) {
        return true;
      }
      return false;
    } else if (selectMenu.value == 'voice-message') {
      if (props.audioUrl) {
        return true;
      }
      return false;
    } else if (selectMenu.value == 'card-message' && props.cardMessageList && props.cardMessageList.length > 0) {
      return true;
    } else if (selectMenu.value == 'rich-message' && props.richMessage) {
      return true;
    } else if (selectMenu.value == 'rich-video-message' && props.richVideoMessage) {
      return true;
    }

    return false;
  };

  function setSelection() {
    selectionEditor.value = saveSelection();
    vm?.$emit('get-cursor-position', selectionEditor.value);
  }

  function saveSelection() {
    selectionEditor.value = window.getSelection();
    return [selectionEditor.value.anchorNode, selectionEditor.value.anchorOffset, selectionEditor.value.focusNode, selectionEditor.value.focusOffset];
  }

  function cursorHandle(e: any) {
    if (e.keyCode) {
      setSelection();
      isEditorFocus.value = true;
    }
  }

  function onEditorFocusIn() {
    if (!props.readOnly) {
      isEditorFocus.value = true;
    }
  }

  function onEditorFocusOut() {
    setTimeout(() => {
      isEditorFocus.value = false;
    }, 200);
  }

  onMounted(() => {
    setContent();
    setSelection();
  });

  watch(
    () => props.modelValue,
    () => {
      const selection = window.getSelection();
      const range = document.createRange();
      if (props.modelValue !== editor.value.innerHTML) {
        if (selectMenu.value == 'message') {
          editor.value.innerHTML = props.modelValue || '';
          removeStyle();
          selection?.removeAllRanges();
          range.selectNodeContents(editor.value);
          range.collapse(false);
          selection?.addRange(range);
          editor.value.focus();
        }
      }
      textCounter.value = countText();
    },
  );

  watch(
    () => props.readOnly,
    () => {
      if (props.readOnly) {
        isEditorFocus.value = false;
      }
    },
  );

  return {
    editor,
    editorConverted,
    menuOptions,
    subMenuOptions,
    textCounter,
    isSelectTemplateModalOpen,
    isPreviewTemplateModalOpen,
    selectedTemplate,
    maxLength,
    selectMenu,
    isEditorFocus,
    resolveEmojiPicker,
    resolveEmits,
    onInput,
    onKeyPress,
    resolveSubMenuAction,
    onCloseTemplateModal,
    onClickPreviewTemplate,
    onClosePreviewTemplateModal,
    onConfirmSelectTemplate,
    onConfirmUseMessageTemplate,
    onUseMessageTemplate,
    onSelectTemplate,
    handleDisableMenu,
    setSelection,
    cursorHandle,
    onEditorFocusIn,
    onEditorFocusOut,
    resolveLineSubType,
    resolveModalHeaderIcon,
    resolveModalHeaderTitle,
    onOpenTemplateModal,
    removeStyle,
  };
}
