import {
  Ref,
  ref,
  onBeforeMount,
  reactive,
  getCurrentInstance,
  ComponentPublicInstance,
  toRaw,
  onMounted,
  watch,
  onBeforeUnmount,
  nextTick,
} from 'vue';
import Sortable from 'sortablejs';
import html2canvas from 'html2canvas';
import router from '@/router';
import { useI18n } from 'vue-i18n';

// utils
import { maxLength, htmlToString } from '@/utils/editor';
import { isValidHttpUrl } from '@/views/modules/communication/utils/richVideoMessage';

import apiService from '@/services/api';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import templateModel from '@/models/communication/template';
import fileServiceModel from '@/models/file-service/file-service';
import { buildCardMessageImagePayload } from '@views/modules/communication/utils/lineCardMessageBuilder';
import { buildRichMessagePayload } from '@views/modules/communication/utils/richMessageBuilder';

import { PATH } from '@/constants/modules/file-service/path';
import { Channel as ChannelConstant } from '@views/modules/communication/pages/main/constants/channel';
import { DEFAULT_CARDMESSAGE_PROPERTIES, DEFAULT_CLOSING_CARD } from '@views/modules/communication/pages/line-oa-campaign/constants/cardMessage';
import { RICH_MESSAGE_TEMPLATE } from '@views/modules/communication/pages/line-oa-campaign/constants/richMessage';
import { MESSAGE_TYPE } from '@/views/modules/communication/pages/line-oa-campaign/constants/messageType';
import { richVideoMapperTagEnum } from '@/constants/modules/commu/rich-video';

interface Props {
  mode?: 'create' | 'edit';
}

export default function useLineTemplateForm(props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const { t } = useI18n();
  const { createTemplateModel, fetchTemplateDetailModel, editTemplateModel } = templateModel();
  const { uploadFileModel, editFileModel, getFileModel } = fileServiceModel();
  //message
  const messageList: Ref<Communication.LineOA.Component.LineMessage[]> = ref([]);
  //card message
  const cardMessageList: Ref<Communication.LineOA.Component.CardMessge[]> = ref([]);
  const validatedCardIndex: Ref<number> = ref(0);
  const thumbnail: Record<string, string> = reactive({ url: '', sref: '' });
  //rich message
  const richMessage: Ref<Communication.LineOA.Component.RichMessage> = ref(null!);
  //rich video message
  const contentRichVideoMessage: Ref<Communication.LineOA.Component.ContentRichVideoMessage> = ref({
    type: 'imagemap',
    baseUrl: '{{mp:-1}}',
    altText: 'default-alt',
    baseSize: {
      width: 1040,
      height: 585,
    },
    video: {
      originalContentUrl: '',
      previewImageUrl: '{{mp:-1}}',
      area: {
        x: 0,
        y: 0,
        width: 1040,
        height: 585,
      },
      externalLink: {
        linkUri: '',
        label: t('communication.rv_learn_more'),
      },
    },
    actions: [
      {
        type: 'uri',
        linkUri: '{{mp:-2}}',
        area: {
          x: 0,
          y: 0,
          width: 1040,
          height: 585,
        },
      },
    ],
  });
  const contentEditorRichVideoMessage: Ref<Communication.LineOA.Component.RichVideoMessage> = ref({
    type: 'imagemap-video',
    sref: '',
    isAction: true,
    actionButtonLabel: {
      dropdown: {
        key: 1,
        label: t('communication.rv_learn_more'),
        customLabel: '',
      },
      url: '',
      mapper: {
        mapperType: 10,
        subType: 2,
        tag: '{{mp:-2}}',
        linkValue: '',
      },
    },
    video: {
      type: 'video',
      originalContentUrl: '',
      previewImageUrl: '',
      sref: '',
      width: 0,
      height: 0,
      mapper: {
        mapperType: 12,
        subType: 2,
        tag: '{{mp:-1}}',
        linkValue: '',
      },
    },
    mapper: [
      {
        mapperType: 12,
        subType: 2,
        tag: '{{mp:-1}}',
        linkValue: '',
      },
      {
        mapperType: 10,
        subType: 2,
        tag: '{{mp:-2}}',
        linkValue: '',
      },
    ],
  });
  //validate rich video
  const validateRichVideo: Ref<Communication.LineOA.Component.ValidateRichVideo> = ref({
    trigger: 0,
    video: true,
    actionButton: true,
    linkUrl: true,
  });

  const keyChange: Ref<number> = ref(1);
  const isLoading: Ref<boolean> = ref(false);
  const isCategoryModalOpen: Ref<boolean> = ref(false);
  const srefList: Ref<string[]> = ref([]);
  const isUploading: Ref<boolean> = ref(false);
  const isUpdated: Ref<boolean> = ref(true);
  const interval: Ref<ReturnType<typeof setInterval>> = ref(null!);
  //personalize
  const mapper: Ref<Campaign.Personalize[]> = ref([]);
  const selectedButton: Ref<any> = ref(null!);
  const lineEditorSelection: Ref<any> = ref(null);
  const tagNumber: Ref<number> = ref(-1);
  const isPersonalizeModalOpen: Ref<boolean> = ref(false);
  const isSegmentSelected: Ref<boolean> = ref(false);
  const personalizeObjColumn: Ref<Campaign.Personalize> = ref(null!);
  const personalizeEditMode: Ref<boolean> = ref(false);
  //coupon
  const isCouponCodeModalOpen: Ref<boolean> = ref(false);
  const couponObjColumn: Ref<Campaign.CouponColumn> = ref(null!);
  const couponEditMode: Ref<boolean> = ref(false);
  //tracking link
  const isTrackingLinkModalOpen: Ref<boolean> = ref(false);
  const trackingLinkObj: Ref<Communication.MapperModel.TrackingLink> = ref(null!);
  const trackingLinkEditMode: Ref<boolean> = ref(false);
  const selectedTrackingLink: Ref<HTMLSpanElement> = ref(null!);

  const fileName: Ref<string> = ref(null!);

  const { openSuccessModal, openDefaultErrorModal } = useValidationModal();

  const templateInfo = reactive({
    templateName: '',
    templateTitle: '',
    messageType: 0,
    channel: 3,
    categoryId: 0,
    templateId: 0,
    updatedTime: '',
  });

  const createTemplate = async () => {
    if (templateInfo.messageType == MESSAGE_TYPE.RICH_MESSAGE || templateInfo.messageType == MESSAGE_TYPE.CARD_MESSAGE) {
      await convertHtmlToBase64(thumbnail);
    }
    createTemplateModel.payload.CategoryId = templateInfo.categoryId;
    createTemplateModel.payload.Channel = templateInfo.channel as Communication.Template.Request.ChannelType;
    createTemplateModel.payload.Name = templateInfo.templateName;
    createTemplateModel.payload.Type = 2;
    createTemplateModel.payload.SubType = templateInfo.messageType ?? null;
    createTemplateModel.payload.Contents = (await resolveLineSubType()) as Communication.Template.Request.SaveContent[];
    createTemplateModel.payload.Thumbnail_Url = thumbnail.url;
    createTemplateModel.payload.Thumbnail_Sref = thumbnail.sref;
    createTemplateModel.payload.Deleted_File_Sref = srefList.value;
    isLoading.value = true;

    apiService
      .apiRequest(createTemplateModel)
      .then(() => {
        openSuccessModal('ดำเนินการสำเร็จ', '', '', undefined, () =>
          router.push({
            path: '/communication/template/list',
            query: {
              channel: templateInfo.channel,
              category: templateInfo.categoryId,
              mode: ChannelConstant.lineOA,
              type: 2,
              messageType: templateInfo.messageType,
            },
          }),
        );
      })
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => (isLoading.value = false));
  };

  const editTemplate = async () => {
    if (templateInfo.messageType == MESSAGE_TYPE.RICH_MESSAGE || templateInfo.messageType == MESSAGE_TYPE.CARD_MESSAGE) {
      await convertHtmlToBase64(thumbnail);
    }

    editTemplateModel.payload.TemplateId = templateInfo.templateId;
    editTemplateModel.payload.CategoryId = templateInfo.categoryId;
    editTemplateModel.payload.Channel = templateInfo.channel as Communication.Template.Request.ChannelType;
    editTemplateModel.payload.Type = 2;
    editTemplateModel.payload.SubType = templateInfo.messageType ?? null;
    editTemplateModel.payload.Name = templateInfo.templateName;
    editTemplateModel.payload.Contents = (await resolveLineSubType()) as Communication.Template.Request.SaveContent[];
    editTemplateModel.payload.Thumbnail_Url = thumbnail.url;
    editTemplateModel.payload.Thumbnail_Sref = thumbnail.sref;
    editTemplateModel.payload.Deleted_File_Sref = srefList.value;
    isLoading.value = true;

    apiService
      .apiRequest(editTemplateModel)
      .then(() =>
        openSuccessModal('ดำเนินการสำเร็จ', '', '', undefined, () =>
          router.push({
            path: '/communication/template/list',
            query: {
              channel: templateInfo.channel,
              category: templateInfo.categoryId,
              mode: ChannelConstant.lineOA,
              type: 2,
              messageType: templateInfo.messageType,
            },
          }),
        ),
      )
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => (isLoading.value = false));
  };

  async function saveTemplate() {
    if (templateInfo.messageType == MESSAGE_TYPE.RICH_MESSAGE || templateInfo.messageType == MESSAGE_TYPE.CARD_MESSAGE) {
      await convertHtmlToBase64(thumbnail);
    }
    editTemplateModel.payload.TemplateId = templateInfo.templateId;
    editTemplateModel.payload.CategoryId = templateInfo.categoryId;
    editTemplateModel.payload.Channel = templateInfo.channel as Communication.Template.Request.ChannelType;
    editTemplateModel.payload.Type = 2;
    editTemplateModel.payload.SubType = templateInfo.messageType ?? null;
    editTemplateModel.payload.Name = templateInfo.templateName;
    editTemplateModel.payload.Contents = (await resolveLineSubType()) as Communication.Template.Request.SaveContent[];
    editTemplateModel.payload.Thumbnail_Url = thumbnail.url;
    editTemplateModel.payload.Deleted_File_Sref = srefList.value;
    isLoading.value = true;

    apiService
      .apiRequest(editTemplateModel)
      .then(() => {
        fetchTemplateDetail();
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isUpdated.value = true;
        isLoading.value = false;
      });
  }

  const fetchTemplateDetail = () => {
    fetchTemplateDetailModel.payload.TemplateId = templateInfo.templateId;
    isLoading.value = true;
    srefList.value = [];

    apiService
      .apiRequest(fetchTemplateDetailModel)
      .then((response) => {
        const responseData = response.data as Communication.Template.Template;

        templateInfo.categoryId = responseData.category_id;
        templateInfo.channel = responseData.channel;
        templateInfo.templateId = responseData.template_id;
        templateInfo.templateName = responseData.name;
        templateInfo.updatedTime = responseData.updated_dt;
        templateInfo.messageType = responseData.subtype;
        templateInfo.templateTitle = responseData.contents[0].title ?? '';
        thumbnail.url = responseData.thumbnail_url;
        thumbnail.sref = responseData.thumbnail_sref;

        // get image name from thumbnail url (for mode edit)
        if (thumbnail.sref && thumbnail.url) {
          fetchFileDetail();
        }

        switch (templateInfo.messageType) {
          case 30:
            return resolveDisplayMessage(responseData.contents);
          case 31:
            return resolveDisplayMessage(responseData.contents);
          case 32:
            return resolveDisplayRichMessage(responseData.contents);
          case 33:
            validatedCardIndex.value = 0;
            return resolveDisplayCard(responseData.contents);
          case 34:
            return resolveRichVideoContentEditor(responseData.contents);
        }
      })
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => {
        isLoading.value = false;
        isUpdated.value = true;
        resolveTagNumber();
        addActionEditToPersonalizeButton();
      });
  };

  const fetchFileDetail = () => {
    getFileModel.payload.Sref = thumbnail.sref;

    apiService
      .apiRequest(getFileModel)
      .then((response) => {
        const res = response.data as Communication.File.Request.Response.File;
        fileName.value = res.file_name;
      })
      .catch((err) => openDefaultErrorModal(err));
  };

  function resolveLineSubType() {
    switch (templateInfo.messageType) {
      case 30:
        return resolveMessageContent();
      case 31:
        return resolveMessageContent();
      case 32:
        return resolveRichMessageContent();
      case 33:
        return resolveCardMessageContent();
      case 34:
        return resolveRichVideoContent();
    }
  }

  const onReorderMessage = (event: Sortable.SortableEvent) => {
    messageList.value.splice(event.newDraggableIndex as number, 0, messageList.value.splice(event.oldDraggableIndex as number, 1)[0]);
    messageList.value.forEach((message) => {
      message.key = keyChange.value;
      keyChange.value++;
    });
  };

  // function setPreviewMessageSize() {
  //   nextTick(() => {
  //     const previewCardContainer = document?.querySelectorAll('.preview-card-message') as any;
  //     if (previewCardContainer.length) {
  //       for (let i = 0; i < previewCardContainer.length; i++) {

  //         previewCardContainer[i].style.height = `${previewCardContainer[i].offsetHeight * 0.45}px`;
  //       }
  //     }
  //   });
  // }

  const addMessage = () => {
    const displayContent = { key: keyChange.value, sender: '' };
    messageList.value.push(displayContent);
    keyChange.value++;
  };

  const onDeleteMessage = (removeMessage: Communication.LineOA.Component.LineMessage) => {
    messageList.value = messageList.value.filter((message) => message.key !== removeMessage.key);
    if (removeMessage.sref != undefined && removeMessage.sref.length) {
      onDeleteFile(removeMessage.sref as string);
    }
  };

  //#region Line Message
  const resolveDisplayMessage = (content: Communication.Template.TemplateContent[]) => {
    const resolvedList: Communication.LineOA.Component.LineMessage[] = [];
    if (content && content.length) {
      content.forEach((content) => {
        const contentOBJ = JSON.parse(content.content as string);
        let contentEditor: any;
        if (isJsonString(content.content_editor as string) && content.content_editor) {
          contentEditor = JSON.parse(content.content_editor || '');
        } else {
          contentEditor = content.content_editor || '';
        }
        let displayContent: Communication.LineOA.Component.LineMessage = { key: keyChange.value };
        if (contentOBJ.type == 'text') {
          mapper.value = toRaw(mapper.value).concat(contentEditor.mapper ? (contentEditor.mapper as Campaign.Personalize) : []);
          displayContent = {
            key: keyChange.value,
            sender: '',
            message: contentEditor.text ? decodeURI(contentEditor.text as string) : contentEditor,
          };
        } else if (contentOBJ.type == 'image') {
          displayContent = {
            key: keyChange.value,
            sender: '',
            imageUrl: contentEditor.originalContentUrl || contentEditor,
            previewImageUrl: contentOBJ.previewImageUrl,
            sref: contentOBJ.sref,
          };
        } else if (contentOBJ.type == 'video') {
          displayContent = {
            key: keyChange.value,
            sender: '',
            videoUrl: contentEditor.originalContentUrl || contentEditor,
            previewImageUrl: contentOBJ.previewImageUrl,
            sref: contentOBJ.sref,
          };
        } else if (contentOBJ.type == 'audio') {
          displayContent = {
            key: keyChange.value,
            sender: '',
            audioUrl: contentEditor.originalContentUrl || contentEditor,
            duration: contentOBJ.duration,
            sref: contentOBJ.sref,
          };
        } else if (contentOBJ.type == 'flex') {
          mapper.value = toRaw(mapper.value).concat(contentEditor.mapper ? (contentEditor.mapper as Campaign.Personalize) : []);
          displayContent = {
            key: keyChange.value,
            sender: '',
            cardMessageList: contentEditor.list_card || [],
            sref: contentOBJ.sref,
            thumbnail: contentEditor.thumbnail,
            altText: contentOBJ.altText,
          };
        } else if (contentOBJ.type == 'imagemap' && contentOBJ.video) {
          // type rich-video-message
          mapper.value = toRaw(mapper.value).concat(contentEditor.mapper ? (contentEditor.mapper as Campaign.Personalize) : []);
          displayContent = {
            key: keyChange.value,
            sender: '',
            richVideoMessage: contentEditor || [],
            sref: contentOBJ.sref,
            thumbnail: contentEditor.thumbnail,
            altText: contentOBJ.altText,
          };
        } else if (contentOBJ.type == 'imagemap') {
          mapper.value = toRaw(mapper.value).concat(contentEditor.mapper ? (contentEditor.mapper as Campaign.Personalize) : []);
          displayContent = {
            key: keyChange.value,
            sender: '',
            richMessage:
              {
                ...contentEditor,
                gridType:
                  contentEditor.gridType !== 'custom-grid'
                    ? RICH_MESSAGE_TEMPLATE.find((message) => message.key == contentEditor.gridType)
                    : { key: 'custom-grid' },
              } || null,
            sref: contentOBJ.sref,
            altText: contentOBJ.altText,
          };
        } else {
          displayContent = { key: keyChange.value, sender: '' };
        }
        resolvedList.push(displayContent);
        keyChange.value++;
      });
    } else {
      resolvedList.push({ key: keyChange.value, sender: '' });
      keyChange.value++;
    }
    return (messageList.value = resolvedList);
  };

  const resolveMessageContent = async (): Promise<Communication.Template.Request.SaveContent[]> => {
    if (messageList.value && messageList.value.length) {
      let thumbnailIndex = 0;

      const resolveMessageList: Communication.Template.Request.SaveContent[] = [];
      for (const message of messageList.value) {
        const resolvedMessage = htmlToString(replacePersonalizeTag(message.message as string, true));
        let resolvedContent = {};
        if (message.message) {
          resolvedContent = { type: 'text', text: resolvedMessage };
        } else if (message.imageUrl) {
          resolvedContent = { type: 'image', originalContentUrl: message.imageUrl, previewImageUrl: message.previewImageUrl, sref: message.sref };
        } else if (message.videoUrl) {
          resolvedContent = {
            type: 'video',
            originalContentUrl: message.videoUrl,
            previewImageUrl: message.previewImageUrl,
            sref: message.sref,
          };
        } else if (message.audioUrl) {
          resolvedContent = { type: 'audio', originalContentUrl: message.audioUrl, duration: message.duration, sref: message.sref };
        } else if (message.richMessage) {
          assignRichMessageMapper(message.richMessage);
          resolvedContent = JSON.parse(
            replaceRichMessageUrl(JSON.stringify(buildRichMessagePayload(message.richMessage, message.altText)), message.richMessage),
          );
        } else if (message.cardMessageList && message.cardMessageList.length) {
          message.thumbnail = {
            url: message?.thumbnail?.url ?? '',
            sref: message?.thumbnail?.sref ?? '',
          };
          await convertHtmlToBase64(message.thumbnail, thumbnailIndex);
          thumbnailIndex++;

          assignCardMessageMapper(message.cardMessageList);
          const cardMessageImageType = JSON.parse(
            replaceCardMessageUrl(JSON.stringify(buildCardMessageImagePayload(message.cardMessageList)), message.cardMessageList),
          );
          const srefCardMessage: string[] = [];
          message.cardMessageList.map((card) => {
            card.sref?.map((sref) => {
              if (!srefCardMessage.includes(sref)) {
                srefCardMessage.push(sref);
              }
            });
          });

          resolvedContent = {
            type: 'flex',
            altText: message.altText,
            sref: srefCardMessage,
            contents: cardMessageImageType,
          };
        } else if (message.richVideoMessage) {
          const contentEditor = message.richVideoMessage;
          contentRichVideoMessage.value.video.originalContentUrl = contentEditor.video.originalContentUrl;

          contentRichVideoMessage.value.video.previewImageUrl = contentEditor.mapper[richVideoMapperTagEnum.video].tag || '';
          contentRichVideoMessage.value.baseUrl = contentEditor.mapper[richVideoMapperTagEnum.video].tag || '';
          contentRichVideoMessage.value.actions[0].linkUri = contentEditor.mapper[richVideoMapperTagEnum.linkUrl]?.tag || '';

          if (contentEditor.isAction) {
            // case แสดงปุ่ม
            if (contentRichVideoMessage.value.video.externalLink) {
              contentRichVideoMessage.value.video.externalLink.linkUri = contentEditor.mapper[richVideoMapperTagEnum.linkUrl]?.tag || '';
              if (contentEditorRichVideoMessage.value.actionButtonLabel.dropdown.key == 0) {
                contentRichVideoMessage.value.video.externalLink.label = contentEditorRichVideoMessage.value.actionButtonLabel.dropdown.customLabel;
              } else {
                contentRichVideoMessage.value.video.externalLink.label = contentEditorRichVideoMessage.value.actionButtonLabel.dropdown.label;
              }
            }
          } else {
            // case ซ่อนปุ่ม
            contentRichVideoMessage.value.actions[0].linkUri = contentEditor.mapper[richVideoMapperTagEnum.video].tag!;
            delete contentRichVideoMessage.value.video.externalLink;
          }

          const widthVideo = contentEditor.video.width;
          const heightVideo = contentEditor.video.height;
          contentRichVideoMessage.value.baseSize.width = widthVideo;
          contentRichVideoMessage.value.baseSize.height = heightVideo;

          contentRichVideoMessage.value.video.area.width = widthVideo;
          contentRichVideoMessage.value.video.area.height = heightVideo;

          contentRichVideoMessage.value.actions[0].area.width = widthVideo;
          contentRichVideoMessage.value.actions[0].area.height = heightVideo;

          contentRichVideoMessage.value.altText = message.altText ?? '';

          resolvedContent = contentRichVideoMessage.value;
        }

        const messageContent = {
          Content: JSON.stringify(resolvedContent),
          Content_Editor: JSON.stringify(resolveContentEditor(message)),
          Title: message.altText || 'default title',
        };
        resolveMessageList.push(messageContent);
      }

      return resolveMessageList;
    }

    return [];
  };

  const resolveContentEditor = (message: Communication.LineOA.Component.LineMessage) => {
    if (message.message) {
      return { type: 'text', text: encodeURI(message.message), mapper: filterMapper(message.message) };
    } else if (message.imageUrl) {
      return { type: 'image', originalContentUrl: message.imageUrl, previewImageUrl: message.previewImageUrl, sref: message.sref };
    } else if (message.videoUrl) {
      return {
        type: 'video',
        originalContentUrl: message.videoUrl,
        previewImageUrl: message.previewImageUrl,
        sref: message.sref,
      };
    } else if (message.audioUrl) {
      return { type: 'audio', originalContentUrl: message.audioUrl, duration: message.duration, sref: message.sref };
    } else if (message.richMessage) {
      return {
        ...message.richMessage,
        type: 'imagemap',
        gridType: message.richMessage.gridType.key,
        mapper: filterRichMessageMapper(message.richMessage),
      };
    } else if (message.cardMessageList && message.cardMessageList.length) {
      return {
        type: 'flex',
        list_card: message.cardMessageList,
        thumbnail: message.thumbnail,
        mapper: filterCardMessageMapper(message.cardMessageList),
      };
    } else if (message.richVideoMessage) {
      return message.richVideoMessage;
    }
  };
  //#endregion Line Message

  //#region Card Message
  function resolveDisplayCard(content: Communication.Template.TemplateContent[]) {
    const resolvedList: Communication.LineOA.Component.CardMessge[] = [];

    if (content && content.length) {
      content.forEach((content) => {
        let contentEditor: any;
        if (isJsonString(content.content_editor as string) && content.content_editor) {
          contentEditor = JSON.parse(content.content_editor || '');
        } else {
          contentEditor = content.content_editor || '';
        }
        contentEditor.list_card.forEach((card: Communication.LineOA.Component.CardMessge) => {
          resolvedList.push(card);
        });
        keyChange.value++;
      });
    } else {
      resolvedList.push(
        {
          key: keyChange.value,
          properties: {
            layout: {
              key: 'small-square',
              size: 'mega',
              width: '375',
              height: '250',
              preview_height: '338',
              ratio: '1.11:1',
            },
            enableImage: true,
            bannerUrl: '',
            enableLabel: true,
            label: {
              text: 'Label',
              bgColor: '#0056ac',
              fontColor: '#ffffff',
              fontStyleList: null,
              fontWeight: 'regular',
              fontItalic: 'normal',
              textDecoration: 'none',
              align: 'center',
            },
            enableAction: true,
            actionType: 1,
            actionLabel: {
              text: 'Action',
              bgColor: '#007fff',
              fontColor: '#ffffff',
              fontStyleList: null,
              fontWeight: 'regular',
              fontItalic: 'normal',
              textDecoration: 'none',
              borderRadius: 30,
            },
            linkUrl: '',
          },
        },
        {
          key: keyChange.value + 1,
          label: 'Closing Card',
          properties: {
            layout: {
              key: 'small-square',
              size: 'mega',
              width: '375',
              height: '250',
              preview_height: '338',
              ratio: '1.11:1',
            },
            enableImage: true,
            bannerUrl: '',
            enableLabel: false,
            label: {
              text: 'Label',
              bgColor: '#000',
              fontColor: '#000',
              fontStyleList: null,
              fontWeight: 'regular',
              fontItalic: 'normal',
              textDecoration: 'none',
              align: 'center',
            },
            enableAction: true,
            actionType: 1,
            actionLabel: {
              text: 'Lead More',
              bgColor: '#ffffff',
              fontColor: '#006CC9',
              fontStyleList: null,
              fontWeight: 'regular',
              fontItalic: 'normal',
              textDecoration: 'none',
              borderRadius: 30,
            },
            linkUrl: '',
          },
        },
      );
      keyChange.value++;
    }
    return (cardMessageList.value = resolvedList);
  }

  function onChangeCardMesssageProperties(cardObj: any) {
    const updatedCardObj = { key: cardObj.key, label: cardObj.cardLabel, sref: cardObj.sref, properties: cardObj };
    cardMessageList.value[cardObj.index] = updatedCardObj;
  }

  function onDeleteCard(removeCard: Communication.LineOA.Component.CardMessge) {
    cardMessageList.value = cardMessageList.value.filter((message) => message.key != removeCard.key);

    if (removeCard.sref != undefined && removeCard.sref.length) {
      removeCard.sref.forEach((sref: string) => {
        onDeleteFile(sref);
      });
    }
    cardMessageList.value.forEach((card, index) => {
      card.key = index + 1;
    });
    if (cardMessageList.value.length) {
      validatedCardIndex.value = 0;
    }
  }

  function addCardMessage(layout: any) {
    const displayContent = {
      key: cardMessageList.value.length + 1,
      properties: DEFAULT_CARDMESSAGE_PROPERTIES,
    };

    if (layout) {
      displayContent.properties.layout = layout;
    }
    cardMessageList.value.forEach((card, index) => {
      card.key = index + 1;
    });
    if (cardMessageList.value.find((card) => card.label == 'Closing Card')) {
      cardMessageList.value.splice(cardMessageList.value.length - 1, 0, displayContent);
      // validatedCardIndex.value = cardMessageList.value.length - 2;
    } else {
      cardMessageList.value.push(displayContent);
    }
    validatedCardIndex.value = cardMessageList.value.length - 1;
    keyChange.value++;
  }

  function addClosingCard(layout: any) {
    const closingCardContent = { key: cardMessageList.value.length + 1, label: 'Closing Card', properties: DEFAULT_CLOSING_CARD };
    if (layout) {
      closingCardContent.properties.layout = layout;
    }
    cardMessageList.value.push(closingCardContent);
    validatedCardIndex.value = cardMessageList.value.length - 1;

    keyChange.value++;
  }

  function onChangeCardLayout(layout: any) {
    cardMessageList.value.forEach((card) => {
      card.properties!.layout = layout;
    });
  }

  function onUploadImageCardMessage(url: string, sref: string, key: number, file_name: string) {
    const cardMessage: Communication.LineOA.Component.CardMessge | undefined = cardMessageList.value.find((card) => card.key == key);
    if (cardMessage) {
      cardMessage.properties!.bannerUrl = url;
      cardMessage.properties!.bannerSref = sref;
      keyChange.value++;
    }
    cardMessage!.properties!.bannerUrl = '';
    cardMessage!.properties!.bannerSref = '';
    fileName.value = file_name;
  }

  function resolveCardMessageContent(): Communication.Template.Request.SaveContent[] {
    if (cardMessageList.value && cardMessageList.value.length) {
      assignCardMessageMapper(cardMessageList.value);
      const cardMessageImageType = buildCardMessageImagePayload(cardMessageList.value);
      const resolveCardMessageList = [
        {
          Content: replaceCardMessageUrl(
            JSON.stringify({
              type: 'flex',
              altText: templateInfo.templateTitle,
              contents: cardMessageImageType,
            }),
            cardMessageList.value,
          ),
          Content_Editor: JSON.stringify({
            type: 'flex',
            list_card: filterCardMessageListCard(cardMessageList.value),
            mapper: filterCardMessageMapper(cardMessageList.value),
            thumbnail: thumbnail,
          }),
          Title: templateInfo.templateTitle,
        },
      ];
      return resolveCardMessageList;
    }
    return [];
  }

  function assignCardMessageMapper(cardMessage: Communication.LineOA.Component.CardMessge[]) {
    if (cardMessage[0].properties?.enableImage && cardMessage[0].properties?.bannerUrl) {
      const cardTrackingOpenTemplate: Communication.MapperModel.TrackingOpen = {
        mapperType: 12,
        subType: 2,
        linkValue: cardMessage[0].properties?.bannerUrl,
        tag: `{{mp:${tagNumber.value}}}`,
      };
      cardMessage[0].mapperTrackingOpen = cardTrackingOpenTemplate;
      tagNumber.value--;
    } else {
      const url =
        process.env.NODE_ENV === 'development'
          ? 'https://devcdpdata.blob.core.windows.net/0-public/Commu/Tracking/tracker.png'
          : 'https://cdpdatav2.blob.core.windows.net/0-public/Commu/Tracking/tracker.png';
      const cardTrackingOpenTemplate: Communication.MapperModel.TrackingOpen = {
        mapperType: 12,
        subType: 2,
        linkValue: url,
        tag: `{{mp:${tagNumber.value}}}`,
      };
      cardMessage[0].mapperTrackingOpen = cardTrackingOpenTemplate;
      tagNumber.value--;
    }
    cardMessage.forEach((card) => {
      if (card.properties?.enableAction && card.properties?.linkUrl) {
        const cardTrackingLinkTemplate: Communication.MapperModel.TrackingLink = {
          mapperType: 10,
          subType: 2,
          linkValue: card.properties?.linkUrl,
          tag: `{{mp:${tagNumber.value}}}`,
        };
        card.mapper = cardTrackingLinkTemplate;
        tagNumber.value--;
      }
    });
  }

  function replaceCardMessageUrl(content: string, cardMessage: Communication.LineOA.Component.CardMessge[]) {
    if (cardMessage[0].mapperTrackingOpen) {
      content = content.replace(cardMessage[0].mapperTrackingOpen.linkValue as string, cardMessage[0].mapperTrackingOpen.tag as string);
    }
    cardMessage.forEach((card) => {
      if (card.mapper) {
        content = content.replace(
          (isUrlValid(card.properties?.linkUrl as string) ? card.properties?.linkUrl : 'https://' + card.properties?.linkUrl) as string,
          card.mapper.tag as string,
        );
      }
    });

    return content;
  }

  function filterCardMessageMapper(cardMessage: Communication.LineOA.Component.CardMessge[]) {
    const currentMapper: any = [];
    if (cardMessage[0].mapperTrackingOpen) currentMapper.push(cardMessage[0].mapperTrackingOpen);
    cardMessage.forEach((card) => {
      if (card.mapper) {
        card.mapper.linkValue = card.mapper.linkValue.trim();
        currentMapper.push(card.mapper);
      }
    });

    return currentMapper;
  }

  function filterCardMessageListCard(cardMessage: Communication.LineOA.Component.CardMessge[]) {
    const currentListCard: any = [];
    cardMessage.forEach((card) => {
      if (card.properties?.linkUrl) {
        card.properties.linkUrl = card.properties.linkUrl.trim();
      }
      if (card.mapper?.linkValue) {
        card.mapper.linkValue = card.mapper.linkValue.trim();
      }
      currentListCard.push(card);
    });
    return currentListCard;
  }

  function resolveCardMessageMapperOnUseTemplate(content: string, cardMessage: Communication.LineOA.Component.CardMessge[]) {
    const cardMessageObj = cardMessage as any;
    if (cardMessageObj.mapper && cardMessageObj.mapper.length > 0) {
      //source id - dest id เหมือน data blueprint backend
      const listSrcId: number[] = [];
      cardMessageObj.mapper.forEach((mapper: Communication.MapperModel.TrackingLink | Communication.MapperModel.TrackingOpen) => {
        listSrcId.push(mapper.tag?.split(/(\d+)/)[1] as unknown as number);
      });
      listSrcId.sort(function (a, b) {
        return a - b;
      });
      let tmpTagNumber = tagNumber.value;
      tmpTagNumber -= listSrcId.length - 1;
      listSrcId
        .slice()
        .reverse()
        .forEach((id) => {
          const target = `{{mp:-${id}}}`;
          content = content.replaceAll(target, `{{mp:${tmpTagNumber++}}}`);
        });
    }

    return JSON.parse(content);
  }
  //#endregion Card Message

  //#region Rich Message
  function resolveDisplayRichMessage(content: Communication.Template.TemplateContent[]) {
    let resolvedContent: Communication.LineOA.Component.RichMessage = null!;

    if (content && content.length) {
      content.forEach((content) => {
        let contentEditor: any;
        if (isJsonString(content.content_editor as string) && content.content_editor) {
          contentEditor = JSON.parse(content.content_editor || '');
        } else {
          contentEditor = content.content_editor || '';
        }
        resolvedContent = {
          ...contentEditor,
          gridType:
            contentEditor.gridType !== 'custom-grid'
              ? RICH_MESSAGE_TEMPLATE.find((message) => message.key == contentEditor.gridType)
              : { key: 'custom-grid' },
        };
        keyChange.value++;
      });
    } else {
      resolvedContent = {
        gridType: RICH_MESSAGE_TEMPLATE[0],
        gridList: [{ key: keyChange.value, label: 'A', properties: { actionType: 1, linkUrl: '', actionLabel: '' } }],
        image: { url: '', width: 0, height: 0 },
      };
      keyChange.value++;
    }
    return (richMessage.value = resolvedContent);
  }

  function onChangeGridTemplate(template: any) {
    richMessage.value.gridType = template;

    tagNumber.value = -1;
    function resolveGridList(numberOfGrid: number) {
      const gridList: any = [];
      const alphabetList = Array.from(Array(numberOfGrid)).map((e, i) => i + 65);
      alphabetList.map((alphabet) => {
        gridList.push({
          key: keyChange.value,
          label: String.fromCharCode(alphabet),
          properties: { actionType: 1, linkUrl: '', actionLabel: '' },
        });
        keyChange.value++;
      });
      return gridList;
    }

    switch (richMessage.value.gridType.key) {
      case 'custom-grid':
        return (richMessage.value.gridList = resolveGridList(1));
      case 'one-grid':
        return (richMessage.value.gridList = resolveGridList(1));
      case 'two-grid-vertical':
        return (richMessage.value.gridList = resolveGridList(2));
      case 'two-grid-horizontal':
        return (richMessage.value.gridList = resolveGridList(2));
      case 'three-grid-horizontal':
        return (richMessage.value.gridList = resolveGridList(3));
      case 'three-grid-one-to-two':
        return (richMessage.value.gridList = resolveGridList(3));
      case 'three-grid-two-to-one':
        return (richMessage.value.gridList = resolveGridList(3));
      case 'four-grid':
        return (richMessage.value.gridList = resolveGridList(4));
      case 'six-grid':
        return (richMessage.value.gridList = resolveGridList(6));
    }
  }

  function onUploadImageRichMessage(url: string, sref: string, file_name: string) {
    if (url && sref) {
      richMessage.value.sref = sref;
      richMessage.value.image.url = url;
      const img = new Image();
      img.src = richMessage.value.image.url;
      img.onload = () => {
        richMessage.value.image.width = img.width;
        richMessage.value.image.height = img.height;
      };
    }

    if (file_name) {
      fileName.value = file_name;
    }
  }

  function resolveRichMessageContent(): Communication.LineOA.CreateCampaign.Request.SaveContent[] {
    if (richMessage.value && richMessage.value.gridList.length) {
      assignRichMessageMapper(richMessage.value);
      trimRichMessageURL(richMessage.value);
      const resolvedRichMessage = [
        {
          Title: templateInfo.templateTitle,
          Content: replaceRichMessageUrl(JSON.stringify(buildRichMessagePayload(richMessage.value, templateInfo.templateTitle)), richMessage.value),
          Content_Editor: JSON.stringify({
            ...richMessage.value,
            type: 'imagemap',
            gridType: richMessage.value.gridType.key,
            mapper: filterRichMessageMapper(richMessage.value),
          }),
        },
      ];
      return resolvedRichMessage;
    }
    return [];
  }

  function trimRichMessageURL(data: any) {
    data.gridList?.forEach((item: any) => {
      if (item.properties?.linkUrl) {
        item.properties.linkUrl = item.properties.linkUrl.trim();
      }

      if (item.mapper?.linkValue) {
        item.mapper.linkValue = item.mapper.linkValue.trim();
      }
    });

    data.mapper?.forEach((item: any) => {
      if (item.linkValue) {
        item.linkValue = item.linkValue.trim();
      }
    });

    return data;
  }

  function assignRichMessageMapper(richMessage: Communication.LineOA.Component.RichMessage) {
    //image
    if (richMessage.image.url) {
      const imageTrackingOpenTemplate: Communication.MapperModel.TrackingOpen = {
        mapperType: 12,
        subType: 2,
        tag: `{{mp:${tagNumber.value}}}`,
        linkValue: `${richMessage.image.url}&q=imagemap`,
      };
      richMessage.image.mapper = imageTrackingOpenTemplate;
      tagNumber.value--;
    }
    // gird
    richMessage.gridList.forEach((grid) => {
      if (grid.properties?.linkUrl) {
        const gridTrackingLinkTemplate: Communication.MapperModel.TrackingLink = {
          mapperType: 10,
          subType: 2,
          linkValue: grid.properties?.linkUrl,
          tag: `{{mp:${tagNumber.value}}}`,
        };
        grid.mapper = gridTrackingLinkTemplate;
        tagNumber.value--;
      }
    });
  }

  function replaceRichMessageUrl(content: string, richMessage: Communication.LineOA.Component.RichMessage) {
    if (richMessage.image.mapper) {
      content = content.replace(`${richMessage.image.url}&q=imagemap`, richMessage.image.mapper?.tag as string);
    }
    richMessage.gridList.forEach((grid) => {
      if (grid.mapper) {
        content = content.replace(
          (isUrlValid(grid.properties?.linkUrl as string) ? grid.properties?.linkUrl : 'https://' + grid.properties?.linkUrl) as string,
          grid.mapper?.tag as string,
        );
      }
    });

    return content;
  }

  function filterRichMessageMapper(richMessage: Communication.LineOA.Component.RichMessage) {
    const currentMapper = [];

    if (richMessage.image.mapper) currentMapper.push(richMessage.image.mapper);
    richMessage.gridList.forEach((grid) => {
      if (grid.mapper) currentMapper.push(grid.mapper);
    });

    return currentMapper;
  }

  function resolveRichMessageMapperOnUseTemplate(content: string, richMessage: Communication.LineOA.Component.RichMessage) {
    const richMessageObj = richMessage as any;
    if (richMessageObj.mapper && richMessageObj.mapper.length > 0) {
      //source id - dest id เหมือน data blueprint backend
      const listSrcId: number[] = [];
      richMessageObj.mapper.forEach((mapper: Communication.MapperModel.TrackingLink | Communication.MapperModel.TrackingOpen) => {
        listSrcId.push(mapper.tag?.split(/(\d+)/)[1] as unknown as number);
      });
      listSrcId.sort(function (a, b) {
        return a - b;
      });
      let tmpTagNumber = tagNumber.value;
      tmpTagNumber -= listSrcId.length - 1;
      listSrcId
        .slice()
        .reverse()
        .forEach((id) => {
          const target = `{{mp:-${id}}}`;
          content = content.replaceAll(target, `{{mp:${tmpTagNumber++}}}`);
        });
    }

    return JSON.parse(content);
  }
  //#endregion Rich Message

  //#region Rich Video
  function resolveRichVideoContentEditor(content: Communication.Template.TemplateContent[]) {
    let resolvedContent: Communication.LineOA.Component.RichVideoMessage = null!;

    if (content && content.length) {
      content.forEach((content) => {
        let contentEditor: any;
        if (isJsonString(content.content_editor as string) && content.content_editor) {
          contentEditor = JSON.parse(content.content_editor || '');
        } else {
          contentEditor = content.content_editor || '';
        }
        resolvedContent = { ...contentEditor };
        keyChange.value++;
      });
    } else {
      keyChange.value++;
    }

    return (contentEditorRichVideoMessage.value = resolvedContent);
  }

  function resolveRichVideoContent(): Communication.Template.Request.SaveContent[] {
    let richVideoMessageContent = [
      {
        Content: '',
        Content_Editor: '',
        Title: templateInfo.templateTitle,
      },
    ];
    const contentEditor = contentEditorRichVideoMessage.value;

    contentRichVideoMessage.value.video.originalContentUrl = contentEditor.video.originalContentUrl;
    contentRichVideoMessage.value.video.previewImageUrl = contentEditor.mapper[richVideoMapperTagEnum.video].tag!;
    contentRichVideoMessage.value.baseUrl = contentEditor.mapper[richVideoMapperTagEnum.video].tag!;
    contentRichVideoMessage.value.actions[0].linkUri = contentEditor.mapper[richVideoMapperTagEnum.linkUrl]?.tag || '';

    if (contentEditor.isAction) {
      // case แสดงปุ่ม
      if (contentRichVideoMessage.value.video.externalLink) {
        contentRichVideoMessage.value.video.externalLink.linkUri = contentEditor.mapper[richVideoMapperTagEnum.linkUrl]?.tag || '';
        if (contentEditorRichVideoMessage.value.actionButtonLabel.dropdown.key == 0) {
          contentRichVideoMessage.value.video.externalLink.label = contentEditorRichVideoMessage.value.actionButtonLabel.dropdown.customLabel;
        } else {
          contentRichVideoMessage.value.video.externalLink.label = contentEditorRichVideoMessage.value.actionButtonLabel.dropdown.label;
        }
      }
    } else {
      // case ซ่อนปุ่ม
      contentRichVideoMessage.value.actions[0].linkUri = contentEditor.mapper[richVideoMapperTagEnum.video].tag!;
      delete contentRichVideoMessage.value.video.externalLink;
    }

    const widthVideo = contentEditorRichVideoMessage.value.video.width;
    const heightVideo = contentEditorRichVideoMessage.value.video.height;
    const maxWidthVideo = 1040;
    const calcuteHeight = Math.ceil(maxWidthVideo * (heightVideo / widthVideo));
    contentRichVideoMessage.value.baseSize.width = maxWidthVideo;
    contentRichVideoMessage.value.baseSize.height = calcuteHeight;

    contentRichVideoMessage.value.video.area.width = maxWidthVideo;
    contentRichVideoMessage.value.video.area.height = calcuteHeight;

    contentRichVideoMessage.value.actions[0].area.width = maxWidthVideo;
    contentRichVideoMessage.value.actions[0].area.height = calcuteHeight;

    contentEditorRichVideoMessage.value.video.width = maxWidthVideo;
    contentEditorRichVideoMessage.value.video.height = calcuteHeight;

    contentEditorRichVideoMessage.value.actionButtonLabel.url = contentEditorRichVideoMessage.value.actionButtonLabel.url.trim();

    contentRichVideoMessage.value.altText = templateInfo.templateTitle;

    richVideoMessageContent[0].Content = JSON.stringify(contentRichVideoMessage.value);
    richVideoMessageContent[0].Content_Editor = JSON.stringify(contentEditorRichVideoMessage.value);
    return richVideoMessageContent;
  }

  function onUploadVideo(video: Communication.LineOA.Component.VideoDetail) {
    thumbnail.url = contentEditorRichVideoMessage.value.video.previewImageUrl;
    thumbnail.sref = contentEditorRichVideoMessage.value.video.sref;
  }
  //#region End Rich Video

  const validateForm = (): boolean => {
    let invalidCounter = 0;

    if (!templateInfo.templateName.trim()) {
      const nameContainer = vm?.$refs['line-template-name'] as HTMLDivElement;
      const nameInput = nameContainer.querySelector('.line-oa-template-name') as HTMLInputElement;
      invalidCounter++;

      nameContainer.classList.add('warning');
      nameInput.focus();
    }

    if ([MESSAGE_TYPE.RICH_MESSAGE, MESSAGE_TYPE.CARD_MESSAGE, MESSAGE_TYPE.VIDEO].includes(templateInfo.messageType)) {
      if (!templateInfo.templateTitle.trim()) {
        const titleContainer = vm?.$refs['line-template-title'] as HTMLDivElement;
        const titleInput = titleContainer.querySelector('.line-oa-template-title') as HTMLInputElement;
        invalidCounter++;

        titleContainer.classList.add('warning');
        titleInput.focus();
      }
    }

    if (templateInfo.messageType == MESSAGE_TYPE.MESSAGE || templateInfo.messageType == MESSAGE_TYPE.MIX) {
      const createTab = vm?.$refs['create-tab'] as ComponentPublicInstance;
      messageList.value.forEach((message, index) => {
        if (
          (!message.message?.length &&
            !message.imageUrl &&
            !message.videoUrl &&
            !message.audioUrl &&
            !message.richMessage &&
            !message.cardMessageList?.length &&
            !message.richVideoMessage) ||
          (message.message && htmlToString(message.message).length > maxLength)
        ) {
          invalidCounter++;
          const lineEditor = createTab.$refs[`line-message-editor-${index}`] as ComponentPublicInstance[];
          const editorContainer = lineEditor[0].$refs['editor-container'] as HTMLDivElement;
          editorContainer.classList.add('warning');
        }
      });
    } else if (templateInfo.messageType == MESSAGE_TYPE.RICH_MESSAGE) {
      richMessage.value?.gridList.forEach((rich, index) => {
        if (
          !richMessage.value.image.url.length ||
          !rich.properties?.linkUrl ||
          !rich.properties?.actionLabel ||
          !rich.properties?.linkUrl.trim().length
        ) {
          invalidCounter++;
          validateRichMessageInput();
        }
      });
    } else if (templateInfo.messageType == MESSAGE_TYPE.CARD_MESSAGE) {
      cardMessageList.value.forEach((card, index) => {
        if (card.properties?.actionType == 1) {
          if (
            (!card.properties?.bannerUrl?.length && card.properties?.enableImage) ||
            (!card.properties?.label.text.length && card.properties?.enableLabel) ||
            (!card.properties?.actionLabel.text.length && card.properties?.enableAction) ||
            (!card.properties?.linkUrl.trim().length && card.properties?.enableAction)
          ) {
            invalidCounter++;
            validateCardMessageInput();
          }
        } else {
          if (
            (!card.properties?.bannerUrl?.length && card.properties?.enableImage) ||
            (!card.properties?.label.text.length && card.properties?.enableLabel) ||
            (!card.properties?.actionLabel.text.length && card.properties?.enableAction)
          ) {
            invalidCounter++;
            validateCardMessageInput();
          }
        }
      });
    } else if (templateInfo.messageType == MESSAGE_TYPE.VIDEO) {
      validateRichVideo.value.trigger++;

      if (!templateInfo.templateTitle.trim()) {
        return false;
      }

      /* return !Object.values(validateRichVideo.value).some((status) => status === false); */
      if (!contentEditorRichVideoMessage.value.video.originalContentUrl) {
        // console.log('case video no url');
        return false;
      }
      if (contentEditorRichVideoMessage.value.isAction == false) {
        // console.log('case is action = false');
        return true;
      }
      if (
        contentEditorRichVideoMessage.value.actionButtonLabel.dropdown.key == 0 &&
        !contentEditorRichVideoMessage.value.actionButtonLabel.dropdown.customLabel
      ) {
        // console.log('case กำหนดเอง แต่ไม่ใส่ข้อมูล');
        return false;
      }
      if (!contentEditorRichVideoMessage.value.actionButtonLabel.url) {
        // console.log('case ไม่ใส่ url');
        return false;
      }
      if (!isValidHttpUrl(contentEditorRichVideoMessage.value.actionButtonLabel.url)) {
        return false;
      }
    }

    return invalidCounter == 0;
  };

  function onGetActiveCard(index: number) {
    validatedCardIndex.value = index;
  }

  function validateCardMessageInput() {
    const cardMessageTab = vm?.$refs['create-card-message-tab'] as ComponentPublicInstance;
    const editorSection = cardMessageTab.$refs['card-editor-section'] as HTMLDivElement;
    let validatedCard: any = null;
    function expandConfig() {
      const configElem = document.querySelectorAll('[data-bs-toggle="collapse"]');
      for (let i = 0; i < configElem.length; i++) {
        configElem[i].classList.remove('collapsed');
        configElem[i].querySelector('.collapse')?.classList.add('show');
      }
    }
    validatedCard = cardMessageList.value.find(
      (card) =>
        (!card.properties?.bannerUrl?.length && card.properties?.enableImage) ||
        (!card.properties?.label.text.length && card.properties?.enableLabel) ||
        (!card.properties?.actionLabel.text.length && card.properties?.enableAction) ||
        (!card.properties?.linkUrl.trim().length && card.properties?.enableAction),
    );

    if (validatedCard) {
      validatedCardIndex.value = cardMessageList.value.findIndex((card) => card.key == validatedCard.key);
      nextTick(() => {
        const bannerInput = editorSection.querySelector(`.input-upload-file-${validatedCard.key as string}`) as HTMLInputElement;
        const labelInput = editorSection.querySelector(`.label-input-${validatedCard.key as string}`) as HTMLInputElement;
        const actionInput = editorSection.querySelector(`.action-input-${validatedCard.key as string}`) as HTMLInputElement;
        const linkInput = editorSection.querySelector(`.link-input-${validatedCard.key as string}`) as HTMLInputElement;
        if (!validatedCard?.properties?.bannerUrl.length) {
          expandConfig();
          bannerInput?.closest('.upload-image-container')?.querySelector('.upload-icon-container')?.classList.add('warning');
        }
        if (!validatedCard?.properties?.label.text.length) {
          expandConfig();
          labelInput?.focus();
          labelInput?.classList.add('warning');
        }
        if (!validatedCard.properties?.actionLabel.text.length) {
          expandConfig();
          actionInput?.focus();
          actionInput?.classList.add('warning');
        }
        if (!validatedCard.properties?.linkUrl.trim().length) {
          expandConfig();
          linkInput?.focus();
          linkInput?.classList.add('warning');
        }
      });
    }
  }

  function validateRichMessageInput() {
    const richMessageTab = vm?.$refs['create-rich-message-tab'] as ComponentPublicInstance;
    const editorSection = richMessageTab.$refs['rich-message-editor-section'] as HTMLDivElement;

    function expandConfig() {
      const configElem = document.querySelectorAll('[data-bs-toggle="collapse"]');
      for (let i = 0; i < configElem.length; i++) {
        configElem[i].classList.remove('collapsed');
        configElem[i].querySelector('.collapse')?.classList.add('show');
      }
    }

    richMessage.value?.gridList.forEach((message, index) => {
      const linkInput = editorSection?.querySelector(`.link-input-${message.label as string}`) as HTMLInputElement;
      const actionInput = editorSection?.querySelector(`.action-label-${message.label as string}`) as HTMLInputElement;
      const imageDisplay = document.querySelector('.image-display-grid');

      if (!richMessage.value.image.url.length) {
        imageDisplay?.classList.add('warning');
      }

      if (!message.properties?.linkUrl.trim().length) {
        expandConfig();
        linkInput.focus();
        linkInput?.classList.add('warning');
      }

      if (!message.properties?.actionLabel.length) {
        expandConfig();
        actionInput.focus();
        actionInput?.classList.add('warning');
      }
    });
  }

  function onRemoveWarningInput(e: Event) {
    const inputElem = e.target as HTMLInputElement;
    inputElem.classList.remove('warning');
  }

  const onClickSave = () => {
    const validateResult = validateForm();
    // console.log('validate : ', validateResult);

    if (validateResult) {
      if (props.mode == 'create') {
        if (templateInfo.categoryId === 0) {
          return onSaveNoCategoryId();
        } else return createTemplate();
      }
      return editTemplate();
    }
  };

  const onInputTemplateName = () => {
    const nameContainer = vm?.$refs['line-template-name'] as HTMLDivElement;
    nameContainer.classList.remove('warning');
  };

  const onInputTemplateTitle = () => {
    const nameContainer = vm?.$refs['line-template-title'] as HTMLDivElement;
    nameContainer.classList.remove('warning');
  };

  function useMessageTemplate(template: Communication.Template.Template, messageKey: number) {
    if (template.contents && template.contents.length) {
      if (template.contents.length > 1) {
        mapper.value = [];
        resolveDisplayMessage(template.contents as Communication.LineOA.CreateCampaign.Content[]);
      } else {
        const currentMessageIndex: number = messageList.value.findIndex((message) => message.key == messageKey);
        const contentOBJ = JSON.parse(template.contents[0].content as unknown as string);
        let contentEditor: any;
        if (isJsonString(template.contents[0].content_editor as string) && template.contents[0].content_editor) {
          contentEditor = JSON.parse(template.contents[0].content_editor || '');
        } else {
          contentEditor = template.contents[0].content_editor || '';
        }
        let displayContent: Communication.LineOA.Component.LineMessage = { key: keyChange.value };
        if (contentOBJ.type == 'text') {
          mapper.value = toRaw(mapper.value).concat(contentEditor.mapper ? (contentEditor.mapper as Campaign.Personalize) : []);
          displayContent = {
            key: keyChange.value,
            sender: '',
            message: contentEditor.text ? decodeURI(contentEditor.text as string) : contentEditor,
          };
          displayContent.message = replacePersonalizeTag(displayContent.message as string, false);
        } else if (contentOBJ.type == 'image') {
          displayContent = {
            key: keyChange.value,
            sender: '',
            imageUrl: contentEditor.originalContentUrl || contentEditor,
            previewImageUrl: contentOBJ.previewImageUrl,
            sref: contentOBJ.sref,
          };
        } else if (contentOBJ.type == 'video') {
          displayContent = {
            key: keyChange.value,
            sender: '',
            videoUrl: contentEditor.originalContentUrl || contentEditor,
            previewImageUrl: contentOBJ.previewImageUrl,
            sref: contentOBJ.sref,
          };
        } else if (contentOBJ.type == 'audio') {
          displayContent = {
            key: keyChange.value,
            sender: '',
            audioUrl: contentEditor.originalContentUrl || contentEditor,
            duration: contentOBJ.duration,
            sref: contentOBJ.sref,
          };
        } else if (contentOBJ.type == 'flex') {
          contentEditor = resolveCardMessageMapperOnUseTemplate(
            JSON.stringify(contentEditor),
            contentEditor as Communication.LineOA.Component.CardMessge[],
          );
          mapper.value = toRaw(mapper.value).concat(contentEditor.mapper ? (contentEditor.mapper as Campaign.Personalize) : []);
          displayContent = {
            key: keyChange.value,
            sender: '',
            cardMessageList: contentEditor.list_card || [],
            sref: contentOBJ.sref,
            thumbnail: contentEditor.thumbnail,
            altText: contentOBJ.altText,
          };
        } else if (contentOBJ.type == 'imagemap' && contentOBJ.video) {
          // type rich-video-message
          contentEditor = resolveRichMessageMapperOnUseTemplate(
            JSON.stringify(contentEditor),
            contentEditor as Communication.LineOA.Component.RichMessage,
          );
          mapper.value = toRaw(mapper.value).concat(contentEditor.mapper ? (contentEditor.mapper as Campaign.Personalize) : []);
          displayContent = {
            key: keyChange.value,
            sender: '',
            richVideoMessage: contentEditor || [],
            sref: contentOBJ.sref,
            thumbnail: contentEditor.thumbnail,
            altText: contentOBJ.altText,
          };
        } else if (contentOBJ.type == 'imagemap') {
          contentEditor = resolveRichMessageMapperOnUseTemplate(
            JSON.stringify(contentEditor),
            contentEditor as Communication.LineOA.Component.RichMessage,
          );
          mapper.value = toRaw(mapper.value).concat(contentEditor.mapper ? (contentEditor.mapper as Campaign.Personalize) : []);
          displayContent = {
            key: keyChange.value,
            sender: '',
            richMessage:
              {
                ...contentEditor,
                gridType:
                  contentEditor.gridType !== 'custom-grid'
                    ? RICH_MESSAGE_TEMPLATE.find((message) => message.key == contentEditor.gridType)
                    : { key: 'custom-grid' },
              } || null,
            sref: contentOBJ.sref,
            altText: contentOBJ.altText,
          };
        } else {
          displayContent = { key: keyChange.value, sender: '' };
        }
        keyChange.value++;
        messageList.value[currentMessageIndex] = displayContent;
      }
      resolveTagNumber();
      setTimeout(() => {
        addActionEditToPersonalizeButton();
      }, 100);
    }
  }

  const clearTemplate = (type: string, messageKey: number) => {
    const message: Communication.LineOA.Component.LineMessage | undefined = messageList.value.find((message) => message.key == messageKey);
    if (message) {
      switch (type) {
        case 'rich-message':
          message.richMessage = null!;
        case 'card-message':
          message.cardMessageList = [];
        case 'rich-video-message':
          message.richVideoMessage = null!;
      }
    }
  };

  function onSaveNoCategoryId() {
    isCategoryModalOpen.value = true;
  }

  function onCloseCategoryModal() {
    isCategoryModalOpen.value = false;
  }

  function onSaveWithCategoryId(cat: Communication.TemplateCard.Response.CategoryList) {
    templateInfo.categoryId = cat.categoryId as number;
    if (templateInfo.categoryId !== 0) {
      isCategoryModalOpen.value = false;
      setTimeout(() => {
        createTemplate();
      }, 200);
    }
  }

  const onUploadFile = (fileOBJ: string[], messageKey: number) => {
    const message: Communication.LineOA.Component.LineMessage | undefined = messageList.value.find((message) => message.key == messageKey);
    if (message) {
      if (fileOBJ[0] === 'image') {
        message.imageUrl = fileOBJ[1];
        message.previewImageUrl = fileOBJ[3];
      } else if (fileOBJ[0] === 'video') {
        message.videoUrl = fileOBJ[1];
        message.previewImageUrl = fileOBJ[3];
      } else if (fileOBJ[0] === 'voice-message') {
        message.audioUrl = fileOBJ[1];
      }
      message.sref = fileOBJ[2];
      keyChange.value++;
      // console.log('aa', fileOBJ[1]);
      // console.log('aa', fileOBJ[3]);
    }
  };

  const onDeleteFile = (sref: string) => {
    srefList.value.push(sref);
  };

  const onUploadAudio = (dura: number, messageKey: number) => {
    const message: Communication.LineOA.Component.LineMessage | undefined = messageList.value.find((message) => message.key == messageKey);
    if (message) {
      message.duration = dura;
    }
  };

  //#region personalize
  function onSelectPersonalize() {
    isPersonalizeModalOpen.value = true;
  }

  function onSelectPersonalizeNoSegment() {
    // openWarningModal('คุณยังไม่ได้เลือก Customer', ' ต้องการไปแท็บ Select Customer หรือไม่ ?', () => (activeTab.value = 1));
    isPersonalizeModalOpen.value = true;
  }

  function onClosePersonalizeModal() {
    isPersonalizeModalOpen.value = false;
    personalizeEditMode.value = false;
    personalizeObjColumn.value = null!;
  }

  function onAddEditPersonalize(personalize: Campaign.Personalize) {
    if (personalizeEditMode.value) {
      editPersonalize(selectedButton.value, personalize);
    } else {
      addPersonalizeButton(personalize);
    }
    addActionEditToPersonalizeButton();
    isPersonalizeModalOpen.value = false;
    personalizeEditMode.value = false;
    personalizeObjColumn.value = null!;
  }

  function addPersonalizeButton(personalize: Campaign.Personalize) {
    const lineEditor = document.getElementsByClassName('content-editor');
    restoreSelection(lineEditorSelection.value);
    const range: any = lineEditorSelection.value.getRangeAt(0);
    range.deleteContents();
    const personalizeObj = Object.assign(personalize, { tag: `{{mp:${tagNumber.value}}}` });
    const span = document.createElement('span');
    span.innerHTML = `${personalize.columnAlias as string}`;
    span.setAttribute(
      'style',
      `background-color: ${personalizeObj.backgroundColor as string}; color: ${
        personalizeObj.fontColor as string
      }; cursor:pointer;border-radius:2px;padding:0px 5px;margin:0px 2px;`,
    );
    span.draggable = true;
    span.setAttribute('contenteditable', 'false');
    span.setAttribute('value', `${JSON.stringify(personalizeObj)}`);
    span.setAttribute('class', 'personalize-button-span');
    const spacebar = document.createTextNode(' ');
    range.insertNode(span);
    range.insertNode(spacebar);
    range.setStartAfter(span);
    lineEditorSelection.value.removeAllRanges();
    lineEditorSelection.value.addRange(range);

    tagNumber.value--;
    for (let i = 0; i < lineEditor.length; i++) {
      lineEditor[i].dispatchEvent(new KeyboardEvent('keydown', { keyCode: 32, which: 32 }));
      const personalizeButton: any = lineEditor[i].getElementsByClassName('personalize-button-span');
      for (let i = 0; i < personalizeButton.length; i++) {
        const mapper: Campaign.Personalize = JSON.parse(personalizeButton[i].getAttribute('value') as string);
        if (mapper.columnAlias === personalizeObj.columnAlias) {
          mapper.defaultValue = personalizeObj.defaultValue;
          personalizeButton[i].setAttribute('value', `${JSON.stringify(mapper)}`);
        }
      }
    }
  }

  function editPersonalize(button: any, personalize: Campaign.Personalize) {
    const lineEditor = document.getElementsByClassName('content-editor');
    for (let i = 0; i < lineEditor.length; i++) {
      const personalizeButton: any = lineEditor[i].getElementsByClassName('personalize-button-span');
      for (let i = 0; i < personalizeButton.length; i++) {
        const mapper: Campaign.Personalize = JSON.parse(personalizeButton[i].getAttribute('value') as string);
        if (mapper.columnAlias === personalize.columnAlias) {
          mapper.defaultValue = personalize.defaultValue;
          personalizeButton[i].setAttribute('value', `${JSON.stringify(mapper)}`);
        }
      }
    }
    button.innerHTML = personalize.columnAlias as string;
    button.setAttribute('value', `${JSON.stringify(personalize)}`);
    button.setAttribute(
      'style',
      `background-color: ${personalize.backgroundColor as string}; color: ${
        personalize.fontColor as string
      }; cursor:pointer;border-radius:2px;padding:0px 5px;margin:0px 2px;`,
    );
  }
  //#endregion personalize

  //#region coupon
  function onSelectCoupon() {
    isCouponCodeModalOpen.value = true;
  }

  function onCloseCouponModal() {
    isCouponCodeModalOpen.value = false;
    couponEditMode.value = false;
    couponObjColumn.value = null!;
  }

  function onAddEditCoupon(coupon: Campaign.CouponColumn[] | Campaign.CouponColumn) {
    if (couponEditMode.value) {
      editCoupon(selectedButton.value, coupon as Campaign.CouponColumn);
    } else {
      addCoupon(coupon as Campaign.CouponColumn[]);
    }
    addActionEditToPersonalizeButton();
    onCloseCouponModal();
  }

  function addCoupon(couponList: Campaign.CouponColumn[]) {
    const lineEditor = document.getElementsByClassName('content-editor');
    restoreSelection(lineEditorSelection.value);
    const range: any = lineEditorSelection.value.getRangeAt(0);
    couponList.forEach((item: any) => {
      range.deleteContents();
      const couponObj = Object.assign(item, { tag: `{{mp:${tagNumber.value}}}` });
      const button = document.createElement('span');
      button.className = 'coupon-button';
      button.setAttribute(
        'style',
        `cursor:pointer;border-radius:2px;padding:0px 5px;margin:0px 2px;border:none;background-color:${couponObj.backgroundColor as string};color:${
          couponObj.fontColor as string
        }`,
      );
      button.draggable = true;
      button.setAttribute('contenteditable', 'false');
      button.setAttribute('value', JSON.stringify(couponObj));
      button.innerHTML = couponObj.couponColumnAlias;
      const spacebar = document.createTextNode(' ');
      range.insertNode(button);
      range.insertNode(spacebar);
      range.setStartAfter(button);
      lineEditorSelection.value.removeAllRanges();
      lineEditorSelection.value.addRange(range);
      tagNumber.value--;
      for (let i = 0; i < lineEditor.length; i++) {
        const couponButton: any = lineEditor[i].getElementsByClassName('coupon-button');
        for (let i = 0; i < couponButton.length; i++) {
          const mapper: Campaign.CouponColumn = JSON.parse(couponButton[i].getAttribute('value') as string);
          if (mapper.columnAlias === couponObj.columnAlias) {
            mapper.defaultValue = couponObj.defaultValue;
            couponButton[i].setAttribute('value', `${JSON.stringify(mapper)}`);
          }
        }
      }
    });
    for (let i = 0; i < lineEditor.length; i++) {
      lineEditor[i].dispatchEvent(new KeyboardEvent('keydown', { keyCode: 32, which: 32 }));
    }
  }

  function editCoupon(button: any, coupon: Campaign.CouponColumn) {
    const lineEditor = document.getElementsByClassName('content-editor');
    for (let i = 0; i < lineEditor.length; i++) {
      const couponButton: any = lineEditor[i].getElementsByClassName('coupon-button');
      for (let i = 0; i < couponButton.length; i++) {
        const mapper: Campaign.CouponColumn = JSON.parse(couponButton[i].getAttribute('value') as string);
        if (
          mapper.couponColumnAlias === coupon.couponColumnAlias &&
          mapper.couponFreeTextRef === coupon.couponFreeTextRef &&
          mapper.couponId === coupon.couponId
        ) {
          mapper.defaultValue = coupon.defaultValue;
          couponButton[i].setAttribute('value', `${JSON.stringify(mapper)}`);
        }
      }
    }
    button.innerHTML = coupon.couponColumnAlias as string;
    button.setAttribute('value', `${JSON.stringify(coupon)}`);
    button.setAttribute(
      'style',
      `background-color: ${coupon.backgroundColor as string}; color: ${
        coupon.fontColor as string
      }; cursor:pointer;border-radius:2px;padding:0px 5px;margin:0px 2px;`,
    );
  }
  //#endregion coupon

  //#region tracking link
  function onSelectTrackingLinkMenu() {
    isTrackingLinkModalOpen.value = true;
  }

  function onCloseTrackingLinkModal() {
    isTrackingLinkModalOpen.value = false;
    trackingLinkEditMode.value = false;
    trackingLinkObj.value = null!;
  }

  function onAddEditTrackingLink(trackingLink: Communication.MapperModel.TrackingLink) {
    if (trackingLinkEditMode.value) {
      editTrackingLink(selectedTrackingLink.value, trackingLink);
    } else {
      addTrackingLink(trackingLink);
    }
    addActionEditToPersonalizeButton();
    isTrackingLinkModalOpen.value = false;
    trackingLinkEditMode.value = false;
    trackingLinkObj.value = null!;
  }

  function addTrackingLink(trackingLink: Communication.MapperModel.TrackingLink) {
    const lineEditor = document.getElementById('line-editor') as HTMLDivElement;
    restoreSelection(lineEditorSelection.value);
    const range: any = lineEditorSelection.value.getRangeAt(0);
    range.deleteContents();
    const trackingLinkObj = Object.assign(trackingLink, { tag: `{{mp:${tagNumber.value}}}` });
    const span = document.createElement('span');
    span.innerHTML = `${process.env.NODE_ENV === 'development' ? 'dev.' : ''}cdp.cx/${trackingLink.alphabetSeries as string}`;
    span.setAttribute(
      'style',
      `background-color: ${trackingLink.backgroundColor as string}; color: ${
        trackingLink.fontColor as string
      }; cursor:pointer; border-radius:2px; padding:0px 5px; margin:0px 2px; text-decoration: underline; text-decoration-color: ${
        trackingLink.fontColor as string
      };`,
    );
    span.draggable = true;
    span.setAttribute('contenteditable', 'false');
    span.setAttribute('value', `${JSON.stringify(trackingLinkObj)}`);
    span.setAttribute('class', 'tracking-link-span');
    const spacebar = document.createTextNode(' ');
    range.insertNode(span);
    range.insertNode(spacebar);
    range.setStartAfter(span);
    lineEditorSelection.value.removeAllRanges();
    lineEditorSelection.value.addRange(range);
    tagNumber.value--;
    lineEditor.dispatchEvent(new KeyboardEvent('keydown', { keyCode: 32, which: 32 }));
  }

  function editTrackingLink(button: HTMLSpanElement, trackingLink: Communication.MapperModel.TrackingLink) {
    button.setAttribute('value', `${JSON.stringify(trackingLink)}`);
    button.innerHTML = `${process.env.NODE_ENV === 'development' ? 'dev.' : ''}cdp.cx/${trackingLink.alphabetSeries as string}`;
    button.setAttribute(
      'style',
      `background-color: ${trackingLink.backgroundColor as string}; color: ${
        trackingLink.fontColor as string
      }; cursor:pointer; border-radius:2px; padding:0px 5px; margin:0px 2px; text-decoration: underline; text-decoration-color: ${
        trackingLink.fontColor as string
      };`,
    );
  }
  //#endregion tracking link

  //#region util for personalize
  function addActionEditToPersonalizeButton() {
    const lineEditor = document.getElementsByClassName('line-editor');
    for (let i = 0; i < lineEditor.length; i++) {
      let range: any = document.createRange();
      if (lineEditor[i]) {
        function setContent() {
          if (!messageList.value[i].audioUrl && !messageList.value[i].imageUrl && !messageList.value[i].videoUrl) {
            const lineEditorContent = lineEditor[i]?.querySelector('.content-editor')?.innerHTML;
            messageList.value[i].message = lineEditorContent;
          }
        }
        //Personalize Action
        const personalizeButton: any = lineEditor[i]?.getElementsByClassName('personalize-button-span');
        for (let i = 0; i < personalizeButton.length; i++) {
          const personalize: Campaign.Personalize = JSON.parse(personalizeButton[i].getAttribute('value') as string);
          personalizeButton[i].onclick = () => {
            personalizeEditMode.value = true;
            personalizeObjColumn.value = personalize;
            isPersonalizeModalOpen.value = true;
            selectedButton.value = personalizeButton[i];
          };
          personalizeButton[i].ondragstart = (e: any) => {
            e.dataTransfer.setData('text/html', e.target.outerHTML);
          };
          personalizeButton[i].ondragend = (e: any) => {
            if (e.dataTransfer.dropEffect === 'copy') {
              personalizeButton[i].remove();
              setContent();
            }
          };
          personalizeButton[i].onmouseup = (e: any) => {
            if (document.caretRangeFromPoint) range = document.caretRangeFromPoint(e.clientX as number, e.clientY as number);
            else {
              // firefox
              const sel = window.getSelection();
              sel?.collapse(e.rangeParent as Node, e.rangeOffset as number);
            }
          };
        }
        //Coupon Action
        const couponButtonList: any = lineEditor[i]?.getElementsByClassName('coupon-button');
        for (let i = 0; i < couponButtonList.length; i++) {
          const couponObj = JSON.parse(couponButtonList[i].getAttribute('value') as string);

          couponButtonList[i].onclick = () => {
            couponEditMode.value = true;
            couponObjColumn.value = couponObj;
            isCouponCodeModalOpen.value = true;
            selectedButton.value = couponButtonList[i];
          };
          couponButtonList[i].ondragstart = (e: any) => {
            e.dataTransfer.setData('text/html', e.target.outerHTML);
          };
          couponButtonList[i].ondragend = (e: any) => {
            if (e.dataTransfer.dropEffect === 'copy') {
              couponButtonList[i].remove();
              setContent();
            }
          };
          couponButtonList[i].onmouseup = (e: any) => {
            if (document.caretRangeFromPoint) range = document.caretRangeFromPoint(e.clientX as number, e.clientY as number);
            else {
              // firefox
              const sel = window.getSelection();
              sel?.collapse(e.rangeParent as Node, e.rangeOffset as number);
            }
          };
        }
        //tracking link Action
        lineEditor[i]?.querySelectorAll<HTMLElement>('.tracking-link-span').forEach((trackingLinkEle) => {
          trackingLinkEle.onclick = () => {
            const trackingLink: Communication.MapperModel.TrackingLink = JSON.parse(trackingLinkEle.getAttribute('value') as string);
            trackingLinkEditMode.value = true;
            trackingLinkObj.value = trackingLink;
            isTrackingLinkModalOpen.value = true;
            selectedTrackingLink.value = trackingLinkEle;
          };
          trackingLinkEle.ondragstart = (e: any) => {
            e.dataTransfer.setData('text/html', e.target.outerHTML);
          };
          trackingLinkEle.ondragend = (e: any) => {
            if (e.dataTransfer.dropEffect === 'copy') {
              trackingLinkEle.remove();
              setContent();
            }
          };
          trackingLinkEle.onmouseup = (e: any) => {
            if (document.caretRangeFromPoint) range = document.caretRangeFromPoint(e.clientX as number, e.clientY as number);
            else {
              // firefox
              const sel = window.getSelection();
              sel?.collapse(e.rangeParent as Node, e.rangeOffset as number);
            }
          };
        });
        setContent();
      }
    }
  }

  function getCursorPosition(selection: any) {
    lineEditorSelection.value = selection;
  }

  function restoreSelection(saved: any) {
    lineEditorSelection.value = window.getSelection();
    lineEditorSelection.value.setBaseAndExtent(saved[0], saved[1], saved[2], saved[3]);
  }

  const replacePersonalizeTag = (contentHtml: string, isReplaced?: boolean) => {
    const htmlElement = new DOMParser().parseFromString(contentHtml, 'text/html');
    const personalizeButton: any = htmlElement.getElementsByClassName('personalize-button-span');
    const couponButtonList: any = htmlElement.getElementsByClassName('coupon-button');
    //personalize
    for (let i = 0; i < personalizeButton.length; i++) {
      const tagMp = JSON.stringify(JSON.parse(personalizeButton[i].getAttribute('value') as string).tag).replaceAll('"', '');
      if (isReplaced) {
        contentHtml = contentHtml.replace(personalizeButton[i].outerHTML as string, tagMp);
      } else {
        contentHtml = contentHtml.replace(tagMp, `{{mp:${tagNumber.value--}}}`);
      }
    }
    //coupon
    for (let i = 0; i < couponButtonList.length; i++) {
      const tagMp = JSON.stringify(JSON.parse(couponButtonList[i].getAttribute('value') as string).tag).replaceAll('"', '');
      if (isReplaced) {
        contentHtml = contentHtml.replace(couponButtonList[i].outerHTML as string, tagMp);
      } else {
        contentHtml = contentHtml.replace(tagMp, `{{mp:${tagNumber.value--}}}`);
      }
    }
    //tracking link
    htmlElement?.querySelectorAll<HTMLElement>('.tracking-link-span').forEach((trackingLink) => {
      const tagMp = JSON.stringify(JSON.parse(trackingLink.getAttribute('value') as string).tag).replaceAll('"', '');
      if (isReplaced) {
        contentHtml = contentHtml.replace(trackingLink.outerHTML, tagMp);
      } else {
        contentHtml = contentHtml.replace(tagMp, `{{mp:${tagNumber.value--}}}`);
      }
    });
    return contentHtml;
  };

  function filterMapper(contentHtml: string) {
    const currentMapper = [];
    const htmlElement = new DOMParser().parseFromString(contentHtml, 'text/html');
    const personalizeButton: any = htmlElement.getElementsByClassName('personalize-button-span');
    const couponButtonList: any = htmlElement.getElementsByClassName('coupon-button');
    //personalize
    for (let i = 0; i < personalizeButton.length; i++) {
      const mapper: Campaign.Personalize = JSON.parse(personalizeButton[i].getAttribute('value') as string);
      currentMapper.push(mapper);
    }
    //coupon
    for (let i = 0; i < couponButtonList.length; i++) {
      const mapper: Campaign.Personalize = JSON.parse(couponButtonList[i].getAttribute('value') as string);
      currentMapper.push(mapper);
    }
    // tracking link
    htmlElement?.querySelectorAll<HTMLElement>('.tracking-link-span').forEach((trackingLink) => {
      const mapper: Communication.MapperModel.TrackingLink = JSON.parse(trackingLink.getAttribute('value') as string);
      currentMapper.push(mapper);
    });

    return currentMapper;
  }

  function isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function resolveTagNumber() {
    if (mapper.value?.length > 0) {
      const tagNumList: number[] = [];
      mapper.value.forEach((mapper) => {
        // const replacedTagNumber = +`-${mapper.tag?.replace(/[^0-9]/g, '') as string}`;
        // if (replacedTagNumber < tagNumber.value) {
        //   tagNumber.value = replacedTagNumber;
        // }
        // mapper.tag = `{{mp:${tagNumber.value}}}`;
        // tagNumber.value--;
        tagNumList.push(mapper.tag?.split(/(-?\d+)/)[1] as unknown as number);
        const min = Math.min(...tagNumList);
        if (min > 0) tagNumber.value = 0;
        else if (min < 0) tagNumber.value = min;
        tagNumber.value--;
      });
    }
  }

  function isUrlValid(url: string) {
    try {
      new URL(url);
      return true;
    } catch (err) {
      return false;
    }
  }
  //#endregion util for personalize

  function autoSave() {
    if (!isUpdated.value && !isUploading.value) return saveTemplate();
  }

  function onUploading(uploading: boolean) {
    isUploading.value = uploading;
  }

  const convertHtmlToBase64 = async (thumbnailObj: Record<string, string>, thumbnailIndex?: number) => {
    const node =
      templateInfo.messageType == MESSAGE_TYPE.RICH_MESSAGE
        ? (document.querySelector('.preview-rich-message-container') as HTMLElement)
        : thumbnailIndex != undefined
        ? (document.querySelectorAll('.card-message-list-container')[thumbnailIndex] as HTMLElement)
        : (document.querySelector('.card-message-list-container') as HTMLElement);

    if (node && templateInfo.messageType == MESSAGE_TYPE.RICH_MESSAGE) {
      const previewGridLayout = node.querySelector('.preview-grid-table');
      const uploadImageArea = node.querySelector('.upload-image-area');
      const uploadDescription = node.querySelector('.upload-description');
      previewGridLayout?.remove();
      uploadImageArea?.remove();
      uploadDescription?.remove();
    } else if (node && templateInfo.messageType == MESSAGE_TYPE.CARD_MESSAGE) {
      const arrowCarousel = node.querySelectorAll('.card-pack-arrow-wrp');
      for (let i = 0; i < arrowCarousel.length; i++) {
        arrowCarousel[i].remove();
      }
    }

    //TODO : Convert into url link file service
    node.style.overflow = 'hidden';
    node.style.transform = 'scale(0.8)';
    node.style.width = '450px';
    node.style.height = '450px';
    if (templateInfo.messageType == MESSAGE_TYPE.MIX) {
      node.style.paddingTop = '6rem';
    }

    // return console.log(thumbnailObj);

    return html2canvas(node, {
      allowTaint: true,
      useCORS: true,
    })
      .then(async function (canvas) {
        const png = canvas.toDataURL('png');
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.onload = function () {
          img.width = 150;
          img.height = 150;
        };
        img.src = png;

        function urltoFile(url: string, filename: string, mimeType: string) {
          return fetch(url)
            .then(function (res) {
              return res.arrayBuffer();
            })
            .then(function (buf) {
              return new File([buf], filename, { type: mimeType });
            });
        }

        //Usage example:
        // await urltoFile(png, `${templateInfo.templateName}.png`, 'image/png').then(function (file) {
        await urltoFile(png, fileName.value ? fileName.value : `${templateInfo.templateName.replace(/[\\/:\*\?\"<>|]/gi, '')}.png`, 'image/png').then(
          function (file) {
            if (!thumbnailObj.sref) {
              uploadFileModel.payload.Path = PATH.SYSTEM.COMMU.LINE;
              uploadFileModel.payload.File = file;
              uploadFileModel.payload.Is_Temp = true;
              return apiService
                .apiFormRequest(uploadFileModel)
                .then((response) => {
                  const res = response.data as Communication.File.Request.Response.File;
                  thumbnailObj.url = res.public_url;
                  thumbnailObj.sref = res.sref;
                })
                .catch((err) => {
                  openDefaultErrorModal(err);
                });
            } else {
              editFileModel.payload.Sref = thumbnailObj.sref;
              editFileModel.payload.Path = PATH.SYSTEM.COMMU.LINE;
              editFileModel.payload.File = file;
              editFileModel.payload.Is_Temp = true;
              return apiService
                .apiFormRequest(editFileModel)
                .then((response) => {
                  const res = response.data as Communication.File.Request.Response.File;
                  thumbnailObj.url = res.public_url;
                })
                .catch((err) => {
                  openDefaultErrorModal(err);
                });
            }
          },
        );
      })
      .catch(function (error) {})
      .finally(() => {
        //TODO : Convert into url link file service
        node.style.transform = 'unset';
        node.style.width = 'unset';
        node.style.height = 'unset';
        // node.style.overflow = 'unset';
        node.style.backgroundColor = 'unset';
        node.style.paddingTop = 'unset';
      });
  };

  onBeforeMount(() => {
    const query = router.currentRoute.value.query;
    templateInfo.messageType = Number(query.messageType);
    if (props.mode == 'create') {
      templateInfo.categoryId = Number(query.category) || 0;
      switch (templateInfo.messageType) {
        case 30:
          return resolveDisplayMessage([]);
        case 31:
          return resolveDisplayMessage([]);
        case 32:
          return resolveDisplayRichMessage([]);
        case 33:
          return resolveDisplayCard([]);
        case 34:
          return '';
      }
    }

    templateInfo.templateId = Number(router.currentRoute.value.params.id) || 0;
    fetchTemplateDetail();
  });

  onMounted(() => {
    if (props.mode == 'edit') interval.value = setInterval(autoSave, 3600000);
  });

  onBeforeUnmount(() => {
    if (interval.value) {
      clearInterval(interval.value);
    }
  });

  watch(
    [templateInfo, messageList],
    () => {
      if (!isLoading.value) isUpdated.value = false;
    },
    {
      deep: true,
    },
  );

  return {
    messageList,
    cardMessageList,
    richMessage,
    contentRichVideoMessage,
    contentEditorRichVideoMessage,
    validateRichVideo,
    templateInfo,
    isLoading,
    isCategoryModalOpen,
    isPersonalizeModalOpen,
    isCouponCodeModalOpen,
    isSegmentSelected,
    personalizeObjColumn,
    personalizeEditMode,
    mapper,
    couponEditMode,
    couponObjColumn,
    validatedCardIndex,
    isTrackingLinkModalOpen,
    trackingLinkEditMode,
    trackingLinkObj,
    onReorderMessage,
    onClickSave,
    onInputTemplateName,
    onInputTemplateTitle,
    useMessageTemplate,
    onCloseCategoryModal,
    onSaveWithCategoryId,
    onUploadFile,
    onDeleteFile,
    onUploadAudio,
    onSelectPersonalizeNoSegment,
    onClosePersonalizeModal,
    onAddEditPersonalize,
    onSelectPersonalize,
    onSelectCoupon,
    onCloseCouponModal,
    onAddEditCoupon,
    getCursorPosition,
    addMessage,
    onDeleteMessage,
    onUploading,
    onChangeCardLayout,
    onChangeCardMesssageProperties,
    onUploadVideo,
    addCardMessage,
    addClosingCard,
    onDeleteCard,
    onUploadImageCardMessage,
    onUploadImageRichMessage,
    onChangeGridTemplate,
    onRemoveWarningInput,
    onGetActiveCard,
    clearTemplate,
    onSelectTrackingLinkMenu,
    onCloseTrackingLinkModal,
    onAddEditTrackingLink,
  };
}
