import { ref, onBeforeMount, Ref, markRaw } from 'vue';
import { useI18n } from 'vue-i18n';
import apiService from '@/services/api';
import campaignModel from '@/models/communication/campaign';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

import CompleteIcon from '@assets/icons/modules/communication/campaign-tab/CompleteIcon.vue';
import CancelIcon from '@assets/icons/modules/communication/campaign-tab/CancelIcon.vue';
import CreditIcon from '@assets/icons/modules/communication/campaign-tab/CreditIcon.vue';
import ActiveIcon from '@assets/icons/modules/communication/campaign-tab/ActiveIcon.vue';
import ScheduleIcon from '@assets/icons/modules/communication/campaign-tab/ScheduleIcon.vue';
import DraftIcon from '@assets/icons/modules/communication/campaign-tab/DraftIcon.vue';

export default function useSmsSummaryCard() {
  const { fetchCampaignSummaryStatsModel } = campaignModel();
  const { openDefaultErrorModal } = useValidationModal();

  const { t } = useI18n();

  const summaryLoading = ref(false);

  const summaryCardList: Ref<Array<Summary.Card>> = ref([
    {
      title: t('communication.draft'),
      icon: 'draft',
    },
    {
      title: t('communication.publish'),
      icon: 'publish',
    },
    {
      title: t('communication.schedule'),
      icon: 'schedule',
    },
    {
      title: t('communication.complete'),
      icon: 'complete',
    },
    {
      title: t('communication.cancel'),
      icon: 'cancel',
    },
    {
      title: t('communication.use_credit'),
      icon: 'credit',
    },
  ]);
  const mapData = (summaryList: Array<Summary.Card>, payload: Summary.Response) => {
    const resolveIcon = (type: string) => {
      switch (type) {
        case 'publish':
          return markRaw(ActiveIcon);
        case 'credit':
          return markRaw(CreditIcon);
        case 'schedule':
          return markRaw(ScheduleIcon);
        case 'complete':
          return markRaw(CompleteIcon);
        case 'cancel':
          return markRaw(CancelIcon);
        case 'draft':
          return markRaw(DraftIcon);
      }
    };
    const resolveValue = (type: string) => {
      switch (type) {
        case 'publish':
          return payload.status_sending;
        case 'credit':
          return payload.use_credit;
        case 'schedule':
          return payload.status_scheduled;
        case 'complete':
          return payload.status_complete;
        case 'cancel':
          return payload.status_cancel;
        case 'draft':
          return payload.status_draft;
      }
    };
    const items: Array<Summary.Card> = [];
    summaryList.forEach((o: Summary.Card, index: number) => {
      items.push({
        title: o.title,
        icon: resolveIcon(o.icon) as unknown as string,
        value: resolveValue(o.icon) as number,
        balance: o.icon == 'credit' ? payload.balance : 0,
        monthly_use_credit: o.icon == 'credit' ? payload.monthly_use_credit : 0,
      });
    });
    return items;
  };

  const fetchCampaignSummaryStats = () => {
    summaryLoading.value = true;
    fetchCampaignSummaryStatsModel.payload.Channel = 1;

    apiService
      .apiRequest(fetchCampaignSummaryStatsModel)
      .then((response) => {
        summaryCardList.value = mapData(summaryCardList.value, response.data as Summary.Response);
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        summaryLoading.value = false;
      });
  };
  onBeforeMount(() => {
    fetchCampaignSummaryStats();
  });
  return { summaryCardList };
}
