import { ref, Ref, reactive, onBeforeMount, watch, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import apiService from '@/services/api';
import campaignModel from '@/models/communication/campaign';

interface Props {
  selectedChannel: Communication.Channel.Component.AllChannelTab | null;
}

export default function useSmsTab(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const router = useRouter();
  const { openDefaultErrorModal } = useValidationModal();
  const { createCampaignModel } = campaignModel();

  const { t } = useI18n();

  const loading = ref(false);
  const campaignId: Ref<number> = ref(0);
  const activeTab: Ref<number> = ref(0);
  const createButtonOptions: Communication.Channel.Component.CreateButtonDropDown[] = [
    {
      label: t('communication.campaign_text'),
      key: 'campaign',
    },
    {
      label: t('communication.template'),
      key: 'template',
    },
  ];

  const createCampaign = (templateId?: number) => {
    loading.value = true;
    createCampaignModel.payload.Channel = props.selectedChannel?.value as Communication.Campaign.ChannelType;
    createCampaignModel.payload.TemplateId = templateId || null;
    createCampaignModel.payload.Name = null;
    createCampaignModel.payload.Description = null;

    return apiService
      .apiRequest(createCampaignModel)
      .then((response) => {
        campaignId.value = response.data.campaignid;
        router.push({
          path: `/communication/sms/create-campaign/${campaignId.value}`,
          query: {
            ...router.currentRoute.value.query,
          },
        });
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const onSelectTemplate = (template: Communication.Template.Template) => {
    createCampaign(template.template_id);
  };

  function onClickCreateButton() {
    const dropdownContent = document.getElementById('dropdown-content') as HTMLDivElement;
    dropdownContent.classList.toggle('show');
  }

  function createTemplate() {
    router.push({
      name: 'sms-create-template',
      query: { category: router.currentRoute.value.query.category || 0 },
    });
  }

  function resolveOptionAction(key: string) {
    switch (key) {
      case 'campaign':
        return createCampaign();
      case 'template':
        return createTemplate();
    }
  }

  watch(activeTab, () => {
    router.replace({ path: router.currentRoute.value.path, query: { ...router.currentRoute.value.query, tab: activeTab.value } });
    vm?.$emit('active-tab', activeTab.value);
  });

  onBeforeMount(() => {
    setTimeout(() => {
      activeTab.value = Number(router.currentRoute.value.query.tab) || 0;
      router.replace({ path: router.currentRoute.value.path, query: { ...router.currentRoute.value.query, tab: activeTab.value } });
      vm?.$emit('active-tab', activeTab.value);
    }, 0);
  });
  return {
    loading,
    activeTab,
    createButtonOptions,
    onSelectTemplate,
    onClickCreateButton,
    resolveOptionAction,
  };
}
