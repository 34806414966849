import { ref, reactive, Ref, watch, markRaw, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

import api from '@services/api';
import useFilters from '@/utils/hooks/useFilters';
import AllCustomerModel from '@/models/segment/taballcustomer';
import datasourceModel from '@/models/segment/result';
import dynamicTableModel from '@/models/component/table/dynamicTable';

import BoxIcon from '@assets/icons/base/button/BoxIcon.vue';
import SmsIcon from '@assets/icons/base/button/SmsIcon.vue';
import EmailIcon from '@assets/icons/base/button/EmailIcon.vue';
import LineIcon from '@assets/icons/base/button/LineIcon.vue';

import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

interface Props {
  dataSource: Segment.Datasource;
  modelValue: any;
  isModalOpen?: boolean;
  activeTab?: number;
}

export default function useAllCustomer(props: Props) {
  const { openDefaultErrorModal } = useValidationModal();

  const { fetchCountRecord } = datasourceModel();
  const { fetchAllCustomerListModel, fetchDisplayColumnModel } = AllCustomerModel();
  const { updateDisplayColumnModel } = dynamicTableModel();

  const { filterDisplayObj } = useFilters();
  const { t } = useI18n();

  const textSearchForModal = ref('');
  const orderingSeq = ref(0);
  const tableIdForModal: Ref<number> = ref(0);
  const orderingKey = ref('');
  const orderingDirection = ref('');
  const rowSortForModal = ref(false);
  const selectAllForModal = ref(false);
  const columnSortForModal = ref(true);
  const loadingForModal = ref(false);
  const tableSelectAll: Ref<boolean> = ref(false);
  const totalRecordForModal: Ref<number> = ref(0);
  const saveSegmentModal = ref({ isOpen: false });
  const listDataSourceList: Ref<Array<any>> = ref([]);
  const listCustomerForModal: Ref<Record<string, any>[]> = ref([]);
  const filtersObjForModal: Ref<Array<any>> = ref([]);
  const previewDataList: Ref<Record<string, any>[]> = ref([]);
  const uniqueKeyModal: Ref<string> = ref('');
  const fieldsForModal: Ref<DynamicTable.Column[]> = ref([]);
  const errorCountDatasource: Ref<number> = ref(0);
  const lengthData = ref(0);

  let searchTimeout: ReturnType<typeof setTimeout> = null!;

  const controllers: AbortController[] = [];
  const itemDropdownSave = ref([{ title: t('segment.custom_segment'), icon: markRaw(BoxIcon) }]);

  const itemDropdownShare = ref([
    { title: 'SMS', icon: markRaw(SmsIcon) },
    { title: 'E-mail', icon: markRaw(EmailIcon) },
    { title: 'Line', icon: markRaw(LineIcon) },
  ]);

  const createSegmentModal = reactive({
    isOpen: false,
    isLoading: false,
    columnList: [] as DynamicTable.Column[],
  });

  const paginationForModal: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 4,
    totalRows: 0,
  });

  const handleColumnChange = (col: Array<any>) => {
    fieldsForModal.value = col;
    const displayList: DynamicTable.UpdateDisplay[] = [];
    col.map((column, index) => {
      displayList.push({
        ColumnId: column.column_id,
        Key_Column: column.key,
        Seq: index + 1,
      });
    });
    // updateDisplayColumnModel.payload.TableId = props.dataSource.tableid;
    // updateDisplayColumnModel.payload.displayTables = displayList;

    // api.apiRequest(updateDisplayColumnModel).catch((err) => {
    //   openDefaultErrorModal(err);
    // });
  };

  const fetchDatasourceDisplay = () => {
    if (props.dataSource != null) {
      loadingForModal.value = true;
      totalRecordForModal.value = 0;
      lengthData.value = 0;
      controllers.push(new AbortController());
      for (let i = 0; i < controllers.length - 1; i++) {
        controllers[i].abort();
      }
      if (orderingKey.value != '') {
        fetchAllCustomerListModel.payload.Ordering = [
          {
            Seq: orderingSeq.value,
            Key: orderingKey.value,
            Direction: orderingDirection.value,
          },
        ];
      }

      fetchAllCustomerListModel.payload.tableId = props.dataSource.tableid;
      fetchAllCustomerListModel.payload.Filters = filtersObjForModal.value;
      fetchAllCustomerListModel.payload.Page = paginationForModal.currentPage;
      fetchAllCustomerListModel.payload.Limit = paginationForModal.perPage;
      fetchAllCustomerListModel.signal = controllers[controllers.length - 1].signal;
      fetchAllCustomerListModel.payload.Search_Value = textSearchForModal.value;
      fetchAllCustomerListModel.payload.Flag_Count = false;
      fetchCountData();
      api
        .apiRequest(fetchAllCustomerListModel)
        .then((response) => {
          findUniqueKey(response.data.datasourcedisplay.columns);
          fieldsForModal.value = response.data.datasourcedisplay.columns;
          tableIdForModal.value = response.data.datasourcedisplay.tableid;
          listCustomerForModal.value = response.data.structuretable;
          if (response.status == 'success') {
            loadingForModal.value = false;
          }
        })
        .catch((err) => {
          if (!(err instanceof TypeError)) {
            openDefaultErrorModal(err);
            ++errorCountDatasource.value;
            if (err.status != 422 && errorCountDatasource.value < 5) {
              retryPayloadDatasource();
            } else {
              loadingForModal.value = false;
              openDefaultErrorModal(err);
            }
          }
        })
        .finally(() => {
          filtersObjForModal.value = [];
        });
    }
  };

  const fetchCountData = () => {
    if (orderingKey.value != '') {
      fetchCountRecord.payload.Ordering = [
        {
          Seq: orderingSeq.value,
          Key: orderingKey.value,
          Direction: orderingDirection.value,
        },
      ];
    }

    fetchCountRecord.payload.tableId = props.dataSource.tableid;
    fetchCountRecord.payload.Filters = filtersObjForModal.value;
    fetchCountRecord.payload.Page = paginationForModal.currentPage;
    fetchCountRecord.payload.Limit = paginationForModal.perPage;
    fetchCountRecord.payload.Search_Value = textSearchForModal.value;

    api
      .apiRequest(fetchCountRecord)
      .then((response) => {
        paginationForModal.totalRows = response.data.filtered_record;
        lengthData.value = response.data.total_record;
        totalRecordForModal.value = response.data.total_record;
      })
      .catch((err) => {
        if (!(err instanceof TypeError)) {
          openDefaultErrorModal(err);
          ++errorCountDatasource.value;
          if (err.status != 422 && errorCountDatasource.value < 5) {
            openDefaultErrorModal(err, () => retryPayloadDatasource());
          } else {
            openDefaultErrorModal(err);
          }
        }
      });
  };

  const retryPayloadDatasource = () => {
    fetchDatasourceDisplay();
  };

  const fetchDisplayColumn = () => {
    fetchDisplayColumnModel.payload.TableId = props.dataSource.tableid;
    createSegmentModal.isLoading = true;

    api
      .apiRequest(fetchDisplayColumnModel)
      .then((response) => {
        const columnList = response.data.payload.columns;

        createSegmentModal.columnList = columnList;
      })
      .catch((err) => {
        openDefaultErrorModal(
          err,
          () => (createSegmentModal.isOpen = false),
          () => (createSegmentModal.isOpen = false),
        );
      })
      .finally(() => (createSegmentModal.isLoading = false));
  };

  const onSortForModal = (sortedColumn: { key: string; direction: string }) => {
    orderingSeq.value = 1;
    orderingKey.value = sortedColumn.key;
    orderingDirection.value = sortedColumn.direction;
    if (paginationForModal.currentPage == 1) {
      fetchDatasourceDisplay();
    } else {
      paginationForModal.currentPage = 1;
    }
  };

  const countTotal = (total: number, length: number, status: boolean) => {
    if (length > 0 && status == true)
      return (
        t('segment.components.save_custom_dialog.table.segments_inbox_before') +
        (total - length) +
        t('segment.components.save_custom_dialog.table.segments_inbox_after')
      );
    if (length == 0 && status == true) return t('segment.components.save_custom_dialog.table.clear_select');
    else
      return (
        t('segment.components.save_custom_dialog.table.segments_inbox_before') +
        length +
        t('segment.components.save_custom_dialog.table.segments_inbox_after')
      );
  };

  const clickStatusBar = (length: number, status: boolean) => {
    if (length == 0 && status == true) {
      tableSelectAll.value = true;
    }
  };

  const onClickSave = () => {
    saveSegmentModal.value.isOpen = true;
  };

  const onCloseCreateSegmentModal = () => {
    createSegmentModal.isOpen = false;
  };

  const onCloseSaveSegmentModal = () => {
    saveSegmentModal.value.isOpen = false;
  };

  const onOpenCreateSegmentModal = () => {
    createSegmentModal.isOpen = true;
    fetchDisplayColumn();
  };

  const onChangeSearch = () => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      if (textSearchForModal.value != '') {
        filtersObjForModal.value = filterDisplayObj(fieldsForModal.value, textSearchForModal.value);
      }
      fetchDatasourceDisplay();
    }, 500);
  };

  function findUniqueKey(columns: PreviewSegment.DatasourceDisplayColumns[]): void {
    const found = columns.find((el: PreviewSegment.DatasourceDisplayColumns) => el.is_primary_key === true);

    if (found) {
      uniqueKeyModal.value = found.key;
    } else {
      throw 'uniqueKeyModal not found';
    }
  }

  watch(
    () => props.dataSource,
    () => {
      textSearchForModal.value = '';
      if (paginationForModal.currentPage == 1 && props.isModalOpen == true) {
        fetchDatasourceDisplay();
      } else {
        paginationForModal.currentPage = 1;
      }
    },
  );

  watch(
    () => props.isModalOpen,
    () => {
      textSearchForModal.value = '';
      fetchDatasourceDisplay();
    },
  );

  watch(
    () => props.activeTab,
    () => {
      if (props.activeTab == 0 && props.isModalOpen == true) {
        textSearchForModal.value = '';
        fetchDatasourceDisplay();
      }
    },
  );

  watch(
    () => textSearchForModal.value,
    () => {
      paginationForModal.currentPage = 1;
      onChangeSearch();
    },
  );

  watch(
    () => paginationForModal.currentPage,
    () => {
      if (textSearchForModal.value.length == 0) return fetchDatasourceDisplay();
      else return onChangeSearch();
    },
  );

  onMounted(() => {
    if (props.isModalOpen) fetchDatasourceDisplay();
  });

  return {
    fieldsForModal,
    listDataSourceList,
    listCustomerForModal,
    rowSortForModal,
    columnSortForModal,
    paginationForModal,
    loadingForModal,
    onSortForModal,
    itemDropdownSave,
    itemDropdownShare,
    textSearchForModal,
    onClickSave,
    previewDataList,
    uniqueKeyModal,
    saveSegmentModal,
    onCloseCreateSegmentModal,
    onCloseSaveSegmentModal,
    createSegmentModal,
    onOpenCreateSegmentModal,
    selectAllForModal,
    totalRecordForModal,
    countTotal,
    clickStatusBar,
    tableSelectAll,
    lengthData,
    tableIdForModal,
    handleColumnChange,
  };
}
