import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';
import { i18n } from '@/libs/i18n';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import advancedFormat from 'dayjs/plugin/advancedFormat';

export default function useConvertDate() {
  const { t, locale } = useI18n();
  function convertUTCToDeviceTimeZone(utcDateString: string): string {
    let resolveValue = '';

    if (!utcDateString) {
      return '-';
    }
    // สร้าง Date object จาก UTC string
    const dateTime = DateTime.fromISO(utcDateString, { zone: 'utc' });
    // ดึง Timezone ของอุปกรณ์
    const deviceTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // แปลงเวลาไปยัง Timezone ของอุปกรณ์
    const apiDateObject = dateTime.setZone(deviceTimeZone);
    const currentDateObject = DateTime.now().setZone(deviceTimeZone);

    const duration = apiDateObject.diff(currentDateObject, ['hour', 'minute', 'second']);

    if (Math.abs(duration.hours) > 24) {
      resolveValue = apiDateObject.toFormat('dd LLL yyyy hh:mm a');
    } else if (Math.abs(duration.hours) <= 24 && Math.abs(duration.hours) >= 1) {
      resolveValue = `${Math.ceil(Math.abs(duration.hours))} ` + t('segment.detail_segment.hours_ago'); // hours_ago
    } else if (Math.abs(duration.minutes) < 60 && Math.abs(duration.minutes) >= 1) {
      resolveValue = `${Math.ceil(Math.abs(duration.minutes))} ` + t('segment.detail_segment.minutes_ago'); // minutes ago
    } else {
      resolveValue = `${Math.ceil(Math.abs(duration.seconds))} ` + t('segment.detail_segment.seconds_ago'); // seconds ago
    }
    // แปลงเวลาไปยัง Timezone ของอุปกรณ์

    return resolveValue;
  }


  const convertDate = (dateString: string, format?: string) => {
    let resolveValue = '';

    if (!dateString) {
      return '-';
    }

    const userInfo = JSON.parse(localStorage.getItem('userInfo') || 'null');
    const timezone = userInfo.timezone || DateTime.fromISO(new Date().toISOString()).zoneName;
    const dateObject = DateTime.fromISO(dateString, { zone: timezone });
    const currentDateObject = DateTime.now().setZone(timezone as string);
    const duration = dateObject.diff(currentDateObject, ['hour', 'minute', 'second']);

    if (Math.abs(duration.hours) > 24) {
      if (locale.value == 'th') {
        dayjs.extend(advancedFormat);
        dayjs.locale(locale.value);
        const date = dayjs(dateString);
        const thaiYear = date.year() + 543;
        return date.format(`วันddddที่ D MMMM ${thaiYear} เวลา HH.mm.ss [น.]`); // วันศุกร์ที่ 14 มิถุนายน 2024 เวลา 17.52.25 น.
      }
      resolveValue = format ? dateObject.toFormat(format) : dateObject.toFormat('dd LLL yyyy hh:mm a');
    } else if (Math.abs(duration.hours) <= 24 && Math.abs(duration.hours) >= 1) {
      resolveValue = `${Math.ceil(Math.abs(duration.hours))} ` + t('segment.detail_segment.hours_ago'); // hours_ago
    } else if (Math.abs(duration.minutes) < 60 && Math.abs(duration.minutes) >= 1) {
      resolveValue = `${Math.ceil(Math.abs(duration.minutes))} ` + t('segment.detail_segment.minutes_ago'); // minutes ago
    } else {
      resolveValue = `${Math.ceil(Math.abs(duration.seconds))} ` + t('segment.detail_segment.seconds_ago'); // seconds ago
    }

    return resolveValue;
  };

  const convertDateLanguage = (dateString: string) => {
    let resolveValue = '';

    if (!dateString) {
      return '-';
    }
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || 'null');
    const timezone = userInfo.timezone || DateTime.fromISO(new Date().toISOString()).zoneName;
    const dateObject = DateTime.fromISO(dateString, { zone: timezone });
    const currentDateObject = DateTime.now().setZone(timezone as string);
    const duration = dateObject.diff(currentDateObject, ['hour', 'minute', 'second']);

    const monthIndex = dateObject.month - 1;
    const yearThai = dateObject.year + 543; // เพิ่ม 543 เพื่อแปลงเป็น พ.ศ.

    //currentLocale.value or i18n.global.locale
    const year = i18n.global.locale === 'th' ? yearThai : dateObject.year;
    // const time = i18n.global.locale === 'th' ? dateObject.toFormat('HH:mm') + ' น.' : dateObject.toFormat('hh:mm a');
    const time = i18n.global.locale === 'th' ? dateObject.toFormat('HH:mm') : dateObject.toFormat('hh:mm a');

    // if (Math.abs(duration.hours) > 24) {
    resolveValue = `${dateObject.day}/${monthIndex + 1}/${year} ${time}`;

    // resolveValue = `${dateObject.day} ${t(`notification.notification_bell.month_thai.${monthIndex}`)} ${year} ${time}`;
    // resolveValue = format ? dateObject.toFormat(format) : dateObject.toFormat('dd LLL yyyy hh:mm a');
    // } else if (Math.abs(duration.hours) <= 24 && Math.abs(duration.hours) >= 1) {
    //   resolveValue = `${Math.ceil(Math.abs(duration.hours))} ` + t('segment.detail_segment.hours_ago'); // hours_ago
    // } else if (Math.abs(duration.minutes) < 60 && Math.abs(duration.minutes) >= 1) {
    //   resolveValue = `${Math.ceil(Math.abs(duration.minutes))} ` + t('segment.detail_segment.minutes_ago'); // minutes ago
    // } else {
    //   resolveValue = `${Math.ceil(Math.abs(duration.seconds))} ` + t('segment.detail_segment.seconds_ago'); // seconds ago
    // }

    return resolveValue;
  };

  const convertDateText = (date: Date | string) => {
    const strDate = new Date(date);
    const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const month_thai = [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน',
      'ธันวาคม',
    ];

    const textDate = strDate.getDate();
    const textMonth = i18n.global.locale === 'th' ? month_thai[strDate.getMonth()] : month[strDate.getMonth()];
    const textYear = i18n.global.locale === 'th' ? strDate.getFullYear() + 543 : strDate.getFullYear();

    const concatDate = `${textDate} ${textMonth} ${textYear}`;
    return concatDate;
  };

  const convertDaily = (dateString: string) => {
    let resolveValue = '';

    if (!dateString) {
      return '-/-/-';
    }
    const userInfo = JSON.parse(localStorage.getItem('userInfo') || 'null');
    const timezone = userInfo.timezone || DateTime.fromISO(new Date().toISOString()).zoneName;
    const dateObject = DateTime.fromISO(dateString, { zone: timezone });

    // const monthIndex = dateObject.month - 1;
    // const yearThai = dateObject.year + 543; // เพิ่ม 543 เพื่อแปลงเป็น พ.ศ.

    const time = dateObject.toFormat('HH:mm');

    //currentLocale.value or i18n.global.locale
    // const year = i18n.global.locale === 'th' ? yearThai : dateObject.year;
    const year = dateObject.year.toString().slice(-2);

    resolveValue = `${dateObject.day}/${dateObject.month}/${year}  ${time}`;
    return resolveValue;
  };

  return {
    convertUTCToDeviceTimeZone,
    convertDate,
    convertDateLanguage,
    convertDateText,
    convertDaily,
  };
}
