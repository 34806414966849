export default {
  data_relationship: 'ความสัมพันธ์ของข้อมูล',
  relationship_overall: 'ความสัมพันธ์โดยรวม',
  detail_data_relationship_list: 'จัดการความสัมพันธ์ของข้อมูล โดยใช้แหล่งที่มาที่แตกต่างกัน',
  overall_diagram: 'การเชื่อมความสัมพันธ์ทั้งหมด',
  button_overall_diagram: 'การเชื่อมความสัมพันธ์ทั้งหมด',
  button_create_relation: 'สร้างความสัมพันธ์​',
  diagram_relation: 'การเชื่อมความสัมพันธ์',
  selected_relationship_pair: 'การจับคู่ความสัมพันธ์ที่เลือก',
  source: 'แหล่งที่มา',
  source_pair: 'แหล่งที่มาที่ต้องการจัดคู่',
  relationship_pair: 'จับคู่ความสัมพันธ์',
  existing_relationship: 'มีความสัมพันธ์ระหว่าง 2 ตารางนี้อยู่แล้ว',
  step: 'ขั้นตอนที่',
  select_source: 'เลือกแหล่งที่มา',
  select_data_type: 'เลือกประเภทข้อมูล',
  select_desired_source: 'เลือกแหล่งที่มาที่ต้องการจังคู่',
  from: 'จาก',
  to: 'ไปยัง',
  data_type: 'ประเภทข้อมูล',
  select_columns_to_pair: 'เลือกคอลัมน์ที่ต้องการจับคู่',
  please_select_source_and_data_type: 'กรุณาเลือกแหล่งที่มาและประเภทข้อมูล',
  to_select_columns_to_pair: 'เพื่อเลือกคอลัมน์ที่ต้องการจับคู่',
  validate_relation: 'ตรวจสอบความสัมพันธ์',
  cancel: 'ยกเลิก',
  apply: 'นำไปใช้',
  relationship_pair_completed: 'จับคู่ความสัมพันธ์เสร็จสิ้น',
  creating_pair_relationships: 'กำลังสร้างจับคู่ความสัมพันธ์',
  edit_pair_relationships: 'กำลังแกไขจับคู่ความสัมพันธ์',
  get_relation_detail: 'โหลดรายละเอียดความสัมพันธ์',
  //column table
  left_source_name: 'จาก : แหล่งข้อมูล',
  left_table_name: 'ประเภทข้อมูล',
  left_column_concat: 'ตาราง (คอลัมน์)',
  right_source_name: 'ไปยัง : แหล่งข้อมูล',
  right_table_name: 'ประเภทข้อมูล',
  right_column_concat: 'ตาราง (คอลัมน์)',
  status: 'สถานะ',
  //status
  active: 'ใช้งาน',
  inactive: 'ไม่ได้ใช้งาน',
  //validate-exist-relation
  validate_exist_relation: 'ตรวจสอบความสัมพันธ์ที่มีอยู่',
  validate_table_different: 'ต้องไม่ใช่ตารางเดียวกัน',
  validate_table_found: 'ไม่พบตาราง',
  validate_already_relationship: 'มีความสัมพันธ์ระหว่าง 2 ตารางนี้อยู่แล้ว',
};
