import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';
export default function () {
  const { t } = useI18n();
  const data = reactive([
    {
      label: t('bi.All'),
      value: 'All',
    },
    {
      label: t('bi.Customer'),
      value: 'Customer',
    },
    {
      label: t('bi.Product'),
      value: 'Product',
    },
    {
      label: t('bi.Transaction'),
      value: 'Transaction',
    },
  ]);

  const source = reactive([
    {
      label: t('bi.All'),
      value: 'All',
    },
    {
      label: t('bi.CRMPOS'),
      value: 'CRM',
    },
    {
      label: t('bi.Csv'),
      value: 'Csv',
    },
    {
      label: t('bi.BP'),
      value: 'BP',
    },
    {
      label: t('bi.FH5'),
      value: 'FH5',
    },
  ]);

  const chartType = reactive([
    {
      label: t('bi.All'),
      value: 'All',
    },
    {
      label: t('bi.PieChart'),
      value: 'Pie Chart',
    },
    {
      label: t('bi.LineChart'),
      value: 'Line Chart',
    },
    {
      label: t('bi.BarChart'),
      value: 'Bar Chart',
    },
    {
      label: t('bi.Heatmap'),
      value: 'Heatmap',
    },
    {
      label: t('bi.Infographic'),
      value: 'Infographic',
    },
    {
      label: t('bi.Radar'),
      value: 'Radar',
    },
    {
      label: t('bi.Box'),
      value: 'Box',
    },
    {
      label: t('bi.GeoMap'),
      value: 'Geo-Map',
    },
    {
      label: t('bi.Table'),
      value: 'Table',
    },
    {
      label: t('bi.Profile'),
      value: 'Profile',
    },
    {
      label: t('bi.Overview'),
      value: 'Overview',
    },
    {
      label: t('bi.Gauge'),
      value: 'Gauge',
    },
  ]);

  return { data, source, chartType };
}
