import {
  onBeforeMount,
  reactive,
  ref,
  Ref,
  getCurrentInstance,
  ComponentPublicInstance,
  watch,
  nextTick,
  toRaw,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import Sortable from 'sortablejs';
import router from '@/router';
import apiService from '@/services/api';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import campaignModel from '@/models/communication/campaign';
import { MESSAGE_TYPE } from '@/views/modules/communication/pages/line-oa-campaign/constants/messageType';
import { Channel as ChannelConstant } from '../../main/constants/channel';
import { Validation } from '../constants/validation';
import { RICH_MESSAGE_TEMPLATE } from '../constants/richMessage';
import { richVideoMapperTagEnum } from '@/constants/modules/commu/rich-video';

import { maxLength, htmlToString } from '@/utils/editor';

import { buildCardMessageImagePayload } from '@views/modules/communication/utils/lineCardMessageBuilder';
import { buildRichMessagePayload } from '@views/modules/communication/utils/richMessageBuilder';

export default function useLineCampaignPage() {
  const vm = getCurrentInstance()?.proxy;
  const { detailCampaignModel, saveCampaignModel, publishCampaignModel, previewCampaignModel, scheduleInfoPreviewModel, saveAsTemplateModel } =
    campaignModel();
  const { openDefaultErrorModal, openSuccessModal, openWarningModal } = useValidationModal();

  //message
  const messageList: Ref<Communication.LineOA.Component.LineMessage[]> = ref([]);
  const srefList: Ref<string[]> = ref([]);

  const selectedCustomerList: Ref<any[]> = ref([]);
  const isSelectAllCustomer: Ref<boolean> = ref(false);
  const isRemoveSegment: Ref<boolean> = ref(false);
  const isRemoveUnConsent: Ref<boolean> = ref(false);
  const excludeCustomerList: Ref<any[]> = ref([]);
  const isSelectAllWithExclude: Ref<boolean> = ref(false);
  const selectedSegment: Ref<Communication.Personalization.PersonalizeSegment> = ref(null!);
  const resolveSelectedRecp: Ref<Campaign.RecipientSegment[]> = ref([]);
  const resolveSelectedRecpUnConsent: Ref<Campaign.RecipientUnConsent> = ref(null!);
  const isDisabledReload: Ref<boolean> = ref(true);
  const isLoading: Ref<boolean> = ref(false);
  const validateResult: Ref<Communication.LineOA.Component.ValidateResult | null> = ref(null);
  const activeTab: Ref<number> = ref(0);
  const isUpdated: Ref<boolean> = ref(true);
  const keyChange: Ref<number> = ref(1);
  let isValidatingContents = false;
  const mapper: Ref<Campaign.Personalize[]> = ref([]);
  const selectedButton: Ref<any> = ref(null!);
  const lineEditorSelection: Ref<any> = ref(null);
  const tagNumber: Ref<number> = ref(-1);
  const readOnly: Ref<boolean> = ref(false);
  const filterSearch: Ref<string> = ref('');
  const filteredRecord: Ref<number> = ref(null!);
  const isUploading: Ref<boolean> = ref(false);
  const interval: Ref<ReturnType<typeof setInterval>> = ref(null!);
  //personalize
  const isPersonalizeModalOpen: Ref<boolean> = ref(false);
  const isSegmentSelected: Ref<boolean> = ref(false);
  const personalizeObjColumn: Ref<Campaign.Personalize> = ref(null!);
  const personalizeEditMode: Ref<boolean> = ref(false);
  //coupon
  const isCouponCodeModalOpen: Ref<boolean> = ref(false);
  const couponObjColumn: Ref<Campaign.CouponColumn> = ref(null!);
  const couponEditMode: Ref<boolean> = ref(false);
  //tracking link
  const isTrackingLinkModalOpen: Ref<boolean> = ref(false);
  const trackingLinkObj: Ref<Communication.MapperModel.TrackingLink> = ref(null!);
  const trackingLinkEditMode: Ref<boolean> = ref(false);
  const selectedTrackingLink: Ref<HTMLSpanElement> = ref(null!);
  //save as template
  const isCategoryModalOpen: Ref<boolean> = ref(false);
  const isCampaignMode: Ref<boolean> = ref(false);
  const templateName: Ref<string> = ref('');

  const creditDisplay: Ref<number> = ref(1);

  const campaignForm: Communication.LineOA.CreateCampaign.CampaignDetail = reactive({
    campaignId: null,
    info: {
      status: null!,
      name: '',
      description: null,
      provider: null,
      sender: '',
      updatedDt: '',
    },
    content: [],
    creditBalance: null,
    estCreditPerRecp: null,
    isCreditEnough: false,
    recpSelect: [],
    hasallowunconsent: false,
    recipientsUnConsent: null,
    recpSelectUnConsent: {
      cids: [],
      recipients: null,
      selection_mode: null,
      selection_mode_desc: null,
      selectunconsentid: null,
    },
    recpDispatchable: 0,
    schedule: {
      scheduleMode: null!,
      scheduleDt: null,
      repeatMode: null,
      repeatEvery: null,
      repeatOn: null,
      endMode: null,
      endDt: null,
      endOccurrence: null,
      nextScheduleDt: null,
      repeatOnVal: null,
      nextScheduleDtLabel: null,
      repeatEveryLabel: null,
      repeatOnLabel: null,
      startOnLabel: null,
      endOnLabel: null,
    },
  });

  const campaign: Ref<Communication.LineOA.CreateCampaign.CampaignDetail> = ref(null!);
  const selectedRecipient: Ref<Segment.Provider.SelectedRecipient> = ref(null!);

  const fetchCampaignDetail = async (campaignId: number) => {
    detailCampaignModel.payload.CampaignId = campaignId;
    isLoading.value = true;
    srefList.value = [];
    mapper.value = [];

    return await apiService
      .apiRequest(detailCampaignModel)
      .then((response) => {
        const detail = response.data;
        campaignForm.campaignId = detail.campaignid;
        campaignForm.info.status = detail.status;
        campaignForm.info.name = detail.name;
        campaignForm.info.provider = detail.provider;
        campaignForm.info.sender = detail.provider?.sms_sender;
        campaignForm.info.updatedDt = detail.updated_dt;
        campaignForm.content = detail.contents;
        campaignForm.creditBalance = detail.credit_balance;
        campaignForm.estCreditPerRecp = detail.est_credit_per_recp;
        campaignForm.isCreditEnough = detail.is_credit_enough;
        campaignForm.recpSelect = detail.recp_select;
        campaignForm.hasallowunconsent = detail.hasallowunconsent;
        campaignForm.recpSelectUnConsent = detail.recp_select_unconsent;
        campaignForm.recipientsUnConsent = detail.recp_pdpa_allowunconsent;
        campaignForm.schedule.scheduleMode = detail.schedule_mode;
        campaignForm.schedule.scheduleDt = campaignForm.schedule.scheduleMode == 1 ? new Date().toISOString() : detail.schedule_dt;
        campaignForm.schedule.repeatMode = detail.repeat_mode;
        campaignForm.schedule.repeatEvery = detail.repeat_every;
        campaignForm.schedule.repeatOn = detail.repeat_on;
        campaignForm.schedule.endMode = detail.end_mode;
        campaignForm.schedule.endDt = detail.end_dt;
        campaignForm.schedule.endOccurrence = detail.end_occurrence;
        campaignForm.recpDispatchable = detail.recp_dispatchable + detail.recp_pdpa_allowunconsent || 0;

        campaign.value = JSON.parse(JSON.stringify(campaignForm));
        validateResult.value = validateForm();
        isUpdated.value = true;
        readOnly.value = campaignForm.info.status == 1 ? false : true;
        isDisabledReload.value = campaignForm.info.status === 1 ? false : true;

        if (isValidatingContents) {
          onClickValidation(Validation.content);
          onClickValidation(Validation.limitMessage);
        }
        isValidatingContents = false;

        // mapping to customer drawer
        selectedRecipient.value = {
          summary_selected: detail.recipients,
          list_source: detail.recp_select.map((item: any) => {
            return {
              type: item.type,
              segment_id: item.segmentid,
              total_selected: item.recipients,
              display_name: item.display_name,
            };
          }),
          hasallowunconsent: detail.hasallowunconsent,
          recipients: detail.recipients,
          recp_pdpa: detail.recp_pdpa,
          recp_credential: detail.recp_credential,
          recp_dispatchable: detail.recp_dispatchable,
          recp_pdpa_allowunconsent: detail.recp_pdpa_allowunconsent,
          recp_pdpa_allowunconsent_cred: detail.recp_pdpa_allowunconsent_cred,
        };
        resolveDisplayMixMessage(campaignForm.content);
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isLoading.value = false;
        resolveTagNumber();
        addActionEditToPersonalizeButton();
      });
  };

  const fetchScheduleInfoPreviewCampaign = (campaignId: number) => {
    scheduleInfoPreviewModel.payload.CampaignId = campaignId;

    apiService
      .apiRequest(scheduleInfoPreviewModel)
      .then((response) => {
        const info = response.data;

        campaignForm.schedule.scheduleMode = info.schedule_mode;
        campaignForm.schedule.scheduleDt = campaignForm.schedule.scheduleMode == 1 ? new Date().toISOString() : info.schedule_dt;
        campaignForm.schedule.repeatMode = info.repeat_mode;
        campaignForm.schedule.repeatEvery = info.repeat_every;
        campaignForm.schedule.repeatOn = info.repeat_on;
        campaignForm.schedule.endMode = info.end_mode;
        campaignForm.schedule.endDt = info.end_dt;
        campaignForm.schedule.endOccurrence = info.end_occurrence;
        campaignForm.schedule.repeatOnVal = info.repeat_on_val;
        campaignForm.schedule.nextScheduleDt = info.next_schedule_dt;
        campaignForm.schedule.nextScheduleDtLabel = info.next_schedule_dt_label;
        campaignForm.schedule.repeatEveryLabel = info.repeat_every_label;
        campaignForm.schedule.repeatOnLabel = info.repeat_on_label;
        campaignForm.schedule.startOnLabel = info.start_on_label;
        campaignForm.schedule.endOnLabel = info.end_on_label;
      })
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => {
        isUpdated.value = true;
        validateResult.value = validateForm();
        campaign.value = JSON.parse(JSON.stringify(campaignForm));
      });
  };
  const isUnConsentModal: Ref<boolean> = ref(false);
  const isPasswordModal: Ref<boolean> = ref(false);
  const isRemoveUnConsentModal: Ref<boolean> = ref(false);
  function onCloseUnConsent() {
    isUnConsentModal.value = false;
  }
  function onOpenPasswordModal() {
    isUnConsentModal.value = false;
    isPasswordModal.value = true;
  }
  function onClosePasswordModal() {
    isPasswordModal.value = false;
  }
  function onOpenRemoveUnConsentModal() {
    isUnConsentModal.value = false;
    isRemoveUnConsentModal.value = true;
  }
  function onCloseRemoveUnConsentModal() {
    isRemoveUnConsentModal.value = false;
  }
  function onValidCodePassword() {
    // () => publishCampaign();
    isPasswordModal.value = false;
    return openWarningModal('เริ่ม Campaign', 'คุณต้องการเริ่ม Campaign นี้หรือไม่', () => publishCampaign());
  }
  async function onConfirmRemoveUnConsent(segmentId?: number) {
    isRemoveUnConsent.value = true;
    resolveSelectedRecpUnConsent.value = resolveRecipientsUnConsent();
    await onSave(true);
    // () => publishCampaign();
    isRemoveUnConsentModal.value = false;
    return openWarningModal('เริ่ม Campaign', 'คุณต้องการเริ่ม Campaign นี้หรือไม่', () => publishCampaign());
  }

  const onPublishCampaign = () => {
    const validate = validateForm();
    //เช็ค selected Recipient unconsent เพื่อแสดง modal ยืนยัน PublishCampaign แบบ selected Recipient unconsent
    if (isUnConsentModal.value == false && selectedRecipient.value.hasallowunconsent == true) {
      return (isUnConsentModal.value = true);
    } else if (validate) {
      return (validateResult.value = validate);
    }

    return openWarningModal('เริ่ม Campaign', 'คุณต้องการเริ่ม Campaign นี้หรือไม่', () => publishCampaign());
  };

  const publishCampaign = async () => {
    publishCampaignModel.payload.CampaignId = Number(router.currentRoute.value.query.campaign);
    isLoading.value = true;

    buildSavePayload();

    try {
      if (!isUpdated.value) await apiService.apiRequest(saveCampaignModel);
      await apiService.apiRequest(publishCampaignModel);
      openSuccessModal('ดำเนินการสำเร็จ', '', '', undefined, () => router.push({ path: '/communication', query: { channel: 'line-oa' } }));
    } catch (err) {
      openDefaultErrorModal(err);
    } finally {
      isLoading.value = false;
    }
  };

  const saveCampaign = (isSwitchTab?: boolean, successModal?: boolean) => {
    isLoading.value = true;

    if (isSwitchTab) {
      return apiService
        .apiRequest(saveCampaignModel)
        .then(() => {
          isUpdated.value = true;
          fetchCampaignDetail(Number(router.currentRoute.value.query.campaign));
          return;
        })
        .catch((err) => {
          openDefaultErrorModal(err);
          fetchCampaignDetail(Number(router.currentRoute.value.query.campaign));
        })
        .finally(() => {
          isSelectAllCustomer.value = false;
          selectedCustomerList.value = [];
          excludeCustomerList.value = [];
          isSelectAllWithExclude.value = false;
          selectedSegment.value = null!;
          saveCampaignModel.payload.Recipients.Segments = [];
          resolveSelectedRecp.value = [];
          filterSearch.value = '';
          filteredRecord.value = null!;
          isRemoveSegment.value = false;
          isRemoveUnConsent.value = false;
          resolveSelectedRecpUnConsent.value = null!;
          setTimeout(() => {
            isLoading.value = false;
          }, 0);
        });
    }

    if (successModal) {
      return apiService
        .apiRequest(saveCampaignModel)
        .then(() => {
          isUpdated.value = true;

          return activeTab.value == 3
            ? openSuccessModal(
                'บันทึกข้อมูลสำเร็จ',
                '',
                '',
                () => fetchCampaignDetail(Number(router.currentRoute.value.query.campaign)),
                () => fetchCampaignDetail(Number(router.currentRoute.value.query.campaign)),
              )
            : openSuccessModal(
                'บันทึกข้อมูลสำเร็จ',
                '',
                '',
                () => fetchCampaignDetail(Number(router.currentRoute.value.query.campaign)),
                () => fetchCampaignDetail(Number(router.currentRoute.value.query.campaign)),
              );
        })
        .catch((err) => {
          openDefaultErrorModal(err);
          fetchCampaignDetail(Number(router.currentRoute.value.query.campaign));
        })
        .finally(() => {
          isSelectAllCustomer.value = false;
          selectedCustomerList.value = [];
          excludeCustomerList.value = [];
          isSelectAllWithExclude.value = false;
          selectedSegment.value = null!;
          saveCampaignModel.payload.Recipients.Segments = [];
          resolveSelectedRecp.value = [];
          filterSearch.value = '';
          filteredRecord.value = null!;
          isRemoveSegment.value = false;
          isRemoveUnConsent.value = false;
          resolveSelectedRecpUnConsent.value = null!;

          setTimeout(() => {
            isLoading.value = false;
          }, 0);
        });
    }

    return apiService
      .apiRequest(saveCampaignModel)
      .then(() => {
        isUpdated.value = true;
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isSelectAllCustomer.value = false;
        selectedCustomerList.value = [];
        excludeCustomerList.value = [];
        isSelectAllWithExclude.value = false;
        selectedSegment.value = null!;
        saveCampaignModel.payload.Recipients.Segments = [];
        resolveSelectedRecp.value = [];
        filterSearch.value = '';
        filteredRecord.value = null!;
        isRemoveSegment.value = false;
        isRemoveUnConsent.value = false;
        resolveSelectedRecpUnConsent.value = null!;

        setTimeout(() => {
          isLoading.value = false;
        }, 0);
      });
  };

  const onSave = async (isSwitchTab?: boolean, successModal?: boolean) => {
    try {
      buildSavePayload();
      await saveCampaign(isSwitchTab, successModal);
    } catch (err) {
      openDefaultErrorModal(err);
    }
  };

  function onClickSaveAsTemplate() {
    validateResult.value = validateForm();

    if (!validateResult.value?.content && !validateResult.value?.limitMessage) {
      isCampaignMode.value = true;
      isCategoryModalOpen.value = true;
    } else {
      onClickValidation(Validation.content);
      onClickValidation(Validation.limitMessage);
    }
  }

  function onCloseCategoryModal() {
    isCampaignMode.value = false;
    isCategoryModalOpen.value = false;
  }

  function inputTemplateName(text: string) {
    templateName.value = text;
  }

  async function saveAsTemplate(cat: Communication.TemplateCard.Response.CategoryList) {
    const categoryId = cat.categoryId as number;
    if (!isUpdated.value) await onSave(true, false);
    saveAsTemplateModel.payload.CategoryId = categoryId;
    saveAsTemplateModel.payload.Channel = 3 as Campaign.ChannelType;
    saveAsTemplateModel.payload.Name = templateName.value;
    saveAsTemplateModel.payload.Type = 2;
    saveAsTemplateModel.payload.SubType = MESSAGE_TYPE.MIX;
    saveAsTemplateModel.payload.Contents = resolveMixMessageContent() as Campaign.SaveContent[];
    resolveSaveAsTemplateMapper(saveAsTemplateModel.payload.Contents);
    saveAsTemplateModel.payload.Thumbnail_Url = null!;
    saveAsTemplateModel.payload.Thumbnail_Sref = null!;
    saveAsTemplateModel.payload.Deleted_File_Sref = [];

    apiService
      .apiRequest(saveAsTemplateModel)
      .then(() => {
        onCloseCategoryModal();

        openSuccessModal('ดำเนินการสำเร็จ', '', '', undefined, () =>
          router.push({
            path: '/communication/template/list',
            query: {
              channel: 3,
              category: categoryId,
              mode: ChannelConstant.lineOA,
              type: 2,
              messageType: MESSAGE_TYPE.MIX,
            },
          }),
        );
      })
      .catch((err) => openDefaultErrorModal(err));
  }

  const validateForm = (): Communication.LineOA.Component.ValidateResult | null => {
    const validateResult: Communication.LineOA.Component.ValidateResult = {};

    //mix message validate
    messageList.value.forEach((message, index) => {
      if (
        !message.message?.length &&
        !message.imageUrl &&
        !message.videoUrl &&
        !message.audioUrl &&
        !message.richMessage &&
        !message.cardMessageList?.length &&
        !message.richVideoMessage
      ) {
        if (!validateResult.content) validateResult.content = [];

        validateResult.content.push({ ref: `line-message-editor-${index}` });
      }
    });

    messageList.value.forEach((message, index) => {
      if (message.message && htmlToString(message.message).length > maxLength) {
        if (!validateResult.limitMessage) validateResult.limitMessage = [];

        validateResult.limitMessage.push({ ref: `line-message-editor-${index}` });
      }
    });

    if (!campaignForm.info.provider) validateResult.provider = { ref: 'sender-dropdown' };
    if (!campaignForm.recpSelect || !campaignForm.recpSelect.length) validateResult.segment = { ref: 'select-segment-container' };
    if (!campaignForm.info.name?.trim()) validateResult.campaignName = { ref: 'campaign-name' };

    if (!campaignForm.isCreditEnough) {
      validateResult.isCreditEnough = { ref: 'isCreditEnough' };
    } else if ((campaignForm.creditBalance || 0) < resolveAllCreditUsed()) {
      validateResult.isCreditEnough = { ref: 'isCreditEnough' };
    }

    return Object.entries(validateResult).length ? validateResult : null;
  };

  const resolveAllCreditUsed = () => {
    let allCreditUsed = 0;
    if (selectedRecipient.value) {
      allCreditUsed =
        (selectedRecipient.value.recp_dispatchable + (selectedRecipient.value.recp_pdpa_allowunconsent || 0)) * (creditDisplay.value || 0);
    }
    return allCreditUsed;
  };

  const buildSavePayload = () => {
    saveCampaignModel.payload.CampaignId = Number(router.currentRoute.value.query.campaign);
    saveCampaignModel.payload.SubType = resolveSubType();
    saveCampaignModel.payload.Info.Description = '';
    saveCampaignModel.payload.Info.Name = campaignForm.info.name;
    saveCampaignModel.payload.Info.ProviderId = campaignForm.info.provider?.providerid;
    saveCampaignModel.payload.Contents = resolveMixMessageContent();
    saveCampaignModel.payload.Deleted_File_Sref = srefList.value;
    saveCampaignModel.payload.Recipients.Segments = resolveSelectedRecp.value;
    saveCampaignModel.payload.Recipients_Unconsent = resolveSelectedRecpUnConsent.value;
    saveCampaignModel.payload.Schedule.Mode = campaignForm.schedule.scheduleMode;
    saveCampaignModel.payload.Schedule.Schedule_DT = resolveDate(campaignForm.schedule.scheduleDt!);
    saveCampaignModel.payload.Schedule.Repeat_Every = campaignForm.schedule.repeatEvery || 1;
    saveCampaignModel.payload.Schedule.Repeat_On = campaignForm.schedule.repeatOn || 0;
    saveCampaignModel.payload.Schedule.End_Mode = campaignForm.schedule.endMode || 1;
    saveCampaignModel.payload.Schedule.End_DT = campaignForm.schedule.endDt ? resolveDate(campaignForm.schedule.endDt) : null;
    saveCampaignModel.payload.Schedule.End_Occurrence = campaignForm.schedule.endOccurrence || 1;
    saveCampaignModel.payload.Schedule.Repeat_Mode = campaignForm.schedule.repeatMode || 1;
  };

  function resolveSubType(): number {
    let subType = 0;
    const messageType = messageList.value.find((message) => message.message || message.message?.length);
    const richMessageType = messageList.value.find((message) => message.richMessage);
    const cardMessageListType = messageList.value.find((message) => message.cardMessageList && message.cardMessageList.length);
    const richVideoType = messageList.value.find((message) => message.richVideoMessage);
    if (!richMessageType && !cardMessageListType && !richVideoType) {
      subType = MESSAGE_TYPE.MESSAGE;
    } else if (!messageType && !cardMessageListType && !richVideoType) {
      subType = MESSAGE_TYPE.RICH_MESSAGE;
    } else if (!messageType && !richMessageType && !richVideoType) {
      subType = MESSAGE_TYPE.CARD_MESSAGE;
    } else if (!messageType && !richMessageType && !cardMessageListType) {
      subType = MESSAGE_TYPE.VIDEO;
    } else {
      subType = MESSAGE_TYPE.MIX;
    }
    return subType;
  }

  const resolveDate = (date: string) => {
    const resolveDate = date.includes('.') ? date.split('.')[0] : date;
    return resolveDate;
  };

  const resolveRecipients = (): Campaign.RecipientSegment[] => {
    if (!selectedSegment.value) {
      return resolveSelectedRecp.value;
    }
    if (isRemoveSegment.value) {
      resolveSelectedRecp.value.push({
        Action: 'remove',
        SegmentId: selectedSegment.value.segment_id,
      });
    } else if (isSelectAllCustomer.value || isSelectAllWithExclude.value) {
      resolveSelectedRecp.value.push({
        Action: 'add',
        Type: 2,
        Selection_Mode: 2,
        SegmentId: selectedSegment.value.segment_id,
        CIDs: excludeCustomerList.value.map((customer) => customer.cid),
        Filter_Search: filterSearch.value,
        Filtered_Record: filteredRecord.value,
      });
    } else {
      resolveSelectedRecp.value.push({
        Action: 'add',
        Type: 2,
        Selection_Mode: 3,
        SegmentId: selectedSegment.value.segment_id,
        CIDs: selectedCustomerList.value.map((customer) => customer.cid),
        Filter_Search: filterSearch.value,
        Filtered_Record: filteredRecord.value,
      });
    }
    resolveSelectedRecp.value = [...new Map(resolveSelectedRecp.value.map((item) => [item['SegmentId'], item])).values()];

    return resolveSelectedRecp.value;
  };

  const resolveRecipientsUnConsent = (): Campaign.RecipientUnConsent => {
    if (isRemoveUnConsent.value) {
      if (campaignForm.recpSelectUnConsent && campaignForm.recpSelectUnConsent.selectunconsentid != null) {
        resolveSelectedRecpUnConsent.value = {
          SelectUnconsentId: campaignForm.recpSelectUnConsent.selectunconsentid,
          Action: 'remove',
        };
      }
    } else if (isSelectAllCustomer.value || isSelectAllWithExclude.value) {
      resolveSelectedRecpUnConsent.value = {
        SelectUnconsentId: campaignForm.recpSelectUnConsent ? campaignForm.recpSelectUnConsent.selectunconsentid || null : null,
        Action: 'add',
        Selection_Mode: 2,
        CIDs: excludeCustomerList.value.map((customer) => customer.cid),
        Filter_Search: filterSearch.value,
        Filtered_Record: filteredRecord.value,
      };
      if (!campaignForm.recpSelectUnConsent) {
        delete resolveSelectedRecpUnConsent.value.SelectUnconsentId;
      }
    } else {
      resolveSelectedRecpUnConsent.value = {
        SelectUnconsentId: campaignForm.recpSelectUnConsent ? campaignForm.recpSelectUnConsent.selectunconsentid || null : null,
        Action: 'add',
        Selection_Mode: 3,
        CIDs: selectedCustomerList.value.map((customer) => customer.cid),
        Filter_Search: filterSearch.value,
        Filtered_Record: filteredRecord.value,
      };
      if (!campaignForm.recpSelectUnConsent) {
        delete resolveSelectedRecpUnConsent.value.SelectUnconsentId;
      }
    }
    return resolveSelectedRecpUnConsent.value;
  };

  function onChangeFilterSearch(searchVal: string, record: number) {
    filterSearch.value = searchVal;
    filteredRecord.value = record;
  }

  //#region Line Message
  const addMessage = () => {
    const displayContent = { key: keyChange.value, sender: campaignForm.info.provider?.name || '' };
    messageList.value.push(displayContent);
    keyChange.value++;
  };

  const onReorderMessage = (event: Sortable.SortableEvent) => {
    messageList.value.splice(event.newDraggableIndex as number, 0, messageList.value.splice(event.oldDraggableIndex as number, 1)[0]);
    messageList.value.forEach((message) => {
      message.key = keyChange.value;
      keyChange.value++;
    });
  };

  // function setPreviewMessageSize() {
  //   nextTick(() => {
  //     const previewCardContainer = document?.querySelectorAll('.preview-card-message') as any;
  //     if (previewCardContainer.length) {
  //       for (let i = 0; i < previewCardContainer.length; i++) {
  //         previewCardContainer[i].style.height = `${previewCardContainer[i].offsetHeight * 0.45}px`;
  //       }
  //     }
  //   });
  // }

  const onDeleteMessage = (removeMessage: Communication.LineOA.Component.LineMessage) => {
    messageList.value = messageList.value.filter((message) => message.key !== removeMessage.key);
    if (removeMessage.sref != undefined && removeMessage.sref.length) {
      onDeleteFile(removeMessage.sref as string);
    }
  };

  const resolveDisplayMixMessage = (content: Communication.LineOA.CreateCampaign.Content[]) => {
    const resolvedList: Communication.LineOA.Component.LineMessage[] = [];
    if (content && content.length) {
      content.forEach((content) => {
        const contentOBJ = JSON.parse(content.content);
        let contentEditor: any;
        if (isJsonString(content.content_editor) && content.content_editor) {
          contentEditor = JSON.parse(content.content_editor || '');
        } else {
          contentEditor = content.content_editor || '';
        }
        let displayContent: Communication.LineOA.Component.LineMessage = { key: keyChange.value };
        if (contentOBJ.type == 'text') {
          mapper.value = toRaw(mapper.value).concat(contentEditor.mapper ? (contentEditor.mapper as Campaign.Personalize) : []);
          displayContent = {
            key: keyChange.value,
            sender: campaignForm.info.provider?.name || '',
            message: contentEditor.text ? decodeURI(contentEditor.text as string) : contentEditor,
          };
        } else if (contentOBJ.type == 'image') {
          displayContent = {
            key: keyChange.value,
            sender: campaignForm.info.provider?.name || '',
            imageUrl: contentEditor.originalContentUrl || contentEditor,
            previewImageUrl: contentOBJ.previewImageUrl,
            sref: contentOBJ.sref,
          };
        } else if (contentOBJ.type == 'video') {
          displayContent = {
            key: keyChange.value,
            sender: campaignForm.info.provider?.name || '',
            videoUrl: contentEditor.originalContentUrl || contentEditor,
            previewImageUrl: contentOBJ.previewImageUrl,
            sref: contentOBJ.sref,
          };
        } else if (contentOBJ.type == 'audio') {
          displayContent = {
            key: keyChange.value,
            sender: campaignForm.info.provider?.name || '',
            audioUrl: contentEditor.originalContentUrl || contentEditor,
            duration: contentOBJ.duration,
            sref: contentOBJ.sref,
          };
        } else if (contentOBJ.type == 'flex') {
          mapper.value = toRaw(mapper.value).concat(contentEditor.mapper ? (contentEditor.mapper as Campaign.Personalize) : []);
          displayContent = {
            key: keyChange.value,
            sender: campaignForm.info.provider?.name || '',
            cardMessageList: contentEditor.list_card || [],
            sref: contentOBJ.sref,
            thumbnail: contentEditor.thumbnail,
            altText: contentOBJ.altText,
          };
        } else if (contentOBJ.type == 'imagemap' && contentOBJ.video) {
          // type rich-video-message
          mapper.value = toRaw(mapper.value).concat(contentEditor.mapper ? (contentEditor.mapper as Campaign.Personalize) : []);
          displayContent = {
            key: keyChange.value,
            sender: campaignForm.info.provider?.name || '',
            richVideoMessage: contentEditor || [],
            sref: contentOBJ.sref,
            thumbnail: contentEditor.thumbnail,
            altText: contentOBJ.altText,
          };
        } else if (contentOBJ.type == 'imagemap') {
          mapper.value = toRaw(mapper.value).concat(contentEditor.mapper ? (contentEditor.mapper as Campaign.Personalize) : []);
          displayContent = {
            key: keyChange.value,
            sender: campaignForm.info.provider?.name || '',
            richMessage:
              {
                ...contentEditor,
                gridType:
                  contentEditor.gridType !== 'custom-grid'
                    ? RICH_MESSAGE_TEMPLATE.find((message) => message.key == contentEditor.gridType)
                    : { key: 'custom-grid' },
              } || null,
            sref: contentOBJ.sref,
            altText: contentOBJ.altText,
          };
        } else {
          displayContent = { key: keyChange.value, sender: campaignForm.info.provider?.name || '' };
        }
        resolvedList.push(displayContent);
        keyChange.value++;
      });
    } else {
      resolvedList.push({ key: keyChange.value, sender: campaignForm.info.provider?.name || '' });
      keyChange.value++;
    }

    return (messageList.value = resolvedList);
  };

  const resolveMixMessageContent = (): Communication.LineOA.CreateCampaign.Request.SaveContent[] => {
    if (messageList.value && messageList.value.length) {
      const resolveMessageList: Communication.LineOA.CreateCampaign.Request.SaveContent[] = messageList.value.map((message) => {
        const resolvedMessage = htmlToString(replacePersonalizeTag(message.message as string, true));
        let resolvedContent = {};
        if (message.message) {
          resolvedContent = { type: 'text', text: resolvedMessage };
        } else if (message.imageUrl) {
          resolvedContent = {
            type: 'image',
            originalContentUrl: message.imageUrl,
            previewImageUrl: message.previewImageUrl,
            sref: message.sref,
          };
        } else if (message.videoUrl) {
          resolvedContent = {
            type: 'video',
            originalContentUrl: message.videoUrl,
            previewImageUrl: message.previewImageUrl,
            sref: message.sref,
          };
        } else if (message.audioUrl) {
          resolvedContent = { type: 'audio', originalContentUrl: message.audioUrl, duration: message.duration, sref: message.sref };
        } else if (message.richMessage) {
          resolvedContent = JSON.parse(
            replaceRichMessageUrl(JSON.stringify(buildRichMessagePayload(message.richMessage, message.altText)), message.richMessage),
          );
        } else if (message.cardMessageList && message.cardMessageList.length) {
          const cardMessageImageType = JSON.parse(
            replaceCardMessageUrl(JSON.stringify(buildCardMessageImagePayload(message.cardMessageList)), message.cardMessageList),
          );
          const srefCardMessage: string[] = [];
          message.cardMessageList.map((card) => {
            card.sref?.map((sref) => {
              if (!srefCardMessage.includes(sref)) {
                srefCardMessage.push(sref);
              }
            });
          });
          resolvedContent = { type: 'flex', altText: message.altText, sref: srefCardMessage, contents: cardMessageImageType };
        } else if (message.richVideoMessage) {
          const tagImagePreviewRichVideo = message.richVideoMessage.mapper[richVideoMapperTagEnum.video].tag || '';
          const tagButtonRichVideo = message.richVideoMessage.mapper[richVideoMapperTagEnum.linkUrl]?.tag || '';
          let objContent: Communication.LineOA.Component.ContentRichVideoMessage;

          const widthVideo = message.richVideoMessage.video.width;
          const heightVideo = message.richVideoMessage.video.height;
          objContent = {
            type: 'imagemap',
            baseUrl: tagImagePreviewRichVideo,
            altText: message.altText ?? '',
            baseSize: { width: widthVideo, height: heightVideo },
            video: {
              originalContentUrl: message.richVideoMessage.video.originalContentUrl,
              previewImageUrl: tagImagePreviewRichVideo,
              area: { x: 0, y: 0, width: widthVideo, height: heightVideo },
            },
            actions: [
              {
                type: 'uri',
                linkUri: tagButtonRichVideo,
                area: { x: 0, y: 0, width: widthVideo, height: heightVideo },
              },
            ],
          };
          if (message.richVideoMessage.isAction) {
            const actionButtonLabel = message.richVideoMessage.actionButtonLabel;

            objContent.video.externalLink = {
              linkUri: tagButtonRichVideo,
              label: actionButtonLabel.dropdown.label,
            };

            if (actionButtonLabel.dropdown.key == 0) {
              objContent.video.externalLink.label = actionButtonLabel.dropdown.customLabel;
            }
          } else {
            objContent.actions[0].linkUri = tagImagePreviewRichVideo;
          }
          resolvedContent = objContent;
        }

        const messageContent = {
          Content: JSON.stringify(resolvedContent),
          Content_Editor: JSON.stringify(resolveContentEditor(message)),
        };
        // console.log('messageContent : ', messageContent);
        return messageContent;
      });

      return resolveMessageList;
    }

    return [];
  };

  const resolveContentEditor = (message: Communication.LineOA.Component.LineMessage) => {
    if (message.message) {
      return { type: 'text', text: encodeURI(message.message), mapper: filterMapper(message.message) };
    } else if (message.imageUrl) {
      return { type: 'image', originalContentUrl: message.imageUrl, previewImageUrl: message.previewImageUrl, sref: message.sref };
    } else if (message.videoUrl) {
      return {
        type: 'video',
        originalContentUrl: message.videoUrl,
        previewImageUrl: message.previewImageUrl,
        sref: message.sref,
      };
    } else if (message.audioUrl) {
      return { type: 'audio', originalContentUrl: message.audioUrl, duration: message.duration, sref: message.sref };
    } else if (message.richMessage) {
      return { ...message.richMessage, type: 'imagemap', gridType: message.richMessage.gridType.key };
    } else if (message.cardMessageList && message.cardMessageList.length) {
      return {
        type: 'flex',
        list_card: message.cardMessageList,
        thumbnail: message.thumbnail,
        mapper: filterCardMessageMapper(message.cardMessageList),
      };
    } else if (message.richVideoMessage) {
      return message.richVideoMessage;
    }
  };

  const onInputCampaignName = () => {
    if (campaignForm.info.name) {
      const campaignName = vm?.$refs['campaign-name'] as HTMLDivElement;
      campaignName.classList.remove('warning');
    }
  };

  function onRemoveWarningInput(e: Event) {
    const inputElem = e.target as HTMLInputElement;
    isValidatingContents = false;
    inputElem.classList.remove('warning');
  }

  const onSelectEmojiCampaignName = (emoji: string) => {
    const campaignNameInput = vm?.$refs['campaign-name-input'] as HTMLInputElement;
    campaignForm.info.name += emoji;
    campaignNameInput.focus();
    if (campaignForm.info.name) {
      const campaignName = vm?.$refs['campaign-name'] as HTMLDivElement;
      campaignName.classList.remove('warning');
    }
  };

  const useMessageTemplate = (template: Communication.Template.Template, messageKey: number) => {
    if (template.contents && template.contents.length) {
      if (template.contents.length > 1) {
        mapper.value = [];
        resolveDisplayMixMessage(template.contents as Communication.LineOA.CreateCampaign.Content[]);
      } else {
        const currentMessageIndex: number = messageList.value.findIndex((message) => message.key == messageKey);
        const contentOBJ = JSON.parse(template.contents[0].content as unknown as string);
        let contentEditor: any;
        if (isJsonString(template.contents[0].content_editor as string) && template.contents[0].content_editor) {
          contentEditor = JSON.parse(template.contents[0].content_editor || '');
        } else {
          contentEditor = template.contents[0].content_editor || '';
        }
        let displayContent: Communication.LineOA.Component.LineMessage = { key: keyChange.value };
        if (contentOBJ.type == 'text') {
          mapper.value = toRaw(mapper.value).concat(contentEditor.mapper ? (contentEditor.mapper as Campaign.Personalize) : []);
          displayContent = {
            key: keyChange.value,
            sender: campaignForm.info.provider?.name || '',
            message: contentEditor.text ? decodeURI(contentEditor.text as string) : contentEditor,
          };
          displayContent.message = replacePersonalizeTag(displayContent.message as string, false);
        } else if (contentOBJ.type == 'image') {
          displayContent = {
            key: keyChange.value,
            sender: campaignForm.info.provider?.name || '',
            imageUrl: contentEditor.originalContentUrl || contentEditor,
            previewImageUrl: contentOBJ.previewImageUrl,
            sref: contentOBJ.sref,
          };
        } else if (contentOBJ.type == 'video') {
          displayContent = {
            key: keyChange.value,
            sender: campaignForm.info.provider?.name || '',
            videoUrl: contentEditor.originalContentUrl || contentEditor,
            previewImageUrl: contentOBJ.previewImageUrl,
            sref: contentOBJ.sref,
          };
        } else if (contentOBJ.type == 'audio') {
          displayContent = {
            key: keyChange.value,
            sender: campaignForm.info.provider?.name || '',
            audioUrl: contentEditor.originalContentUrl || contentEditor,
            duration: contentOBJ.duration,
            sref: contentOBJ.sref,
          };
        } else if (contentOBJ.type == 'flex') {
          contentEditor = resolveCardMessageMapperOnUseTemplate(
            JSON.stringify(contentEditor),
            contentEditor as Communication.LineOA.Component.CardMessge[],
          );
          mapper.value = toRaw(mapper.value).concat(contentEditor.mapper ? (contentEditor.mapper as Campaign.Personalize) : []);
          displayContent = {
            key: keyChange.value,
            sender: campaignForm.info.provider?.name || '',
            cardMessageList: contentEditor.list_card || [],
            sref: contentOBJ.sref,
            thumbnail: contentEditor.thumbnail,
            altText: contentOBJ.altText,
          };
        } else if (contentOBJ.type == 'imagemap' && contentOBJ.video) {
          // type rich-video-message
          contentEditor = resolveRichMessageMapperOnUseTemplate(
            JSON.stringify(contentEditor),
            contentEditor as Communication.LineOA.Component.RichMessage,
          );
          mapper.value = toRaw(mapper.value).concat(contentEditor.mapper ? (contentEditor.mapper as Campaign.Personalize) : []);
          displayContent = {
            key: keyChange.value,
            sender: campaignForm.info.provider?.name || '',
            richVideoMessage: contentEditor || [],
            sref: contentOBJ.sref,
            thumbnail: contentEditor.thumbnail,
            altText: contentOBJ.altText,
          };
          // console.log('mapper in richVideo ', mapper.value);
        } else if (contentOBJ.type == 'imagemap') {
          contentEditor = resolveRichMessageMapperOnUseTemplate(
            JSON.stringify(contentEditor),
            contentEditor as Communication.LineOA.Component.RichMessage,
          );
          mapper.value = toRaw(mapper.value).concat(contentEditor.mapper ? (contentEditor.mapper as Campaign.Personalize) : []);
          displayContent = {
            key: keyChange.value,
            sender: campaignForm.info.provider?.name || '',
            richMessage:
              {
                ...contentEditor,
                gridType:
                  contentEditor.gridType !== 'custom-grid'
                    ? RICH_MESSAGE_TEMPLATE.find((message) => message.key == contentEditor.gridType)
                    : { key: 'custom-grid' },
              } || null,
            sref: contentOBJ.sref,
            altText: contentOBJ.altText,
          };
        } else {
          displayContent = { key: keyChange.value, sender: campaignForm.info.provider?.name || '' };
        }
        keyChange.value++;
        messageList.value[currentMessageIndex] = displayContent;
      }
      resolveTagNumber();
      setTimeout(() => {
        addActionEditToPersonalizeButton();
      }, 100);
    }
  };

  const clearTemplate = (type: string, messageKey: number) => {
    const message: Communication.LineOA.Component.LineMessage | undefined = messageList.value.find((message) => message.key == messageKey);
    if (message) {
      switch (type) {
        case 'rich-message':
          message.richMessage = null!;
        case 'card-message':
          message.cardMessageList = [];
        case 'rich-video-message':
          message.richVideoMessage = null!;
      }
    }
  };

  const onAddCustomer = (addedDetail: {
    isSelectAll: boolean;
    selectedList: any[];
    excludeList: any[];
    isSelectAllWithExclude: boolean;
    segment: Communication.Personalization.PersonalizeSegment;
    totalRows: number;
  }) => {
    isSelectAllCustomer.value = addedDetail.isSelectAll;
    selectedCustomerList.value = addedDetail.selectedList;
    excludeCustomerList.value = addedDetail.excludeList;
    isSelectAllWithExclude.value = addedDetail.isSelectAllWithExclude;
    selectedSegment.value = addedDetail.segment;
    resolveSelectedRecp.value = resolveRecipients();

    const resolveCustomerNumber = addedDetail.isSelectAll
      ? addedDetail.totalRows
      : addedDetail.isSelectAllWithExclude
      ? addedDetail.totalRows - addedDetail.excludeList.length
      : addedDetail.selectedList.length;

    onSave(true);
  };

  function onApplySelectedCustomer(addedDetail: {
    isSelectAll: boolean;
    selectedList: any[];
    excludeList: any[];
    isSelectAllWithExclude: boolean;
    segment: number;
    totalRows: number;
  }) {
    isSelectAllCustomer.value = addedDetail.isSelectAll;
    selectedCustomerList.value = addedDetail.selectedList;
    excludeCustomerList.value = addedDetail.excludeList;
    isSelectAllWithExclude.value = addedDetail.isSelectAllWithExclude;
    selectedSegment.value = { segment_id: addedDetail.segment };
    if (selectedCustomerList.value.length <= 0) {
      isRemoveSegment.value = true;
    }
    resolveSelectedRecp.value = resolveRecipients();

    onSave(true);
  }

  function onApplySelectedCustomerUnConsent(addedDetail: {
    isSelectAll: boolean;
    selectedList: any[];
    excludeList: any[];
    isSelectAllWithExclude: boolean;
    totalRows: number;
  }) {
    isSelectAllCustomer.value = addedDetail.isSelectAll;
    selectedCustomerList.value = addedDetail.selectedList;
    excludeCustomerList.value = addedDetail.excludeList;
    isSelectAllWithExclude.value = addedDetail.isSelectAllWithExclude;
    if (selectedCustomerList.value.length <= 0 && excludeCustomerList.value.length <= 0) {
      isRemoveUnConsent.value = true;
    }
    resolveSelectedRecpUnConsent.value = resolveRecipientsUnConsent();

    onSave(true);
  }

  function onRemoveSegment(segmentId: number) {
    isRemoveSegment.value = true;
    selectedSegment.value = { segment_id: segmentId };
    resolveSelectedRecp.value = resolveRecipients();

    onSave(true);
  }

  const onSelectSender = (sender: Campaign.LineOAProvider) => {
    const scheduleTab = vm?.$refs['setting-and-schedule-tab'] as ComponentPublicInstance;
    const senderDropdownContainer = scheduleTab.$refs['sender-dropdown-container'] as HTMLDivElement;
    senderDropdownContainer?.classList.remove('warning');
    campaignForm.info.provider = sender;
  };

  const onSelectDateTime = (date: Date) => {
    campaignForm.schedule.scheduleDt = date?.toISOString() || null;
  };

  const onSelectRepeatMode = (mode: Campaign.RepeatMode) => {
    campaignForm.schedule.repeatMode = mode;
  };

  const onSelectRepeatOn = (repeatList: number[]) => {
    let sum = 0;

    repeatList.forEach((value) => (sum += value));
    campaignForm.schedule.repeatOn = sum;
  };

  const onInputEndOccurrence = (occurence: string) => {
    campaignForm.schedule.endOccurrence = Number(occurence);
  };

  const onSelectEndDateTime = (date: Date) => {
    campaignForm.schedule.endDt = date?.toISOString() || null;
  };

  const onInputRepeatEvery = (input: string) => {
    campaignForm.schedule.repeatEvery = Number(input) || 0;
  };

  const onSelectEndMode = (mode: Campaign.EndMode) => {
    campaignForm.schedule.endMode = mode;
  };

  const onSelectScheduleType = (scheduleType: Campaign.ScheduleMode) => {
    if (scheduleType == 1) {
      campaignForm.schedule.scheduleDt = new Date().toISOString();
    } else {
      campaignForm.schedule.scheduleDt = campaign.value.schedule.scheduleDt;
    }

    campaignForm.campaignId = campaign.value.campaignId;
    campaignForm.info.name = campaign.value.info.name;
    campaignForm.info.sender = campaign.value.info.sender;
    campaignForm.info.updatedDt = campaign.value.info.updatedDt;
    campaignForm.content = campaign.value.content;
    campaignForm.recpSelect = campaign.value.recpSelect;
    campaignForm.schedule.repeatMode = campaign.value.schedule.repeatMode;
    campaignForm.schedule.repeatEvery = campaign.value.schedule.repeatEvery;
    campaignForm.schedule.repeatOn = campaign.value.schedule.repeatOn;
    campaignForm.schedule.endMode = campaign.value.schedule.endMode;
    campaignForm.schedule.endDt = campaign.value.schedule.endDt;
    campaignForm.schedule.endOccurrence = campaign.value.schedule.endOccurrence;
    campaignForm.schedule.scheduleMode = scheduleType;
  };

  const onClickValidation = (validateType: number) => {
    if (validateType == Validation.content) {
      isValidatingContents = true;
      activeTab.value = 0;

      nextTick(() => {
        const createTab = vm?.$refs['create-tab'] as ComponentPublicInstance;

        validateResult.value?.content?.forEach((content) => {
          const lineEditor = createTab.$refs[content.ref] as ComponentPublicInstance[];
          const editorContainer = lineEditor[0].$refs['editor-container'] as HTMLDivElement;
          editorContainer.classList.add('warning');
        });
      });
    }

    if (validateType == Validation.segment) {
      activeTab.value = 1;

      nextTick(() => {
        const segmentTab = vm?.$refs['segment-tab'] as ComponentPublicInstance;
        const container = segmentTab.$refs['select-segment-container'] as HTMLDivElement;
        container.classList.add('warning');
      });
    }

    if (validateType == Validation.provider) {
      activeTab.value = 2;

      nextTick(() => {
        const scheduleTab = vm?.$refs['setting-and-schedule-tab'] as ComponentPublicInstance;
        const senderDropdownContainer = scheduleTab.$refs['sender-dropdown-container'] as HTMLDivElement;
        senderDropdownContainer?.classList.add('warning');
      });
    }

    if (validateType == Validation.campaignName) {
      isValidatingContents = true;
      const campaignName = vm?.$refs['campaign-name'] as HTMLDivElement;
      campaignName.classList.add('warning');
    }

    if (validateType == Validation.limitMessage) {
      isValidatingContents = true;
      activeTab.value = 0;

      nextTick(() => {
        const createTab = vm?.$refs['create-tab'] as ComponentPublicInstance;
        validateResult.value?.limitMessage?.forEach((limitMessage) => {
          const lineEditor = createTab.$refs[limitMessage.ref] as ComponentPublicInstance[];
          const editorContainer = lineEditor[0].$refs['editor-container'] as HTMLDivElement;
          editorContainer.classList.add('warning');
        });
      });
    }
  };

  const onUploadFile = (fileOBJ: string[], messageKey: number) => {
    const message: Communication.LineOA.Component.LineMessage | undefined = messageList.value.find((message) => message.key == messageKey);
    if (message) {
      if (fileOBJ[0] === 'image') {
        message.imageUrl = fileOBJ[1];
        message.previewImageUrl = fileOBJ[3];
      } else if (fileOBJ[0] === 'video') {
        message.videoUrl = fileOBJ[1];
        message.previewImageUrl = fileOBJ[3];
      } else if (fileOBJ[0] === 'voice-message') {
        message.audioUrl = fileOBJ[1];
      }
      message.sref = fileOBJ[2];
      keyChange.value++;
    }
  };

  const onDeleteFile = (sref: string) => {
    srefList.value.push(sref);
  };

  const onUploadAudio = (dura: number, messageKey: number) => {
    const message: Communication.LineOA.Component.LineMessage | undefined = messageList.value.find((message) => message.key == messageKey);
    if (message) {
      message.duration = dura;
    }
  };

  function getCursorPosition(selection: any) {
    lineEditorSelection.value = selection;
  }

  function restoreSelection(saved: any) {
    lineEditorSelection.value = window.getSelection();
    lineEditorSelection.value.setBaseAndExtent(saved[0], saved[1], saved[2], saved[3]);
  }
  //#region personalize
  function onSelectSegment() {
    if (campaignForm.recpSelect && campaignForm.recpSelect?.length > 0) return (isSegmentSelected.value = true);
    else return (isSegmentSelected.value = false);
  }

  function onSelectPersonalize() {
    isPersonalizeModalOpen.value = true;
  }

  function onSelectPersonalizeNoSegment() {
    // openWarningModal('คุณยังไม่ได้เลือก Customer', ' ต้องการไปแท็บ Select Customer หรือไม่ ?', () => (activeTab.value = 1));
    isPersonalizeModalOpen.value = true;
  }

  function onClosePersonalizeModal() {
    isPersonalizeModalOpen.value = false;
    personalizeEditMode.value = false;
    personalizeObjColumn.value = null!;
  }

  function onAddEditPersonalize(personalize: Campaign.Personalize) {
    if (personalizeEditMode.value) {
      editPersonalize(selectedButton.value, personalize);
    } else {
      addPersonalizeButton(personalize);
    }
    addActionEditToPersonalizeButton();
    isPersonalizeModalOpen.value = false;
    personalizeEditMode.value = false;
    personalizeObjColumn.value = null!;
  }

  function addPersonalizeButton(personalize: Campaign.Personalize) {
    const lineEditor = document.getElementsByClassName('content-editor');
    restoreSelection(lineEditorSelection.value);
    const range: any = lineEditorSelection.value.getRangeAt(0);
    range.deleteContents();
    const personalizeObj = Object.assign(personalize, { tag: `{{mp:${tagNumber.value}}}` });
    const span = document.createElement('span');
    span.innerHTML = `${personalize.columnAlias as string}`;
    span.setAttribute(
      'style',
      `background-color: ${personalizeObj.backgroundColor as string}; color: ${
        personalizeObj.fontColor as string
      }; cursor:pointer;border-radius:2px;padding:0px 5px;margin:0px 2px;`,
    );
    span.draggable = true;
    span.setAttribute('contenteditable', 'false');
    span.setAttribute('value', `${JSON.stringify(personalizeObj)}`);
    span.setAttribute('class', 'personalize-button-span');
    const spacebar = document.createTextNode(' ');
    range.insertNode(span);
    range.insertNode(spacebar);
    range.setStartAfter(span);
    lineEditorSelection.value.removeAllRanges();
    lineEditorSelection.value.addRange(range);

    mapper.value.push(personalize);
    tagNumber.value--;
    for (let i = 0; i < lineEditor.length; i++) {
      lineEditor[i].dispatchEvent(new KeyboardEvent('keydown', { keyCode: 32, which: 32 }));
      const personalizeButton: any = lineEditor[i].getElementsByClassName('personalize-button-span');
      for (let i = 0; i < personalizeButton.length; i++) {
        const mapper: Campaign.Personalize = JSON.parse(personalizeButton[i].getAttribute('value') as string);
        if (mapper.columnAlias === personalizeObj.columnAlias) {
          mapper.defaultValue = personalizeObj.defaultValue;
          personalizeButton[i].setAttribute('value', `${JSON.stringify(mapper)}`);
        }
      }
    }
  }

  function editPersonalize(button: any, personalize: Campaign.Personalize) {
    const lineEditor = document.getElementsByClassName('content-editor');
    for (let i = 0; i < lineEditor.length; i++) {
      const personalizeButton: any = lineEditor[i].getElementsByClassName('personalize-button-span');
      for (let i = 0; i < personalizeButton.length; i++) {
        const mapper: Campaign.Personalize = JSON.parse(personalizeButton[i].getAttribute('value') as string);
        if (mapper.columnAlias === personalize.columnAlias) {
          mapper.defaultValue = personalize.defaultValue;
          personalizeButton[i].setAttribute('value', `${JSON.stringify(mapper)}`);
        }
      }
    }
    button.innerHTML = personalize.columnAlias as string;
    button.setAttribute('value', `${JSON.stringify(personalize)}`);
    button.setAttribute(
      'style',
      `background-color: ${personalize.backgroundColor as string}; color: ${
        personalize.fontColor as string
      }; cursor:pointer;border-radius:2px;padding:0px 5px;margin:0px 2px;`,
    );
  }
  //#endregion personalize

  //#region coupon
  function onSelectCoupon() {
    isCouponCodeModalOpen.value = true;
  }

  function onCloseCouponModal() {
    isCouponCodeModalOpen.value = false;
    couponEditMode.value = false;
    couponObjColumn.value = null!;
  }

  function onAddEditCoupon(coupon: Campaign.CouponColumn[] | Campaign.CouponColumn) {
    if (couponEditMode.value) {
      editCoupon(selectedButton.value, coupon as Campaign.CouponColumn);
    } else {
      addCoupon(coupon as Campaign.CouponColumn[]);
    }
    addActionEditToPersonalizeButton();
    onCloseCouponModal();
  }

  function addCoupon(couponList: Campaign.CouponColumn[]) {
    const lineEditor = document.getElementsByClassName('content-editor');
    restoreSelection(lineEditorSelection.value);
    const range: any = lineEditorSelection.value.getRangeAt(0);
    couponList.forEach((item: any) => {
      range.deleteContents();
      const couponObj = Object.assign(item, { tag: `{{mp:${tagNumber.value}}}` });
      const button = document.createElement('span');
      button.className = 'coupon-button';
      button.setAttribute(
        'style',
        `cursor:pointer;border-radius:2px;padding:0px 5px;margin:0px 2px;border:none;background-color:${couponObj.backgroundColor as string};color:${
          couponObj.fontColor as string
        }`,
      );
      button.draggable = true;
      button.setAttribute('contenteditable', 'false');
      button.setAttribute('value', JSON.stringify(couponObj));
      button.innerHTML = couponObj.couponColumnAlias;
      const spacebar = document.createTextNode(' ');
      range.insertNode(button);
      range.insertNode(spacebar);
      range.setStartAfter(button);
      lineEditorSelection.value.removeAllRanges();
      lineEditorSelection.value.addRange(range);

      mapper.value.push(item as Campaign.Personalize);
      tagNumber.value--;
      for (let i = 0; i < lineEditor.length; i++) {
        const couponButton: any = lineEditor[i].getElementsByClassName('coupon-button');
        for (let i = 0; i < couponButton.length; i++) {
          const mapper: Campaign.CouponColumn = JSON.parse(couponButton[i].getAttribute('value') as string);
          if (mapper.columnAlias === couponObj.columnAlias) {
            mapper.defaultValue = couponObj.defaultValue;
            couponButton[i].setAttribute('value', `${JSON.stringify(mapper)}`);
          }
        }
      }
    });
    for (let i = 0; i < lineEditor.length; i++) {
      lineEditor[i].dispatchEvent(new KeyboardEvent('keydown', { keyCode: 32, which: 32 }));
    }
  }

  function editCoupon(button: any, coupon: Campaign.CouponColumn) {
    const lineEditor = document.getElementsByClassName('content-editor');
    for (let i = 0; i < lineEditor.length; i++) {
      const couponButton: any = lineEditor[i].getElementsByClassName('coupon-button');
      for (let i = 0; i < couponButton.length; i++) {
        const mapper: Campaign.CouponColumn = JSON.parse(couponButton[i].getAttribute('value') as string);
        if (
          mapper.couponColumnAlias === coupon.couponColumnAlias &&
          mapper.couponFreeTextRef === coupon.couponFreeTextRef &&
          mapper.couponId === coupon.couponId
        ) {
          mapper.defaultValue = coupon.defaultValue;
          couponButton[i].setAttribute('value', `${JSON.stringify(mapper)}`);
        }
      }
    }
    button.innerHTML = coupon.couponColumnAlias as string;
    button.setAttribute('value', `${JSON.stringify(coupon)}`);
    button.setAttribute(
      'style',
      `background-color: ${coupon.backgroundColor as string}; color: ${
        coupon.fontColor as string
      }; cursor:pointer;border-radius:2px;padding:0px 5px;margin:0px 2px;`,
    );
  }
  //#endregion coupon

  //#region tracking link
  function onSelectTrackingLinkMenu() {
    isTrackingLinkModalOpen.value = true;
  }

  function onCloseTrackingLinkModal() {
    isTrackingLinkModalOpen.value = false;
    trackingLinkEditMode.value = false;
    trackingLinkObj.value = null!;
  }

  function onAddEditTrackingLink(trackingLink: Communication.MapperModel.TrackingLink) {
    if (trackingLinkEditMode.value) {
      editTrackingLink(selectedTrackingLink.value, trackingLink);
    } else {
      addTrackingLink(trackingLink);
    }
    addActionEditToPersonalizeButton();
    isTrackingLinkModalOpen.value = false;
    trackingLinkEditMode.value = false;
    trackingLinkObj.value = null!;
  }

  function addTrackingLink(trackingLink: Communication.MapperModel.TrackingLink) {
    const lineEditor = document.getElementById('line-editor') as HTMLDivElement;
    restoreSelection(lineEditorSelection.value);
    const range: any = lineEditorSelection.value.getRangeAt(0);
    range.deleteContents();
    const trackingLinkObj = Object.assign(trackingLink, { tag: `{{mp:${tagNumber.value}}}` });
    const span = document.createElement('span');
    span.innerHTML = `${process.env.NODE_ENV === 'development' ? 'dev.' : ''}cdp.cx/${trackingLink.alphabetSeries as string}`;
    span.setAttribute(
      'style',
      `background-color: ${trackingLink.backgroundColor as string}; color: ${
        trackingLink.fontColor as string
      }; cursor:pointer; border-radius:2px; padding:0px 5px; margin:0px 2px; text-decoration: underline; text-decoration-color: ${
        trackingLink.fontColor as string
      };`,
    );
    span.draggable = true;
    span.setAttribute('contenteditable', 'false');
    span.setAttribute('value', `${JSON.stringify(trackingLinkObj)}`);
    span.setAttribute('class', 'tracking-link-span');
    const spacebar = document.createTextNode(' ');
    range.insertNode(span);
    range.insertNode(spacebar);
    range.setStartAfter(span);
    lineEditorSelection.value.removeAllRanges();
    lineEditorSelection.value.addRange(range);
    lineEditor.dispatchEvent(new KeyboardEvent('keydown', { keyCode: 32, which: 32 }));

    mapper.value.push(trackingLink as unknown as Campaign.Personalize);
    tagNumber.value--;
  }

  function editTrackingLink(button: HTMLSpanElement, trackingLink: Communication.MapperModel.TrackingLink) {
    button.setAttribute('value', `${JSON.stringify(trackingLink)}`);
    button.innerHTML = `${process.env.NODE_ENV === 'development' ? 'dev.' : ''}cdp.cx/${trackingLink.alphabetSeries as string}`;
    button.setAttribute(
      'style',
      `background-color: ${trackingLink.backgroundColor as string}; color: ${
        trackingLink.fontColor as string
      }; cursor:pointer; border-radius:2px; padding:0px 5px; margin:0px 2px; text-decoration: underline; text-decoration-color: ${
        trackingLink.fontColor as string
      };`,
    );
  }
  //#endregion tracking link

  //#region line rich message
  function replaceRichMessageUrl(content: string, richMessage: Communication.LineOA.Component.RichMessage) {
    if (richMessage.image.mapper) {
      content = content.replace(`${richMessage.image.url}&q=imagemap`, richMessage.image.mapper?.tag as string);
    }
    richMessage.gridList.forEach((grid) => {
      if (grid.mapper) {
        content = content.replace(
          (isUrlValid(grid.properties?.linkUrl as string) ? grid.properties?.linkUrl : 'https://' + grid.properties?.linkUrl) as string,
          grid.mapper?.tag as string,
        );
      }
    });

    return content;
  }

  function resolveRichMessageMapperOnUseTemplate(content: string, richMessage: Communication.LineOA.Component.RichMessage) {
    const richMessageObj = richMessage as any;
    if (richMessageObj.mapper && richMessageObj.mapper.length > 0) {
      //source id - dest id เหมือน data blueprint backend
      const listSrcId: number[] = [];
      richMessageObj.mapper.forEach((mapper: Communication.MapperModel.TrackingLink | Communication.MapperModel.TrackingOpen) => {
        listSrcId.push(mapper.tag?.split(/(\d+)/)[1] as unknown as number);
      });
      listSrcId.sort(function (a, b) {
        return a - b;
      });
      let tmpTagNumber = tagNumber.value;
      tmpTagNumber -= listSrcId.length - 1;
      listSrcId
        .slice()
        .reverse()
        .forEach((id) => {
          const target = `{{mp:-${id}}}`;
          content = content.replaceAll(target, `{{mp:${tmpTagNumber++}}}`);
        });
    }

    return JSON.parse(content);
  }
  //#endregion line rich message

  //#region line card message
  function replaceCardMessageUrl(content: string, cardMessage: Communication.LineOA.Component.CardMessge[]) {
    if (cardMessage[0].mapperTrackingOpen) {
      content = content.replace(cardMessage[0].mapperTrackingOpen.linkValue as string, cardMessage[0].mapperTrackingOpen.tag as string);
    }
    cardMessage.forEach((card) => {
      if (card.mapper) {
        content = content.replace(
          (isUrlValid(card.properties?.linkUrl as string) ? card.properties?.linkUrl : 'https://' + card.properties?.linkUrl) as string,
          card.mapper.tag as string,
        );
      }
    });

    return content;
  }

  function filterCardMessageMapper(cardMessage: Communication.LineOA.Component.CardMessge[]) {
    const currentMapper: any = [];
    if (cardMessage[0].mapperTrackingOpen) currentMapper.push(cardMessage[0].mapperTrackingOpen);
    cardMessage.forEach((card) => {
      if (card.mapper) currentMapper.push(card.mapper);
    });

    return currentMapper;
  }

  function resolveCardMessageMapperOnUseTemplate(content: string, cardMessage: Communication.LineOA.Component.CardMessge[]) {
    const cardMessageObj = cardMessage as any;
    if (cardMessageObj.mapper && cardMessageObj.mapper.length > 0) {
      //source id - dest id เหมือน data blueprint backend
      const listSrcId: number[] = [];
      cardMessageObj.mapper.forEach((mapper: Communication.MapperModel.TrackingLink | Communication.MapperModel.TrackingOpen) => {
        listSrcId.push(mapper.tag?.split(/(\d+)/)[1] as unknown as number);
      });
      listSrcId.sort(function (a, b) {
        return a - b;
      });
      let tmpTagNumber = tagNumber.value;
      tmpTagNumber -= listSrcId.length - 1;
      listSrcId
        .slice()
        .reverse()
        .forEach((id) => {
          const target = `{{mp:-${id}}}`;
          content = content.replaceAll(target, `{{mp:${tmpTagNumber++}}}`);
        });
    }

    return JSON.parse(content);
  }
  //#endregion line card message

  //#region util for personalize
  function addActionEditToPersonalizeButton() {
    const lineEditor = document.getElementsByClassName('line-editor');
    for (let i = 0; i < lineEditor.length; i++) {
      let range: any = document.createRange();
      if (lineEditor[i]) {
        function setContent() {
          if (messageList.value.length >= 1) {
            if (!messageList.value[i].audioUrl && !messageList.value[i].imageUrl && !messageList.value[i].videoUrl) {
              const lineEditorContent = lineEditor[i]?.querySelector('.content-editor')?.innerHTML;
              messageList.value[i].message = lineEditorContent;
            }
          }
        }
        //Personalize Action
        const personalizeButton: any = lineEditor[i]?.getElementsByClassName('personalize-button-span');
        for (let i = 0; i < personalizeButton.length; i++) {
          const personalize: Campaign.Personalize = JSON.parse(personalizeButton[i].getAttribute('value') as string);
          personalizeButton[i].onclick = () => {
            personalizeEditMode.value = true;
            personalizeObjColumn.value = personalize;
            isPersonalizeModalOpen.value = true;
            selectedButton.value = personalizeButton[i];
          };
          personalizeButton[i].ondragstart = (e: any) => {
            e.dataTransfer.setData('text/html', e.target.outerHTML);
          };
          personalizeButton[i].ondragend = (e: any) => {
            if (e.dataTransfer.dropEffect === 'copy') {
              personalizeButton[i].remove();
              setContent();
            }
          };
          personalizeButton[i].onmouseup = (e: any) => {
            if (document.caretRangeFromPoint) range = document.caretRangeFromPoint(e.clientX as number, e.clientY as number);
            else {
              // firefox
              const sel = window.getSelection();
              sel?.collapse(e.rangeParent as Node, e.rangeOffset as number);
            }
          };
        }
        //Coupon Action
        const couponButtonList: any = lineEditor[i]?.getElementsByClassName('coupon-button');
        for (let i = 0; i < couponButtonList.length; i++) {
          const couponObj = JSON.parse(couponButtonList[i].getAttribute('value') as string);

          couponButtonList[i].onclick = () => {
            couponEditMode.value = true;
            couponObjColumn.value = couponObj;
            isCouponCodeModalOpen.value = true;
            selectedButton.value = couponButtonList[i];
          };
          couponButtonList[i].ondragstart = (e: any) => {
            e.dataTransfer.setData('text/html', e.target.outerHTML);
          };
          couponButtonList[i].ondragend = (e: any) => {
            if (e.dataTransfer.dropEffect === 'copy') {
              couponButtonList[i].remove();
              setContent();
            }
          };
          couponButtonList[i].onmouseup = (e: any) => {
            if (document.caretRangeFromPoint) range = document.caretRangeFromPoint(e.clientX as number, e.clientY as number);
            else {
              // firefox
              const sel = window.getSelection();
              sel?.collapse(e.rangeParent as Node, e.rangeOffset as number);
            }
          };
        }
        //tracking link Action
        lineEditor[i]?.querySelectorAll<HTMLElement>('.tracking-link-span').forEach((trackingLinkEle) => {
          trackingLinkEle.onclick = () => {
            const trackingLink: Communication.MapperModel.TrackingLink = JSON.parse(trackingLinkEle.getAttribute('value') as string);
            trackingLinkEditMode.value = true;
            trackingLinkObj.value = trackingLink;
            isTrackingLinkModalOpen.value = true;
            selectedTrackingLink.value = trackingLinkEle;
          };
          trackingLinkEle.ondragstart = (e: any) => {
            e.dataTransfer.setData('text/html', e.target.outerHTML);
          };
          trackingLinkEle.ondragend = (e: any) => {
            if (e.dataTransfer.dropEffect === 'copy') {
              trackingLinkEle.remove();
              setContent();
            }
          };
          trackingLinkEle.onmouseup = (e: any) => {
            if (document.caretRangeFromPoint) range = document.caretRangeFromPoint(e.clientX as number, e.clientY as number);
            else {
              // firefox
              const sel = window.getSelection();
              sel?.collapse(e.rangeParent as Node, e.rangeOffset as number);
            }
          };
        });
        setContent();
      }
    }
  }

  const replacePersonalizeTag = (contentHtml: string, isReplaced?: boolean) => {
    const htmlElement = new DOMParser().parseFromString(contentHtml, 'text/html');
    const personalizeButton: any = htmlElement.getElementsByClassName('personalize-button-span');
    const couponButtonList: any = htmlElement.getElementsByClassName('coupon-button');

    //personalize
    for (let i = 0; i < personalizeButton.length; i++) {
      const tagMp = JSON.stringify(JSON.parse(personalizeButton[i].getAttribute('value') as string).tag).replaceAll('"', '');
      if (isReplaced) {
        contentHtml = contentHtml.replace(personalizeButton[i].outerHTML as string, tagMp);
      } else {
        contentHtml = contentHtml.replace(tagMp, `{{mp:${tagNumber.value--}}}`);
      }
    }
    //coupon
    for (let i = 0; i < couponButtonList.length; i++) {
      const tagMp = JSON.stringify(JSON.parse(couponButtonList[i].getAttribute('value') as string).tag).replaceAll('"', '');
      if (isReplaced) {
        contentHtml = contentHtml.replace(couponButtonList[i].outerHTML as string, tagMp);
      } else {
        contentHtml = contentHtml.replace(tagMp, `{{mp:${tagNumber.value--}}}`);
      }
    }
    //tracking link
    htmlElement?.querySelectorAll<HTMLElement>('.tracking-link-span').forEach((trackingLink) => {
      const tagMp = JSON.stringify(JSON.parse(trackingLink.getAttribute('value') as string).tag).replaceAll('"', '');
      if (isReplaced) {
        contentHtml = contentHtml.replace(trackingLink.outerHTML, tagMp);
      } else {
        contentHtml = contentHtml.replace(tagMp, `{{mp:${tagNumber.value--}}}`);
      }
    });
    return contentHtml;
  };

  function filterMapper(contentHtml: string) {
    const currentMapper = [];
    const htmlElement = new DOMParser().parseFromString(contentHtml, 'text/html');
    const personalizeButton: any = htmlElement.getElementsByClassName('personalize-button-span');
    const couponButtonList: any = htmlElement.getElementsByClassName('coupon-button');
    //personalize
    for (let i = 0; i < personalizeButton.length; i++) {
      const mapper: Campaign.Personalize = JSON.parse(personalizeButton[i].getAttribute('value') as string);
      currentMapper.push(mapper);
    }
    //coupon
    for (let i = 0; i < couponButtonList.length; i++) {
      const mapper: Campaign.Personalize = JSON.parse(couponButtonList[i].getAttribute('value') as string);
      currentMapper.push(mapper);
    }
    // tracking link
    htmlElement?.querySelectorAll<HTMLElement>('.tracking-link-span').forEach((trackingLink) => {
      const mapper: Communication.MapperModel.TrackingLink = JSON.parse(trackingLink.getAttribute('value') as string);
      currentMapper.push(mapper);
    });

    return currentMapper;
  }

  function resolveSaveAsTemplateMapper(contents: Campaign.SaveContent[]) {
    if (contents && contents.length) {
      let newTagMp = -1;

      for (let i = 0; i < contents.length; i++) {
        const contentType = JSON.parse(contents[i].Content_Editor).type;
        const mapper = JSON.parse(contents[i].Content_Editor).mapper;

        if (mapper && mapper.length > 0) {
          let content = contents[i].Content;
          let contentEditor = contents[i].Content_Editor;

          if (contentType === 'text') {
            const htmlContentEditor = new DOMParser().parseFromString(decodeURI(JSON.parse(contents[i].Content_Editor).text as string), 'text/html');
            const personalizeButton: any = htmlContentEditor?.querySelectorAll('.personalize-button-span');
            const couponButtonList: any = htmlContentEditor?.querySelectorAll('.coupon-button');
            //personalize
            for (let i = 0; i < personalizeButton.length; i++) {
              const tagMp = JSON.stringify(JSON.parse(personalizeButton[i].getAttribute('value') as string).tag)
                .replaceAll('"', '')
                .replaceAll('{', '')
                .replaceAll('}', '');
              content = content.replace(tagMp, `mp:${newTagMp}`);
              contentEditor = contentEditor.replaceAll(tagMp, `mp:${newTagMp}`);
              newTagMp--;
            }
            //coupon
            for (let i = 0; i < couponButtonList.length; i++) {
              const tagMp = JSON.stringify(JSON.parse(couponButtonList[i].getAttribute('value') as string).tag)
                .replaceAll('"', '')
                .replaceAll('{', '')
                .replaceAll('}', '');
              content = content.replace(tagMp, `mp:${newTagMp}`);
              contentEditor = contentEditor.replaceAll(tagMp, `mp:${newTagMp}`);
              newTagMp--;
            }
            //tracking link
            htmlContentEditor?.querySelectorAll<HTMLElement>('.tracking-link-span').forEach((trackingLinkEle) => {
              const tagMp = JSON.stringify(JSON.parse(trackingLinkEle.getAttribute('value') as string).tag)
                .replaceAll('"', '')
                .replaceAll('{', '')
                .replaceAll('}', '');
              content = content.replace(tagMp, `mp:${newTagMp}`);
              contentEditor = contentEditor.replaceAll(tagMp, `mp:${newTagMp}`);
              newTagMp--;
            });
          }
          if (contentType === 'flex') {
            const cardMessage: Communication.LineOA.Component.CardMessge[] = JSON.parse(contents[i].Content_Editor).list_card;
            let targetMp;
            if (cardMessage[0].mapperTrackingOpen) {
              targetMp = cardMessage[0].mapperTrackingOpen.tag?.replaceAll('"', '').replaceAll('{', '').replaceAll('}', '');
              content = content.replace(targetMp as string, `mp:${newTagMp}`);
              contentEditor = contentEditor.replaceAll(targetMp as string, `mp:${newTagMp}`);
              newTagMp--;
            }
            cardMessage.forEach((card) => {
              if (card.mapper) {
                targetMp = card.mapper?.tag?.replaceAll('"', '').replaceAll('{', '').replaceAll('}', '');
                content = content.replace(targetMp as string, `mp:${newTagMp}`);
                contentEditor = contentEditor.replaceAll(targetMp as string, `mp:${newTagMp}`);
                newTagMp--;
              }
            });
          }
          if (contentType === 'imagemap') {
            const richMessage: Communication.LineOA.Component.RichMessage = JSON.parse(contents[i].Content_Editor);
            let targetMp;
            if (richMessage.image.mapper) {
              targetMp = richMessage.image.mapper?.tag?.replaceAll('"', '').replaceAll('{', '').replaceAll('}', '');
              content = content.replace(targetMp as string, `mp:${newTagMp}`);
              contentEditor = contentEditor.replaceAll(targetMp as string, `mp:${newTagMp}`);
              newTagMp--;
            }
            richMessage.gridList.forEach((grid) => {
              if (grid.mapper) {
                targetMp = grid.mapper?.tag?.replaceAll('"', '').replaceAll('{', '').replaceAll('}', '');
                content = content.replace(targetMp as string, `mp:${newTagMp}`);
                contentEditor = contentEditor.replaceAll(targetMp as string, `mp:${newTagMp}`);
                newTagMp--;
              }
            });
          }
          if (contentType == 'imagemap-video') {
            const richVideoMessage: Communication.LineOA.Component.RichVideoMessage = JSON.parse(contents[i].Content_Editor);

            if (richVideoMessage.mapper) {
              const tagList = richVideoMessage.mapper.map((map) => map.tag);
              tagList.forEach((tag) => {
                // tag จะมีสูงสุดแค่ 2 ตัว
                if (tag) {
                  content = content.replace(new RegExp(tag, 'g'), `{{mp:${newTagMp}}}`);
                  contentEditor = contentEditor.replace(new RegExp(tag, 'g'), `{{mp:${newTagMp}}}`);
                  newTagMp--;
                }
              });
            }
          }
          contents[i].Content = content;
          contents[i].Content_Editor = contentEditor;
        }
      }
    }
  }

  function isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function resolveTagNumber() {
    if (mapper.value?.length > 0) {
      const tagNumList: number[] = [];
      mapper.value.forEach((mapper) => {
        // const replacedTagNumber = +`-${mapper.tag?.replace(/[^0-9]/g, '') as string}`;
        // if (replacedTagNumber < tagNumber.value) {
        //   tagNumber.value = replacedTagNumber;
        // }
        // mapper.tag = `{{mp:${tagNumber.value}}}`;
        // tagNumber.value--;
        tagNumList.push(mapper.tag?.split(/(-?\d+)/)[1] as unknown as number);
        const min = Math.min(...tagNumList);
        if (min > 0) tagNumber.value = 0;
        else if (min < 0) tagNumber.value = min;
        tagNumber.value--;
      });
    }
  }

  function isUrlValid(url: string) {
    try {
      new URL(url);
      return true;
    } catch (err) {
      return false;
    }
  }
  //#endregion util for personalize

  function autoSave() {
    if (!isUpdated.value && !readOnly.value && !isUploading.value) return onSave(false, true);
  }

  function onUploading(uploading: boolean) {
    isUploading.value = uploading;
  }

  watch(
    () => campaignForm.info.name,
    () => {
      validateResult.value = validateForm();
    },
  );

  watch(
    [campaignForm, selectedCustomerList, messageList],
    () => {
      onSelectSegment();
      if (!isLoading.value && !readOnly.value) isUpdated.value = false;
    },
    {
      deep: true,
    },
  );

  watch(activeTab, async () => {
    if (!isUpdated.value && !readOnly.value) {
      await onSave(true);
    } else {
      await fetchCampaignDetail(Number(router.currentRoute.value.query.campaign));
    }

    if (activeTab.value === 3) {
      fetchScheduleInfoPreviewCampaign(Number(router.currentRoute.value.query.campaign));
    }
  });

  onBeforeMount(() => {
    if (router.currentRoute.value.query.campaign) {
      fetchCampaignDetail(Number(router.currentRoute.value.query.campaign));
    }
  });

  onMounted(() => {
    interval.value = setInterval(autoSave, 3600000);
  });

  onBeforeUnmount(() => {
    if (interval.value) {
      clearInterval(interval.value);
    }
  });

  return {
    readOnly,
    campaignForm,
    messageList,
    campaign,
    selectedRecipient,
    isDisabledReload,
    isLoading,
    validateResult,
    activeTab,
    isUpdated,
    isPersonalizeModalOpen,
    isCouponCodeModalOpen,
    isSegmentSelected,
    personalizeObjColumn,
    personalizeEditMode,
    mapper,
    couponEditMode,
    couponObjColumn,
    isCategoryModalOpen,
    isCampaignMode,
    isUnConsentModal,
    isPasswordModal,
    isRemoveUnConsentModal,
    isTrackingLinkModalOpen,
    trackingLinkEditMode,
    trackingLinkObj,
    onSelectEmojiCampaignName,
    onChangeFilterSearch,
    onSelectSender,
    onSelectDateTime,
    onSelectRepeatMode,
    onSelectRepeatOn,
    onInputEndOccurrence,
    onSelectEndDateTime,
    onSelectScheduleType,
    onInputRepeatEvery,
    onSave,
    onAddCustomer,
    onSelectEndMode,
    publishCampaign,
    onPublishCampaign,
    onClickValidation,
    onInputCampaignName,
    addMessage,
    onReorderMessage,
    onDeleteMessage,
    useMessageTemplate,
    onUploadFile,
    onDeleteFile,
    onUploadAudio,
    onSelectPersonalizeNoSegment,
    onClosePersonalizeModal,
    onAddEditPersonalize,
    onSelectPersonalize,
    onSelectCoupon,
    onCloseCouponModal,
    onAddEditCoupon,
    getCursorPosition,
    onUploading,
    onRemoveSegment,
    onApplySelectedCustomer,
    onRemoveWarningInput,
    clearTemplate,
    onClickSaveAsTemplate,
    onCloseCategoryModal,
    inputTemplateName,
    saveAsTemplate,
    onCloseUnConsent,
    onOpenPasswordModal,
    onOpenRemoveUnConsentModal,
    onCloseRemoveUnConsentModal,
    onClosePasswordModal,
    onApplySelectedCustomerUnConsent,
    onValidCodePassword,
    onConfirmRemoveUnConsent,
    onSelectTrackingLinkMenu,
    onCloseTrackingLinkModal,
    onAddEditTrackingLink,
  };
}
